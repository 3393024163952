const updatingSourceReducer = (state = false, action) => {
    switch(action.type) {
        case 'CHANGE_UPDATING':
            state = !state;
            return state;
        default:
            return state;
    }
}

export default updatingSourceReducer;