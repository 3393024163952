/**
 * Defines up to which number of facets should be opened. 
 */
const min_facets_show = 5;

/**
 * Sets the maximum number of sections that should be opened directly. If more sections are available, all sections in the tab are closed.
 */
const min_sections_show = 4;

/**
 * Defines the default colors of portal elements.
 */
const default_color_config = {
    head_color: "#fff",  //head background
    head_item_color: "#000", //head items color (searchbar button and settings dropdown)
    nav_color: "#fff",   //main navbar background (result and article)
    nav_item_color: "#eee",  //deselected navbar items background
    nav_item_active_color: "#bdbdbd",   //selected navbar items background
    nav_item_text_color: "#000", //deselected navbar items text color
    nav_item_active_text_color: "#000",  //selected navbar items text color
    view_button_color: "#fff",  //view buttons background (grid, list, table, felxible)
    view_button_text_color: "#000", //view buttons text color
    add_button_color: "#fff",   //confirm buttons background (save edit, apply new references, download)
    add_button_text_color: "#000",  //confirm buttons text color
    delete_button_color: "#fff",    //delete buttons background ()
    delete_button_text_color: "#000",   //delete buttons text color
    remove_button_color: "#fff",    //remove buttons background (reset edit, remove from download-table, remove from compare list)
    remove_button_text_color: "#000"    //remove buttons text color
    //evtl noch parameter für anzeigen/verbergen und bearbeiten buttons (articlereferenzen)
};

/**
 * Defines the CONIGON default PDF properties.
 */
const default_pdf_config_conigon = {
    page_margin_top: 80,
    page_margin_bottom: 20,
    font_size_register: 18,
    font_size_section: 12,
    font_size_tabletext: 11,
    page_margin_header_top: 30,
    page_header_height: 50,
    fillColor_page_header: "#0A2E39",
    textColor_tabletext: "#888888",
    textColor_section: "#6666BB",
    textColor_register: "#6644DD",
    positionLeft_register: 42,
    columnWidth_fieldLabel: 180,
    logo_width: 150,
    logo_height: 20
};

/**
 * Defines the default PDF properties.
 */
const default_pdf_config = {
    page_margin_top: 80,
    page_margin_bottom: 20,
    font_size_register: 18,
    font_size_section: 12,
    font_size_tabletext: 11,
    page_margin_header_top: 30,
    page_header_height: 50,
    fillColor_page_header: "#DDDDDD",
    textColor_page_header: "#0A2E39",
    textColor_tabletext: "#888888",
    textColor_section: "#6666BB",
    textColor_register: "#6644DD",
    positionLeft_register: 42,
    columnWidth_fieldLabel: 180,
    logo_src: "logo.png",
    logo_type: "image/png",
    logo_width: 150,
    logo_height: 20,
    logo_right: 100,
    headline_left: 350
};

const download_table_limit = 100;

const compare_list_limit = 100;

const active_article_limit = 5;

export { min_facets_show, min_sections_show, default_color_config, default_pdf_config_conigon, default_pdf_config,
    download_table_limit, compare_list_limit, active_article_limit
};