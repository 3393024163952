import React, { Component } from "react";
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { connect } from "react-redux";
import { set_shopping_cart_modal, add_to_shopping_cart, change_item_quantity } from "../actions";
import { translate, createHTMLElement, getPrice, getProductByID, checkPicture } from "../utils/Utils";
import "../Home.css";
import PicturePreview from "./PicturePreview";

const mapStateToProps = (state) => {
    return {
        shoppingCartModal: state.shoppingCartModal,
        shoppingCartModalItem: state.shoppingCartModalItem,
        shoppingCart: state.shoppingCart,
        fetchedProducts: state.fetchedProducts,
        portalConfig: state.portalConfig
    };
};

const mapDispatchToProps = () => {
    return {
        set_shopping_cart_modal,
        add_to_shopping_cart,
        change_item_quantity
    };
};

class ShoppingCartModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1
        };
        this.isNavMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
    }

    changeAmount(event) {
        this.setState({
            value: event.target.value
        });
    }

    getShoppingCartIndex(item_id) {
        const shoppingCart = this.props.shoppingCart;
        for (var i = 0; i < shoppingCart.length; i++) {
            if (shoppingCart[i].id_ === item_id) {
                return i;
            }
        }
        return null;
    }

    addToShoppingCart() {
        const item_id = this.props.shoppingCartModalItem;
        const shoppingCart = this.props.shoppingCart;
        let index = this.getShoppingCartIndex(item_id);
        if (index !== null) {
            if (shoppingCart[index].quantity !== this.state.value) {
                this.props.change_item_quantity(index, this.state.value);
                this.toggleModal();
            } else {
                this.toggleModal();
            }
        } else {
            let product = {};
            product.id_ = item_id;
            product.quantity = this.state.value;
            this.props.add_to_shopping_cart(product);
            this.toggleModal();
        }
    }

    toggleModal() {
        this.setState({
            value: 1
        });
        this.props.set_shopping_cart_modal();
    }

    handleKeyDown(event) {
        if (event.key === "Enter") {
            this.addToShoppingCart();
        }
    }

    createField(item, field) {
        const portalConfig = this.props.portalConfig;
        let htmlElement = createHTMLElement(item, field, portalConfig.reference_user, portalConfig.reference_password, portalConfig.reference_url);
        return (
            <div className="img" dangerouslySetInnerHTML={htmlElement.html} />
        );
    }

    getPrice(item) {
        if (item === null || item === undefined) {
            return 0;
        }
        if (item.includes(translate("currency"))) {
            return item.substring(0, item.length - 2);
        }
        return item;
    }

    render() {
        const portalConfig = this.props.portalConfig;
        const label_field = portalConfig.label_field;
        const image_field = portalConfig.image_field;
        const item = getProductByID(this.props.fetchedProducts, this.props.shoppingCartModalItem);
        if (item === null) {
            return null;
        } else {
            return (
                <MDBModal
                    className="shoppingcart-modal"
                    isOpen={this.props.shoppingCartModal}
                    toggle={() => this.toggleModal()}
                >
                    <MDBModalHeader
                        toggle={() => this.toggleModal()}
                    >
                        {translate("add_to_cart", "article")}
                    </MDBModalHeader>
                    <MDBModalBody className="body-container">
                        <MDBRow center>
                            {!image_field.includes(":") ?
                                <PicturePreview
                                    item={item}
                                    view="shopping-cart-modal"
                                />
                                :
                                <div className={this.isNavMobileSize ? "img-transform" : ""}>
                                    {checkPicture(item)}
                                </div>
                            }
                        </MDBRow>
                        <MDBRow className="list-row">
                            <MDBCol>
                                {label_field + ":"}
                            </MDBCol>
                            <MDBCol>
                                {item[label_field]}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="list-row">
                            <MDBCol>
                                {translate("sum")}:
                            </MDBCol>
                            <MDBCol>
                                {getPrice(item[portalConfig.price_field])} {translate("currency")}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="list-row">
                            <MDBCol>
                                {translate("amount")}:
                            </MDBCol>
                            <MDBCol>
                                <input
                                    min="1"
                                    type="number"
                                    value={this.state.value}
                                    autoFocus
                                    onChange={event => this.changeAmount(event)}
                                    onKeyDown={event => this.handleKeyDown(event)}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.addToShoppingCart()}
                        >
                            {translate("add_to_cart", "article")}
                        </button>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.toggleModal()}
                        >
                            {translate("close")}
                        </button>
                    </MDBModalFooter>
                </MDBModal>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(ShoppingCartModal);