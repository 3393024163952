import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { deepEqual, getProductByID, getUsageRequiredFields, saveEditStore, translate } from "../../utils/Utils";
import PropTypes from "prop-types";
import RequiredTextInput from "../RequiredTextInput";
import { read, utils } from "xlsx";
import { fetchProducts } from "../../utils/Webservice";
import store from "../../store/store";
import deepmerge from "deepmerge";
import "../../Home.css";

class ImportProductsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            validInputCounter: 0,
            requiredFields: getUsageRequiredFields(),
            overwriteFields: [],
            blob: null,
            notEqualFields: {},
            fileLoaded: false,
            missingProducts: [],
            missingMetaProducts: [],
            missingProductsAction: "",
            importProducts: [],
            requiredInputs: {}
        };
        this.changeValidation = this.changeValidation.bind(this);
    }

    closeModal() {
        if (!this.state.loading) {
            this.props.close(this.props.modalID);
            this.setState({
                validInputCounter: 0,
                overwriteFields: [],
                fileLoaded: false,
                notEqualFields: {},
                missingProducts: [],
                missingMetaProducts: [],
                missingProductsAction: "",
                importProducts: [],
                requiredInputs: {}
            });
        }
    }

    changeValidation(offset, key, value) {
        let required_inputs = Object.assign({}, this.state.requiredInputs);
        if (value) {
            required_inputs[key] = value;
        }/* else {
            if (required_inputs.hasOwnProperty(key)) {

            } else {

            }
        }*/
        this.setState({
            validInputCounter: this.state.validInputCounter + offset,
            requiredInputs: required_inputs
        });
    }

    changeOverwrite(key) {
        const { overwriteFields } = this.state;
        if (overwriteFields.includes(key)) {
            this.setState({
                overwriteFields: [...overwriteFields].filter(field_key => field_key !== key)
            });
        } else {
            let overwrite_fields = [...overwriteFields];
            overwrite_fields.push(key);
            this.setState({
                overwriteFields: overwrite_fields
            });
        }
    }

    readUploadFile(e) {
        e.preventDefault();
        if (e.target.files.length) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = read(data, { type: "array" });
                //console.log(workbook);
                let subtable_config = {};
                let import_products_fields = [];
                let products = {};
                let not_equal_fields = {};
                let missing_products = [];
                let import_products = [];
                let missing_meta_products = [];
                for (let sheet_name of workbook.SheetNames) {
                    const worksheet = workbook.Sheets[sheet_name];
                    const json_array = utils.sheet_to_json(worksheet);
                    //console.log(json_array);
                    for (let excel_row of json_array) {
                        if (sheet_name === "meta") {
                            const meta_data = JSON.parse(Buffer.from(excel_row.data, "base64").toString());
                            if (meta_data.hasOwnProperty("products")) {
                                //console.log("import_template: ", meta_data);
                                for (let meta_product of meta_data.products) {
                                    //console.log(meta_product);
                                    //console.log(products[meta_product.ID]);
                                    if (products.hasOwnProperty(meta_product.ID)) {
                                        //const import_product = deepmerge(meta_product, products[meta_product.ID]);
                                        const import_product = this.getMergedProduct(meta_product, products[meta_product.ID]);
                                        //console.log(import_product);
                                        import_products.push(import_product);
                                    } else {
                                        //console.log(meta_product);
                                        //const keys = Object.keys(products);
                                        //console.log(keys);
                                        missing_meta_products.push(meta_product);
                                    }
                                }
                                for (let product of import_products) {
                                    const not_equal_field = this.compareImportedProductWithExistingProduct(product, meta_data.field_list);
                                    if (not_equal_field.length) {
                                        not_equal_fields[product.ID] = not_equal_field;
                                    }
                                }
                                //console.log(not_equal_fields);
                            } else {
                                //console.log("subtable_config: ", meta_data);
                            }
                            //subtable_config = Object.assign(subtable_config, meta_data);
                        } else if (sheet_name === "Subtables") {
                            //console.log("Subtable-row: ", excel_row);
                        } else {
                            //console.log("product: ", excel_row);
                            if (!products.hasOwnProperty(excel_row.ID)) {
                                products[excel_row.ID] = excel_row;
                            } else {
                                console.log("products.hasOwnProperty(excel_row.ID)", excel_row.ID);
                            }
                        }
                    }
                }
                if (missing_meta_products.length/*Object.keys(products).length > import_products.length*/) {
                    //console.log(products);
                    //console.log(import_products);
                    //console.log(missing_meta_products);
                    missing_products = Object.keys(products).filter(key => !import_products.map(product => product.ID).includes(key)).map(key => products[key]);
                    //console.log(missing_products);
                }
                /*if (missing_products.length) {
                    console.log(missing_products);
                    console.log(products);
                    console.log(import_products);
                }*/
                console.log(not_equal_fields);
                this.setState({
                    fileLoaded: true,
                    notEqualFields: not_equal_fields,
                    missingProducts: missing_products,
                    missingMetaProducts: missing_meta_products,
                    importProducts: import_products
                });
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        } else {
            this.setState({
                validInputCounter: 0,
                overwriteFields: [],
                fileLoaded: false,
                notEqualFields: {},
                missingProducts: [],
                missingMetaProducts: [],
                missingProductsAction: "",
                importProducts: [],
                requiredInputs: {}
            });
        }
    }

    getMergedProduct(meta_product, import_product) {
        const { importProducts } = this.state;
        let merged_product = {};
        //console.log(meta_product);
        //console.log(import_product);
        for (let key of Object.keys(meta_product)) {
            if (key.includes(":")) {
                if (key.includes(":JSON")) {
                    console.log("JSON fehlt");
                    //merged_product[key] = meta_product[key];
                } else {
                    console.log("fehlt");
                }
            } else {
                let is_equal = true;
                if (import_product.hasOwnProperty(key)) {
                    const meta_data = meta_product[key];
                    const import_data = import_product[key];
                    if (typeof (meta_data) === typeof (import_data)) {
                        is_equal = deepEqual(meta_data, import_data);
                        if (is_equal) {
                            merged_product[key] = meta_product[key];
                        } else {
                            console.log("fehlt");
                        }
                    } else {
                        if (typeof (meta_data) === "object") {
                            if (Array.isArray(meta_data)) {
                                const import_data_arr = import_data.toString().split(",");
                                is_equal = deepEqual(meta_data, import_data_arr);
                                if (is_equal) {
                                    merged_product[key] = meta_product[key];
                                } else {
                                    merged_product[key] = import_data_arr;
                                }
                            } else {
                                console.log("fehlt");
                            }
                        } else if (typeof (meta_data) === "boolean") {
                            console.log("fehlt");
                        } else if (typeof (meta_data) === "number") {
                            console.log("fehlt");
                        } else {
                            is_equal = meta_data === import_data.toString();
                            if (is_equal) {
                                merged_product[key] = meta_product[key];
                            } else {
                                console.log("fehlt");
                            }
                        }
                    }
                } else {
                    merged_product[key] = meta_product[key];
                }
            }
        }
        //console.log(merged_product);
        return merged_product;
    }

    compareImportedProductWithExistingProduct(import_product, field_list) {
        const { fetchedProducts, portalConfig } = store.getState();
        const { keyfield } = portalConfig;
        const saved_product = getProductByID(fetchedProducts, import_product[keyfield]);
        let not_equal_fields = [];
        for (let field of field_list) {
            if (field.dataField.includes(":JSON")) {
                console.log("json fehlt");
            } else {
                //const saved_product_data = saved_product[field.dataField];
                const import_product_data = import_product[field.dataField];
                if (saved_product.hasOwnProperty(field.dataField)) {
                    let is_equal = deepEqual(saved_product[field.dataField], import_product_data);
                    if (!is_equal) {
                        not_equal_fields.push({
                            "key": field.dataField,
                            "saved_value": saved_product[field.dataField],
                            "import_value": import_product[field.dataField]
                        });
                    } else {
                        //console.log(true);
                    }
                } else {
                    if ((!saved_product.hasOwnProperty(field.dataField) && import_product_data === "")) {
                        //console.log(true);
                    } else {
                        not_equal_fields.push(field.dataField);
                    }
                }
            }
        }
        return not_equal_fields;
    }

    handleMissingProductsButton(action) {
        this.setState({
            missingProductsAction: action
        });
    }

    getChangedProduct(field, import_product, changes) {
        const dataField = field.dataField;
        let changed_product = Object.assign({}, import_product);
        if (Array.isArray(import_product[dataField])) {
            if (changes[dataField].includes(",")) {
                changed_product[dataField] = changes[dataField].split(",");
            } else {
                changed_product[dataField] = [changes[dataField]];
            }
        } else {
            changed_product[dataField] = changes[dataField];
        }
    }

    importData() {
        const { notEqualFields, requiredFields, missingProductsAction, overwriteFields, importProducts, requiredInputs } = this.state;
        const { fetchedProducts, portalConfig } = store.getState();
        const { keyfield } = portalConfig;
        console.log("import", importProducts);
        //!this.state.fileLoaded || requiredFields.length > validInputCounter || (this.state.missingProducts.length && !this.state.missingProductsAction)
        let changed_products = [];
        for (let import_product of importProducts) {
            let changed_product = Object.assign({}, import_product);
            for (let required_field of requiredFields) {
                const dataField = required_field.dataField;
                if (Array.isArray(import_product[dataField])) {
                    if (requiredInputs[dataField].includes(",")) {
                        changed_product[dataField] = requiredInputs[dataField].split(",");
                    } else {
                        changed_product[dataField] = [requiredInputs[dataField]];
                    }
                } else {
                    changed_product[dataField] = requiredInputs[dataField];
                }
                //changed_product = Object.assign({}, this.getChangedProduct(required_field, import_product, requiredInputs));
                if (!overwriteFields.length) {
                    saveEditStore(changed_product[keyfield], required_field, changed_product[dataField]);  //TODO: muss noch verbessert werden
                }
            }
            console.log(notEqualFields);
            console.log(overwriteFields);
            for (let overwrite_field of overwriteFields) {
                /*const dataField = overwrite_field.dataField;
                if (Array.isArray(import_product[dataField])) {
                    if (requiredInputs[dataField].includes(",")) {
                        changed_product[dataField] = requiredInputs[dataField].split(",");
                    } else {
                        changed_product[dataField] = [requiredInputs[dataField]];
                    }
                } else {
                    changed_product[dataField] = requiredInputs[dataField];
                }*/
                //changed_product = Object.assign({}, this.getChangedProduct());
            }
            changed_products.push(changed_product);
            //console.log(changed_product);
        }
        console.log(changed_products);
        this.closeModal();
    }

    render() {
        const { loading, requiredFields, validInputCounter, overwriteFields } = this.state;
        const { isOpen } = this.props;
        const header_text = this.props.headerText ? this.props.headerText : "";
        const body_text = this.props.bodyText ? this.props.bodyText : "";
        const body_loading_text = this.props.bodyLoadingText ? this.props.bodyLoadingText : "";
        //console.log(overwriteFields);
        return (
            <MDBModal
                className={loading ? "new-products-modal new-products-modal-loading" : "new-products-modal"}
                isOpen={isOpen}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    {header_text}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer className="body">
                        {requiredFields.map(field => {
                            return (
                                <MDBRow className="mb-3">
                                    <MDBCol>
                                        {field.dataField}
                                    </MDBCol>
                                    <MDBCol>
                                        <RequiredTextInput
                                            inputID={field.dataField.replace(/\s/g, "").toLowerCase()}
                                            changeValidation={this.changeValidation}
                                            fieldKey={field.dataField}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            );
                        })}
                        <MDBRow center>
                            <div>
                                <input
                                    type="file"
                                    onChange={e => this.readUploadFile(e)}
                                />
                            </div>
                        </MDBRow>
                        {Object.keys(this.state.notEqualFields).length ?
                            <MDBTable className="import-products-modal-table">
                                <MDBTableHead>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            Feld
                                        </th>
                                        <th>
                                            Gespeicherte Werte
                                        </th>
                                        <th>
                                            Importierte Werte
                                        </th>
                                        <th />
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {Object.keys(this.state.notEqualFields).map(key => (
                                        this.state.notEqualFields[key].map(obj => (
                                            <tr>
                                                <td>
                                                    {key}
                                                </td>
                                                <td>
                                                    {obj["key"]}
                                                </td>
                                                <td>
                                                    {obj["saved_value"]}
                                                </td>
                                                <td>
                                                    {obj["import_value"]}
                                                </td>
                                                <td>
                                                    <div class="custom-control custom-checkbox" /*style={{ marginTop: "5px" }}*/>
                                                        <input type="checkbox" class="custom-control-input" id={obj["key"] + "-overwrite-checkbox"}
                                                            checked={overwriteFields.includes(obj["key"])}
                                                            onChange={() => this.changeOverwrite(obj["key"])}
                                                        />
                                                        <label class="custom-control-label" for={obj["key"] + "-overwrite-checkbox"}>Overwrite</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                            :
                            null
                        }
                        {this.state.missingProducts.length ?
                            <div>
                                <div style={{ color: "red" }}>
                                    In der Import-Datei wurde die ID folgender Daten verändert:
                                </div>
                                <ul>
                                    {this.state.missingProducts.map(product => (
                                        <li>
                                            {product.ID}
                                        </li>
                                    ))}
                                </ul>
                                <div>
                                    Diese Daten ignorieren oder abspeichern?
                                </div>
                                <button
                                    className="btn btn-sm"
                                    onClick={() => this.handleMissingProductsButton("ignore")}
                                    disabled={this.state.missingProductsAction === "ignore"}
                                >
                                    Ignorieren
                                </button>
                                <button
                                    className="btn btn-sm"
                                    onClick={() => this.handleMissingProductsButton("save")}
                                    disabled={this.state.missingProductsAction === "save"}
                                >
                                    Abspeichern
                                </button>
                            </div>
                            :
                            null
                        }
                        {this.state.fileLoaded ?
                            Object.keys(this.state.notEqualFields).length ?
                                overwriteFields.length ?
                                    <div>
                                        Möchten Sie die gespeicherten Daten durch den Import überschreiben?
                                    </div>
                                    :
                                    <div>
                                        Wenn Sie gespeicherte Daten überschreiben wollen, wählen Sie die Felder aus.
                                    </div>
                                :
                                requiredFields.length > validInputCounter ?
                                    <div>
                                        requiredFields fehlt
                                    </div>
                                    :
                                    this.state.missingProducts.length && !this.state.missingProductsAction ?
                                        <div>
                                            missingProductsAction fehlt
                                        </div>
                                        :
                                        <div>
                                            bereit zum import
                                        </div>
                            :
                            <div>
                                Bitte importieren Sie eine Datei.
                            </div>
                        }
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.importData()}
                        //disabled={requiredFields.length > validInputCounter}
                        disabled={!this.state.fileLoaded || requiredFields.length > validInputCounter || (this.state.missingProducts.length && !this.state.missingProductsAction)}
                    >
                        {"Importieren"}
                    </button>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    >
                        {"Abbrechen"}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

ImportProductsModal.propTypes = {
    //close: PropTypes.func.isRequired,
    //fileName: PropTypes.string.isRequired
};

export default ImportProductsModal;