import React, { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { connect } from "react-redux";
import { change_delete_product_modal_status } from "../actions";
import { translate } from "../utils/Utils";

const mapStateToProps = (state) => {
    return {
        openDeleteProductModal: state.openDeleteProductModal
    };
};

const mapDispatchToProps = () => {
    return {
        change_delete_product_modal_status
    };
};

class DeleteProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    closeModal() {
        this.props.change_delete_product_modal_status();
    }

    confirmDeleteProduct() {
        this.closeModal();
        this.props.confirmDeleteProduct();
    }
    
    render() {
        return (
            <div>
                <MDBModal
                    isOpen={this.props.openDeleteProductModal}
                    toggle={() => this.closeModal()}
                >
                    <MDBModalHeader
                        toggle={() => this.closeModal()}
                    >
                        {translate("delete_product")}
                    </MDBModalHeader>
                    <MDBModalBody className="mt-2">
                        {translate("ask_delete_product")}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.confirmDeleteProduct()}
                        >
                            {translate("yes")}
                        </button>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.closeModal()}
                        >
                            {translate("no")}
                        </button>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(DeleteProductModal);