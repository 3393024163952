const downloadTableModalPropsReducer = (state = { downloadTableFields: [], openModal: false, productIDs: [], templates: { default: [] }, active_template: "" }, action) => {
    switch (action.type) {
        case 'SET_DOWNLOAD_TABLE_TEMPLATE':
            state.templates[action.payload.template_name] = action.payload.download_table_fields;
            state.active_template = action.payload.template_name;
            if (action.payload.delete_template_name) {
                delete state.templates[action.payload.delete_template_name];
            }
            //state.downloadTableFields = action.payload;
            return state;
        case 'CHANGE_DOWNLOAD_TABLE_MODAL_STATUS':
            state = Object.assign({}, state);
            state.openModal = !state.openModal;
            return state;
        case 'ADD_DOWNLOAD_TABLE_PRODUCT':
            state.productIDs.push(action.payload);
            return state;
        case 'SET_DOWNLOAD_TABLE_PRODUCTS':
            state = Object.assign({}, state);
            state.productIDs = action.payload;
            return state;
        case 'SET_DOWNLOAD_TABLE_ACTIVE_TEMPLATE':
            //state = Object.assign({}, state);
            state.active_template = action.payload;
            return state;
        case 'DELETE_DOWNLOAD_TABLE_TEMPLATE':
            delete state.templates[action.payload];
            state.active_template = "default";

            /*state.templates[action.payload.template_name] = action.payload.download_table_fields;
            state.active_template = action.payload.template_name;
            if (action.payload.delete_template_name) {
                delete state.templates[action.payload.delete_template_name];
            }*/
            return state;
        default:
            return state;
    }
}

export default downloadTableModalPropsReducer;