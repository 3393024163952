import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow } from "mdbreact";
import { translate } from "../../utils/Utils";

class SimpleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    closeModal() {
        if (!this.state.loading) {
            this.props.close(this.props.modalID);
        }
    }

    actionClick() {
        this.props.action();
        if (this.props.useLoading) {
            this.setState({
                loading: true
            });
        }
    }

    render() {
        const header_text = this.props.headerText ? this.props.headerText : "";
        const body_text = this.props.bodyText ? this.props.bodyText : "";
        const body_loading_text = this.props.bodyLoadingText ? this.props.bodyLoadingText : "";
        return (
            <MDBModal
                className={this.state.loading ? "simple-modal simple-modal-loading" : "simple-modal"}
                isOpen={this.props.isOpen}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    {header_text}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer className="body">
                        <MDBRow>
                            {body_text}
                        </MDBRow>
                        {body_loading_text && this.state.loading ?
                            <MDBRow className="mt-4">
                                <text style={{color: "red"}}>
                                    {body_loading_text}
                                </text>
                            </MDBRow>
                            :
                            null
                        }
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    {this.props.modalID === "stillWorkedOnModal" ? null :
                        <button
                            className="btn btn-sm"
                            onClick={() => this.actionClick()}
                            disabled={this.state.loading}
                        >
                            {translate("yes")}
                        </button>
                    }
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                        disabled={this.state.loading}
                    >
                        {this.props.modalID === "stillWorkedOnModal" ? "OK" : translate("no")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default SimpleModal;