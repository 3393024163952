import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactiveList } from "@appbaseio/reactivesearch";
import {
    MDBRow, MDBContainer
} from "mdbreact";
import PropTypes from "prop-types";
import { checkAndLoadReferences, getListItemFields } from "../../utils/functions";
import "../../Home.css";
import ListItem from "./ListItem";
import { deepEqual } from "../../utils/Utils";

const { ResultListWrapper } = ReactiveList;

const mapStateToProps = (state) => {
    return {
        loadedImages: state.loadedImages
    };
};

const mapDispatchToProps = () => {
    return {

    };
};

class ListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
        this.listItemFields = getListItemFields();
    }

    componentDidMount() {
        const { data } = this.props;
        checkAndLoadReferences(data, "img");
    }

    componentDidUpdate() {
        const { data } = this.props;
        if (!deepEqual(data, this.state.data)) {
            checkAndLoadReferences(data, "img");
            this.setState({
                data
            });
        }
    }

    render() {
        const { data, isNewReferenceModal } = this.props;
        return (
            <MDBContainer fluid>
                <MDBRow className="results">
                    <ResultListWrapper className="result-list">
                        {data.map(item => (
                            <ListItem
                                item={item}
                                isNewReferenceModal={isNewReferenceModal}
                                notify={this.props.notify}
                                listItemFields={this.listItemFields}
                            />
                        ))}
                    </ResultListWrapper>
                </MDBRow>
            </MDBContainer>
        );
    }
}

ListView.propTypes = {
    data: PropTypes.array.isRequired,
    isNewReferenceModal: PropTypes.bool,
    notify: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps())(ListView);