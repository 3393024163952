import React, { Component } from "react";
import { connect } from "react-redux";
import {
    MDBCol, MDBRow, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBIcon, MDBCollapse, MDBCard, MDBCollapseHeader,
    MDBCardBody, MDBTabPane, MDBContainer
} from "mdbreact";
import {
    set_active_article, set_shopping_cart_modal, set_shopping_cart_modal_item, set_compare_modal,
    add_compare_item, switch_edit, clear_edit, change_new_copied_product_modal_status, set_new_copied_product,
    change_delete_product_modal_status, delete_product_by_id, set_active_section,
    change_open_all_sections_status, open_subtable_modal, set_active_section_modal
} from "../actions";
import {
    getTableText, getArticleRegisterTabList, getSectionList, getSectionContent, createHTMLElement,
    translate, getField, checkArrayIncludesValue, getTableRowIDField, getTimestamp,
    openNewCopiedProductTab, getProductByID, getButtons, checkPicture, getReferenceFiles
} from "../utils/Utils";
import ArticleReferences from "./ArticleReferences";
import "../css/Article.css";
import "../Home.css";
import SubTable from "./SubTable";
import { min_sections_show } from "../utils/constants";
import PDFPreview from "./PDFPreview";
import RefFilePicturePreview from "./RefFilePicturePreview";
import PicturePreview from "./PicturePreview";
import DownloadDocButton from "./DownloadDocButton";
import { checkAndLoadReferences, createDataField } from "../utils/functions";
import ReferencePicturePreview from "./ReferencePicturePreview";

var refs = {};

const mapStateToProps = (state) => {
    return {
        articleTabs: state.articleTabs,
        shoppingCart: state.shoppingCart,
        fetchedProducts: state.fetchedProducts,
        compareItems: state.compareItems,
        language: state.language,
        edit: state.edit,
        saveChanges: state.saveChanges,
        editedFields: state.editedFields,
        uploaded: state.uploaded,
        selectFields: state.selectFields,
        portalConfig: state.portalConfig,
        openAllSections: state.openAllSections,
        subtables: state.subtables,
        newCopiedProducts: state.newCopiedProducts,
        changeIndexFields: state.changeIndexFields,
        updateSourceFields: state.updateSourceFields,
        newReferenceModalProps: state.newReferenceModalProps,
        loadedImages: state.loadedImages
    };
};

const mapDispatchToProps = () => {
    return {
        set_active_article,
        set_shopping_cart_modal,
        set_shopping_cart_modal_item,
        set_compare_modal,
        add_compare_item,
        switch_edit,
        clear_edit,
        change_new_copied_product_modal_status,
        set_new_copied_product,
        change_delete_product_modal_status,
        delete_product_by_id,
        set_active_section,
        change_open_all_sections_status,
        open_subtable_modal,
        set_active_section_modal
    };
};

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changed_subtable: false,
            sections_opened: this.getSectionsOpened(),
            numPages: 1,
            pageNumber: 1,
            clicked_ref_files: [],
            activeRefFile: "",
            pic_loaded: false
        };
        this.isNavMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
        this.registerTabList = getArticleRegisterTabList(this.props.portalConfig.field_list);
        this.handleUpdateSubtable = this.handleUpdateSubtable.bind(this);
        this.product = getProductByID(this.props.fetchedProducts, this.props.item_id);
        console.log(this.props.item_id);
    }

    componentDidMount() {
        //console.log(this.product);
        //const { data } = this.props;
        checkAndLoadReferences([this.product]);
    }

    getSectionsOpened() {
        const { portalConfig, item_id } = this.props;
        const { field_list, open_all_sections_button } = portalConfig;
        const registerTabList = getArticleRegisterTabList(field_list);
        let sections_opened = [];
        for (let tab of registerTabList) {
            const sectionList = getSectionList(tab);
            let tab_sections_with_content = [];
            for (let section of sectionList) {
                const section_content = getSectionContent(tab, section, item_id);
                if (section_content.length) {
                    tab_sections_with_content.push(section);
                }
            }
            if (tab_sections_with_content.length <= min_sections_show && open_all_sections_button) {
                sections_opened = sections_opened.concat(tab_sections_with_content);
            }
        }
        //console.log(sections_opened);
        return sections_opened;
    }

    getArticleTabsContentObject(tab_list) {
        const { item_id } = this.props;
        let article_tabs_content_object = {};
        for (let tab of tab_list) {
            const sectionList = getSectionList(tab);
            for (let section of sectionList) {
                const section_content = getSectionContent(tab, section, item_id);
                if (section_content.length !== 0) {
                    if (article_tabs_content_object[tab]) {
                        article_tabs_content_object[tab] = Object.assign(article_tabs_content_object[tab], {
                            [section]: section_content
                        });
                    } else {
                        article_tabs_content_object[tab] = {
                            [section]: section_content
                        };
                    }
                }
            }
        }
        console.log(article_tabs_content_object);
        return article_tabs_content_object;
    }

    toggleArticle(tab) {
        const { articleTabs, isNewReferenceModal, newReferenceModalProps } = this.props;
        if (isNewReferenceModal && newReferenceModalProps.articleTabs.active_section !== tab) {
            this.props.set_active_section_modal(tab);
        } else if (!isNewReferenceModal && articleTabs.active_section !== tab) {
            this.props.set_active_section(tab);
        }
    };

    getArticleNavItem(tab) {
        if (this.getArticleTabsContentObject(this.registerTabList)[tab]) {
            const { articleTabs, isNewReferenceModal, newReferenceModalProps, item_id } = this.props;
            const active_section = isNewReferenceModal ? newReferenceModalProps.articleTabs.active_section : articleTabs.active_section;
            return (
                <MDBNavItem key={item_id + "-" + tab + "-tab"}>
                    <MDBNavLink className={active_section === tab ? "article-tabs-active" : "article-tabs"}
                        link to="#" active={active_section === tab} onClick={() => this.toggleArticle(tab)} role="tab"
                    >
                        {tab.trim() === "" ? "NO_NAME" : tab}
                    </MDBNavLink>
                </MDBNavItem>
            );
        } else {
            return null;
        }
    }

    getArticleContent(tab) {
        const { item_id } = this.props;
        let tab_content_object = {};
        const sectionList = getSectionList(tab);
        for (let section of sectionList) {
            const section_content = getSectionContent(tab, section, item_id);
            if (section_content.length) {
                tab_content_object[section] = section_content;
            }
        }
        if (Object.keys(tab_content_object).length) {
            return (
                <MDBTabPane key={item_id + "-" + tab + "-tab-pane"} tabId={tab} role="tabpanel">
                    {this.getTabContent(tab_content_object, sectionList)}
                </MDBTabPane>
            );
        } else {
            return null;
        }
    }

    toggleCollapse(section) {
        let new_sections_opened = [];
        if (checkArrayIncludesValue(this.state.sections_opened, section)) {
            new_sections_opened = this.state.sections_opened.filter(section_opened => section_opened !== section);
        } else {
            for (let section_opened of this.state.sections_opened) {
                new_sections_opened.push(section_opened);
            }
            new_sections_opened.push(section);
        }
        this.setState({
            sections_opened: new_sections_opened
        });
    }

    addToCompareList(item_id) {
        if (!this.props.compareItems.includes(item_id)) {
            this.props.add_compare_item(item_id);
            this.props.notify("compare_success");
        } else {
            this.props.notify("compare_info")
        }
    }

    handleUpdateSubtable() {
        this.setState({
            changed_subtable: true
        });
    }

    onDocumentLoadSuccess(pdf) {
        console.log(pdf);
        this.setState({
            numPages: pdf.numPages
        });
    }

    createField(field) {
        const { portalConfig, isNewProduct, isNewReferenceModal, loadedImages } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        const item = this.product;
        let value = item[field.dataField];
        if (field.dataField.includes(":REFERENCE")) {
            if (item[field.dataField]) {
                const product_data = item[field.dataField];
                const file_obj = JSON.parse(product_data)[0];
                const file_id = file_obj.TargetID;
                if (file_obj.TYPE.includes("DAM") && loadedImages.hasOwnProperty(file_id)) {
                    return (
                        <ReferencePicturePreview
                            fileID={file_id}
                            src={loadedImages[file_id]}
                        />
                    );
                } else {
                    //console.log(field.dataField);
                    //return null;
                }
            }
            const htmlElement = createHTMLElement(item, field.dataField, reference_user, reference_password, reference_url);
            refs[field.dataField] = htmlElement.arr;
            value = htmlElement.html.__html;
        }
        return getField(item, field, value, isNewProduct, isNewReferenceModal);
    }

    openCopyProductModal(product_id) {
        openNewCopiedProductTab(product_id);
        //this.props.change_new_copied_product_modal_status();
        //this.props.set_new_copied_product(product_id);
    }

    openDeleteProductModal(product_id) {
        this.props.change_delete_product_modal_status();
        this.props.delete_product_by_id(product_id);
    }

    createNewSubtableRow(field, edited_subtable) {
        const subtable = edited_subtable ? edited_subtable : [];
        let new_subtable = [];
        let new_row = {};
        let table_row_id_field = "";
        let new_id = getTimestamp(true);
        for (let json_field of this.props.portalConfig.field_list_json) {
            if (json_field.hasOwnProperty(field.dataField)) {
                table_row_id_field = getTableRowIDField(json_field[field.dataField]);
                if (!edited_subtable || !edited_subtable.length) {
                    for (let sub_field of json_field[field.dataField]) {
                        if (sub_field.usage.includes("ID")) {
                            new_row[sub_field.dataField] = new_id;
                        } else {
                            new_row[sub_field.dataField] = "";
                        }
                    }
                }
            }
        }
        if (edited_subtable && edited_subtable.length) {
            for (let key of Object.keys(subtable[0])) {
                if (key === table_row_id_field) {
                    new_row[key] = new_id;
                } else {
                    new_row[key] = "";
                }
            }
        }
        new_subtable.push(new_row);
        for (let table_row of subtable) {
            new_subtable.push(table_row);
        }
        this.props.open_subtable_modal(this.props.item_id, new_row, new_subtable, new_id, field, table_row_id_field, true);
    }

    checkEditFieldIncludesField(field) {
        const { editedFields, item_id, language } = this.props;
        for (var i = 0; i < editedFields.length; i++) {
            if (editedFields[i]["ID"].toString() === item_id.toString()) {
                if (editedFields[i]["language"] === language) {
                    if (editedFields[i]["changes"].hasOwnProperty(field.editField ? field.editField : field.dataField)) {
                        return editedFields[i]["changes"][field.editField ? field.editField : field.dataField];
                    }
                }
            }
        }
        return null;
    }

    checkSubtableExists(field) {
        const item = this.product;
        const edited_subtable = this.checkEditFieldIncludesField(field);
        /*if (edited_subtable) {
            return edited_subtable;
        } else if (item[field.dataField] && JSON.parse(item[field.dataField]) && JSON.parse(item[field.dataField]).length) {
            return JSON.parse(item[field.dataField]);
        }*/
        if (edited_subtable) {
            return edited_subtable;
        } else {
            if (item[field.dataField]) {
                try {
                    let subtable = JSON.parse(item[field.dataField]);
                    if (subtable.length) {
                        return subtable;
                    }
                } catch (error) {
                    let sub_data = item[field.dataField].replace(/\n/g, "\\n").replace(/\r/g, "\\r");
                    try {
                        let subtable = JSON.parse(sub_data);
                        if (subtable.length) {
                            return subtable;
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }
        return false;
    }

    handleRefFileButtonClick(fileName) {
        let clicked_ref_files = [];
        if (this.state.clicked_ref_files.includes(fileName)) {
            clicked_ref_files = this.state.clicked_ref_files.filter(ref_file_name => ref_file_name !== fileName);
        } else {
            clicked_ref_files = this.state.clicked_ref_files.map(ref_file_name => ref_file_name);
            clicked_ref_files.push(fileName);
        }
        this.setState({
            clicked_ref_files
        });
    }

    handleRefFileTabClick(fileName) {
        this.setState({
            activeRefFile: this.state.activeRefFile === fileName ? "" : fileName
        });
    }

    getReferenceFiles(item_field) {
        const item = this.product;
        const ref_files = typeof (item[item_field]) === "string" ? [item[item_field]] : item[item_field];
        const reference_image_config = this.props.portalConfig.reference_image_config;
        const config_filetypes = this.props.portalConfig.config_filetypes;
        let pdf_files = [];
        let office_files = [];
        let image_files = [];
        let ref_file_objects = [];
        //console.log(ref_files);
        for (let fileName of ref_files) {
            const file_extension = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
            if (config_filetypes.preview.includes(file_extension)) {
                pdf_files.push(fileName);
                ref_file_objects.push({
                    fileName,
                    type: "pdf"
                });
            } else if (config_filetypes.download.includes(file_extension) || !config_filetypes.download.length) {
                office_files.push(fileName);
                ref_file_objects.push({
                    fileName,
                    type: "doc"
                });
            } else {
                if (reference_image_config.fields && reference_image_config.fields.includes(item_field)) {
                    //const image_name = fileName.substring(0, fileName.indexOf(".") + 1) + reference_image_config.suffix;
                    const image_name = fileName.substring(0, fileName.lastIndexOf(".") + 1) + reference_image_config.suffix;
                    image_files.push(image_name);
                    ref_file_objects.push({
                        fileName: image_name,
                        type: "pic"
                    });
                }
            }
        }
        if (image_files.length) {
            if (pdf_files.length || office_files.length) {
                return this.getReferenceFilesTabs(ref_file_objects, item_field);
            } else {
                return this.getRefPictures(ref_file_objects, item_field);
            }
        } else {
            if (pdf_files.length || office_files.length) {
                return this.getReferenceFilesTabs(ref_file_objects, item_field);
            } else {
                return null;
            }
        }
    }

    getRefPictures(ref_file_objects, item_field) {
        //console.log(ref_file_objects);
        return (
            <div className="ref-pictures-container">
                {ref_file_objects.map(file_obj => (
                    <RefFilePicturePreview
                        key={this.props.item_id + "-" + item_field.replace(/\s/g, "") + "-" + file_obj.fileName}
                        fileName={file_obj.fileName}
                    />
                ))}
            </div>
        );
    }

    getRefFileTabContent(file_obj, item_field) {
        if (file_obj.type === "pdf") {
            return (
                <PDFPreview
                    fileName={file_obj.fileName}
                    field_value={item_field}
                />
            );
        } else if (file_obj.type === "doc") {
            return (
                <DownloadDocButton fileName={file_obj.fileName} />
            );
        } else {
            return (
                <RefFilePicturePreview
                    key={this.props.item_id + "-" + item_field.replace(/\s/g, "") + "-" + file_obj.fileName}
                    fileName={file_obj.fileName}
                />
            );
        }
    }

    getReferenceFilesTabs(ref_file_objects, item_field) {
        return (
            <MDBContainer>
                <MDBNav className="nav-tabs">
                    {ref_file_objects.map(file_obj => (
                        <MDBNavItem>
                            <MDBNavLink
                                className="ref-file-tab-link"
                                link to="#"
                                active={this.state.activeRefFile === file_obj.fileName}
                                onClick={() => this.handleRefFileTabClick(file_obj.fileName)}
                                role="tab"
                            >
                                {file_obj.fileName}
                            </MDBNavLink>
                        </MDBNavItem>
                    ))}
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeRefFile} >
                    {ref_file_objects.map(file_obj => (
                        <MDBTabPane tabId={file_obj.fileName} role="tabpanel">
                            {this.state.activeRefFile === file_obj.fileName ?
                                this.getRefFileTabContent(file_obj, item_field)
                                :
                                null
                            }
                        </MDBTabPane>
                    ))}
                </MDBTabContent>
            </MDBContainer>
        );
    }

    createContent(field) {
        const { isNewReferenceModal, portalConfig, item_id, edit } = this.props;
        const { image_field, button_field } = portalConfig;
        const item = this.product;
        const item_field = field.dataField.includes(":REFERENCE_FILE") ? field.dataField.substring(0, field.dataField.length - 15) : "";
        let subtable = [];
        if (field.dataField.includes(":JSON") && item[field.dataField]) {
            try {
                subtable = JSON.parse(item[field.dataField]);
            } catch (error) {
                let sub_data = item[field.dataField].replace(/\n/g, "\\n").replace(/\r/g, "\\r");
                try {
                    subtable = JSON.parse(sub_data);
                } catch (err) {
                    console.log(err);
                }
            }
        }
        return (
            <div key={item_id + "-" + field.dataField.replace(/\s/g, "") + "-content"}>
                <MDBRow className="card-body-content">
                    <MDBCol
                        size="3"
                        className={!field.dataField.includes(":JSON") ? "body-content border-right border-light" :
                            edit ? "body-content border-right border-light" : "body-content font-larger"}
                    >
                        {
                            getTableText(field.text && field.text.replace(/\s/g, "") ? field.text : field.dataField)
                        }
                    </MDBCol>
                    {!field.dataField.includes(":JSON") ? null :
                        !edit || isNewReferenceModal ? null :
                            <button className="new_subtable_row_button btn btn-sm"
                                onClick={() => this.createNewSubtableRow(field, this.checkSubtableExists(field))}
                            >
                                {translate("create_new_row")}
                            </button>
                    }
                    <MDBCol>
                        {field.dataField === image_field && !image_field.includes(":REFERENCE_FILE") ?
                            !image_field.includes(":") ?
                                <PicturePreview
                                    isNewReferenceModal={isNewReferenceModal}
                                    item={item}
                                    view="article"
                                />
                                :
                                checkPicture(item, isNewReferenceModal, "details")
                            :
                            field.dataField.includes(":JSON") ?
                                null
                                :
                                <div className={this.isNavMobileSize || isNewReferenceModal ? "loaded-img-sm" : "content-value"}>
                                    {field.dataField.includes(":REFERENCE_FILE") ?
                                        this.getReferenceFiles(item_field)
                                        :
                                        //createDataField(this.product, field)
                                        portalConfig.elastic_index.includes("_bios_") && field.edit_depends_on && item["IsReadOnly"] ?
                                            item[field.dataField]
                                            :
                                            this.createField(field)
                                    }
                                </div>
                        }
                        {field.dataField !== button_field ? null :
                            getButtons(item_id, this.props.notify, "ml-auto", isNewReferenceModal, true)
                        }
                        {refs[field.dataField] && refs[field.dataField].length ?
                            <ArticleReferences
                                references={refs[field.dataField]}
                                field={field}
                                referenceObjects={JSON.parse(item[field.dataField])}
                                sourceProductID={item_id}
                                notify={this.props.notify}
                                isNewReferenceModal={isNewReferenceModal}
                            />
                            :
                            null
                        }
                    </MDBCol>
                </MDBRow>
                {typeof (field) === "object" && field.dataField.includes(":JSON") ?
                    <MDBRow className="mt-3">
                        <MDBCol>
                            <div className="article-subtable">
                                <SubTable
                                    fieldListField={field}
                                    item_id={item_id}
                                    subtable={subtable}
                                    changed_subtable={this.state.changed_subtable}
                                    handleUpdateSubtable={this.handleUpdateSubtable}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    :
                    null
                }
            </div>
        );
    }

    getSection(section_content, section, index) {
        const { isNewReferenceModal, openAllSections, allArticleSections, item_id } = this.props;
        const sections_opened = !isNewReferenceModal && openAllSections ? allArticleSections : this.state.sections_opened;
        if (section === "") {
            return (
                <MDBCard key={item_id + "-" + index + "-section"} className="mdb-card">
                    <MDBCardBody className="block-example">
                        {section_content.map(field => {
                            return this.createContent(field);
                        })}
                    </MDBCardBody>
                </MDBCard>
            );
        } else {
            return (
                <MDBCard key={item_id + "-" + section + "-section"} className="mdb-card">
                    <MDBCollapseHeader
                        tagClassName='d-flex justify-content-sm-start'
                        onClick={() => this.toggleCollapse(section)}
                    >
                        <MDBIcon
                            icon={checkArrayIncludesValue(sections_opened, section) ? 'angle-up' : 'angle-down'}
                        />
                        <div className="section-text">
                            {section}
                        </div>
                    </MDBCollapseHeader>
                    <MDBCollapse id={section}
                        isOpen={checkArrayIncludesValue(sections_opened, section)}
                    >
                        <MDBCardBody className="block-example border-top border-light">
                            {section_content.map(field => {
                                return this.createContent(field);
                            })}
                        </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>
            );
        }
    }

    openShoppingCartModal(item_id) {
        this.props.set_shopping_cart_modal();
        this.props.set_shopping_cart_modal_item(item_id);
    }

    getTabContent(tab_content_object, sectionList) {
        //const accordion_id = this.props.isNewReferenceModal ? "article-tab-content-modal" : "article-tab-content";
        return (
            <div className="md-accordion" /*id={accordion_id}*/>
                {sectionList.map((section, index) => {
                    if (tab_content_object[section] && tab_content_object[section].length) {
                        return this.getSection(tab_content_object[section], section, index);
                    }
                    return null;
                })}
            </div>
        );
    }

    render() {
        const { portalConfig, openAllSections, saveChanges, edit, articleTabs, isNewReferenceModal, newReferenceModalProps } = this.props;
        const { color_config, show_edit, open_all_sections_button } = portalConfig;
        const { add_button_color, add_button_text_color, remove_button_color, remove_button_text_color } = color_config;
        const registerTabList = this.registerTabList;
        //console.log(open_all_sections_button);
        return (
            <div>
                <MDBNav>
                    {registerTabList.map((tab) => this.getArticleNavItem(tab))}
                    <div className="switch row">
                        {isNewReferenceModal || !open_all_sections_button ? null :
                            <div className="custom-control custom-checkbox custom-switch column margin-right10">
                                <input type="checkbox" className="custom-control-input" id="defaultUnchecked"
                                    checked={openAllSections}
                                    onChange={() => this.props.change_open_all_sections_status()}
                                />
                                <label className="custom-control-label" htmlFor="defaultUnchecked">
                                    {translate("open_all_sections")}
                                </label>
                            </div>
                        }
                        {this.isNavMobileSize || isNewReferenceModal ? null :
                            <div>
                                {!show_edit ? null :
                                    saveChanges ?
                                        <div className="column">
                                            <button
                                                className="btn btn-sm"
                                                style={{ backgroundColor: add_button_color, color: add_button_text_color }}
                                                onClick={() => this.props.openSaveModal()}
                                            >
                                                {translate("save_changes")}
                                            </button>
                                        </div>
                                        :
                                        null
                                }
                                {!show_edit ? null :
                                    saveChanges ?
                                        <div className="column">
                                            <button
                                                className="btn btn-sm"
                                                style={{ backgroundColor: remove_button_color, color: remove_button_text_color }}
                                                onClick={() => this.props.resetChanges()}
                                            >
                                                {translate("reset_changes")}
                                            </button>
                                        </div>
                                        :
                                        null
                                }
                                {!show_edit ? null : (
                                    <div className="custom-switch column">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="switch_edit"
                                            checked={edit}
                                            onChange={() => this.props.switch_edit()}
                                            readOnly
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="switch_edit"
                                        >
                                            {translate("edit")}
                                        </label>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </MDBNav>
                <MDBTabContent
                    //activeItem={articleTabs.active_section}
                    activeItem={isNewReferenceModal ? newReferenceModalProps.articleTabs.active_section : articleTabs.active_section}
                    className="mt-n4 pt-n4 ml-n3 pl-n3"
                >
                    {registerTabList.map((tab) => this.getArticleContent(tab))}
                </MDBTabContent>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Article);