import React, { Component } from "react";
import { connect } from "react-redux";
import {
    close_subtable_modal, set_subtable_modal, add_edit_field,
    add_change_index_field, switch_save_changes, update_source_fields, set_change_index_fields, set_update_source_fields
} from "../actions";
import {
    MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTable, MDBTableBody
} from "mdbreact";
import merge from "deepmerge";
import { translate, getProductByID, getIndexLanguage, getChangeIndexFields, getUpdateSourceFields, createNewCopiedProduct } from "../utils/Utils";
import "../css/SubTableModal.css";
import EditField from "./EditField";

const mapStateToProps = (state) => {
    return {
        subTableModal: state.subTableModal,
        fetchedProducts: state.fetchedProducts,
        portalConfig: state.portalConfig,
        language: state.language,
        saveChanges: state.saveChanges,
        changeIndexFields: state.changeIndexFields,
        updateSourceFields: state.updateSourceFields,
        newProducts: state.newProducts,
        newCopiedProducts: state.newCopiedProducts
        //timeout: state.timeout
    };
};

const mapDispatchToProps = () => {
    return {
        close_subtable_modal,
        set_subtable_modal,
        add_edit_field,
        add_change_index_field,
        switch_save_changes,
        update_source_fields,
        set_change_index_fields,
        set_update_source_fields
    };
};

class SubTableModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.edited_subtable_row = {};
        this.setSubtableModal = this.setSubtableModal.bind(this);
    }

    getCopiedSubtableRow() {
        let edited_subtable_row = {};
        //console.log(this.props.subTableModal.subtable_row);
        for (let key in this.props.subTableModal.subtable_row) {
            let value = this.props.subTableModal.subtable_row[key];
            if (value) {
                for (let field_list of this.props.portalConfig.field_list_json) {
                    if (field_list.hasOwnProperty(this.props.subTableModal.fieldListField.dataField)) {
                        for (let field of field_list[this.props.subTableModal.fieldListField.dataField]) {
                            if (field.dataField === key) {
                                edited_subtable_row[field.editField ? field.editField : key] = value;
                            }
                        }
                    }
                }
            }
        }
        /*Object.keys(this.props.subTableModal.subtable_row).map(key => {
            let value = this.props.subTableModal.subtable_row[key];
            if (value) {
                this.props.portalConfig.field_list_json.map(field_list => {
                    if (field_list.hasOwnProperty(this.props.subTableModal.fieldListField.dataField)) {
                        field_list[this.props.subTableModal.fieldListField.dataField].map(field => {
                            if (field.dataField === key) {
                                edited_subtable_row[field.editField ? field.editField : key] = value;
                            }
                        });
                    }
                });
            }
        });*/
        return edited_subtable_row;
    }

    setSubtableModal(edited_subtable, edited_subtable_row, table_row_id_field, subtable_modal_row) {
        if (Object.keys(this.edited_subtable_row).length < 1) {
            let new_edited_subtable_row = {
                "table_row_id": edited_subtable_row.table_row_id
            };
            if (this.props.subTableModal.copied_row) {
                new_edited_subtable_row["table_row"] = this.getCopiedSubtableRow();
                new_edited_subtable_row["table_row"][edited_subtable_row.changes.key] = edited_subtable_row.changes.value;
            } else {
                new_edited_subtable_row["table_row"] = {
                    [edited_subtable_row.changes.key]: edited_subtable_row.changes.value
                };
            }
            this.edited_subtable_row = new_edited_subtable_row;
            this.props.set_subtable_modal(edited_subtable, subtable_modal_row);
        } else {
            let new_edited_subtable_row = Object.assign({}, this.edited_subtable_row);
            new_edited_subtable_row.table_row[edited_subtable_row.changes.key] = edited_subtable_row.changes.value;
            this.edited_subtable_row = new_edited_subtable_row;
            this.props.set_subtable_modal(edited_subtable, subtable_modal_row);
        }

    }

    enableSaveButton() {
        if (!this.props.saveChanges) {
            this.props.switch_save_changes();
        }
    }

    handleConfirmButton() {
        const { portalConfig, language, changeIndexFields, updateSourceFields, newProducts, newCopiedProducts } = this.props;
        const elastic_index = getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language);
        const subtable_modal = this.props.subTableModal;
        const field_list_field = subtable_modal.fieldListField;
        const editField = field_list_field.editField;
        const item_id = subtable_modal.item_id;
        const dataField = field_list_field.dataField;
        let change_index_fields = getChangeIndexFields(changeIndexFields, item_id, language, true, true, this.edited_subtable_row, editField && editField !== "0" ? editField : dataField, "", elastic_index, subtable_modal.new_subtable_row, false, newProducts.includes(item_id));
        let update_source_fields = getUpdateSourceFields(updateSourceFields, item_id, language, dataField, JSON.stringify(subtable_modal.subtable), false, newProducts.includes(item_id));

        if (newCopiedProducts.includes(item_id)) {
            let field_exists = false;
            for (var i = 0; i < changeIndexFields.length; i++) {
                if (changeIndexFields[i][portalConfig.keyfield] === item_id) {
                    field_exists = true;
                    i = changeIndexFields.length;
                }
            }
            if (!field_exists) {
                let { new_copied_product, mapped_key_obj } = createNewCopiedProduct(item_id);
                new_copied_product[portalConfig.keyfield] = item_id;
                change_index_fields = changeIndexFields;
                let new_change_index_field = {};
                for (let key in new_copied_product) {
                    if (new_copied_product[key]) {
                        const mapped_key = mapped_key_obj[key] ? mapped_key_obj[key] : key;
                        new_change_index_field = merge(new_change_index_field, getChangeIndexFields(change_index_fields, item_id, language, false, false, new_copied_product[key], mapped_key, "", elastic_index, false, true, newProducts.includes(item_id)));
                    }
                }
                /*Object.keys(new_copied_product).filter(key => new_copied_product[key]).map(key => {
                    const mapped_key = mapped_key_obj[key] ? mapped_key_obj[key] : key;
                    new_change_index_field = merge(new_change_index_field, getChangeIndexFields(change_index_fields, item_id, language, false, false, new_copied_product[key], mapped_key, "", elastic_index, false, true, newProducts.includes(item_id)));
                });*/
                change_index_fields.push(new_change_index_field);
                change_index_fields = getChangeIndexFields(change_index_fields, item_id, language, true, true, this.edited_subtable_row, editField && editField !== "0" ? editField : dataField, "", elastic_index, subtable_modal.new_subtable_row, false, newProducts.includes(item_id));
                update_source_fields = updateSourceFields;
                let new_field = {};
                for (let key in new_copied_product) {
                    if (new_copied_product[key]) {
                        new_field = merge(new_field, getUpdateSourceFields(update_source_fields, item_id, language, key, new_copied_product[key], true, newProducts.includes(item_id)));
                    }
                }
                /*Object.keys(new_copied_product).filter(key => new_copied_product[key]).map(key => {
                    new_field = merge(new_field, getUpdateSourceFields(update_source_fields, item_id, language, key, new_copied_product[key], true, newProducts.includes(item_id)));
                });*/
                update_source_fields.push(new_field);
                update_source_fields = getUpdateSourceFields(update_source_fields, item_id, language, dataField, JSON.stringify(subtable_modal.subtable), false, newProducts.includes(item_id));
            }
        }
        this.props.add_edit_field(item_id, editField && editField !== "0" ? editField : dataField, language, subtable_modal.subtable);
        this.props.set_change_index_fields(change_index_fields);
        this.props.set_update_source_fields(update_source_fields);
        this.props.setEditSubtable(subtable_modal.subtable);
        this.enableSaveButton();
        this.closeModal();
    }

    closeModal() {
        this.edited_subtable_row = {};
        this.props.close_subtable_modal();
    }

    render() {
        const portalConfig = this.props.portalConfig;
        const field_list_json = portalConfig.field_list_json;
        const subtable_modal = this.props.subTableModal;
        if (this.props.subTableModal.copied_row) {
            //this.edited_subtable_row = this.getCopiedSubtableRow();
        }
        if (subtable_modal.hasOwnProperty("subtable")) {
            const subtable_row = subtable_modal.subtable_row;
            //console.log("here");
            return (
                <MDBModal
                    size="lg"
                    isOpen
                    toggle={() => this.closeModal()}
                >
                    <MDBModalHeader
                        toggle={() => this.closeModal()}
                    >
                        {translate("edit_subtable_fields")}
                    </MDBModalHeader>
                    <MDBModalBody
                        className="sub-table-modal-body"
                    >
                        <div className="subtable_modal_table">
                            <MDBTable>
                                <MDBTableBody>
                                    {field_list_json.map(json_field => {
                                        if (json_field.hasOwnProperty(subtable_modal.fieldListField.dataField)) {
                                            return (
                                                json_field[subtable_modal.fieldListField.dataField].map(field => {
                                                    if (!field.usage.includes("ID")) {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {field.text}
                                                                </td>
                                                                <td className="border-left border-light">
                                                                    <EditField
                                                                        item={getProductByID(this.props.fetchedProducts, subtable_modal.item_id)}
                                                                        field={field}
                                                                        fieldListField={subtable_modal.fieldListField}
                                                                        value={subtable_row[field.dataField]}
                                                                        subtable_modal={subtable_modal}
                                                                        table_row_id_field={subtable_modal.subtable_row_id_field}
                                                                        isSubtableModal={true}
                                                                        setSubtableModal={this.setSubtableModal}
                                                                        new_subtable_row={subtable_modal.new_subtable_row}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            );
                                        }
                                        return null;
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn
                            color="primary"
                            disabled={Object.keys(this.edited_subtable_row).length < 1}
                            onClick={() => this.handleConfirmButton()}
                        >
                            {translate("confirm")}
                        </MDBBtn>
                        <MDBBtn color="secondary"
                            onClick={() => this.closeModal()}
                        >
                            {translate("close")}
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            );
        } else {
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(SubTableModal);