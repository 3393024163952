const loadedImagesReducer = (state = {}, action) => {
    switch(action.type) {
        case 'ADD_IMAGE':
            let added_image_state = {...state};
            added_image_state[action.payload.id] = action.payload.url;
            state = {...added_image_state};
            return state;
        case "ADD_IMAGES":
            let added_images_state = {...state};
            for (let img_obj of action.payload) {
                added_images_state[img_obj.id] = img_obj.url;
            }
            state = {...added_images_state};
            return state;
        default:
            return state;
    }
}

export default loadedImagesReducer;