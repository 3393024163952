import React, { Component } from "react";
import { connect } from "react-redux";
import {  } from "../../actions";

const mapStateToProps = (state) => {
    return {
        //fetchedProducts
    };
};

const mapDispatchToProps = () => {
    return {
        
    };
};

class OfflineSearchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //clickedItems: this.getClickedItems(),
            //reactiveSearchComponents: getComponents(this.props.field.dataField, this.props.portalConfig.field_list),
            //sortBy: this.getOrder()
            //data: getOfflineDataByDataField()
        };
        //this.getDefaultQuery = this.getDefaultQuery.bind(this);
    }

    render() {
        //console.log(this.props.fetchedProducts);
        return (
            <div>
                {this.props.field.dataField}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(OfflineSearchList);