import React, { Component } from "react";
import PropTypes from "prop-types";
import { checkDataExists, getMultiselectMaxcharByField, translate } from "../utils/Utils";
import { MDBTooltip, MDBIcon, MDBTable, MDBTableBody } from "mdbreact";
import "../css/EditFieldsTable.css";
import EditField from "./EditField";
import SingleSelect from "./SingleSelect";
import TextInput from "./TextInput";

class EditFieldsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editedFields: {},
            isEditing: false,
            isLoading: false
        };
        this.changeText = this.changeText.bind(this);
        this.setEditingStatus = this.setEditingStatus.bind(this);
    }

    changeText(value, dataField) {
        let new_edited_fields = Object.assign({}, this.state.editedFields);
        new_edited_fields[dataField] = value;
        //console.log(new_edited_fields);
        this.setState({
            editedFields: new_edited_fields
        });
    }

    setEditingStatus(bool) {
        this.setState({
            isEditing: bool
        });
    }

    getEditField(field) {
        switch (field.edit_type) {
            case "_SimpleText":
                return (
                    <TextInput
                        fieldID={field.dataField}
                        type="text"
                        value=""
                        maxLength={getMultiselectMaxcharByField(field)}
                        changeText={this.changeText}
                        timeoutObject={{ active: true, time: 500 }}
                        setWriting={this.setEditingStatus}
                        isLoading={this.state.isLoading}
                    />
                );
            /*case "_Selection":
                return (
                    //<SingleSelect />
                    <input
                        type="text"
                    />
                );*/
            default:
                return (
                    <TextInput
                        fieldID={field.dataField}
                        type="text"
                        value=""
                        maxLength={getMultiselectMaxcharByField(field)}
                        changeText={this.changeText}
                        timeoutObject={{ active: true, time: 500 }}
                        setWriting={this.setEditingStatus}
                        isLoading={this.state.isLoading}
                    />
                );
        }
    }

    clickSaveButton() {
        this.props.setEditedFields(this.state.editedFields);
        this.setState({
            isEditing: true,
            isLoading: true
        });
    }

    render() {
        const { editFields } = this.props;
        //console.log("render EditFieldsTable");
        if (!editFields) {
            return null;
        }
        return (
            <div>
                <table className="edit-fields-table">
                    <tbody>
                        {editFields.map(edit_field => {
                            return (
                                <tr>
                                    <td>
                                        {edit_field.dataField}
                                    </td>
                                    <td>
                                        {this.getEditField(edit_field)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div style={{ marginTop: "20px" }}>
                    {"Nach dem Speichern wird das Portal neu geladen und alle Änderungen übernommen."}
                </div>
                <div style={{ justifyContent: "center", display: "flex", marginTop: "10px" }}>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.clickSaveButton()}
                        disabled={this.state.isEditing || this.state.isLoading}
                    >
                        {translate("save")}
                    </button>
                </div>
            </div>
        );
    }
}

EditFieldsTable.propTypes = {
    editFields: PropTypes.array.isRequired,
    setEditedFields: PropTypes.func.isRequired
};

export default EditFieldsTable;