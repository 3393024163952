import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import {
    getComponents, translate, getDefaultQuery, deepEqual, hideFacetComponent, getClickedFacetItems, setElasticQuery,
    getFacetComponentID, compareAlphabeticalOrder
} from "../utils/Utils";
import "../css/SearchMultiList.css";
import "../Home.css";
import { MDBSpinner, MDBIcon } from "mdbreact";
import {
    update_search_query, set_search_query, delete_search_query_field,
    set_edit_checkbox_type, set_search_query_modal, delete_search_query_field_modal, update_search_query_modal,
    set_active_article, set_active_article_modal
} from "../actions";
import TextInput from "./TextInput";
import PropTypes from "prop-types";
import { getElasticDataField } from "../utils/functions";

const mapStateToProps = (state) => {
    return {
        searchQuery: state.searchQuery,
        portalConfig: state.portalConfig,
        numberOfResults: state.numberOfResults,
        activeArticle: state.activeArticle,
        newReferenceModalProps: state.newReferenceModalProps
    };
};

const mapDispatchToProps = () => {
    return {
        update_search_query,
        set_search_query,
        delete_search_query_field,
        set_edit_checkbox_type,
        set_search_query_modal,
        delete_search_query_field_modal,
        update_search_query_modal,
        set_active_article,
        set_active_article_modal
    };
};

class SearchMultiList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bucket_size: parseInt(this.props.portalConfig.bucket_size),
            new_query: false,
            searchText: "",
            componentId: getFacetComponentID(this.props.field.dataField, this.props.isNewReferenceModal, this.props.isFacetModal),
            elasticDataField: getElasticDataField(this.props.field.dataField),
            isOverflownWidth: false
        };
        this.not_empty_doc_count_sum = 0;
        this.clickedItems = getClickedFacetItems(this.props.field, this.props.isNewReferenceModal);
        this.getDefaultQuery = this.getDefaultQuery.bind(this);
        this.changeFilterText = this.changeFilterText.bind(this);
        this.getCustomQuery = this.getCustomQuery.bind(this);
    }

    componentDidMount() {
        if (this.clickedItems.length) {
            this.setState({
                new_query: true
            });
        }
    }

    componentDidUpdate() {
        const { field, isNewReferenceModal, emptyComponent, isOpen } = this.props;
        const newClickedItems = getClickedFacetItems(field, isNewReferenceModal);
        const list_element = document.getElementById(this.state.componentId + "-facet");
        if (list_element) {
            const scrollWidth = list_element.scrollWidth;
            const clientWidth = list_element.clientWidth;
            if (isOpen) {
                if (clientWidth) {
                    if (!this.state.isOverflownWidth && (scrollWidth > clientWidth)) {
                        this.setState({
                            isOverflownWidth: true
                        });
                    }
                } else {
                    //console.log("rend");
                    //this.setState({});
                }
            }
        }
        if (newClickedItems.length < 1 && this.clickedItems.length > 0) {
            this.clickedItems = [];
            if (!emptyComponent) {
                this.props.handleFacetClick(field.dataField, []);
            }
            if (field.depend) {
                this.setState({
                    new_query: false
                });
            }
        }
        if (!deepEqual(this.clickedItems, newClickedItems)) {
            this.clickedItems = newClickedItems;
            if (!emptyComponent) {
                this.props.handleFacetClick(field.dataField, newClickedItems);
            }
            this.setState({
                new_query: true
            });
        }

    }

    getDefaultQuery() {
        const { field, portalConfig } = this.props;
        let defaultQuery = getDefaultQuery(field, this.state.elasticDataField + "." + portalConfig.field_extension);
        //let defaultQuery1 = getDefaultQuery(field, this.state.elasticDataField + "." + portalConfig.field_extension, portalConfig.default_filter1);
        defaultQuery.aggs.values.aggs.values.terms.size = this.state.bucket_size;
        //defaultQuery1.aggs.values.aggs.values.terms.size = this.state.bucket_size;
        if (this.state.searchText) {
            /*defaultQuery.query.bool.must.push({
                "wildcard":{
                    [field.dataField]:{
                      "wildcard": "*" + this.state.searchText + "*",
                      //"wildcard":"*Internet Solvay.com (Public)*",
                      "boost": 1
                   }
                }
             })*/
            /*defaultQuery.query.bool.filter = {
                "regexp": {
                    [field.dataField]: ".*" + this.state.searchText + ".*"
                }
            };*/

            defaultQuery.query.bool.filter = [
                {
                    "regexp": {
                        [field.dataField]: ".*" + this.state.searchText + ".*"
                    }
                }
            ];
            /*defaultQuery1.query.bool.filter = [ 
                {
                "regexp": {
                    [field.dataField]: ".*" + this.state.searchText + ".*"
                }
            }
            ];*/
            /*defaultQuery.query = {
                wildcard: {
                    [field.dataField]: {
                        value: "*" + this.state.searchText + "*"
                    }
                }
            };*/
        }
        //console.log(JSON.stringify(defaultQuery));
        if (field.text === "ID") {
            //console.log(1);
        }
        /*console.log(defaultQuery);
        console.log(defaultQuery1);
        const x = deepEqual(defaultQuery, defaultQuery1);*/
        //console.log(x);
        return defaultQuery;
    }

    getCustomQuery() {
        let customQuery = {};
        if (this.state.searchText) {
            customQuery = {
                "query": {
                    "bool": {
                        "filter": {
                            "regexp": {
                                [this.state.elasticDataField]: ".*" + this.state.searchText + ".*"
                            }
                        }
                    }
                }
            };
        }
        return customQuery;
    }

    handleClick(key, setQuery, dataField, clickedItems) {
        const { isNewReferenceModal, activeArticle, newReferenceModalProps, isFacetModal } = this.props;
        let newClickedItems = [];
        let is_empty = false;
        let new_value = null;
        const checked_key = this.getCheckedKey(key);
        for (let item_key of clickedItems) {
            if (item_key !== "empty" && item_key !== "not_empty") {
                newClickedItems.push(item_key);
            } else {
                is_empty = true;
            }
        };
        if ((checked_key === "true" && clickedItems.includes("false")) || (this.props.field.edit_type === "_Checkbox" && checked_key === "true" && clickedItems.includes("0"))) {
            newClickedItems = [key];
            new_value = key;
        } else if ((checked_key === "false" && clickedItems.includes("true")) || (this.props.field.edit_type === "_Checkbox" && checked_key === "false" && clickedItems.includes("1"))) {
            newClickedItems = [key];
            new_value = key;
        } else if (checked_key === "empty" || checked_key === "not_empty") {
            if (!is_empty || (clickedItems.includes("empty") && checked_key === "not_empty") || (clickedItems.includes("not_empty") && checked_key === "empty")) {
                newClickedItems = [key];
            }
            is_empty = true;
        } else {
            if (clickedItems.includes(key)) {
                newClickedItems = newClickedItems.filter(item_key => item_key !== key);
            } else {
                newClickedItems.push(key);
            }
        }
        this.clickedItems = newClickedItems;
        if (isFacetModal) {
            const component_id = getFacetComponentID(dataField, isNewReferenceModal, false);
            if (this.props.field.filter === "DynamicRangeSlider") {
                const range_slider = document.getElementById(component_id + "-dynamic-range-slider");
                console.log(range_slider);
            } else {
                const filter_facet = document.getElementById(component_id + "-" + key.replace(/\s/g, "") + "-facet-item");
                filter_facet.click();
            }
        } else {
            if (!this.props.emptyComponent) {
                this.props.handleFacetClick(dataField, newClickedItems);
            }
            if (!newClickedItems.length) {
                if (isNewReferenceModal) {
                    this.props.delete_search_query_field_modal(dataField);
                } else {
                    this.props.delete_search_query_field(dataField);
                }
            } else {
                if (new_value) {
                    if (isNewReferenceModal) {
                        this.props.set_search_query_modal(new_value, dataField);
                    } else {
                        this.props.set_search_query(new_value, dataField);
                    }
                } else if (is_empty) {
                    if (isNewReferenceModal) {
                        this.props.set_search_query_modal(key, dataField);
                    } else {
                        this.props.set_search_query(key, dataField);
                    }
                } else {
                    if (isNewReferenceModal) {
                        this.props.update_search_query_modal(key, dataField);
                    } else {
                        this.props.update_search_query(key, dataField);
                    }
                }
            }
            if (isNewReferenceModal && newReferenceModalProps.activeArticle !== "main") {
                this.props.set_active_article_modal("main");
            } else if (!isNewReferenceModal && activeArticle !== "main") {
                this.props.set_active_article("main");
            }
            setElasticQuery(this.state.elasticDataField + "." + this.props.portalConfig.field_extension, setQuery, newClickedItems, isNewReferenceModal, this.props.field.edit_type === "_Checkbox" && newClickedItems.length ? checked_key : null);
        }
    }

    getMinMaxDocCount(data) {
        let max_doc_count = 0;
        let min_doc_count = data[0].doc_count;
        for (var i = 0; i < data.length; i++) {
            if (max_doc_count < data[i].doc_count) {
                max_doc_count = data[i].doc_count;
            }
            if (min_doc_count > data[i].doc_count) {
                min_doc_count = data[i].doc_count;
            }
        }
        return {
            min_doc_count,
            max_doc_count
        };
    }

    getTagCloudData(data) {
        let tag_cloud_data = [];
        const min_max_doc_count = this.getMinMaxDocCount(data);
        for (let data_field of data) {
            tag_cloud_data.push({
                key: data_field.key,
                size: ((data_field.doc_count / min_max_doc_count.max_doc_count) * 2.19 + 0.81) + "em"
            });
        };
        return tag_cloud_data;
    }

    /**
     * überprüft ob das showSearch field angezeigt werden soll oder nicht.
     * @param {object} field 
     * @returns 
     */
    getShowSearch(field) {
        if (!this.props.showAsCloud && field.filter_props && field.filter_props.showSearch) {
            return true;
        }
        return false;
    }

    loadMore() {
        this.setState({
            bucket_size: this.state.bucket_size + parseInt(this.props.portalConfig.bucket_size)
        });
    }

    changeFilterText(value) {
        this.setState({
            searchText: value
        });
    }

    getData(buckets) {
        let data = buckets;
        for (let i = 0; i < buckets.length; i++) {
            if (buckets[i].key.includes(this.props.portalConfig.multiselect_separator)) {
                i = buckets.length;
            }
        }
        return data;
    }

    getCheckedKey(item_key) {
        if (this.props.field.edit_type === "_Checkbox" && item_key !== "empty" && item_key !== "not_empty") {
            return item_key && (item_key === "1" || item_key === "true" || item_key === true || item_key === 1) ? "true" : "false";
        }
        return item_key;
    }

    render() {
        const { field, isNewReferenceModal, emptyComponent, isFacetModal, portalConfig, showAsCloud } = this.props;
        const dataField = field.dataField;
        const clickedItems = getClickedFacetItems(field, isNewReferenceModal);
        const componentId = this.state.componentId;
        return (
            <div className="search-field" style={emptyComponent || hideFacetComponent(field, this.clickedItems.length > 0) ? { display: "none" } : {}}>
                {field.filter_props && field.filter_props.showSearch && Array.isArray(portalConfig.default_filter) ?    //TODO: ändern, wenn suche durch neuer default_filter funktioniert
                    <TextInput
                        type="search"
                        value={this.state.searchText}
                        changeText={this.changeFilterText}
                        placeholder={translate("search")}
                        timeoutObject={{ active: true, time: 500}}
                    />
                    :
                    null
                }
                <ReactiveComponent
                    react={{
                        and: getComponents(dataField, isNewReferenceModal)
                    }}
                    filterLabel={field.text && field.text.replace(/\s/g, "") ? field.text : dataField}
                    showFilter={isFacetModal ? false : true}
                    componentId={componentId}
                    defaultQuery={this.getDefaultQuery}
                    render={({ aggregations, setQuery, error, loading }) => {
                        if (error) {
                            //alert(error)
                            console.warn(componentId, error);
                        }
                        if (this.state.new_query) {
                            this.setState({
                                new_query: false
                            });
                            setElasticQuery(this.state.elasticDataField + "." + portalConfig.field_extension, setQuery, clickedItems, isNewReferenceModal, field.edit_type === "_Checkbox" ? this.getCheckedKey(clickedItems[0]) : null);
                        }
                        if (loading) {
                            return (
                                <div className="search-bar-spinner">
                                    <MDBSpinner small />
                                </div>
                            );
                        }
                        if (aggregations) {
                            const data = this.getData(aggregations.values.values.buckets);
                            if (emptyComponent) {
                                //console.log(componentId);
                                return (
                                    <div id={componentId + "-facet"}>
                                        <div id={componentId + "-empty-facet-item"} onClick={() => this.handleClick("empty", setQuery, dataField, clickedItems)}>
                                            {translate("empty")}
                                        </div>
                                        <div id={componentId + "-not-empty-facet-item"} onClick={() => this.handleClick("not_empty", setQuery, dataField, clickedItems)}>
                                            {translate("not_empty")}
                                        </div>
                                        {data.sort((a, b) => compareAlphabeticalOrder(a.key, b.key)).map(item => {
                                            const key = item.key;
                                            return (
                                                <div key={componentId + "-" + key/*.replace(/\s/g, "")*/ + "-facet-item-empty"} id={componentId + "-" + key.replace(/\s/g, "") + "-facet-item"} onClick={() => this.handleClick(key, setQuery, dataField, clickedItems)}>
                                                    {(item.key_as_string ? item.key_as_string : this.getCheckedKey(key)) + (field.unit_filter && field.display_unit_on_value === "1" ? " " + field.unit_filter.replace(/\s/g, "") : "")}
                                                </div>
                                            );
                                        })}
                                        {data.length === this.state.bucket_size || data.length === this.state.bucket_size - 1 ?
                                            <button
                                                onClick={() => this.loadMore()}
                                                id={componentId + "-facet-load-more-button"}
                                            >
                                                {translate("load_more")}
                                            </button>
                                            :
                                            null
                                        }
                                    </div>
                                );
                            }
                            if (showAsCloud) {
                                const tag_cloud_data = data.length < 1 ? [] : this.getTagCloudData(data);
                                return (
                                    <div className="list-container search-cloud" role="menu" id={componentId + "-facet"}>
                                        {tag_cloud_data.map(item => {
                                            const key = item.key;
                                            return (
                                                <span
                                                    key={componentId + "-" + key/*.replace(/\s/g, "")*/ + "-facet-item"}
                                                    id={componentId + "-" + key.replace(/\s/g, "") + "-facet-item"}
                                                    className="cloud-span"
                                                    role="menuitem"
                                                    style={{
                                                        fontSize: item.size,
                                                        backgroundColor: clickedItems.includes(key) ? "#0b6aff" : "white",
                                                        color: clickedItems.includes(key) ? "white" : "black"
                                                    }}
                                                    onClick={() => this.handleClick(key, setQuery, dataField, clickedItems)}
                                                >
                                                    {key}
                                                </span>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            /*const not_empty_doc_count_sum = aggregations.values.doc_count;
                            let doc_count_sum = 0;
                            data.map(item => doc_count_sum += item.doc_count);
                            const not_empty_doc_count_sum = doc_count_sum + aggregations.values.values.sum_other_doc_count;*/
                            const show_doc_count = field.filter_props && field.filter_props.showCount;
                            /*if (this.not_empty_doc_count_sum < not_empty_doc_count_sum) {
                                this.not_empty_doc_count_sum = not_empty_doc_count_sum;
                            }*/
                            const elements = this.state.isOverflownWidth ? portalConfig.show_max_facet_items + 1 : portalConfig.show_max_facet_items;
                            return (
                                <div className="multidatalist-container" id={componentId + "-facet"} style={{ maxHeight: (elements * 25) + "px" }}>
                                    {aggregations.empty_values.doc_count ?
                                        <div className="checkbox-container" style={{ maxHeight: 25 }} id={componentId + "-empty-facet-item"} onClick={() => this.handleClick("empty", setQuery, dataField, clickedItems)}>
                                            {clickedItems.includes("empty") ?
                                                <MDBIcon icon="check" size="lg" key={componentId + "-empty-checkbox"} />
                                                :
                                                <MDBIcon far icon="square" size="lg" key={componentId + "-empty-checkbox"} />
                                            }
                                            <label className="" htmlFor={componentId + "-empty-checkbox"}>
                                                <span>
                                                    {translate("empty")}
                                                </span>
                                                {show_doc_count ?
                                                    <span className={clickedItems.includes("empty") ? "checkbox-doc-count doc-count-selected" : "checkbox-doc-count"}>
                                                        {"(" + aggregations.empty_values.doc_count + ")"}
                                                        {/*"(" + (aggregations.empty_values.doc_count + aggregations.null_values.doc_count) + ")"*/}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </label>
                                        </div>
                                        :
                                        null
                                    }
                                    {aggregations.empty_values.doc_count ?
                                        <div className="checkbox-container" style={{ maxHeight: 25 }} id={componentId + "-not-empty-facet-item"} onClick={() => this.handleClick("not_empty", setQuery, dataField, clickedItems)}>
                                            {clickedItems.includes("not_empty") ?
                                                <MDBIcon icon="check" size="lg" key={componentId + "-not-empty-checkbox"} />
                                                :
                                                <MDBIcon far icon="square" size="lg" key={componentId + "-not-empty-checkbox"} />
                                            }
                                            <label className="" htmlFor={componentId + "-not-empty-checkbox"}>
                                                <span>
                                                    {translate("not_empty")}
                                                </span>
                                                {show_doc_count ?
                                                    <span className={clickedItems.includes("not_empty") ? "checkbox-doc-count doc-count-selected" : "checkbox-doc-count"}>
                                                        {/*"(" + (this.state.searchText ? this.not_empty_doc_count_sum : not_empty_doc_count_sum) + ")"*/}
                                                        {"(" + aggregations.values.doc_count + ")"}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </label>
                                        </div>
                                        :
                                        null
                                    }
                                    {data.map(item => {
                                        const key = item.key;
                                        const isSelected = clickedItems.includes(key);
                                        return (
                                            <div key={componentId + "-" + key/*.replace(/\s/g, "")*/ + "-facet-item"} className="checkbox-container" style={{ maxHeight: 25 }} id={componentId + "-" + key.replace(/\s/g, "") + "-facet-item"} onClick={() => this.handleClick(key, setQuery, dataField, clickedItems)}>
                                                {isSelected ?
                                                    <MDBIcon icon="check" size="lg" key={field.dataField + "-checkbox"} />
                                                    :
                                                    <MDBIcon far icon="square" size="lg" key={field.dataField + "-checkbox"} />
                                                }
                                                <label className="" htmlFor={field.dataField + "-checkbox"}>
                                                    <span>
                                                        {(item.key_as_string ? item.key_as_string : this.getCheckedKey(key)) + (field.unit_filter && field.display_unit_on_value === "1" ? " " + field.unit_filter.replace(/\s/g, "") : "")}
                                                    </span>
                                                    {show_doc_count ?
                                                        <span className={isSelected ? "checkbox-doc-count doc-count-selected" : "checkbox-doc-count"}>
                                                            {"(" + item.doc_count + ")"}
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </label>
                                            </div>
                                        );
                                    })}
                                    {data.length === this.state.bucket_size || data.length === this.state.bucket_size - 1 ?
                                        <button
                                            className="btn btn-sm"
                                            onClick={() => this.loadMore()}
                                            id={componentId + "-facet-load-more-button"}
                                        >
                                            {translate("load_more")}
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }}
                />
            </div>
        );
    }
}

SearchMultiList.propTypes = {
    isOpen: PropTypes.bool,
    field: PropTypes.object.isRequired,
    emptyComponent: PropTypes.bool,
    isFacetModal: PropTypes.bool,
    isNewReferenceModal: PropTypes.bool,
    handleFacetClick: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps())(SearchMultiList);