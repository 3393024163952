import merge from "deepmerge";

const editedFieldsReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_EDIT_FIELD':
            //console.log(action);
            state = getEditedFields(state, action.payload.productID, action.payload.language, action.payload.field, action.payload.value);
            //console.log(state);
            return state;
        case 'SET_EDITED_FIELDS':
            state = action.payload;
            return state;
        case 'CLEAR_EDIT':
            state = [];
            return state;
        case 'ADD_MULTI_EDIT_FIELD':
            //let new_state = [];
            let new_state = state.filter(field => !action.payload.productIDs.includes(field["ID"]));
            /*state.map(field => {
                if (!action.payload.productIDs.includes(field["ID"])) {
                    new_state.push(field);
                }
            });*/
            for (let productID of action.payload.productIDs) {
                let new_field = {};
                for (let key of Object.keys(action.payload.multiEditObject)) {
                    new_field = merge(new_field, getEditedFields(state, productID, action.payload.language, key, action.payload.multiEditObject[key], true));
                };
                /*Object.keys(action.payload.multiEditObject).map(key => {
                    new_field = merge(new_field, getEditedFields(state, productID, action.payload.language, key, action.payload.multiEditObject[key], true));
                });*/
                new_state.push(new_field);
            };
            /*action.payload.productIDs.map(productID => {
                let new_field = {};
                Object.keys(action.payload.multiEditObject).map(key => {
                    new_field = merge(new_field, getEditedFields(state, productID, action.payload.language, key, action.payload.multiEditObject[key], true));
                });
                new_state.push(new_field);
            });*/
            console.log(new_state);
            state = new_state;
            return state;
        default:
            return state;
    }
}

function getEditedFields(state, productID, language, field, value, multiEdit = false) {
    let new_state = [];
    for (let field of state) {
        new_state.push(field);
    }
    /*state.map(field => {
        new_state.push(field);
    });*/
    for (var i = 0; i < state.length; i++) {
        if (state[i]["ID"] === productID) {
            if (state[i]["language"] === language) {
                new_state[i]["changes"][field] = value;
                if (multiEdit) {
                    return new_state[i];
                }
                return new_state;
            }
        }
    }
    let obj = {}
    obj["ID"] = productID;
    obj["language"] = language;
    let changed = {};
    changed[field] = value;
    obj["changes"] = changed;
    if (multiEdit) {
        return obj;
    }
    new_state.push(obj);
    return new_state;
}

export default editedFieldsReducer;