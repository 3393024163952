import { getUpdateSourceFields } from "../utils/Utils";

const updateSourceFieldsReducer = (state = [], action) => {
    switch(action.type) {
        case 'UPDATE_SOURCE_FIELDS':
            state = getUpdateSourceFields(state, action.payload.productID, action.payload.language, action.payload.field, action.payload.value, false, action.payload.isNewProduct);
            console.log(state);
            return state;
        case 'SET_UPDATE_SOURCE_FIELDS':
            state = action.payload;
            return state;
        case 'CLEAR_UPDATE_SOURCE_FIELDS':
            state = [];
            return state;
        default:
            return state;
    }
}

export default updateSourceFieldsReducer;