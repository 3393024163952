import React, { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBTooltip, MDBIcon } from "mdbreact";
import { downloadFileFromServer, translate } from "../../utils/Utils";
import PropTypes from "prop-types";
import store from "../../store/store";

class PictureModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    closeModal() {
        this.props.close();
    }

    render() {
        const { image_base_url, reference_user, reference_password } = store.getState().portalConfig;
        const { fileName, src = "" } = this.props;
        const image_source = src ? src : image_base_url + fileName + "?ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
        //console.log(image_source);
        return (
            <MDBModal
                size="lg"
                isOpen
                toggle={() => this.closeModal()}
                className="picture-modal"
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    <MDBTooltip
                        domElement
                        tag="span"
                        placement="top"
                    >
                        <span>
                            <button
                                className="btn btn-sm"
                                onClick={() => downloadFileFromServer(fileName, src)}
                            >
                                <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                {translate("download")}
                            </button>
                        </span>
                        <span>{fileName}</span>
                    </MDBTooltip>
                </MDBModalHeader>
                <MDBModalBody>
                    <img
                        className="picture-modal-pic"
                        alt={fileName}
                        //src={process.env.PUBLIC_URL + "/no_image_found.png"}
                        src={image_source}
                    />
                </MDBModalBody>
            </MDBModal>
        );
    }
}

PictureModal.propTypes = {
    close: PropTypes.func.isRequired,
    fileName: PropTypes.string.isRequired,
    src: PropTypes.string
};

export default PictureModal;