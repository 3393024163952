const routePathsReducer = (state = [], action) => {
    switch(action.type) {
        case 'ADD_ROUTE_PATH':
            state = state.map(route => route);
            state.push(action.payload);
            return state;
        default:
            return state;
    }
}

export default routePathsReducer;