import React, { Component } from "react";
import Portal from "./Portal";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import translations from './translations/translations.json';
import merge from "deepmerge";
import { getCheckedConfigValue } from "./utils/Utils";
import PropTypes from "prop-types";

class PortalApp extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, config: null }
    }

    componentDidMount() {
        if (this.props.config) {
            this.initTranslation(this.props.config);
        } else {
            import(/* webpackPreload: true */ /* webpackChunkName: "config" */'./config.json')
                .then(moduleImport => moduleImport.default)
                .then(config => {
                    //console.log(config);
                    this.initTranslation(config);
                });
        }
    }

    initTranslation(config) {
        i18n.init({
            interpolation: { escapeValue: false },
            //fallbackLng: process.env.NODE_ENV !== 'production' ? [getCheckedConfigValue(config.default_language, "string", "de", "default_language")] : null,
            fallbackLng: [getCheckedConfigValue(config.default_language, "string", "de", "default_language")],
            // ignore locals 'en-US' -> 'en'
            load: 'languageOnly',
            resources: merge(translations, getCheckedConfigValue(config.custom_language, "object", {}, "custom_language")),
            defaultNS: 'default',
        }).then(() => this.setState({ loading: false, config: config }));
    }

    render() {
        const { loading, config } = this.state;
        return (
            loading ? <span>Loading...</span> :
                <I18nextProvider i18n={i18n}>
                    <Portal config={config} />
                </I18nextProvider>
        );
    }
}

PortalApp.propTypes = {
    config: PropTypes.object
};

export default PortalApp;