import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBTooltip } from "mdbreact";
import { getCompareList, translate } from "../../utils/Utils";
import "../../Home.css";
import { set_compare_modal, delete_compare_item, set_compare_items } from "../../actions";
import CompareModal from "./CompareModal";

var refs = {};

const mapStateToProps = (state) => {
    return {
        //compareModal: state.compareModal,
        //compareItems: state.compareItems,
        //selectFields: state.selectFields,
        //fetchedProducts: state.fetchedProducts,
        portalConfig: state.portalConfig
    };
};

const mapDispatchToProps = () => {
    return {
        set_compare_modal,
        delete_compare_item,
        set_compare_items
    };
};

class ModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //switch1: false,
            //compare_list: getCompareList(),
            //updated: false
        };
        //this.isMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
    }

    render() {
        const { portalConfig } = this.props;
        //console.log("ModalContainer----------------------------");
        return (
            <div>
                {portalConfig.show_comparelist ?
                    <CompareModal notify={this.props.notify} />
                    :
                    null
                }
            </div>
        );
    }
}

ModalContainer.propTypes = {
    notify: PropTypes.func.isRequired
    //close: PropTypes.func.isRequired,
    //fileName: PropTypes.string.isRequired,
    //src: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps())(ModalContainer);