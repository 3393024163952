const shoppingCartReducer = (state = [], action) => {
    switch(action.type) {
        case 'ADD_TO_SHOPPING_CART':
            state.push(action.payload);
            return state;
        case 'CHANGE_ITEM_QUANTITY':
            state[action.payload.index].quantity = action.payload.value;
            return state;
        case 'DELETE_SHOPPING_CART_ITEM':
            state = state.filter(item => item.id_ !== action.payload);
            return state;
        case 'UPDATE_SHOPPING_CART':
            for (var i = 0; i < state.length; i++) {
                if (state[i].item[action.keyfield] === action.payload[action.keyfield]) {
                    state[i].item = action.payload;
                    return state;
                }
            }
            return state;
        case 'SET_SHOPPING_CART':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default shoppingCartReducer;