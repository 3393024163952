import React from "react";
import {
    MDBTooltip, MDBRow, MDBCard, MDBCollapseHeader, MDBIcon, MDBCollapse, MDBCardBody, MDBNavItem, MDBNavLink
} from "mdbreact";
import merge from "deepmerge";
import PriceTable from "../components/PriceTable";
import EditField from "../components/EditField";
import i18n from "i18next";
import SubTable from "../components/SubTable";
import store from "../store/store";
import {
    add_products, update_products, new_tab, add_product, add_compare_item, change_delete_product_modal_status,
    delete_product_by_id, add_download_table_product, //add_image_base64,
    change_updating, clear_change_index_fields, clear_update_source_fields, clear_edit, set_clear_subtable, switch_save_changes,
    switch_edit, add_edit_field, set_active_article,
    set_shopping_cart_modal, set_shopping_cart_modal_item, set_fetched_products, set_tabs, set_compare_items,
    set_shopping_cart, set_download_table_products, add_new_product, set_change_index_fields,
    set_update_source_fields, add_new_copied_product, select_new_reference, deselect_new_reference, set_active_article_modal,
    add_new_tab_modal, set_result_view, replace_last_article_tab, add_image, set_reference_modal_article_tabs
} from "../actions";
import { getChangeIndexBulk, getUpdateSourceBulk } from "./Webservice";
import SearchMultiList from "../components/SearchMultiList";
import OfflineSearchList from "../components/offline_components/OfflineSearchList";
import SearchMultiDataList from "../components/custom_reactive_search/SearchMultiDataList";
import SearchDynamicRange from "../components/SearchDynamicRange";
import CustomReactiveSearch from "../components/CustomReactiveSearch";
import { active_article_limit, default_color_config, default_pdf_config } from "./constants";
import PDF from "../components/PDF";
import { saveAs } from "file-saver";
import HiddenFacet from "../components/HiddenFacet";
import DownloadDocButton from "../components/DownloadDocButton";
import RefFilePicturePreview from "../components/RefFilePicturePreview";
import PDFPreview from "../components/PDFPreview";
import { comparePosition } from "./functions";

//start check functions

function checkDataExists(data, expected_type) {
    if (!data) {
        return false;
    }
    switch (expected_type) {
        case "array":
            if (Array.isArray(data) && data.length) {
                console.log("true");
                return true;
            }
            console.log("true");
        //break;
        default:
            console.log("false");
            return false;
    }
}

//end check functions

//start get functions

function getUsageRequiredFields() {
    const field_list = store.getState().portalConfig.field_list;
    let usage_required_fields = [];
    if (field_list.filter(field => field.usage.includes("REQUIRED")).length) {
        usage_required_fields = [...field_list].filter(field => field.usage.includes("REQUIRED"));
    }
    return usage_required_fields;
}

//end get functions

/**
 * nimmt hits_options und hits_shown aus checkedconfig, fügt ggf. hits_shown dem array hinzu und gibt das sortierte array zurück
 */
function getHitsArray(hits_options, hits_shown) {
    var hits_array = hits_options.map(hit => hit);
    if (hits_array.includes(hits_shown)) {
        return hits_array.sort(compareNumbers);
    } else {
        hits_array.push(hits_shown);
        return hits_array.sort(compareNumbers);
    }
};

function compareNumbers(a, b) {
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

function getMatchString(clickedItems) {
    let match_str = "";
    for (var i = 0; i < clickedItems.length; i++) {
        match_str += clickedItems[i];
        if (i + 1 < clickedItems.length) {
            match_str += ", ";
        }
    }
    return match_str;
}

/**
 * gibt das mitgegebene menu_filter object als array zurück.
 * @param {object} menu_filter 
 * @returns 
 */
function getMenuFilterAsArray(menu_filter) {
    let menu_filter_array = [];
    for (let key in menu_filter) {
        if (key !== "custom_table") {
            menu_filter[key].map(field => menu_filter_array.push(field.dataField));
        }
    }
    return menu_filter_array;
}

/**
 * gibt alle header_fields zurück, welche durch custom_table hinzugefügt wurden um nach dessen dataField filtern zu können.
 * @param {object} table_templates 
 * @param {string[]} menu_filter_array 
 * @returns 
 */
function getCustomTableTemplateHeaderFields(table_templates, menu_filter_array) {
    let new_header_fields = [];
    for (let key in table_templates) {
        for (let field of table_templates[key].custom_table) {
            let field_already_includes = false;
            for (var i = 0; i < new_header_fields.length; i++) {
                if (new_header_fields[i].dataField === field.dataField) {
                    field_already_includes = true;
                    i = new_header_fields.length;
                }
            }
            if (!field_already_includes && field.dataField !== "actions_field" && !menu_filter_array.includes(field.dataField)) {
                new_header_fields.push(field);
            }
        }
    }
    return new_header_fields;
}

/**
 * Gibt ALLE Reactive-Komponenten zurück.
 */
function getAllComponents(isNewReferenceModal = false) {
    const { searchQuery, menuFilter, newReferenceModalProps } = store.getState();
    let all_components = [];
    for (let key in menuFilter) {
        for (let field of menuFilter[key]) {
            if (isNewReferenceModal ? newReferenceModalProps.searchQuery.hasOwnProperty(field.dataField) : searchQuery.hasOwnProperty(field.dataField)) {
                if (isNewReferenceModal) {
                    all_components.push(field.dataField + "-new-reference-modal");
                } else {
                    all_components.push(field.dataField)
                }
            }
        }
    }
    if (isNewReferenceModal) {
        all_components.push("mainSearchButton-new-reference-modal");
    } else {
        all_components.push("mainSearchButton");
    }
    return all_components;
}

/**
 * Gibt alle Komponenten außer sich selbst zurück.
 * @returns {[]}  && allComponents[i] !== "mainSearchButton"
 */
function getComponents(componentId, isNewReferenceModal = false) {
    let all_components = getAllComponents(isNewReferenceModal);
    let components = [];
    for (var i = 0; i < all_components.length; i++) {
        if (all_components[i] !== componentId) {
            components.push(all_components[i]);
        }
    }
    return components;
}

function hideFacetComponent(field, is_clicked) {
    const searchQuery = store.getState().searchQuery;
    if (field.depend && !searchQuery[field.depend] && !is_clicked) {
        return true;
    }
    return false;
}

function getClickedFacetItems(field, isNewReferenceModal) {
    const searchQuery = isNewReferenceModal ? store.getState().newReferenceModalProps.searchQuery : store.getState().searchQuery;
    const dataField = field.dataField;
    if (searchQuery[dataField]) {
        return searchQuery[dataField];
    }
    return [];
}

function setElasticQuery(keyfield, setQuery, clickedItems, isNewReferenceModal, value = null) {
    if (clickedItems.includes("empty")) {
        const query = JSON.parse(JSON.stringify({
            "query": {
                "bool": {
                    "must_not": [
                        {
                            "regexp": {
                                [keyfield]: "@&.*[a-zA-Z0-9]+.*"
                            }
                        }
                    ]
                }
                /*"bool": {
                    "should": [
                        {
                            "term": {
                                [keyfield]: {
                                    "value": "",
                                    "boost": 1
                                }
                            }
                        },
                        {
                            "bool": {
                                "must_not": [
                                    {
                                        "exists": {
                                            "field": keyfield.substring(0, keyfield.indexOf(".")),
                                            "boost": 1
                                        }
                                    }
                                ],
                                "adjust_pure_negative": true,
                                "boost": 1
                            }
                        }
                    ],
                    "adjust_pure_negative": true,
                    "boost": 1
                }*/
                /*"bool": {
                    "should": [
                        {
                            "script": {
                                "script": {
                                    "source": "InternalSqlScriptUtils.nullSafeFilter(InternalSqlScriptUtils.eq(InternalSqlScriptUtils.ltrim(InternalSqlScriptUtils.docValue(doc,params.v0)),params.v1))",
                                    "lang": "painless",
                                    "params": {
                                        "v0": keyfield,
                                        "v1": ""
                                    }
                                },
                                "boost": 1
                            }
                        },
                        {
                            "bool": {
                                "must_not": [
                                    {
                                        "exists": {
                                            "field": keyfield,
                                            "boost": 1
                                        }
                                    }
                                ],
                                "adjust_pure_negative": true,
                                "boost": 1
                            }
                        }
                    ]
                }*/
            }
        }));
        setQuery({
            query,
            value: translate("empty"),
            componentType: isNewReferenceModal ? "modal" : "default"
        });
    } else if (clickedItems.includes("not_empty")) {
        const query = JSON.parse(JSON.stringify({
            "query": {
                "regexp": {
                    [keyfield]: "@&.*[a-zA-Z0-9]+.*"
                }
            }
        }));
        setQuery({
            query,
            value: translate("not_empty"),
            componentType: isNewReferenceModal ? "modal" : "default"
        });
    } else {
        const query = JSON.parse(JSON.stringify({
            query: {
                terms: {
                    [keyfield]: clickedItems
                }
            }
        }));
        setQuery({
            query,
            value: value ? value : clickedItems.toString(),
            componentType: isNewReferenceModal ? "modal" : "default"
        });
    }
}

function sortKeyfield(a, b, reference_sortorder) {
    let a_key_ = a[reference_sortorder];
    let b_key_ = b[reference_sortorder];
    if (a_key_ < b_key_) {
        return -1;
    }
    if (a_key_ > b_key_) {
        return 1;
    }
    return 0;
}

function createCustomTable(field_list, image_field) {
    return {
        sorted: {
            dataField: "",
            order: "no"
        },
        custom_table: getTableList(field_list, image_field, true)
    };
}

function createCustomTableTemplates(field_list, image_field) {
    return {
        default: createCustomTable(field_list, image_field)
    };
}

function getFlipCardFields() {
    const { portalConfig } = store.getState();
    const flip_card_fields = portalConfig.field_list.filter(field =>
        field.usage.includes("FLIP") && field.usage.includes("SHOW") && field.dataField !== portalConfig.image_field && !field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE"))
        .sort(comparePosition);
    //console.log(flip_card_fields);
    return flip_card_fields;
}

function getTableList(field_list = store.getState().portalConfig.field_list, image_field = store.getState().portalConfig.image_field, custom_template = false) {
    //const { portalConfig } = store.getState();
    //const { field_list, image_field } = portalConfig;
    let table_list = [];
    if (custom_template) {
        table_list.push({ dataField: "actions_field" });
    }
    for (let field of field_list.sort(comparePosition)) {
        if (field.usage.includes("TABLE") && field.usage.includes("SHOW") && field.dataField !== image_field && !field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE")) {
            table_list.push(field);
        }
    }
    /*field_list.filter(field => field.usage.includes("TABLE") && field.usage.includes("SHOW") && field.dataField !== image_field && !field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE")).sort(comparePosition).map(field => {
        table_list.push(field);
    });*/
    return table_list;
}

function getForeignkeyList() {
    let foreignkey_list = {};
    /*for (let field of field_list) {
        if (field.filter === "MultiDataList" || field.filter === "DynamicRangeSlider" || field.edit_type === "_Selection" || field.dataField.includes(":SUBTABLE")) {
            if (field.hasOwnProperty("edit_foreignkey_id") && field.edit_foreignkey_id !== "") {
                if (!edit_list.hasOwnProperty(field.edit_foreignkey_id)) {
                    edit_list[field.edit_foreignkey_id] = {
                        "foreignkey": true
                    };
                }
            } else {
                if (!edit_list.hasOwnProperty(field.dataField)) {
                    edit_list[field.dataField] = {
                        "foreignkey": false
                    };
                }
            }
        }
    }*/
    return foreignkey_list;
}

function getEditList(field_list, field_list_json) {
    let edit_list = {};
    for (let field of field_list) {
        if (field.filter === "MultiDataList" || field.filter === "DynamicRangeSlider" || field.edit_type === "_Selection" || field.dataField.includes(":SUBTABLE")) {
            if (field.hasOwnProperty("edit_foreignkey_id") && field.edit_foreignkey_id !== "") {
                if (!edit_list.hasOwnProperty(field.edit_foreignkey_id)) {
                    edit_list[field.edit_foreignkey_id] = {
                        "foreignkey": true
                    };
                }
            } else {
                if (!edit_list.hasOwnProperty(field.dataField)) {
                    edit_list[field.dataField] = {
                        "foreignkey": false
                    };
                }
            }
        }
    }
    for (let field of field_list_json) {
        for (let key in field) {
            if (Array.isArray(field[key])) {
                for (let subtable_field of field[key]) {
                    if (subtable_field.usage.includes("EDIT") && subtable_field.edit_type === "_Selection") {
                        if (subtable_field.hasOwnProperty("edit_foreignkey_id") && subtable_field.edit_foreignkey_id !== "") {
                            if (!edit_list.hasOwnProperty(subtable_field.edit_foreignkey_id)) {
                                edit_list[subtable_field.edit_foreignkey_id] = {
                                    "foreignkey": true
                                };
                            }
                        } else {
                            if (!edit_list.hasOwnProperty(subtable_field.dataField)) {
                                edit_list[subtable_field.dataField] = {
                                    "foreignkey": false
                                };
                            }
                        }
                    }
                }
            }
        }
    }
    return edit_list;
}

function getIndexLanguage(language, elastic_index, default_language) {
    if (typeof (elastic_index) === "object") {
        if (elastic_index.hasOwnProperty(language)) {
            return elastic_index[language];
        } else {
            return elastic_index[default_language];
        }
    } else {
        return elastic_index;
    }
}

/**
 * 
 * @param {*} value 
 * @param {*} multiselect_separator 
 * @returns 
 */
function getChangeIndexValue(value, multiselect_separator) {
    let change_index_value = Array.isArray(value) ? [] : "";
    //let change_index_value = value;
    if (Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
            const field = value[i];
            if (multiselect_separator === "array") {
                change_index_value.push(field["value"].toString());
            } else {
                change_index_value += field["value"].toString();
                if (i < value.length - 1) {
                    if (multiselect_separator === "\n") {
                        change_index_value += "X_enter_X";
                    } else {
                        change_index_value += multiselect_separator;
                    }
                }
            }
        }
        /*value.map((field, index) => {
            if (multiselect_separator === "array") {
                change_index_value.push(field["value"].toString());
            } else {
                change_index_value += field["value"].toString();
                if (index < value.length - 1) {
                    if (multiselect_separator === "\n") {
                        change_index_value += "X_enter_X";
                    } else {
                        change_index_value += multiselect_separator;
                    }
                }
            }
            //sourceValue.push(field["text"]);
        });*/
    } else {
        change_index_value = value;
    }
    return change_index_value;

}

/**
 * Returns a query filter based on default_filter.
 */
function getFetchFilterNew(default_filter) {
    let fetch_filter = {
        "must": [],
        "must_not": []
    };
    for (let query of default_filter) {
        if (query.hasOwnProperty("default")) {
            fetch_filter.must.push(query["default"]);
        } else {
            for (let query_key in query) {
                const inner_query = query[query_key];
                for (let inner_query_key in inner_query) {
                    const inner_inner_query = inner_query[inner_query_key];
                    if (query_key === "bool") {
                        if (inner_inner_query.hasOwnProperty("exists")) {
                            fetch_filter[inner_query_key].push(inner_inner_query);
                        } else {
                            for (let key in inner_inner_query) {
                                fetch_filter[inner_query_key].push({
                                    "match": inner_inner_query[key]
                                });
                            }
                        }
                    } else {
                        if (Array.isArray(inner_inner_query)) {
                            let query_object = {};
                            query_object[inner_query_key] = inner_inner_query.join(", ");
                            fetch_filter.must.push({
                                "match": query_object
                            });
                        } else {
                            fetch_filter.must.push({
                                "match": inner_query
                            });
                        }
                    }
                }
            }
        }
    }
    /*default_filter.map(query => {
        if (query.hasOwnProperty("default")) {
            fetch_filter.must.push(query["default"]);
        } else {
            Object.keys(query).map(query_key => {
                const inner_query = query[query_key];
                Object.keys(inner_query).map(inner_query_key => {
                    const inner_inner_query = inner_query[inner_query_key];
                    if (query_key === "bool") {
                        if (inner_inner_query.hasOwnProperty("exists")) {
                            fetch_filter[inner_query_key].push(inner_inner_query);
                        } else {
                            Object.keys(inner_inner_query).map(key => {
                                fetch_filter[inner_query_key].push({
                                    "match": inner_inner_query[key]
                                });
                            });
                        }
                    } else {
                        if (Array.isArray(inner_inner_query)) {
                            let query_object = {};
                            query_object[inner_query_key] = inner_inner_query.join(", ");
                            fetch_filter.must.push({
                                "match": query_object
                            });
                        } else {
                            fetch_filter.must.push({
                                "match": inner_query
                            });
                        }
                    }

                });
            });
        }
    });*/
    return fetch_filter;
}

function getLabel(string, label_field_length) {
    let str = "";
    if (string !== null) {
        str = string;
        if (string.length > label_field_length) {
            str = string.substr(0, label_field_length);
            if (string.indexOf(" ", label_field_length) === label_field_length) {
                str += " ";
            }
            str += "...";
        }
    }
    return str;
}

/**
 * Checks whether the given value is contained in the given array.
 * @param {[]} array Given array.
 * @param {string} value Given value.
 */
function checkArrayIncludesValue(array, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i] === value) {
            return true;
        }
    }
    return false;
}

/**
 * Checks whether the text given contains the character ":". If true, the character is deleted and the new text is returned. 
 * Otherwise the given text is returned.
 * @param {string} text Given text.
 */
function getTableText(text) {
    let erg = text;
    if (text.includes(":")) {
        let index = text.indexOf(":");
        erg = text.substr(0, index);
    }
    return erg;
}

function getBasketList() {
    //let basket_list = [];
    const basket_list = store.getState().portalConfig.field_list.filter(field => field.usage.includes("BASKET") && field.usage.includes("SHOW")).sort(comparePosition);
    //basket_list.sort(comparePosition);
    return basket_list;
}

function getCompareList() {
    const compare_list = store.getState().portalConfig.field_list.filter(field => field.usage.includes("COMPARE") && field.usage.includes("SHOW")).sort(comparePosition);
    /*let compare_list = [];
    field_list.map(feld => {
        if (feld.usage.indexOf("COMPARE") !== -1) {
            compare_list.push(feld);
        }
    });
    compare_list.sort(comparePosition);*/
    return compare_list;
}

/**
 * Creates an array which contains all register tab names.
 * @param {object} field_list Given field_list from portalConfig.
 * @returns Array which contains all register tab names.
 */
function getArticleRegisterTabList(field_list = store.getState().portalConfig.field_list) {
    let registerTabList = [];
    for (let field of field_list.sort(comparePosition)) {
        if (!checkArrayIncludesValue(registerTabList, field.registertab)) {
            registerTabList.push(field.registertab);
        }
    }
    return registerTabList;
}

/**
 * Creates an array which contains all section names in the given register tab.
 * @param {string} registerTab Given register tab name.
 * @returns Array which contains all section names.
 */
function getSectionList(registerTab) {
    let sectionList = [];
    for (let field of store.getState().portalConfig.field_list.sort(comparePosition)) {
        if (field.registertab === registerTab && field.usage.includes("SHOW")) {
            if (!sectionList.includes(field.section)) {
                sectionList.push(field.section);
            }
        }
    }
    /*store.getState().portalConfig.field_list.filter(field => field.registertab === registerTab && field.usage.includes("SHOW")).sort(comparePosition).map(field => {
        if (!sectionList.includes(field.section)) {
            sectionList.push(field.section);
        }
    });*/
    return sectionList;
}

function getAllSections(field_list) {
    let sections = [];
    for (let field of field_list) {
        if (!checkArrayIncludesValue(sections, field.section)) {
            sections.push(field.section);
        }
    }
    /*field_list.map(field => {
        if (!checkArrayIncludesValue(sections, field.section)) {
            sections.push(field.section);
        }
    });*/
    //console.log(sections);
    return sections;
}

function getSectionContent(registerTab, section, item_id) {
    const { fetchedProducts, edit } = store.getState();
    const item = getProductByID(fetchedProducts, item_id);
    const section_fields = getSectionFields(registerTab, section);
    let section_content = [];
    if (edit) {
        for (var i = 0; i < section_fields.length; i++) {
            // item[section_fields[i].dataField]
            //if (item[section_fields[i].dataField] !== null && item[section_fields[i].dataField] !== undefined || section_fields[i].usage.includes("EDIT") || section_fields[i].dataField.includes(":JSON")) {
            section_content.push(section_fields[i]);
            //}
        }
    } else {
        for (var j = 0; j < section_fields.length; j++) {
            const section_field = section_fields[j];
            const section_data_field = section_field.dataField;
            if (section_data_field.includes(":REFERENCE_FILE")) {
                if (item[section_data_field.substring(0, section_data_field.length - 15)]) {
                    section_content.push(section_field);
                }
            } else {
                if (item[section_data_field] || typeof (item[section_data_field]) === "boolean") {
                    if (section_data_field.includes(":JSON")) {
                        try {
                            const json_table = JSON.parse(item[section_data_field]);
                            if (json_table.length) {
                                section_content.push(section_field);
                            }
                        } catch (error) {
                            let sub_data = item[section_data_field].replace(/\n/g, "\\n").replace(/\r/g, "\\r");
                            try {
                                const sub_table = JSON.parse(sub_data);
                                if (sub_table.length) {
                                    section_content.push(section_field);
                                }
                            } catch (err) {
                                console.log(err);
                            }
                        }
                    } else if (typeof (item[section_data_field]) === "string") {
                        if (item[section_data_field].trim().length) {
                            section_content.push(section_field);
                        }
                    } else {
                        section_content.push(section_field);
                    }
                }
            }
        }
    }
    return section_content;
}

function getSectionFields(registerTab, section) {
    let section_fields = [];
    for (let field of store.getState().portalConfig.field_list.sort(comparePosition)) {
        if (field.registertab === registerTab && field.section === section) {
            section_fields.push(field);
        }
    }
    // console.log(section_fields);
    /*store.getState().portalConfig.field_list.filter(feld => feld.registertab === registerTab).filter(feld => feld.section === section).sort(comparePosition).map(field => {
        section_fields.push(field);
    });*/
    return section_fields;
}

/**
 * Translates the given field into the current language. 
 * @param {string} field The field to be translated.
 * @param {string} namespace Namespace.
 */
function translate(field, namespace = "home") {
    return i18n.t(`${namespace}:${field}`);
}

async function fetchPic(reference_user, reference_password, uri, id_, default_pic = "no_image_found.png", ctsweb = "bwi") {//muss configuriert sein (ctsweb umbauen in reference_additional_url)
    if (id_ != null) {
        let erg = default_pic;
        let url = uri + "&ctsWebsite=" + ctsweb + "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
        //console.log(url);
        const resp = await fetch(url).catch(error => {
            //console.log("1", error);
            /*console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);*/
        });
        const json = resp ? await resp.json().catch(error => {
            //console.log("2", error);
            /*console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);*/
        }) : null;
        if (resp && resp.status === 200) {
            let image = document.createElement("img");
            image.src = json;
            //let file = new File(image, "tst");
            //console.log(file);
            /*let canvas = document.createElement("canvas");
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = json;
            img.onload = function () {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                canvas.height = img.naturalHeight;
                canvas.width = img.naturalWidth;
                ctx.drawImage(img, 0, 0);

                var uri = canvas.toDataURL('image/png');
                var b64 = uri.replace(/^data:image.+;base64,/, '');
                img.src = b64;
                let x = store.getState().imageBase64;
                if (!x[id_]) {
                    //console.log(111);
                    //store.dispatch(add_image_base64(id_, b64));
                }

            };*/
            //console.log(jsonjson);

            if (Array.isArray(json)) {
                erg = json[0];
                //console.log(erg);
            } else {
                erg = json;
            }
            erg += "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
            //console.log(id_);
            //console.log(erg);
            store.dispatch(add_image(id_, erg));
            /*if (document.getElementById(id_) != null) {
                var elms = document.querySelectorAll("[id='" + id_ + "']");
                for (var i = 0; i < elms.length; i++) {
                    elms[i].src = erg;
                }
            }*/
        }
    }
}

function createHTMLElement(item, fieldname_, reference_user, reference_password, reference_url) {
    let html_erg = "";
    let arr = [];
    let pic_arr = [];
    if (fieldname_ === "Produkt-Datenblatt (smartdoc):REFERENCE") {
        //console.log(1);
    }
    if (item.hasOwnProperty(fieldname_)) {
        if (fieldname_.indexOf(":REFERENCE") !== -1 && item[fieldname_]) {
            let eles = item[fieldname_];
            let obj = JSON.parse(eles);
            obj.forEach(function (entry) {
                if (entry.TYPE.indexOf("DAM") !== -1) {
                    html_erg += "<img id='" + entry.TargetID + "' src='no_image_found.png' class='html-element-img'><br>";
                    if (!pic_arr.includes(entry.TargetID)) {
                        pic_arr.push(entry.TargetID);
                        //fetchPic(reference_user, reference_password, reference_url + "/file/previewurl/" + entry.TargetID + "/?format=png&width=200&height=190", entry.TargetID);
                    }
                } else {   // falls PIM 
                    if (!arr.includes(entry.TargetID)) {
                        arr.push(entry.TargetID);
                    }
                }
            });

        } else {        // falls also Tabelle oder "Standardtext"
            html_erg = item[fieldname_];
        }
    } else if (fieldname_ === store.getState().portalConfig.image_field) {
        html_erg += "<img src='no_image_found.png'>";
    }
    return {
        html: { __html: html_erg },
        arr: arr
    };
}

function getPicture(item, default_pic, image_field, image_base_url, image_suffix) {
    let pic = process.env.PUBLIC_URL + "/" + default_pic;
    if (!((image_field === "XX_IMAGE_FIELD_XX") || (image_field === ""))) {
        if (!item[image_field]) {  // falls aber fuer das Element kein Bild vorhanden.. 
            pic = process.env.PUBLIC_URL + "/" + default_pic;
        } else {
            if (item[image_field].indexOf("TYPE") !== -1) {
                let pici = JSON.parse(item[image_field])[0].ItemID;
                pic = image_base_url + pici.replace(/"/g, '') + image_suffix;
            } else {
                pic = image_base_url + item[image_field].replace(/"/g, '') + image_suffix;
            }
        }
    }
    return pic;
}

function getPDFConfig(pdf_config) {
    return Object.assign(default_pdf_config, pdf_config);
}

/**
 * Copies the values of all of the enumerable own properties from custom color_config to a default_color_config.
 * @param {object} color_config Custom color_config.
 * @returns {object} Merged color_config.
 */
function getColorConfig(color_config) {
    return Object.assign(default_color_config, color_config);
}

function getEditedItem(article, changes) {
    return merge(article, changes);
}

function getSelectionList(selectFields, foreignkey) {
    let selectionList = [];
    //console.log(foreignkey)
    if (foreignkey !== null && selectFields !== undefined && selectFields !== null) {
        for (let field of selectFields) {
            if (field["field_id"] === foreignkey) {
                selectionList.push(field)
            }
        }
        /*selectFields.map(field => {
            //console.log(field);
            if (field["field_id"] === foreignkey) {
                selectionList.push(field)
            }
        });*/
    } else {
        return selectionList;
    }
    return selectionList;
}

function getSelection(selectFields, foreignkey, id) {
    /*let value = "";
    if (Array.isArray(id)) {
        value = id.filter(val => val);
    } else {
        value = id;
    }*/
    let value = id.toString();
    const selectionList = getSelectionList(selectFields, foreignkey);
    for (let selection of selectionList) {
        if (selection["value_id"].toString() === id) {
            value = selection["label_string"];
        }
    }
    /*selectionList.map(selection => {
        if (selection["value_id"] === id) {
            value = selection["label_string"];
        }
    });*/
    return value;
}

function getMapping(field, value, field_list_mapping) {
    for (var i = 0; i < field_list_mapping.length; i++) {
        if (field_list_mapping[i].dataField === field) {
            let mapping = field_list_mapping[i].mapping;
            for (var j = 0; j < mapping.length; j++) {
                if (typeof (mapping[j].src) === "string") {
                    if (mapping[j].src === value) {
                        return mapping[j];
                    }
                } else {
                    if (mapping[j].src.includes(value)) {
                        return mapping[j];
                    }
                }
            }
        }
    }
    return null;
}

function getMappingComponent(mapping, selectFields, field, htmlElement) {
    let text = "";
    let tooltip_text = getSelection(selectFields, field, htmlElement);
    if (mapping.hasOwnProperty("text") && mapping.text !== "" && mapping.text !== null) {
        text = mapping.text;
    } else {
        text = tooltip_text;
    }
    if (mapping.show === "1") { // 0 = nur text, 1 = nur punkt, 2 = beides
        if (!mapping.hasOwnProperty("icon") || mapping.icon === "" || mapping.icon === null) {
            return (
                <MDBTooltip placement="top" domElement tag="span">
                    <div className="traffic-light" style={{ backgroundColor: mapping.color }} />
                    <div>{tooltip_text}</div>
                </MDBTooltip>
            );
        } else {
            return null; //muss return icon sein
        }
    } else if (mapping.show === "2") {
        if (!mapping.hasOwnProperty("icon") || mapping.icon === "" || mapping.icon === null) {
            return (
                <MDBTooltip placement="top" domElement tag="span">
                    <div>
                        <div style={{ color: mapping.color, float: "left" }}>
                            {text}
                        </div>
                        <div className="traffic-light" style={{ backgroundColor: mapping.color }} />
                    </div>
                    <div>{tooltip_text}</div>
                </MDBTooltip>
            );
        } else {
            return null; //muss return icon sein
        }
    } else {
        return (
            <MDBTooltip placement="bottom" domElement tag="span">
                <div style={{ color: mapping.color }}>
                    {text}
                </div>
                <div>{tooltip_text}</div>
            </MDBTooltip>
        );
    }
}

function getField(item, field, value, isNewProduct = false, isModal = false, use_edit_value = false, edit_value = null) {
    const { edit, selectFields, portalConfig } = store.getState();
    const { keyfield, field_list_mapping, field_list_json, elastic_index } = portalConfig;
    const field_value = use_edit_value ? edit_value : value;
    if (field.dataField.includes(":TABLE")) {
        if (field_value) {
            return <PriceTable priceTable={field_value} id={item[keyfield]} />
        } else {
            return <div>{field_value}</div>;
        }
    } else if (field.dataField.includes(":JSON")) {
        if (!field_list_json.length || !field_value) {
            return null;
        } else {
            let subtable = [];
            try {
                subtable = JSON.parse(field_value);
            } catch (error) {
                let sub_data = field_value.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
                try {
                    subtable = JSON.parse(sub_data);
                } catch (err) {
                    console.log(err);
                }
            }
            return (
                <SubTable
                    fieldListField={field}
                    item_id={item[keyfield]}
                    subtable={subtable}
                />
            );
        }
    } else if (edit && !use_edit_value && (field.usage.includes("EDIT") || isNewProduct) && field.dataField !== keyfield && !field.dataField.includes(":REFERENCE")) {
        return <EditField item={item} field={field} isNewProduct={isNewProduct} isModal={isModal} />
    } else if (field.edit_type === "_Checkbox") {
        return (
            <div className="checkbox">
                {field_value && (field_value === true || field_value === "true" || field_value === "1" || field_value === 1) ?
                    <MDBIcon icon="check" size="lg" key={field.dataField + "-checkbox"} />
                    :
                    <MDBIcon far icon="square" size="lg" key={field.dataField + "-checkbox"} />
                }
            </div>
        );
    } else if (field.edit_type === "_Selection" && store.getState().portalConfig.multiselect_separator && typeof (field_value) === "string" && field_value.includes(store.getState().portalConfig.multiselect_separator)) {
        const bullet_points = field_value.split(store.getState().portalConfig.multiselect_separator).filter(val => val).map(val => store.getState().selectFields[field.dataField] ? getSelection(store.getState().selectFields[field.dataField], field.edit_foreignkey_id, val) : val);
        return (
            <ul style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 0 }}>
                {bullet_points.map(val => (
                    <li>
                        {val}
                    </li>
                ))}
            </ul>
        );
    } else if (field.filter === "MultiDataList" || field.edit_type === "_Selection") {
        if (Array.isArray(field_value) && field.display_type !== "string") {
            // console.log(field);
            return (
                <ul style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 0 }}>
                    {field_value.filter(val => val).map(val => {
                        const mapping = getMapping(field.dataField, val, field_list_mapping);
                        let mapped_value = val;
                        if (mapping === null) {
                            mapped_value = getSelection(selectFields[field.dataField], field.edit_foreignkey_id ? field.edit_foreignkey_id : field.dataField, val);
                        }
                        return (
                            <li key={field.dataField + "-" + val}>
                                {/*mapped_value*/}
                                {mapping && mapping["dest"] ?
                                    getMappingComponent(mapping, selectFields[field.dataField], field.edit_foreignkey_id ? field.edit_foreignkey_id : field.dataField, val)
                                    :
                                    mapped_value
                                }
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            if (!field_value) {
                return null;
            }
            const mapping = getMapping(field.dataField, field_value, field_list_mapping);
            if (mapping === null) {
                let val = getSelection(selectFields[field.edit_foreignkey_id ? field.edit_foreignkey_id : field.dataField], field.edit_foreignkey_id, field_value);
                return <div>{val}</div>;
            } else {
                if (!mapping.hasOwnProperty("dest") || mapping.dest === "" || mapping.dest === null) {
                    return null;
                } else {
                    return getMappingComponent(mapping, selectFields[field.dataField], field.edit_foreignkey_id, field_value);
                }
            }
        }
    } else if (field.display_type === "date") {
        return <div>{getLocaleDateString(field_value)}</div>
    } else if (field.display_type === "datetime") {
        return <div>{getLocaleDateString(field_value, true)}</div>
    } else {
        if (!field_value) {
            return null;
        }
        if (elastic_index.includes("_bios_") && typeof (field_value) === "string") {
            const value_text = field_value.replace(/,/g, ", ");
            return <div dangerouslySetInnerHTML={{ __html: value_text }} />;
        }
        return <div dangerouslySetInnerHTML={{ __html: field_value }} />;
    }
}

function getLocaleDateString(date_string, time = false) {
    const language = store.getState().language;
    const date = new Date(date_string);
    const locale_date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date_location = getDateLocation(language);
    let locale_date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getUTCDate()));
    let locale_date_string = time ? locale_date.toLocaleTimeString(date_location, locale_date_options) : locale_date.toLocaleDateString(date_location, locale_date_options);
    if (language === "fr") {
        let new_locale_date_string = "";
        const locale_date_string_arr = locale_date_string.match(/\w+/g);
        for (var i = 0; i < locale_date_string_arr.length; i++) {
            const word = locale_date_string_arr[i];
            if (i === 0 || i === 2) {
                new_locale_date_string += word.replace(word[0], word[0].toUpperCase()) + (i === 0 ? ", " : " ");
            } else if (i === 1) {
                new_locale_date_string += word + " ";
            } else if (i === 3) {
                new_locale_date_string += word + (time ? ", " : "");
            } else {
                new_locale_date_string += word + (i === 6 ? "" : ":");
            }
        }
        /*locale_date_string.match(/\w+/g).map((word, index) => {
            if (index === 0 || index === 2) {
                new_locale_date_string += word.replace(word[0], word[0].toUpperCase()) + (index === 0 ? ", " : " ");
            } else if (index === 1) {
                new_locale_date_string += word + " ";
            } else if (index === 3) {
                new_locale_date_string += word + (time ? ", " : "");
            } else {
                new_locale_date_string += word + (index === 6 ? "" : ":");
            }
        });*/
        locale_date_string = new_locale_date_string;
    }
    return locale_date_string;
}

function getDateLocation(language) {
    switch (language) {
        case "de":
            return "de-DE";
        case "en":
            return "en-GB";
        case "fr":
            return "fr-FR"
        default:
            return "de-DE";
    }
}

function getPrice(price_field) {
    if (price_field === null || price_field === undefined) {
        return 0;
    }
    if (price_field.includes(translate("currency"))) {
        return price_field.substring(0, price_field.length - 2);
    }
    return price_field;
}

function includesObject(selectFields, obj) {
    for (let key in selectFields) {
        if (selectFields[key]["value_id"] === obj["value_id"]) {
            return true;
        }
    }
    /*Object.keys(selectFields).map(key => {
        if (selectFields[key]["value_id"] === obj["value_id"]) {
            return true;
        }
    });*/
    return false;
}

function getFieldData(field) {
    const { image_field } = store.getState().portalConfig;
    if (field === image_field || field.dataField === image_field) {
        return image_field;
    } else {
        return field.dataField;
    }
}

/**
 * soll alle fields, welche in der field_list usage="ROUTE" besitzen, als array zurückgeben
 */
function getRoutes() {

}

function getCheckedConfigValue(config_value, type, default_value, warn_id, max_value = null) {
    switch (type) {
        case "suffix_or_logo":
            if (config_value === null || typeof (config_value) !== "string" || !config_value.includes(".")) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value;
            }
        case "bool_as_int":
            if ((!config_value && config_value !== 0) || (config_value !== 0 && config_value !== 1)) {
                if (typeof (config_value) === "string" && new Array(0, 1).includes(parseInt(config_value))) {
                    return parseInt(config_value);
                }
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value;
            }
        case "url":
            if (config_value === null || typeof (config_value) !== "string" || !config_value.includes("http")) {
                configWarn(warn_id, config_value);
                if (typeof (default_value) !== "string") {
                    return default_value + "";
                } else {
                    return default_value;
                }
            } else {
                return config_value;
            }
        case "object":
            if (config_value === null || typeof (config_value) !== "object" || !Object.keys(config_value).length) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value;
            }
        case "array":
            if (config_value === null || typeof (config_value) !== "object" || config_value.length < 1) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value;
            }
        case "language":
            if (config_value === null) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                let tmp = "";
                if (typeof (config_value) !== "string") {
                    configWarn(warn_id, config_value);
                    tmp = (config_value + "").toLowerCase();
                } else {
                    tmp = config_value.toLowerCase();
                }
                if (tmp !== "de" && tmp !== "en" && tmp !== "fr") {
                    configWarn(warn_id, config_value);
                    return default_value;
                } else {
                    return tmp;
                }
            }
        case "elastic_index":
            if (!config_value || (typeof (config_value) !== "object" && typeof (config_value) !== "string")) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value;
            }
        case "number_array":
            if (config_value === null || config_value.length < 1) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value.map(value => {
                    if (typeof (value) !== "number") {
                        configWarn(warn_id, config_value);
                        return parseInt(value);
                    } else {
                        return value;
                    }
                });
            }
        case "number":
            if (config_value === null) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                if (typeof (config_value) === "number") {
                    return config_value;
                } else {
                    configWarn(warn_id, config_value);
                    return default_value;
                }
            }
        case "view_object":
            if (!config_value || (!config_value.hasOwnProperty("GRID") && !config_value.hasOwnProperty("LIST") &&
                !config_value.hasOwnProperty("TABLE"))) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                var view_array = [];
                if (config_value.hasOwnProperty("GRID") && config_value["GRID"] !== 0) {
                    view_array.push({
                        "view": "grid",
                        "value": config_value["GRID"]
                    });
                }
                if (config_value.hasOwnProperty("LIST") && config_value["LIST"] !== 0) {
                    view_array.push({
                        "view": "list",
                        "value": config_value["LIST"]
                    });
                }
                if (config_value.hasOwnProperty("TABLE") && config_value["TABLE"] !== 0) {
                    view_array.push({
                        "view": "table",
                        "value": config_value["TABLE"]
                    });
                }
                if (view_array.length < 1) {
                    configWarn(warn_id, config_value);
                    if (config_value.hasOwnProperty("FLEXIBLE") && config_value["FLEXIBLE"] !== 0) {
                        return ["grid", "custom"];
                    } else {
                        return ["grid"];
                    }
                } else {
                    view_array = view_array.sort(inverseViewCompare).map(obj => obj.view);
                    if (config_value.hasOwnProperty("FLEXIBLE") && config_value["FLEXIBLE"] !== 0) {
                        view_array.push("custom");
                    }
                    return view_array;
                }
            }
        case "string":
            if (config_value === null || typeof (config_value) !== "string") {
                configWarn(warn_id, config_value);
                if (typeof (default_value) !== "string") {
                    return default_value.toString();
                } else {
                    return default_value;
                }
            } else {
                if (max_value && parseInt(config_value) > parseInt(max_value)) {
                    return default_value;
                }
                return config_value;
            }
        case "version":
            if (!config_value || typeof (config_value) !== "string" || (config_value !== "0" && config_value !== "1")) {
                configWarn(warn_id, config_value);
                return default_value;
            } else {
                return config_value;
            }
        default:
            if (config_value === null || typeof (config_value) !== "string") {
                configWarn(warn_id, config_value);
                if (typeof (default_value) !== "string") {
                    return default_value.toString();
                } else {
                    return default_value;
                }
            } else {
                if (max_value && parseInt(config_value) > parseInt(max_value)) {
                    return default_value;
                }
                return config_value;
            }
    }
}

function configWarn(warn_id, config_value) {
    console.warn(warn_id + " besitzt keinen gültigen Wert!", config_value);
}

function inverseViewCompare(a, b) {
    if (a.value < b.value) {
        return 1;
    } else if (a.value > b.value) {
        return -1;
    } else {
        return 0;
    }
}

/**
 * Get the product by ID.
 * @param {object[]} products Array of products.
 * @param {string|number} product_id Product ID. 
 * @returns {object?} Product by ID or null if no product was found.
 */
function getProductByID(products, product_id) {
    if (product_id) {
        for (var i = 0; i < products.length; i++) {
            if (products[i][store.getState().portalConfig.keyfield].toString() === product_id.toString()) {
                return products[i];
            }
        }
    }
    return null;
}


/**
 * Format: DD.MM.JJJJ
 * @param {Date} date 
 */
function getDateString(date = new Date()) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    return day + "." + month + "." + date.getFullYear();

}

function getFileTimestamp() {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    const timestamp = "_" + date.getFullYear() + month + day + "_" + hours + minutes + seconds;
    return timestamp;
}

function getTimestamp(timestamp_as_id = false, timestamp_with_ms = false) {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    if (timestamp_as_id) {
        const year = date.getFullYear().toString().substr(2, date.getFullYear().toString().length);
        const timestamp = year + month + day + hours + minutes + seconds;
        if (timestamp_with_ms) {
            return timestamp + date.getMilliseconds();
        }
        return timestamp;
    } else {
        const timestamp = date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds + "." + date.getMilliseconds() + "Z";
        return timestamp;
    }
}

function getDefaultQuery(field = null, aggregationsField = null, default_filter1 = null) {
    const default_filter = default_filter1 ? default_filter1 : store.getState().portalConfig.default_filter;
    const sort_by = field && field.filter_props && field.filter_props.sortBy ? field.filter_props.sortBy : "_key";
    const sort_order = field && field.filter_props && field.filter_props.sortOrder ? field.filter_props.sortOrder : "asc";
    let query = {};
    if (aggregationsField) {
        query = {
            "aggs": {
                "values": {
                    "filter": {
                        "bool": {
                            "must": [
                                {
                                    "regexp": {
                                        [aggregationsField]: "@&.*[a-zA-Z0-9]+.*"
                                    }
                                }
                            ]
                        }
                    },
                    /*"filter": {
                        "script": {
                            "script": {
                                "source": "doc['" + aggregationsField + "'].size() > 0 && doc['" + aggregationsField + "'].value.trim().length() > 0"
                            }
                        }
                    },*/
                    "aggs": {
                        "values": {
                            "terms": {
                                "field": aggregationsField,
                                "order": {
                                    [sort_by]: sort_order
                                }
                            }
                        }
                    }
                },
                /*"empty_values": {
                    "filter": {
                        "script": {
                            "script": {
                                "source": "doc['" + aggregationsField + "'].size() > 0 && doc['" + aggregationsField + "'].value.trim().length() === 0"
                            }
                        }
                    },
                    "aggs": {
                        "empty_values": {
                            "terms": {
                                "field": aggregationsField
                            }
                        }
                    }
                },
                "null_values": {
                    "filter": {
                        "bool": {
                            "must_not": {
                                "exists": {
                                    "field": aggregationsField
                                }
                            }
                        }
                    },
                    "aggs": {
                        "null_values": {
                            "terms": {
                                "field": aggregationsField
                            }
                        }
                    }
                },*/
                "empty_values": {
                    "filter": {
                        "bool": {
                            "must_not": [
                                {
                                    "regexp": {
                                        [aggregationsField]: "@&.*[a-zA-Z0-9]+.*"
                                    }
                                }
                            ]
                        }
                    },
                    "aggs": {
                        "empty_values": {
                            "terms": {
                                "field": aggregationsField
                            }
                        }
                    }
                }
                /*"empty_values": {
                    "filter": {
                        "bool": {
                            "should": [
                                {
                                    "script": {
                                        "script": {
                                            "source": "InternalSqlScriptUtils.nullSafeFilter(InternalSqlScriptUtils.eq(InternalSqlScriptUtils.ltrim(InternalSqlScriptUtils.docValue(doc,params.v0)),params.v1))",
                                            "lang": "painless",
                                            "params": {
                                                "v0": aggregationsField,
                                                "v1": ""
                                            }
                                        },
                                        "boost": 1
                                    }
                                },
                                {
                                    "bool": {
                                        "must_not": [
                                            {
                                                "exists": {
                                                    "field": aggregationsField,
                                                    "boost": 1
                                                }
                                            }
                                        ],
                                        "adjust_pure_negative": true,
                                        "boost": 1
                                    }
                                }
                            ]
                        },
                    }
                }*/
            },
            "track_total_hits": true
        }
    }
    if (Array.isArray(default_filter)) {
        if (default_filter[0]["default"] && !aggregationsField) {
            query = {
                query: {
                    bool: {
                        must: [
                            {
                                "match_all": {}
                            }
                        ]
                    }
                },
                "track_total_hits": true
            };
        } else {
            query.query = {
                "bool": getFetchFilterNew(default_filter)
            };
        }
    } else {
        if (default_filter["match_all"] && !aggregationsField) {
            query = {
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match_all": {}
                            }
                        ]
                    }
                },
                "track_total_hits": true
            };
        } else {
            if (default_filter["match_all"]) {
                query.query = {
                    bool: {
                        must: [default_filter]
                    }
                };
            } else {
                query.query = default_filter;
            }
        }
    }
    if (!aggregationsField) {
        query["sort"] = [
            {
                "_id": "asc"
            }
        ];
    }
    //console.log(JSON.stringify(query));
    //console.log(query);
    return query;
}

/**
 * überprüft den wert von multiselect_maxchar des mitgegebenen fields und gibt den wert zurück, falls multiselect_maxchar
 * existiert und !== 0 ist. ansonsten wird 150 zurück gegeben.
 * @param {{}} field 
 * @returns {number}
 */
function getMultiselectMaxcharByField(field) {
    //console.log(field);
    let maxLength = 150;
    if (field.multiselect_maxchar && field.multiselect_maxchar !== "0") {
        maxLength = parseInt(field.multiselect_maxchar);
    }
    //console.log(maxLength);
    return maxLength;
}

function getSelectFieldsByID(selectFields, id) {
    /*if (!id) {
        return selectFields;
    }*/
    if (id && selectFields.hasOwnProperty(id)) {
        return selectFields[id];
    }
    return [];
}

function getTableRowIDField(table_row_structure) {
    for (var i = 0; i < table_row_structure.length; i++) {
        if (table_row_structure[i].usage.includes("ID")) {
            //console.log(table_row_structure[i]);
            return table_row_structure[i].dataField;
        }
    }
    return null;
}

function getArticleSubtables(item) {
    let subtables = {};
    for (let field of store.getState().portalConfig.field_list) {
        if (field.dataField.includes(":JSON") && item[field.dataField]) {
            subtables[field.dataField] = JSON.parse(item[field.dataField]);
        }
    }
    console.log(subtables);
    return subtables;
}

function getSubtableFields(field_list) {
    let subtable_fields = [];
    for (let field of field_list) {
        if (field.dataField.includes(":JSON")) {
            subtable_fields.push(field.dataField);
        }
    }
    /*field_list.map(field => {
        if (field.dataField.includes(":JSON")) {
            //subtables[field.dataField] = JSON.parse(item[field.dataField]);
            subtable_fields.push(field.dataField);
            //console.log(field);
        }
    });*/
    //console.log(field_list);
    //console.log(subtables);
    return subtable_fields;
}

function getSubtableFieldsByDatafield(dataField) {
    let subtable_fields = [];
    subtable_fields = store.getState().portalConfig.field_list_json.filter(json => json.hasOwnProperty(dataField))[0][dataField];
    return subtable_fields;
}

function isObject(value) {
    return value !== null && typeof (value) === "object";
}

/*function checkCompareOrder(a, b) {
    //console.log(a);
    switch (typeof (a)) {
        case "string":
            return compareAlphabeticalOrder(a, b);
        default:
            return 0;
    }
}*/

function deepEqual(object1 = {}, object2 = {}) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (areObjects && !deepEqual(val1, val2)) {
            return false;
        } else if (!areObjects && val1 !== val2) {
            return false;
        }
    }
    return true;
}

/**
 * bereitet ein produkt zum speichern in den store vor indem nicht genutzte parameter gelöscht werden
 */
function getCheckedProduct(product) {
    let checked_product = Object.assign({}, product);
    if (checked_product.hasOwnProperty("highlight")) {
        delete checked_product["highlight"];
    }
    if (checked_product.hasOwnProperty("_index")) {
        delete checked_product["_index"];
    }
    if (checked_product.hasOwnProperty("_type")) {
        delete checked_product["_type"];
    }
    if (checked_product.hasOwnProperty("_id")) {
        delete checked_product["_id"];
    }
    if (checked_product.hasOwnProperty("_score")) {
        delete checked_product["_score"];
    }
    //delete checked_product["_click_id"];
    if (checked_product.hasOwnProperty("_click_id")) {
        delete checked_product["_click_id"];
    }
    if (checked_product.hasOwnProperty("sort")) {
        delete checked_product["sort"];
    }
    return checked_product;
}

function addToFetchedProducts(data) {
    const keyfield = store.getState().portalConfig.keyfield;
    const fetchedProducts = store.getState().fetchedProducts;
    let addProducts = [];
    let updateProducts = [];
    for (let product of data) {
        const checked_product = getCheckedProduct(product);
        var includes = false;
        var equal_index = -1;
        for (var i = 0; i < fetchedProducts.length; i++) {
            if (!fetchedProducts[i] || !checked_product) {
                //console.log(1);
            }
            if (fetchedProducts[i].hasOwnProperty("sort") || checked_product.hasOwnProperty("sort")) {
                console.log(1);
            }
            if (fetchedProducts[i][keyfield] === checked_product[keyfield]) {
                includes = true;
                if (!deepEqual(fetchedProducts[i], checked_product)) {
                    equal_index = i;
                    //console.log(fetchedProducts[i]);
                    //console.log(checked_product);
                    //const a = deepEqual(fetchedProducts[i], checked_product);
                }
                i = fetchedProducts.length;
            }
        }
        if (!includes) {
            addProducts.push(checked_product);
            //console.log(item);
        } else if (equal_index !== -1) {
            updateProducts.push({ product: checked_product, index: equal_index });
        }
    }
    if (addProducts.length > 0) {
        //console.log(addProducts);
        store.dispatch(add_products(addProducts));
    }
    if (updateProducts.length > 0) {
        //console.log(updateProducts);
        store.dispatch(update_products(updateProducts));
    }
}

function checkFieldFilter(data_field, filter) {
    const field_list = store.getState().portalConfig.field_list;
    for (var i = 0; i < field_list.length; i++) {
        if (field_list[i].dataField === data_field) {
            return field_list[i].filter === filter;
        }
    }
    return false;
}

function getFieldListFieldByLabelField(label_field) {
    const field_list = store.getState().portalConfig.field_list;
    for (var i = 0; i < field_list.length; i++) {
        if (field_list[i].text === label_field) {
            return field_list[i];
        }
    }
    return null;
}

function getMappedData(data, mapping_fields) {
    //console.log(data);
    //console.log(mapping_fields);
    //let data_keys = data.map(data_field => data_field.key);
    let mapped_data = [];
    for (let map_field of mapping_fields) {
        for (let data_field of data) {
            if (data_field.key === map_field.value) {
                let new_field = Object.assign({}, map_field);
                new_field.doc_count = data_field.doc_count;
                mapped_data.push(new_field);
            }
        }
    }
    /*mapping_fields.map(map_field => {
        data.map(data_field => {
            if (data_field.key === map_field.value) {
                let new_field = Object.assign({}, map_field);
                new_field.doc_count = data_field.doc_count;
                mapped_data.push(new_field);
            }
        });
    });*/
    //console.log(mapped_data);
    return mapped_data;
}

/**
 * gibt die field_list-fields, welche sich nicht im mitgegebenen fields_array befinden, in alphabetisch sortierter reihenfolge zurück.
 * wenn action_field = true, wird dem sortiertem array das action_field (zum anzeigen der buttons) hinzugefügt, wenn nirgends vorhanden.
 * @param {array} fields_array 
 * @param {boolean} action_field 
 * @returns 
 */
function getFieldListFields(fields_array = [], action_field = false) {
    const { portalConfig } = store.getState();
    let selected_fields = [];
    let field_list_fields = [];
    for (let field of fields_array) {
        selected_fields.push(field.dataField);
    }
    /*fields_array.map(field => {
        selected_fields.push(field.dataField)
    });*/
    if (action_field) {
        if (!selected_fields.includes("actions_field")) {
            field_list_fields.push({ dataField: "actions_field" });
        }
    }
    for (let field of portalConfig.field_list.sort((a, b) => compareAlphabeticalOrder(a.dataField, b.dataField))) {
        if (field.usage.includes("SHOW") && field.dataField !== portalConfig.image_field && !field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE")) {
            if (!selected_fields.includes(field.dataField)) {
                field_list_fields.push(field);
            }
        }
    }
    /*portalConfig.field_list.filter(field => field.usage.includes("SHOW") && field.dataField !== portalConfig.image_field && !field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE")).sort((a, b) => compareAlphabeticalOrder(a.dataField, b.dataField)).map(field => {
        if (!selected_fields.includes(field.dataField)) {
            field_list_fields.push(field);
        }
    });*/
    return field_list_fields;
}

function compareAlphabeticalOrder(a, b) {
    const a_lower_case = a.toLowerCase();
    const b_lower_case = b.toLowerCase();
    if (a_lower_case < b_lower_case) {
        return -1;
    }
    if (a_lower_case > b_lower_case) {
        return 1;
    }
    return 0;
}

/**
 * gibt ein produkt-datenfeld als string zurück.
 * @param {*} dataField product[dataField]
 * @returns 
 */
function getDatafieldAsString(dataField) {
    if (typeof (dataField) === "string") {
        return dataField;
    } else {
        if (Array.isArray(dataField)) {
            return dataField.toString();
        } else {
            return JSON.stringify(dataField);
        }
    }
}

/**
 * gibt die select-field id anhand dem fieldnamen zurück. existiert kein entsprechendes select-field, wird der fieldname zurück gegeben.
 * @param {string} field_name field.dataField
 * @param {object[]} select_fields store.selectFields
 * @returns {string}
 */
function getSelectFieldID(field_name, select_fields) {
    const portalConfig = store.getState().portalConfig;
    for (var i = 0; i < select_fields.length; i++) {
        if (field_name && select_fields[i][portalConfig.foreignkey_reference2main].toString() === field_name.toString()) {
            return select_fields[i][portalConfig.foreignkey_reference2changes] + "";
        }
    }
    return field_name;
}

function getSelectFieldName(id, select_fields) {
    const portalConfig = store.getState().portalConfig;
    if (select_fields) {
        for (var i = 0; i < select_fields.length; i++) {
            //let x = select_fields[i][portalConfig.foreignkey_reference2changes];
            if (select_fields[i][portalConfig.foreignkey_reference2changes] && select_fields[i][portalConfig.foreignkey_reference2changes].toString() === id.toString()) {
                return select_fields[i][portalConfig.foreignkey_reference2main] + "";
            }
        }
    }
    return id;
}

function getDeleteBulkData(index_id) {
    return JSON.stringify({ "delete": { "_id": index_id } }) + "\n";
}

function getChangeIndexBulkData(action, field_data, index_id = "") {
    //console.log(field_data);
    let bulk_data = Object.assign({}, field_data);
    if (bulk_data.changes) {
        bulk_data.changes = JSON.stringify(bulk_data.changes);
    }
    bulk_data.date = getTimestamp();
    return JSON.stringify({ [action]: index_id ? { "_id": index_id } : {} }) + "\n" + JSON.stringify(index_id ? { "doc": bulk_data } : bulk_data) + "\n";
}

function getUpdateSourceBulkData(action, field_data, index_id = "") {
    //console.log(field_data);
    return JSON.stringify({ [action]: index_id ? { "_id": index_id } : {} }) + "\n" + JSON.stringify(index_id ? { "doc": field_data } : field_data) + "\n";
}

function deleteProductsInStore(delete_products_ids) {
    const { articleTabs, compareItems, activeArticle, shoppingCart, downloadTableModalProps } = store.getState();
    const tabs = articleTabs.tabs;
    if (delete_products_ids.includes(activeArticle)) {
        store.dispatch(set_active_article("main"));
    }
    if (tabs.length) {
        const new_tabs = tabs.filter(tab_id => !delete_products_ids.includes(tab_id));
        store.dispatch(set_tabs(new_tabs));
    }
    if (compareItems.length) {
        const new_compare_items = compareItems.filter(item_id => !delete_products_ids.includes(item_id));
        store.dispatch(set_compare_items(new_compare_items));
    }
    if (shoppingCart.length) {
        const new_shopping_cart = shoppingCart.filter(item => !delete_products_ids.includes(item.id_));
        store.dispatch(set_shopping_cart(new_shopping_cart));
    }
    if (downloadTableModalProps.productIDs.length) {
        const new_download_table_product_ids = downloadTableModalProps.productIDs.filter(item_id => !delete_products_ids.includes(item_id));
        store.dispatch(set_download_table_products(new_download_table_product_ids));
    }
}

/**
* Function to upload edited product fields to elasticsearch change index and source index.
*/
function uploadChanges(changeIndexFields = store.getState().changeIndexFields, updateSourceFields = store.getState().updateSourceFields) {
    //updateFetchedProducts(updateSourceFields);
    const { fetchedProducts, portalConfig } = store.getState();
    let new_fetched_products = [];
    let delete_products_ids = [];
    for (var i = 0; i < fetchedProducts.length; i++) {
        const product = fetchedProducts[i];
        let updated_counter = 0;
        if (updated_counter < updateSourceFields.length) {
            let no_updated_counter = 0;
            for (var j = 0; j < updateSourceFields.length; j++) {
                const new_product_data = updateSourceFields[j];
                if (new_product_data.ID.toString() === product[portalConfig.keyfield].toString()) {
                    if (new_product_data.changes === "delete") {
                        delete_products_ids.push(product[portalConfig.keyfield].toString());
                    } else {
                        let new_product = Object.assign(Object.assign({}, product), new_product_data.changes);
                        new_fetched_products.push(new_product);
                    }
                    updated_counter++;
                    j = updateSourceFields.length;
                } else {
                    no_updated_counter++;
                }
            }
            if (no_updated_counter === updateSourceFields.length) {
                new_fetched_products.push(product);
            }
        } else {
            new_fetched_products.push(product);
        }
    }
    store.dispatch(change_updating());
    if (delete_products_ids.length) {
        deleteProductsInStore(delete_products_ids);
    }
    store.dispatch(set_fetched_products(new_fetched_products));
    const test = false;
    if (test) {
        console.log("test");
    } else {
        const change_index_bulk = getChangeIndexBulk(changeIndexFields);
        const update_source_bulk = getUpdateSourceBulk(updateSourceFields);
        Promise.all([change_index_bulk, update_source_bulk]).then(() => {
            resetChanges();
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        });
    }
}

function resetChanges() {
    store.dispatch(clear_change_index_fields());
    store.dispatch(clear_update_source_fields());
    store.dispatch(clear_edit());
    store.dispatch(set_clear_subtable());
    if (store.getState().saveChanges) {
        store.dispatch(switch_save_changes());
    }
    if (store.getState().edit) {
        store.dispatch(switch_edit());
    }
}

function getChangeIndexFields(state, productID, language, is_subtable_field, is_subtable_modal, value, field, elastic_index_changes_structure, elastic_index, new_row, multiEdit = false, isNewProduct = false) {
    let new_state = [];
    for (let field of state) {
        new_state.push(field);
    }
    /*state.map(field => {
        new_state.push(field);
    });*/
    for (var i = 0; i < state.length; i++) {
        if (state[i]["ID"] === productID.toString()) {
            if (state[i].language === language) {
                if ((!is_subtable_field && !is_subtable_modal && state[i].subtable_field === "") || (is_subtable_modal && state[i].subtable_field !== "" && state[i].modus !== "delete" && state[i].subtable_id === value.table_row_id) || (is_subtable_field && state[i].subtable_field !== "" && state[i].modus !== "delete" && state[i].subtable_id === value.table_row_id)) {
                    let changes = Object.assign({}, state[i].changes);
                    if (is_subtable_modal) {
                        changes = Object.assign(Object.assign({}, changes), value.table_row);
                    } else {
                        if (is_subtable_field) {
                            if (value.changes === "deleted") {
                                if (state[i].modus === "create") {
                                    new_state = [];
                                    for (var j = 0; j < state.length; j++) {
                                        const field = state[j];
                                        if (j !== i) {
                                            new_state.push(field);
                                        }
                                    }
                                    /*state.map((field, index) => {
                                        if (index !== i) {
                                            new_state.push(field);
                                        }
                                    });*/
                                    return new_state;
                                }
                                changes = "";
                                new_state[i].modus = "delete";
                            } else {
                                changes[value.changes.key] = value.changes.value;
                            }
                        } else {
                            changes[field] = value;
                        }
                    }
                    new_state[i].changes = changes;
                    if (multiEdit) {
                        return new_state[i];
                    }
                    return new_state;
                }
            }
        }
    }
    let obj = {}
    obj["ID"] = productID.toString();
    obj["language"] = language;
    obj["date"] = "";
    let changed = {};
    changed[field] = value;
    obj["changes"] = changed;
    obj["index"] = elastic_index;
    if (is_subtable_modal) {
        obj["changes"] = value.table_row;
        obj["target"] = "subtable";
        obj["subtable_id"] = value.table_row_id;
        obj["subtable_field"] = field;
        if (new_row) {
            obj["modus"] = "create";
        } else {
            obj["modus"] = "update";
        }
    } else {
        if (is_subtable_field) {
            obj["target"] = "subtable";
            obj["subtable_id"] = value.table_row_id;
            obj["subtable_field"] = field;
            if (value.changes === "deleted") {
                obj["changes"] = "";
                obj["modus"] = "delete";
            } else {
                obj["changes"] = {
                    [value.changes.key]: value.changes.value
                };
                obj["modus"] = "update";
            }
        } else {
            obj["target"] = "maintable";
            obj["subtable_id"] = "";
            obj["subtable_field"] = "";
            obj["changes"] = {
                [field]: value
            };
            obj["modus"] = isNewProduct ? "create" : "update";
        }
    }
    obj["changed_by"] = "admin";
    obj["ID_new"] = "";
    if (multiEdit) {
        //console.log(obj);
        return obj;
    }
    new_state.push(obj);
    //console.log(obj);
    return new_state;
}

function getUpdateSourceFields(state, productID, language, field, value, multiEdit = false, isNewProduct = false) {
    let new_state = [];
    for (let field of state) {
        new_state.push(field);
    }
    /*state.map(field => {
        new_state.push(field);
    });*/
    const parsed_value = Array.isArray(value) ? value.map(val => val.text ? val.text : val) : value;
    for (var i = 0; i < new_state.length; i++) {
        if (new_state[i]["ID"] === productID.toString()) {
            if (new_state[i]["language"] === language) {
                let changes = new_state[i]["changes"];
                changes[field] = parsed_value;
                new_state[i]["changes"] = changes;
                //console.log(new_state)
                if (multiEdit) {
                    return new_state[i];
                }
                return new_state;
            }
        }
    }
    let obj = {};
    obj["ID"] = productID.toString();
    obj["language"] = language;
    obj["changes"] = {
        [field]: parsed_value
        //[isNewProduct ? field + ".keyword" : field]: parsed_value
    };
    if (multiEdit) {
        //console.log(obj);
        return obj;
    }
    new_state.push(obj);
    //console.log(new_state);
    return new_state;
}

function getNewCheckboxValue(value = null, inverse = false) {
    let new_value = null;
    if (value) {
        if (typeof (value) === "string") {
            if (value === "1") {
                new_value = "0";
            } else if (value === "0") {
                new_value = "1";
            } else if (value === "true") {
                new_value = "false";
            } else if (value === "false") {
                new_value = "true";
            }
        } else if (typeof (value) === "number") {
            if (value === 0) {
                new_value = 1;
            } else {
                new_value = 0;
            }
        } else {
            new_value = false;
        }
    } else {
        const edit_checkbox_type = getEditCheckboxType(value);
        if (edit_checkbox_type.type === "string") {
            if (edit_checkbox_type.value === "number") {
                new_value = inverse ? "0" : "1";
            } else {
                new_value = inverse ? "false" : "true";
            }
        } else if (edit_checkbox_type.type === "number") {
            new_value = inverse ? 0 : 1;
        } else {
            new_value = inverse ? false : true;
        }
    }
    return new_value;
}

function getEditCheckboxType(item_key) {
    let edit_checkbox_type = {};
    if (item_key) {
        if (typeof (item_key) === "string") {
            if (item_key.length === 1) {
                edit_checkbox_type = {
                    type: "string",
                    value: "number"
                }
            } else {
                edit_checkbox_type = {
                    type: "string",
                    value: "bool"
                }
            }
        } else if (typeof (item_key) === "number") {
            edit_checkbox_type = {
                type: "number"
            }
        } else {
            edit_checkbox_type = {
                type: "bool"
            }
        }
    } else {
        edit_checkbox_type = {
            type: "bool"
        }
    }
    return edit_checkbox_type;
}

function saveEditStore(item_id, field, value, multiselect = false, select_fields = null, fieldListField = null, subtable_modal = null, table_row_id_field = null, isSubtableModal = null, setEditSubtable = null, setSubtableModal = null, isNewProduct = false) {
    const { portalConfig, language, changeIndexFields, updateSourceFields, newCopiedProducts } = store.getState();
    const elastic_index = getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language);
    const keyfield = portalConfig.keyfield;
    const multiselect_separator = portalConfig.multiselect_separator;
    let dataField = field.dataField;
    let editField = field.editField && field.editField !== "0" ? field.editField : false;
    if (fieldListField) {
        dataField = fieldListField.dataField;
        if (fieldListField.editField && fieldListField.editField !== "0") {
            editField = fieldListField.editField;
        } else {
            editField = false;
        }
    }
    let edited_subtable = [];
    let edited_subtable_row = {};
    let is_subtable_field = false;
    let subtable_modal_row = {};
    if (subtable_modal && subtable_modal.item_id === item_id) {
        for (let subtable_row of subtable_modal.subtable) {
            if (subtable_row[table_row_id_field] === subtable_modal.subtable_row_id) {
                let new_subtable_row = Object.assign({}, subtable_row);
                new_subtable_row[field.dataField] = value;
                subtable_modal_row = Object.assign({}, new_subtable_row);
                edited_subtable.push(new_subtable_row);
                edited_subtable_row = {
                    "table_row_id": subtable_modal.subtable_row_id,
                    "changes": {
                        "key": field.editField ? field.editField : field.dataField,
                        "value": value
                    }
                };
                is_subtable_field = true;
            } else {
                edited_subtable.push(subtable_row);
            }
        }
        if (!isSubtableModal) {
            setEditSubtable(edited_subtable);
        }
    }
    if (isSubtableModal) {
        setSubtableModal(edited_subtable, edited_subtable_row, table_row_id_field, subtable_modal_row);
    } else {
        store.dispatch(add_edit_field(item_id, editField ? editField : dataField, language, !is_subtable_field ? value : edited_subtable));
        let change_index_value = value;
        let update_source_value = value;
        if (multiselect) {
            change_index_value = multiselect_separator === "array" ? [] : "";
            update_source_value = [];
            for (var i = 0; i < value.length; i++) {
                const val = value[i];
                if (multiselect_separator === "array") {
                    change_index_value.push(val["value"].toString());
                } else {
                    change_index_value += val["value"].toString();
                    if (i < value.length - 1) {
                        if (multiselect_separator === "\n") {
                            change_index_value += "X_enter_X";
                        } else {
                            change_index_value += multiselect_separator;
                        }
                    }
                }
                update_source_value.push(field.filter === "MultiDataList" ? val["value"] : val["text"]);
            }
        }
        let change_index_fields = getChangeIndexFields(changeIndexFields, item_id, language, is_subtable_field, isSubtableModal, !is_subtable_field ? change_index_value : edited_subtable_row, editField ? editField : dataField, "", elastic_index, false, false, isNewProduct);
        let update_source_fields = getUpdateSourceFields(updateSourceFields, item_id, language, dataField, is_subtable_field ? JSON.stringify(edited_subtable) : field.filter === "MultiDataList" ? update_source_value : getSelectFieldName(update_source_value, select_fields), false, isNewProduct);
        if (newCopiedProducts.includes(item_id)) {
            let field_exists = false;
            for (var j = 0; j < changeIndexFields.length; j++) {
                if (changeIndexFields[j][keyfield] === item_id) {
                    field_exists = true;
                    j = changeIndexFields.length;
                }
            }
            if (!field_exists) {
                let { new_copied_product, mapped_key_obj } = createNewCopiedProduct(item_id);
                new_copied_product[keyfield] = item_id;
                change_index_fields = changeIndexFields;
                let new_change_index_field = {};
                for (let key in new_copied_product) {
                    if (new_copied_product[key]) {
                        const mapped_key = mapped_key_obj[key] ? mapped_key_obj[key] : key;
                        new_change_index_field = merge(new_change_index_field, getChangeIndexFields(change_index_fields, item_id, language, false, false, new_copied_product[key], mapped_key, "", elastic_index, false, true, isNewProduct));
                    }
                }
                change_index_fields.push(new_change_index_field);
                change_index_fields = getChangeIndexFields(change_index_fields, item_id, language, is_subtable_field, isSubtableModal, !is_subtable_field ? change_index_value : edited_subtable_row, editField ? editField : dataField, "", elastic_index, false, false, isNewProduct)
                update_source_fields = updateSourceFields;
                let new_field = {};
                for (let key in new_copied_product) {
                    if (new_copied_product[key]) {
                        new_field = merge(new_field, getUpdateSourceFields(update_source_fields, item_id, language, key, new_copied_product[key], true, isNewProduct));
                    }
                }
                update_source_fields.push(new_field);
                update_source_fields = getUpdateSourceFields(update_source_fields, item_id, language, dataField, is_subtable_field ? JSON.stringify(edited_subtable) : field.filter === "MultiDataList" ? update_source_value : getSelectFieldName(update_source_value, select_fields), false, isNewProduct);
            }
        }
        store.dispatch(set_change_index_fields(change_index_fields));
        store.dispatch(set_update_source_fields(update_source_fields));
        enableSaveEditButton();
    }
}

function enableSaveEditButton() {
    if (!store.getState().saveChanges) {
        store.dispatch(switch_save_changes());
    }
}

function openArticleDetails(event, item_id, isNewReferenceModal = false) {
    //console.log(event);
    //if (event.target.tagName === "IMG" || event.target.className.includes("info") || event.target.className.includes("buttons-td")) {
    if (isNewReferenceModal) {
        if (store.getState().newReferenceModalProps.articleTabs.tabs.includes(item_id)) {
            store.dispatch(set_active_article_modal(item_id));
        } else {
            store.dispatch(add_new_tab_modal(item_id));
            store.dispatch(set_active_article_modal(item_id));
        }
    } else {
        if (store.getState().articleTabs.tabs.includes(item_id)) {
            store.dispatch(set_active_article(item_id));
        } else {
            setActiveArticle(item_id);
        }
    }
    //}
}

function selectNewReference(item_id) {
    store.dispatch(select_new_reference(item_id));
}

function deselectNewReference(item_id) {
    store.dispatch(deselect_new_reference(item_id));
}

function addToDownloadTable(item_id, notify) {
    if (!store.getState().downloadTableModalProps.productIDs.includes(item_id)) {
        store.dispatch(add_download_table_product(item_id));
        notify("download_table_success");
    } else {
        notify("download_table_info")
    }
}

function openShoppingCartModal(item_id) {
    store.dispatch(set_shopping_cart_modal());
    store.dispatch(set_shopping_cart_modal_item(item_id));
}

function openCopyProductModal(item_id) {
    openNewCopiedProductTab(item_id);
}

function addToCompareList(item_id, notify) {
    if (!store.getState().compareItems.includes(item_id)) {
        store.dispatch(add_compare_item(item_id));
        notify("compare_success");
    } else {
        notify("compare_info");
    }
}

function openDeleteProductModal(item_id) {
    store.dispatch(change_delete_product_modal_status());
    store.dispatch(delete_product_by_id(item_id));
}

function getButtons(item_id, notify, className, isNewReferenceModal = false, article_buttons = false) {
    const { portalConfig, newReferenceModalProps, edit, fetchedProducts } = store.getState();
    const { color_config, show_pdf, field_list, label_field, pdf_config, show_details, show_excel, show_basket, show_comparelist } = portalConfig;
    return (
        <div
            className={className}
        //onClick={(event) => openArticleDetails(event, item_id, isNewReferenceModal)}
        >
            {show_pdf && !isNewReferenceModal && article_buttons && !edit ?
                //(show_pdf || show_excel) && !isNewReferenceModal && article_buttons && !edit ?
                <PDF
                    item_id={item_id}
                    item={getProductByID(fetchedProducts, item_id)}
                    registerTabList={getArticleRegisterTabList(field_list)}
                    field_list={field_list}
                    label_field={label_field}
                    pdf_config={pdf_config}
                    color_config={color_config}
                />
                :
                null
            }
            {!show_details || article_buttons ? null :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button info-button"
                        //style={{ backgroundColor: color_config.view_button_color }}
                        onClick={(event) => openArticleDetails(event, item_id, isNewReferenceModal)}
                    >
                        <MDBIcon icon="info" className="info-icon" />
                    </button>
                    <span>{translate("details")}</span>
                </MDBTooltip>
            }
            {isNewReferenceModal ? newReferenceModalProps.selectedReferences && newReferenceModalProps.selectedReferences.includes(item_id) ?
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button delete-button"
                        //style={{ backgroundColor: color_config.view_button_color }}
                        onClick={() => deselectNewReference(item_id)}
                    >
                        <MDBIcon icon="minus" className="delete-icon" />
                    </button>
                    <span>{translate("delete")}</span>
                </MDBTooltip>
                :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button select-button"
                        //style={{ backgroundColor: color_config.view_button_color }}
                        onClick={() => selectNewReference(item_id)}
                    >
                        <MDBIcon icon="plus" className="select-icon" />
                    </button>
                    <span>{translate("add_reference")}</span>
                </MDBTooltip>
                :
                null
            }
            {!show_excel || isNewReferenceModal || edit ? null :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button"
                        //style={{ backgroundColor: color_config.view_button_color }}
                        onClick={() => addToDownloadTable(item_id, notify)}
                    >
                        <MDBIcon icon="file-download" />
                    </button>
                    <span>{translate("add_to_download_table")}</span>
                </MDBTooltip>
            }
            {!show_basket || isNewReferenceModal || edit ? null :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button"
                        //style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color, borderColor: color_config.view_button_text_color }}
                        onClick={() => openShoppingCartModal(item_id)}
                    >
                        <MDBIcon icon="shopping-cart" />
                    </button>
                    <span>{translate("add_to_cart", "article")}</span>
                </MDBTooltip>
            }
            {!edit || isNewReferenceModal ? null :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button"
                        //style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color, borderColor: color_config.view_button_text_color }}
                        onClick={() => openCopyProductModal(item_id)}
                    >
                        <MDBIcon icon="copy" />
                    </button>
                    <span>{translate("Copy")}</span>
                </MDBTooltip>
            }
            {!show_comparelist || edit || isNewReferenceModal ? null :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button"
                        //style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color, borderColor: color_config.view_button_text_color }}
                        onClick={() => addToCompareList(item_id, notify)}
                    >
                        <MDBIcon icon="exchange-alt" />
                    </button>
                    <span>{translate("add_to_compare_list")}</span>
                </MDBTooltip>
            }
            {!edit || isNewReferenceModal ? null :
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <button
                        className="btn-xs article-button delete-button"
                        //style={{ backgroundColor: color_config.view_button_color }}
                        onClick={() => openDeleteProductModal(item_id)}
                    >
                        <MDBIcon icon="trash-alt" className="delete-icon" />
                    </button>
                    <span>{translate("delete")}</span>
                </MDBTooltip>
            }
        </div>
    );
}

function getMultiSelectButtons(callBack, className, isNewReferenceModal = false) {
    const { portalConfig, multiEdit, edit } = store.getState();
    const { show_excel, show_comparelist } = portalConfig;
    return (
        <MDBRow className={className}>
            {!show_excel || isNewReferenceModal || edit ? null :
                <button
                    className="btn-xs article-button"
                    onClick={() => callBack("download")}
                >
                    <MDBIcon icon="file-download" />
                </button>
            }
            {!show_comparelist || isNewReferenceModal || edit ? null :
                <button
                    className="btn-xs article-button"
                    onClick={() => callBack("compare")}
                >
                    <MDBIcon icon="exchange-alt" />
                </button>
            }
            {!edit || isNewReferenceModal || multiEdit ? null :
                <button
                    className="btn-xs article-button"
                    onClick={() => callBack("copy")}
                >
                    <MDBIcon icon="copy" />
                </button>
            }
            {!edit || isNewReferenceModal || multiEdit ? null :
                <button
                    className="btn-xs article-button delete-button"
                    onClick={() => callBack("delete")}
                >
                    <MDBIcon icon="trash-alt" className="delete-icon" />
                </button>
            }
        </MDBRow>
    );
}

function createNewCopiedProduct(copied_product_id) {
    const { fetchedProducts, portalConfig } = store.getState();
    const product = getProductByID(fetchedProducts, copied_product_id);
    let new_copied_product = {};
    let mapped_key_obj = {};
    for (let field of portalConfig.field_list) {
        if (field.editField && field.editField !== "0") {
            mapped_key_obj[field.dataField] = field.editField;
        }
        new_copied_product[field.dataField] = product[field.dataField];
    }
    new_copied_product[portalConfig.keyfield] = getTimestamp(true);
    console.log(new_copied_product);
    console.log(mapped_key_obj);
    return { new_copied_product, mapped_key_obj };
}

function saveCopiedProductChangeIndex(new_copied_product, mapped_key_obj) {
    const { portalConfig, language, changeIndexFields } = store.getState();
    const elastic_index = getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language);
    const keyfield = portalConfig.keyfield;
    let change_index_fields = [...changeIndexFields];
    let new_field = {};
    for (let key in new_copied_product) {
        if (new_copied_product[key]) {
            const mapped_key = mapped_key_obj[key] ? mapped_key_obj[key] : key;
            new_field = merge(new_field, getChangeIndexFields(change_index_fields, new_copied_product[keyfield], language, false, false, new_copied_product[key], mapped_key, "", elastic_index, false, true, true));
        }
    }
    change_index_fields.push(new_field);
    console.log(change_index_fields);
    store.dispatch(set_change_index_fields(change_index_fields));
}

function saveCopiedProductUpdateSourceIndex(new_copied_product) {
    const { portalConfig, language, updateSourceFields } = store.getState();
    const keyfield = portalConfig.keyfield;
    let update_source_fields = [...updateSourceFields];
    let new_field = {};
    for (let key in new_copied_product) {
        if (new_copied_product[key]) {
            new_field = merge(new_field, getUpdateSourceFields(update_source_fields, new_copied_product[keyfield], language, key, new_copied_product[key], true));
        }
    }
    update_source_fields.push(new_field);
    console.log(update_source_fields);
    store.dispatch(set_update_source_fields(update_source_fields));
}

function saveCopiedProductToStore(new_copied_product, mapped_key_obj) {
    saveCopiedProductChangeIndex(new_copied_product, mapped_key_obj);
    saveCopiedProductUpdateSourceIndex(new_copied_product);
    enableSaveEditButton();
}

function openNewCopiedProductTab(copied_product_id) {
    const { new_copied_product, mapped_key_obj } = createNewCopiedProduct(copied_product_id);
    const new_product_id = new_copied_product[store.getState().portalConfig.keyfield];
    saveCopiedProductToStore(new_copied_product, mapped_key_obj);
    store.dispatch(add_product(new_copied_product));
    store.dispatch(add_new_product(new_product_id));
    store.dispatch(add_new_copied_product(new_product_id));
    setActiveArticle(new_product_id);
    /*store.dispatch(new_tab(new_product_id));
    store.dispatch(set_active_article(new_product_id));*/
}

function getFacetFilterCollapses(depend_facet_clicked_obj, clickFacetCollapse, facets_open_obj, handleFacetClick, isNewReferenceModal, index_creation_date, isFacetModal = false) {
    const { menuFilter, hideFacets, portalConfig } = store.getState();
    return (
        <div className="facet-filter-container" style={isFacetModal ? { marginTop: "10px" } : {}}>
            {menuFilter.facet.map(field => {
                const text = field.text && field.text.replace(/\s/g, "") ? field.text : field.dataField;
                return (
                    <MDBCard key={field.dataField + "_facet_card" + (isNewReferenceModal ? "_modal" : "")} className={hideFacets.includes(field.dataField) || hideFacetComponent(field, depend_facet_clicked_obj[field.dataField]) ? "hide-facet" : "mdb-card-facet mt-2"}>
                        <MDBCollapseHeader
                            tagClassName='d-flex justify-content-sm-start'
                            onClick={() => clickFacetCollapse(field.dataField)}
                        >
                            <MDBIcon
                                icon={facets_open_obj[field.dataField] ? 'angle-up' : 'angle-down'}
                            />
                            <div className="section-text">
                                {field.unit_filter && field.display_unit_on_attribute === "1" ? text + " [" + field.unit_filter.trim() + "]" : text}
                            </div>
                        </MDBCollapseHeader>
                        <MDBCollapse id={field.dataField + "-collapse"}
                            isOpen={facets_open_obj[field.dataField]}
                        >
                            <MDBCardBody className="block-example border-top border-light">
                                {getFilterElement(field, handleFacetClick, isFacetModal, isNewReferenceModal, facets_open_obj[field.dataField])}
                            </MDBCardBody>
                        </MDBCollapse>
                    </MDBCard>
                );
            })}
            {menuFilter.other.map(field => {
                return (
                    <HiddenFacet
                        key={field.text + "_facet" + (isNewReferenceModal ? "_modal" : "")}
                        field={field}
                        emptyComponent
                        isFacetModal={isFacetModal}
                        isNewReferenceModal={isNewReferenceModal}
                    />
                );
            })}
            {menuFilter.custom_table.map(field => {
                return (
                    <SearchMultiList
                        key={field.text + "_facet" + (isNewReferenceModal ? "_modal" : "")}
                        field={field}
                        emptyComponent
                        isFacetModal={isFacetModal}
                        isNewReferenceModal={isNewReferenceModal}
                    />
                );
            })}
            {portalConfig.show_creation_date && !isNewReferenceModal ?
                <MDBCard key={"data_creation_date"} className="mdb-card-facet mt-2 cursor-default creation-date">
                    <MDBCollapseHeader
                        tagClassName='d-flex justify-content-sm-start cursor-default'
                        style={{ cursor: "default" }}
                    >
                        <div className="section-text" style={{ fontWeight: "400" }}>
                            {translate("data_creation_date") + ": " + index_creation_date}
                        </div>
                    </MDBCollapseHeader>
                </MDBCard>
                :
                null
            }
        </div>
    );
}

function getFilterElement(field, handleFacetClick, isFacetModal, isNewReferenceModal, isOpen = false) {
    const { portalConfig, fetchedProducts } = store.getState();
    const field_extension = portalConfig.field_extension;
    if (field.filter === "textFilter" || field.filter === "MultiList") {
        if (window.navigator.onLine) {
            return (
                <div /*id={field.dataField + "-facet"}*/ className="search-menu">
                    <SearchMultiList
                        field={field}
                        handleFacetClick={handleFacetClick}
                        isFacetModal={isFacetModal}
                        isNewReferenceModal={isNewReferenceModal}
                        isOpen={isOpen}
                    />
                </div>
            );
        } else {
            return (
                <div className="search-menu">
                    <OfflineSearchList
                        field={field}
                        fetchedProducts={fetchedProducts}
                        isFacetModal={isFacetModal} //muss noch in OfflineSearchList implementiert werden
                    />
                </div>
            );
        }
    } else if (field.filter === "MultiDataList") {
        return (
            <div className="search-menu">
                <SearchMultiDataList
                    isOpen={isOpen}
                    field={field}
                    handleFacetClick={handleFacetClick}
                    isFacetModal={isFacetModal}
                    isNewReferenceModal={isNewReferenceModal}
                />
            </div>
        );
    } else if (field.filter === "numberFilter" || field.filter === "DynamicRangeSlider") {
        return (
            <div className="search-menu">
                <SearchDynamicRange
                    field={field}
                    handleFacetClick={handleFacetClick}
                    isFacetModal={isFacetModal}
                    isNewReferenceModal={isNewReferenceModal}
                //hideFacet={this.hideFacet}
                />
            </div>
        );
    } else if (field.filter === "TagCloud") {
        return (
            <div className="search-menu">
                <SearchMultiList
                    field={field}
                    showAsCloud
                    handleFacetClick={handleFacetClick}
                    isFacetModal={isFacetModal}
                    isNewReferenceModal={isNewReferenceModal}
                />
            </div>
        );
    } else if (field.filter === "DateRange") {
        return (
            <div className="search-menu">
                <CustomReactiveSearch
                    dataField={field.dataField}
                    text={field.text}
                    filter={field.filter}
                    field_extension={field_extension}
                    componentId={field.dataField}
                    handleFacetClick={handleFacetClick}
                    isFacetModal={isFacetModal}
                    isNewReferenceModal={isNewReferenceModal}
                />
            </div>
        );
    }
    return null;
}

function getAllFacetsOpenedObject() {
    let new_facet_open = {};
    for (let field of store.getState().menuFilter.facet) {
        new_facet_open[field.dataField] = true;
    }
    /*store.getState().menuFilter.facet.map(field => {
        new_facet_open[field.dataField] = true
    });*/
    return new_facet_open;
}

function getFacetComponentID(dataField, isNewReferenceModal, isFacetModal) {
    let component_id = dataField;
    if (isNewReferenceModal) {
        component_id += "-new-reference-modal";
        if (isFacetModal) {
            component_id += "-facet-modal";
        }
    } else if (isFacetModal) {
        component_id += "-facet-modal";
    }
    return component_id;
    //this.props.isNewReferenceModal ? this.props.isFacetModal ? this.props.field.dataField + "-new-reference-modal-facet-modal" : this.props.field.dataField + "-new-reference-modal" : this.props.isFacetModal ? this.props.field.dataField + "-facet-modal" : this.props.field.dataField
}

function getResultTableStyle(isNewReferenceModal = false) {
    let style = {
        //height: window.innerHeight - (Object.keys(store.getState().searchQuery).length ? 422 : 422) //390 : 355
        height: window.innerHeight - 422
    };
    if (isNewReferenceModal) {
        style["maxWidth"] = document.getElementById("home-container-modal").offsetWidth - 92;
    }
    return style;
}

function getReferenceFiles(item_field, value, key = "") {
    const reference_image_config = store.getState().portalConfig.reference_image_config;
    const config_filetypes = store.getState().portalConfig.config_filetypes;
    return (
        <div className="ref-file-preview">
            {value.map(fileName => {
                //console.log(fileName);
                const elementKey = key + "-" + fileName + "-ref-file";
                //console.log(fileName.substring(fileName.indexOf(".") + 1));
                //const file_type = fileName.substring(fileName.indexOf(".") + 1);
                const file_extension = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
                if (config_filetypes.preview.includes(file_extension.toLowerCase())) {
                    return (
                        <PDFPreview
                            key={elementKey}
                            fileName={fileName}
                            view="subtable"
                        />
                    );
                } else if (config_filetypes.download.includes(file_extension.toLowerCase()) || !config_filetypes.download.length) {
                    return (
                        <DownloadDocButton
                            fileName={fileName}
                            key={elementKey}
                        />
                    );
                } else {
                    if (reference_image_config.fields && reference_image_config.fields.includes(item_field)) {
                        const image_name = fileName.substring(0, fileName.indexOf(".") + 1) + reference_image_config.suffix;
                        return (
                            <RefFilePicturePreview
                                key={elementKey}
                                fileName={image_name}
                            />
                        );
                    }
                    return null;
                }
            })}
        </div>
    );
}

function downloadFileFromServer(file_name, url = "") {
    saveAs(
        url ? url : store.getState().portalConfig.image_base_url + file_name,
        file_name
    );
}

function getFlipCardBackDataByField(field_value, field) {
    //const { item } = this.props;
    const { portalConfig, selectFields } = store.getState();
    const { field_list_mapping, grid_fancy_field_length
    } = portalConfig;
    //const field_value = item[field.dataField];
    if (field.filter === "MultiDataList" || field.edit_type === "_Selection") {
        if (Array.isArray(field_value) && field.display_type !== "string") {
            return (
                <ul style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 0 }}>
                    {field_value.filter(val => val).map(val => {
                        const mapping = getMapping(field.dataField, val, field_list_mapping);
                        let mapped_value = val;
                        if (mapping === null) {
                            mapped_value = getSelection(selectFields[field.dataField], field.edit_foreignkey_id, val);
                        }
                        return (
                            <li key={field.dataField + "-" + val}>
                                {mapped_value}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            if (!field_value) {
                return null;
            }
            const mapping = getMapping(field.dataField, field_value, field_list_mapping);
            if (mapping === null) {
                let val = getSelection(selectFields[field.edit_foreignkey_id ? field.edit_foreignkey_id : field.dataField], field.edit_foreignkey_id, field_value);
                return <div>{val}</div>;
            } else {
                if (!mapping.hasOwnProperty("dest") || mapping.dest === "" || mapping.dest === null) {
                    return null;
                } else {
                    return getMappingComponent(mapping, selectFields[field.dataField], field.edit_foreignkey_id, field_value);
                }
            }
        }
    } else {
        if (field_value && (field_value.length > grid_fancy_field_length)) {
            return (
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <span>{getLabel(field_value, grid_fancy_field_length)}</span>
                    <span>{field_value}</span>
                </MDBTooltip>
            );
        } else {
            return <span>{field_value}</span>;
        }
    }
}

function checkPicture(item, isNewReferenceModal = false, view = "list") {
    const { portalConfig, loadedImages } = store.getState();
    const { image_field, image_base_url, image_suffix, reference_user, reference_password, reference_url, viewport_mobile
    } = portalConfig;
    const isNavMobileSize = window.innerWidth < parseInt(viewport_mobile);
    //console.log(image_obj.TargetID);
    if (!image_field.includes(":REFERENCE")) {
        return (
            <img
                className={isNavMobileSize || isNewReferenceModal ? "img-transform img" : "img"}
                src={getPicture(item, "no_image_found.png", image_field, image_base_url, image_suffix)}
                alt=""
            />
        );
    } else {
        const item_field = image_field.includes(":REFERENCE_FILE") ? image_field.substring(0, image_field.length - 15) : image_field;
        //console.log(item[item_field]);
        if (!item[item_field]) {
            return (
                <img
                    alt="no_image_found.png"
                    src={process.env.PUBLIC_URL + "/no_image_found.png"}
                    style={{ maxWidth: "180px", maxHeight: "180px", margin: "auto", display: "block" }}
                />
            );
        }
        const image_obj = image_field.includes(":REFERENCE_FILE") ? {} : JSON.parse(item[item_field])[0];
        let style = {
            "maxHeight": "180px"
        };
        if (view === "grid") {
            style["display"] = "flex";
            //style["position"] = "relative";
        } else if (view !== "details") {
            style["justifyContent"] = "center";
            style["display"] = "flex";
            //style["width"] = "180px";
        }
        if (image_obj.hasOwnProperty("TargetID") && loadedImages.hasOwnProperty(image_obj.TargetID)) {
            return (
                <div className={isNavMobileSize || isNewReferenceModal ? "loaded-img-sm" : ""} style={style}>
                    <img
                        alt="no_image_found.png"
                        src={loadedImages[image_obj.TargetID]}
                    //style={{ maxWidth: "180px", maxHeight: "180px", margin: "auto", display: "block" }}
                    />
                </div>
            );
        }
        return (
            <div className={isNavMobileSize || isNewReferenceModal ? "loaded-img-sm" : ""} style={style}>
                {/*this.props.imageBase64[item[keyfield].toString()] ?
                    <img src={"data:image/png;base64, " + this.props.imageBase64[item[keyfield]]} alt="" />
                    :*/

                    image_field.includes(":REFERENCE_FILE") ?
                        item[item_field] ?
                            getReferenceFilePicture(item_field, item[item_field])
                            :
                            <img
                                alt="no_image_found.png"
                                src={process.env.PUBLIC_URL + "/no_image_found.png"}
                                style={{ maxWidth: "180px", maxHeight: "180px", margin: "auto", display: "block" }}
                            />
                        :
                        <div dangerouslySetInnerHTML={createHTMLElement(item, image_field, reference_user, reference_password, reference_url).html} />
                }
            </div>
        );
    }
}

function getReferenceFilePicture(item_field, ref_files) {
    const { portalConfig } = store.getState();
    const { reference_image_config } = portalConfig;
    const file_name = Array.isArray(ref_files) ? ref_files[0] : ref_files;
    if (reference_image_config.fields && reference_image_config.fields.includes(item_field)) {
        const image_name = file_name.substring(0, file_name.length - 3) + reference_image_config.suffix;
        return (
            <RefFilePicturePreview
                view="grid"
                fileName={image_name}
            />
        );
    } else {
        return <img alt="" src={process.env.PUBLIC_URL + "/no_image_found.png"} />;
    }
}

function downloadNewFile(format, createEmptyXMLFields, download_products, download_fields) {
    if (format === "txt") {
        downloadTXTFile(download_products, download_fields);
    } else if (format === "xml") {
        downloadXMLFile(createEmptyXMLFields, download_products, download_fields);
    }
}

function downloadTXTFile(download_products, download_fields) {
    const { portalConfig } = store.getState();
    const element = document.createElement("a");
    const txt = portalConfig.elastic_index.includes("_bios_") ? getBIOSContent(download_products) : getTXTContent(download_products, download_fields);
    const file = new Blob([txt], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = translate("download_table") + getFileTimestamp() + ".txt";
    //document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

function getProductsByID(product_ids) {
    let products = [];
    for (var i = 0; i < product_ids.length; i++) {
        const product_id = product_ids[i];
        const product = getProductByID(store.getState().fetchedProducts, product_id);
        products.push(product);
    }
    return products;
}

function getFieldListFieldsByDataFields(data_fields = []) {
    return store.getState().portalConfig.field_list.filter(field => data_fields.includes(field.dataField));
}

function getBIOSContent(download_products) {
    const bios_name_key = "Name";
    const bios_value_key = "Value";
    const bios_setted_value_key = "GesetzterWert";
    const headerline = "English";
    let txt = headerline + "\n";
    for (var i = 0; i < download_products.length; i++) {
        const product = download_products[i];
        const bios_data = product[bios_value_key];
        const bios_setted_value = product[bios_setted_value_key];
        if (bios_data && bios_setted_value) {
            txt += product[bios_name_key] + "\n";
            if (typeof (bios_data) === "string") {
                if (bios_data.includes(",")) {
                    const values = bios_data.split(",");
                    for (let value of values) {
                        if (value.indexOf("*") === 0) {
                            if (value.substring(1) === bios_setted_value) {
                                txt += "\t" + value + "\n";
                            } else {
                                txt += "\t" + value.substring(1) + "\n";
                            }
                        } else {
                            if (value === bios_setted_value) {
                                txt += "\t*" + value + "\n";
                            } else {
                                txt += "\t" + value + "\n";
                            }
                        }
                    }
                } else {
                    if (bios_data.indexOf("*") === 0) {
                        txt += "\t" + bios_data + "\n";
                    } else {
                        txt += "\t*" + bios_data + "\n";
                    }
                }
            } else {
                txt += "\t" + bios_data.toString() + "\n"
            }
        }
    }
    return txt;
}

function getTXTContent(download_products, download_fields) {
    let txt = "";
    console.log(download_fields);
    for (var i = 0; i < download_products.length; i++) {
        const product = download_products[i];
        txt += "#" + (i + 1) + "\n";
        for (let field of download_fields) {
            const data = product[field.dataField] ? product[field.dataField].toString() : "";
            txt += field.text + "\n\t" + data + "\n\n";
        }
        txt += "\n\n";
        /*if (i === downloadTableModalProps.productIDs.length - 1) {
            txt += "\n\n\n";
        }*/
    }
    return txt;
}

function downloadXMLFile(createEmptyXMLFields, download_products, download_fields) {
    const parser = new DOMParser();
    const xml = getXMLContent(createEmptyXMLFields, download_products, download_fields);
    const xmlDoc = parser.parseFromString(xml, "application/xml");
    //console.log(xmlDoc);
    const element = document.createElement("a");
    const file = new Blob([xml], { type: 'application/xml' });
    element.href = URL.createObjectURL(file);
    element.download = translate("download_table") + getFileTimestamp() + ".xml";
    element.click();
}

function getXMLContent(createEmptyXMLFields, download_products, download_fields) {
    let xml = "<?xml version='1.0' standalone='yes' ?>\n<documents>\n";
    for (var i = 0; i < download_products.length; i++) {
        const product = download_products[i];
        xml += "\t<document>\n";
        for (let field of download_fields) {
            const field_id = field.text.replace(/[\s()]/g, "");
            if (createEmptyXMLFields) {
                const data = product[field.dataField] ? product[field.dataField].toString() : "";
                xml += "\t\t<" + field_id + ">\n\t\t\t<![CDATA[\n\t\t\t\t" + data + "\n\t\t\t]]>\n\t\t</" + field_id + ">\n";
            } else {
                if (product[field.dataField]) {
                    const data = product[field.dataField].toString();
                    xml += "\t\t<" + field_id + ">\n\t\t\t<![CDATA[\n\t\t\t\t" + data + "\n\t\t\t]]>\n\t\t</" + field_id + ">\n";
                }
            }
        }
        xml += "\t</document>\n";
    }

    /*const { fetchedProducts, downloadTableModalProps } = store.getState();
    let xml = "<?xml version='1.0' standalone='yes' ?>\n<documents>\n";
    for (let product_id of downloadTableModalProps.productIDs) {
        const product = getProductByID(fetchedProducts, product_id);
        xml += "\t<document>\n";
        for (let field of downloadTableModalProps.templates[downloadTableModalProps.active_template]) {
            const field_id = field.text.replace(/[\s()]/g, "");
            if (createEmptyXMLFields) {
                const data = product[field.dataField] ? product[field.dataField].toString() : "";
                xml += "\t\t<" + field_id + ">\n\t\t\t<![CDATA[\n\t\t\t\t" + data + "\n\t\t\t]]>\n\t\t</" + field_id + ">\n";
            } else {
                if (product[field.dataField]) {
                    const data = product[field.dataField].toString();
                    xml += "\t\t<" + field_id + ">\n\t\t\t<![CDATA[\n\t\t\t\t" + data + "\n\t\t\t]]>\n\t\t</" + field_id + ">\n";
                }
            }
        }
        xml += "\t</document>\n";
    }*/
    xml += "</documents>";
    return xml;
}

function setActiveArticle(item_id) {
    const { articleTabs } = store.getState();
    if (articleTabs.tabs.length < active_article_limit) {
        store.dispatch(new_tab(item_id));
    } else {
        store.dispatch(replace_last_article_tab(item_id));
    }
    store.dispatch(set_active_article(item_id));
}

function closeAllArticleTabs(isNewReferenceModal) {
    const { activeArticle, newReferenceModalProps } = store.getState();
    if (isNewReferenceModal && newReferenceModalProps.activeArticle !== "main") {
        store.dispatch(set_active_article_modal("main"));
    } else if (!isNewReferenceModal && activeArticle !== "main") {
        store.dispatch(set_active_article("main"));
    }
    if (isNewReferenceModal) {
        store.dispatch(set_reference_modal_article_tabs());
    } else {
        store.dispatch(set_tabs([]));
    }
}

//Home
function getNavItem(item_id, isNewReferenceModal) {
    const { portalConfig, fetchedProducts } = store.getState();
    //const portalConfig = this.props.portalConfig;
    const item = getProductByID(fetchedProducts, item_id);
    const tab_field = portalConfig.tab_field;
    return (
        <div key={item_id + "-nav-item"} /*onClick={() => { this.toggle(item_id) }}*/>
            <MDBNavItem>
                {item[tab_field] && item[tab_field].length > portalConfig.tab_field_length ?
                    <MDBTooltip placement="bottom">
                        {getNavLinkTab(item_id, getLabel(item[tab_field], portalConfig.tab_field_length), isNewReferenceModal)}
                        <div>{item[tab_field]}</div>
                    </MDBTooltip>
                    :
                    <div>
                        {getNavLinkTab(item_id, item[tab_field] ? item[tab_field] : "NO_DATA", isNewReferenceModal)}
                    </div>
                }
            </MDBNavItem>
        </div>
    );
}

//Home
function getNavLinkTab(item_id, label, isNewReferenceModal) {
    const { newReferenceModalProps, activeArticle } = store.getState();
    const active_article = isNewReferenceModal ? newReferenceModalProps.activeArticle : activeArticle;
    return (
        <MDBNavLink
            link to="#" active={active_article === item_id} role="tab"
            style={getTabStyle(item_id, isNewReferenceModal)}
        >
            <div className="tab-label">
                {label}
                <i className="fas fa-times fa-xs icon-top" /*onClick={() => this.closeTab(item_id)}*/ />
            </div>
        </MDBNavLink>
    );
}

//Home
function getTabStyle(item_id, isNewReferenceModal) {
    const { newReferenceModalProps, activeArticle, portalConfig } = store.getState();
    const active_article = isNewReferenceModal ? newReferenceModalProps.activeArticle : activeArticle;
    const color_config = portalConfig.color_config;
    return {
        backgroundColor: active_article === item_id ? color_config.nav_item_active_color : color_config.nav_item_color,
        color: active_article === item_id ? color_config.nav_item_active_text_color : color_config.nav_item_text_color
    };
}

export {
    getAllComponents, getComponents, getTableList,
    getLabel, getTableText, sortKeyfield, getBasketList,
    getArticleRegisterTabList, getSectionList, getSectionContent, getSectionFields, getCompareList, translate,
    createHTMLElement, getPicture, getPDFConfig, getEditList, getEditedItem, getSelection, getSelectionList,
    getMapping, getField, getMappingComponent, getColorConfig, getPrice, includesObject,
    getHitsArray, getIndexLanguage,
    getFieldData, createCustomTable, getRoutes, createCustomTableTemplates, getCheckedConfigValue, checkArrayIncludesValue,
    getProductByID, getFileTimestamp, getTimestamp, getFetchFilterNew, getDefaultQuery, getSelectFieldsByID,
    getTableRowIDField, getArticleSubtables, getSubtableFields, getAllSections, getMatchString, isObject, deepEqual,
    addToFetchedProducts, checkFieldFilter,
    getMappedData, getFieldListFields, compareAlphabeticalOrder, getDatafieldAsString, getMenuFilterAsArray,
    getCustomTableTemplateHeaderFields, getSelectFieldID, getMultiselectMaxcharByField, getChangeIndexValue,
    uploadChanges, resetChanges, getChangeIndexFields, getUpdateSourceFields, hideFacetComponent, getClickedFacetItems,
    setElasticQuery, getChangeIndexBulkData, getUpdateSourceBulkData, getNewCheckboxValue, saveEditStore, getButtons,
    openArticleDetails, getDeleteBulkData, deleteProductsInStore, openNewCopiedProductTab, createNewCopiedProduct,
    addToDownloadTable, openShoppingCartModal, addToCompareList, getFacetFilterCollapses, getAllFacetsOpenedObject,
    getFacetComponentID, getFieldListFieldByLabelField, getResultTableStyle, getFlipCardFields,
    getReferenceFiles, downloadFileFromServer, getFlipCardBackDataByField, checkPicture, getForeignkeyList, getMultiSelectButtons,
    downloadNewFile, getProductsByID, getFieldListFieldsByDataFields, checkDataExists, getUsageRequiredFields, setActiveArticle,
    closeAllArticleTabs, getNavItem, fetchPic, getLocaleDateString, getDateString, getSubtableFieldsByDatafield
};