import React, { Component } from "react";
import PropTypes from "prop-types";
import "../css/SubtableDropdown.css";
import { compareAlphabeticalOrder, translate } from "../utils/Utils";

class SubtableDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            active: "all"
        };
        this.dropdownListener = this.dropdownListener.bind(this);
    }

    componentDidUpdate() {
        if (!this.props.filterActive && this.state.active !== "all") {
            //console.log("reset");
            this.setState({
                active: "all"
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.dropdownListener);
    }

    componentDidMount() {
        document.addEventListener("click", this.dropdownListener);
    }

    dropdownListener(evt) {
        const { dropdownKey } = this.props;
        const flyoutElement = document.getElementById(dropdownKey + "-subtable-dropdown");
        let targetElement = evt.target; // clicked element
        if (targetElement) {
            if (/*targetElement === flyoutElement || */targetElement === flyoutElement.firstChild) {
                this.setState({
                    open: !this.state.open
                });
                // This is a click inside.
                return;
            } else {
                if (this.state.open) {
                    this.setState({
                        open: false
                    });
                }
            }
        }
    }

    handleClick(data) {
        if (data !== this.state.active) {
            if (this.props.dropdownClick) {
                this.props.dropdownClick(this.props.field, data);
            }
            this.setState({
                active: data
            });
        }
    }

    render() {
        const { field, dataArray, dropdownKey } = this.props;
        return (
            <div
                id={dropdownKey + "-subtable-dropdown"}
                className={this.state.open ? "subtable-dropdown subtable-dropdown-open" : "subtable-dropdown"}
            >
                <span
                    className="subtable-dropdown-label"
                    style={this.state.active !== "all" ? { color: "#007bff" } : {}}
                >
                    {field.text ? field.text : field.dataField}
                </span>
                <div className="subtable-dropdown-content">
                    {dataArray && dataArray.length ?
                        <span
                            style={this.state.active === "all" ? { backgroundColor: "#007bff", color: "white" } : {}}
                            onClick={() => this.handleClick("all")}
                        >
                            {translate("all")}
                        </span>
                        :
                        null
                    }
                    {dataArray && dataArray.length ?
                        dataArray.sort(compareAlphabeticalOrder).map(data => {
                            const dropdownItemKey = dropdownKey + "-" + data + "-subtable-dropdown-item";
                            return (
                                <span
                                    key={dropdownItemKey}
                                    style={this.state.active === data ? { backgroundColor: "#007bff", color: "white" } : {}}
                                    onClick={() => this.handleClick(data)}
                                >
                                    {data}
                                </span>
                            );
                        })
                        :
                        <span>NO DATA</span>
                    }
                </div>
            </div>
        );
    }
}

SubtableDropdown.propTypes = {
    field: PropTypes.object.isRequired,
    dataArray: PropTypes.array,
    dropdownKey: PropTypes.string.isRequired,
    dropdownClick: PropTypes.func,
    filterActive: PropTypes.number.isRequired
};

export default SubtableDropdown;