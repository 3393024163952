import React, { Component } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { change_item_quantity, add_to_shopping_cart, delete_shopping_cart_item
        } from "../actions";
import { translate } from "../utils/Utils";
import "../css/QuantityDropdown.css";

const mapStateToProps = (state) => {
    return {
        shoppingCart: state.shoppingCart,
        //language: state.language
    };
};

const mapDispatchToProps = () => {
    return {
        delete_shopping_cart_item,
        change_item_quantity,
        add_to_shopping_cart
    };
};

class QuantityDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: false,
            value: this.props.item.quantity
        };
    }

    click(quantity) {
        if (quantity === 0) {
            this.props.delete_shopping_cart_item(this.props.item.id_);
            this.props.updateShoppingCart();
        } else if (quantity === 6) {
            this.setState({
                inputValue: true,
                value: 6
            });
        } else {
            this.setState({
                value: quantity
            });
            this.props.change_item_quantity(this.getShoppingCartIndex(this.props.item.id_), quantity);
            this.props.updateShoppingCart();
        }
    }

    getShoppingCartIndex(item_id) {
        const shoppingCart = this.props.shoppingCart;
        for (var i = 0; i < shoppingCart.length; i++) {
            if (shoppingCart[i].id_ === item_id) {
                return i;
            }
        }
        return null;
    }

    getDropdownItems() {
        return (
            <div>
                <MDBDropdownItem onClick={() => this.click(0)}>
                    {"0 (" + translate("delete") + ")"}
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => this.click(1)}>
                    1
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => this.click(2)}>
                    2
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => this.click(3)}>
                    3
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => this.click(4)}>
                    4
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => this.click(5)}>
                    5
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => this.click(6)}>
                    6+
                </MDBDropdownItem>
            </div>
        );
    }

    changeQuantity(event) {
        this.setState({
            value: event.target.value
        });
    }

    updateQuantity() {
        this.props.change_item_quantity(this.getShoppingCartIndex(this.props.item.id_), this.state.value);
        this.props.updateShoppingCart();
        this.setState({
            inputValue: false
        });
    }

    handleKeyDown(event) {
        if (event.key === "Enter") {
            this.updateQuantity();
        }
    }

    render() {
        if (this.state.inputValue) {
            return (
                <div>
                    <input 
                        className="drop-down-input"
                        min="1" 
                        type="number" 
                        value={this.state.value}
                        autoFocus
                        onChange={event => this.changeQuantity(event)}
                        onClick={event => this.changeQuantity(event)}
                        onKeyDown={event => this.handleKeyDown(event)}
                    />
                    <MDBBtn
                        color="primary" 
                        size="sm"
                        onClick={() => this.updateQuantity()}
                    >
                        {translate("confirm")}
                    </MDBBtn>
                </div>
            );
        } else {
            return (
                <MDBDropdown size="sm">
                    <MDBDropdownToggle caret color="primary" className="drop-down">
                        {translate("amount") + ": " + this.state.value + " "}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic>
                        {this.getDropdownItems()}
                    </MDBDropdownMenu>
                </MDBDropdown>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(QuantityDropdown);