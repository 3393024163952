import React, { Component } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInput
} from "mdbreact";
import { getFieldListFields, translate } from "../utils/Utils";
import "../css/NewTemplateModal.css";
import "../Home.css";
import DragDropDoubleList from "./DragDropDoubleList";
import PropTypes from "prop-types";

class NewTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName: translate("template"),
            selectedFields: this.props.selectedFields,
            fieldListFields: getFieldListFields(this.props.selectedFields)
        };
        this.handleDragDrop = this.handleDragDrop.bind(this);
    }

    closeModal() {
        this.props.closeNewTemplateModal();
    }

    showSaveButton() {
        const template_name = this.state.templateName;
        if (this.props.templateNames.includes(template_name) || template_name.trim().length === 0) {
            return false;
        } else {
            return true;
        }
    }

    saveTemplate() {
        this.props.saveNewTemplate(this.state.templateName, this.state.selectedFields);
    }

    handleDragDrop(result) {
        this.setState({
            selectedFields: result,
            fieldListFields: getFieldListFields(result)
        });
    }

    render() {
        return (
            <MDBModal
                id={this.props.modalID}
                className="new-template-modal"
                size="lg"
                isOpen
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader 
                    toggle={() => this.closeModal()}
                >
                    {translate("new_template")}
                </MDBModalHeader>
                <MDBModalBody
                    className="modal-body"
                >
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol>
                                <MDBInput 
                                    label={translate("template_name")}
                                    value={this.state.templateName}
                                    onInput={event => this.setState({
                                        templateName: event.target.value
                                    })}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <DragDropDoubleList 
                                selectedFields={this.state.selectedFields}
                                fieldListFields={this.state.fieldListFields}
                                handleDragDrop={this.handleDragDrop}
                            />
                        </MDBRow>
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    {!this.showSaveButton() ? null :
                        <button
                            className="btn btn-sm"
                            onClick={() => this.saveTemplate()}
                        >
                            {translate("save")}
                        </button>
                    }
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        )
    }
}

NewTemplateModal.propTypes = {
    //openNewTemplateModal: PropTypes.bool.isRequired,
    closeNewTemplateModal: PropTypes.func.isRequired,
    templateNames: PropTypes.array.isRequired,
    selectedFields: PropTypes.array.isRequired,
    saveNewTemplate: PropTypes.func.isRequired,
    modalID: PropTypes.string.isRequired
};

export default NewTemplateModal;