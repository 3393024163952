const openPortalSettingsModalReducer = (state = false, action) => {
    switch(action.type) {
        case 'CHANGE_PORTAL_SETTINGS_MODAL_STATUS':
            state = !state;
            return state;
        default:
            return state;
    }
}

export default openPortalSettingsModalReducer;