const menuFilterReducer = (state = {}, action) => {
    switch(action.type) {
        case 'SET_MENU_FILTER':
            state = action.payload;
            return state;
        case 'SET_CUSTOM_TABLE_HEADER_FIELDS':
            //console.log(action.payload);
            let new_state = Object.assign({}, state);
            new_state.custom_table = action.payload;
            state = Object.assign({}, new_state);
            return state;
        default:
            return state;
    }
}

export default menuFilterReducer;