import React, { Component } from "react";
import { DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "../css/SearchDate.css";

class SearchDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date()
        };
    }

    handleChange(range) {
        let queryObject = this.getQueryObject(range);
        this.setState({
            startDate: range.startDate,
            endDate: range.endDate
        });
        this.props.handleFacetClick(this.props.dataField, queryObject.queryArray);
        this.props.setQuery({
            query: {
                terms: {
                    [this.props.dataField]: queryObject.queryArray
                }
            },
            value: queryObject.value
        });
        /*if (isNewReferenceModal && newReferenceModalProps.activeArticle !== "main") {
            this.props.set_active_article_modal("main");
        } else if (!isNewReferenceModal && activeArticle !== "main") {
            this.props.set_active_article("main");
        }*/
    }

    getQueryObject(range) {
        let startDate = new Date(range.startDate);
        let endDate = new Date(range.endDate);
        let queryArray = [];
        let value = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())).toISOString().substring(0, 10) + ", " +
            new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())).toISOString().substring(0, 10);
        for (let bucket of this.props.aggregations.values.buckets) {
            if (bucket["key"] >= startDate.getTime() && bucket["key"] <= endDate.getTime()) {
                queryArray.push(bucket["key_as_string"]);
            }
        }
        /*this.props.aggregations.values.buckets.map(bucket => {
            if (bucket["key"] >= startDate.getTime() && bucket["key"] <= endDate.getTime()) {
                queryArray.push(bucket["key_as_string"]);
            }
        });*/
        return {
            queryArray: queryArray,
            value: value
        };
    }

    render() {
        return (
            <DateRange
                ranges={[{
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    key: 'selection'
                }]}
                onChange={range => this.handleChange(range.selection)}
                scroll={{
                    calendarWidth: 5,
                    calendarHeight: 5
                }}

            />
        );
    }
}

export default SearchDate;