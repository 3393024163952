const imageBase64Reducer = (state = {}, action) => {
    switch(action.type) {
        case 'ADD_IMAGE_BASE64':
            state = Object.assign({}, state);
            state[action.payload.image_id] = action.payload.image_str;
            /*let images = state.map(image => image);
            images.push(action.payload);
            state = images;*/
            //state = action.payload;
            return state;
        default:
            return state;
    }
}

export default imageBase64Reducer;