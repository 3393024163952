import React, { Component } from "react";
import { downloadFileFromServer, translate } from "../utils/Utils";
import {
    MDBIcon, MDBTooltip
} from "mdbreact";
import { fetchPreview } from "../utils/Webservice";
import store from "../store/store";
import PropTypes from "prop-types";

class DownloadDocButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docExists: false
        };
    }

    componentDidMount() {
        this.checkDocExists();
    }

    checkDocExists() {
        const { fileName } = this.props;
        const { portalConfig } = store.getState();
        const { image_base_url, reference_user, reference_password } = portalConfig;
        fetchPreview(image_base_url + fileName /*+ "?ctsUser=" + reference_user + "&ctsPassword=" + reference_password*/, reference_user, reference_password).then(() => {
            this.setState({
                docExists: true
            });
        });
    }

    render() {
        const { fileName } = this.props;
        if (!this.state.docExists) {
            return (
                <div className="file-not-found">
                    <MDBIcon icon="exclamation-triangle" size="lg" />
                    <span>
                        {fileName}
                    </span>
                </div>
            );
        }
        return (
            <div style={{ textAlign: "center" }}>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <span>
                        <button
                            style={{ whiteSpace: "nowrap" }}
                            className="btn btn-sm"
                            onClick={() => downloadFileFromServer(fileName)}
                        >
                            <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                            {translate("download")}
                        </button>
                    </span>
                    <span>{fileName}</span>
                </MDBTooltip>
            </div>
        );
    }
}

DownloadDocButton.propTypes = {
    fileName: PropTypes.string.isRequired
};

export default DownloadDocButton;