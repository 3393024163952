import React, { Component } from "react";
import { ReactiveComponent } from "@appbaseio/reactivesearch";
import { getComponents } from "../utils/Utils";
import SearchRange from "./SearchRange";
import SearchDate from "./SearchDate";
import "../css/CustomReactiveSearch.css";

class CustomReactiveSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //components: getComponents(this.props.componentId, this.props.menuFilter)
        };
    }

    render() {
        const { isFacetModal, isNewReferenceModal, dataField } = this.props;
        return (
            <ReactiveComponent
                react={{
                    and: getComponents(this.props.componentId),
                    //or: ["mainSearch"]
                }}
                showFilter={this.props.isFacetModal ? false : true}
                componentId={this.props.componentId}
                defaultQuery={() => ({
                    aggs: {
                        values: {
                            terms: {
                                field: this.props.filter === "DynamicRangeSlider" || this.props.filter === "SearchBarButton" ? dataField + "." + this.props.field_extension : dataField,
                                size: 10000
                            },
                        },
                    },
                })}
                render={({ aggregations, setQuery }) => {
                    if (aggregations !== null) {
                        if (this.props.filter === "DynamicRangeSlider") {
                            return (
                                <SearchRange dataField={dataField} aggregations={aggregations} setQuery={setQuery} />
                            );
                        } else if (this.props.filter === "DateRange") {
                            return (
                                <div>
                                    <div className="custom_title">
                                        {this.props.text}
                                    </div>
                                    <SearchDate dataField={dataField} aggregations={aggregations} setQuery={setQuery} handleFacetClick={this.props.handleFacetClick} isFacetModal={isFacetModal}
                                        isNewReferenceModal={isNewReferenceModal} />
                                </div>
                            );
                        }
                    } else {
                        return null;
                    }
                }}
            />

        );
    }
}

export default CustomReactiveSearch;