import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactiveList } from "@appbaseio/reactivesearch";
import {
    MDBRow, MDBContainer
} from "mdbreact";
import {
    getFlipCardFields, deepEqual
} from "../../utils/Utils";
import PropTypes from "prop-types";
import FlippingCard from "./FlippingCard";
import { checkAndLoadReferences, showGridViewArticleButtons } from "../../utils/functions";
import "../../Home.css";

const { ResultCardsWrapper } = ReactiveList;

const mapStateToProps = (state) => {
    return {
        loadedImages: state.loadedImages
    };
};

const mapDispatchToProps = () => {
    return {

    };
};

class GridView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
        this.cardBackFields = getFlipCardFields();
        this.showButtons = showGridViewArticleButtons();
    }

    componentDidMount() {
        const { data } = this.props;
        checkAndLoadReferences(data, "img");
    }

    componentDidUpdate() {
        const { data } = this.props;
        if (!deepEqual(data, this.state.data)) {
            checkAndLoadReferences(data, "img");
            this.setState({
                data
            });
        }
    }

    render() {
        const { data, isNewReferenceModal } = this.props;
        return (
            <MDBContainer fluid>
                <MDBRow className="results grid-view-result-cards">
                    <ResultCardsWrapper>
                        {data.map(item =>
                            <FlippingCard
                                item={item}
                                isNewReferenceModal={isNewReferenceModal}
                                notify={this.props.notify}
                                cardBackFields={this.cardBackFields}
                                showButtons={this.showButtons}
                                //imageData={this.props.imageBase64[item[portalConfig.keyfield]]}
                            />
                        )}
                    </ResultCardsWrapper>
                </MDBRow>
            </MDBContainer>
        );
    }
}

GridView.propTypes = {
    data: PropTypes.array.isRequired,
    isNewReferenceModal: PropTypes.bool,
    notify: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps())(GridView);