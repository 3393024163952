import React, { Component } from "react";
import {
    MDBTable, MDBTableHead, MDBTableBody, MDBContainer, MDBIcon, MDBSelect, MDBTooltip
} from "mdbreact";
import {
    getTableText, translate, createHTMLElement, getField, getMenuFilterAsArray,
    getCustomTableTemplateHeaderFields, getIndexLanguage, uploadChanges, getChangeIndexValue, getChangeIndexFields,
    getUpdateSourceFields, getNewCheckboxValue, getButtons, getResultTableStyle, getMultiSelectButtons,
    addToFetchedProducts, getTimestamp
}
    from "../utils/Utils";
import { connect } from "react-redux";
import "../css/SearchResults.css";
import "../Home.css";
import {
    set_custom_table_templates, set_active_article, set_shopping_cart_modal,
    set_shopping_cart_modal_item, add_compare_item, set_template, change_new_copied_product_modal_status,
    set_new_copied_product, change_delete_product_modal_status, update_search_query,
    set_custom_table_header_fields, add_multi_edit_field, switch_save_changes, switch_edit_custom_view,
    set_hidden_facets, set_download_table_products, set_compare_items
} from "../actions";
import EditTemplateModal from "./EditTemplateModal";
import NewTemplateModal from "./NewTemplateModal";
import SimpleModal from "./modals/SimpleModal";
import ExcelButton from "./ExcelButton";
import { fetchProducts } from "../utils/Webservice";
import MultiEditField from "./MultiEditField";
import merge from "deepmerge";
import CustomTableModal from "./modals/CustomTableModal";
import FacetAsDropdown from "./FacetAsDropdown";
import PropTypes from "prop-types";
import MultiSelectConfirmModal from "./modals/MultiSelectConfirmModal";
import { download_table_limit } from "../utils/constants";
import MultiSelectCopyModal from "./modals/MultiSelectCopyModal";
import { getMenuFilter } from "../utils/functions";

const mapStateToProps = (state) => {
    return {
        customTable: state.customTable,
        edit: state.edit,
        selectFields: state.selectFields,
        hits: state.hits,
        articleTabs: state.articleTabs,
        compareItems: state.compareItems,
        customTableTemplates: state.customTableTemplates,
        template: state.template,
        editCustomView: state.editCustomView,
        portalConfig: state.portalConfig,
        searchQuery: state.searchQuery,
        fetchedProducts: state.fetchedProducts,
        menuFilter: state.menuFilter,
        multiEdit: state.multiEdit,
        language: state.language,
        saveChanges: state.saveChanges,
        resultView: state.resultView,
        changeIndexFields: state.changeIndexFields,
        updateSourceFields: state.updateSourceFields,
        hideFacets: state.hideFacets,
        hiddenFacets: state.hiddenFacets,
        downloadTableModalProps: state.downloadTableModalProps
    };
};

const mapDispatchToProps = () => {
    return {
        set_active_article,
        set_shopping_cart_modal,
        set_shopping_cart_modal_item,
        add_compare_item,
        set_custom_table_templates,
        set_template,
        change_new_copied_product_modal_status,
        set_new_copied_product,
        change_delete_product_modal_status,
        update_search_query,
        set_custom_table_header_fields,
        add_multi_edit_field,
        switch_save_changes,
        switch_edit_custom_view,
        set_hidden_facets,
        set_download_table_products,
        set_compare_items
    };
};

class CustomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customTableTemplates: this.props.customTableTemplates,
            openDeleteTemplateModal: false,
            multiSelectConfirmModal: false,
            multiSelectType: "",
            openEditTemplateModal: false,
            openNewTemplateModal: false,
            stillWorkedOnModal: false,
            menu_filter_array: getMenuFilterAsArray(this.props.menuFilter),
            download_fetched_products: false,
            openConfirmModal: false,
            multiEditObject: {},
            multiEditKeyMapping: {},
            multiEditKeysArray: [],
            multiEditValueMapping: {},
            openConfirmModalBodyText: "",
            excelModalData: {
                loading: false,
                open: false,
                excelTableFields: [],
                fetched_products: [],
                numberOfResults: 0
            },
            multiSelectCopyModal: false
        };
        this.setCustomTableFacets();
        this.loading_counter = 0;
        this.fetched_products = [];
        this.editCustomTable = this.editCustomTable.bind(this);
        this.saveNewTemplate = this.saveNewTemplate.bind(this);
        this.closeEditTemplateModal = this.closeEditTemplateModal.bind(this);
        this.closeNewTemplateModal = this.closeNewTemplateModal.bind(this);
        this.saveEditedTemplate = this.saveEditedTemplate.bind(this);
        this.deleteCurrentTemplate = this.deleteCurrentTemplate.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleMultiEdit = this.handleMultiEdit.bind(this);
        this.saveMultiEditToStore = this.saveMultiEditToStore.bind(this);
        this.setMultiEditObject = this.setMultiEditObject.bind(this);
        this.downloadExcelData = this.downloadExcelData.bind(this);
        this.setExcelData = this.setExcelData.bind(this);
        this.closeCustomTableModal = this.closeCustomTableModal.bind(this);
        this.clickTemplateSelect = this.clickTemplateSelect.bind(this);
        this.handleNewTemplate = this.handleNewTemplate.bind(this);
        this.handleEditTemplate = this.handleEditTemplate.bind(this);
        this.handleDeleteTemplateModal = this.handleDeleteTemplateModal.bind(this);
        this.openDownloadExcelModal = this.openDownloadExcelModal.bind(this);
        this.switchDownloadAll = this.switchDownloadAll.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.openMultiSelectModal = this.openMultiSelectModal.bind(this);
        this.handleMultiSelectConfirmModal = this.handleMultiSelectConfirmModal.bind(this);
        this.setMultiSelectProducts = this.setMultiSelectProducts.bind(this);
        this.saveCopiedProducts = this.saveCopiedProducts.bind(this);
        this.getCopiedProducts = this.getCopiedProducts.bind(this);
    }

    setCustomTableFacets(template_fields = this.props.customTableTemplates[this.props.template].custom_table) {
        const facets = getMenuFilter().facet.map(field => field.dataField);
        let custom_table_facets = template_fields.filter(field => !facets.includes(field.dataField) && field.dataField !== "actions_field" && !this.props.hiddenFacets.includes(field.dataField)).map(field => field.dataField);
        for (let facet of this.props.hiddenFacets) {
            custom_table_facets.push(facet);
        }
        //console.log(custom_table_facets);
        this.props.set_hidden_facets(custom_table_facets);
    }

    createField(item, field) {
        const { portalConfig } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        if (typeof (field) === "object" && field.dataField.includes(":JSON")) {
            return null;
        }
        let value = item[field.dataField];
        if (field.dataField.includes(":REFERENCE")) {
            const htmlElement = createHTMLElement(item, field.dataField, reference_user, reference_password, reference_url);
            //arr = htmlElement.arr;
            value = htmlElement.html.__html;
        }
        return getField(item, field, value);
    }

    editCustomTable(new_custom_table) {
        let customTableTemplates = JSON.parse(JSON.stringify(this.state.customTableTemplates));
        customTableTemplates[this.props.template] = new_custom_table;
        this.props.set_custom_table_templates(customTableTemplates);
        this.setState({
            customTableTemplates: customTableTemplates
        });
    }

    getOrder(dataField) {
        const custom_table_template = this.state.customTableTemplates[this.props.template];
        if (custom_table_template.sorted.dataField === dataField) {
            return custom_table_template.sorted.order;
        } else {
            return "no";
        }
    }

    getCustomTableData() {
        let data = this.props.data.map(item => item);
        if (this.state.customTableTemplates[this.props.template].sorted.order !== "no") {
            data.sort((a, b) => this.sortDataByField(a, b));
        }
        return data;
    }

    sortDataByField(a, b) {
        const custom_table_template = this.state.customTableTemplates[this.props.template];
        let a_field = a[custom_table_template.sorted.dataField];
        let b_field = b[custom_table_template.sorted.dataField];
        if (custom_table_template.sorted.order === "asc") {
            if (a_field < b_field) {
                return -1;
            }
            if (a_field > b_field) {
                return 1;
            }
            return 0;
        } else {
            if (a_field > b_field) {
                return -1;
            }
            if (a_field < b_field) {
                return 1;
            }
            return 0;
        }
    }

    getArrowIcon() {
        if (this.state.customTableTemplates[this.props.template].sorted.order === "asc") {
            return (
                <MDBIcon icon="arrow-up" className="icon" />
            );
        } else {
            return (
                <MDBIcon icon="arrow-down" className="icon" />
            );
        }
    }

    clickTemplateSelect(value) {
        if (value !== this.props.template) {
            this.props.set_template(value);
        }
    }

    getTableTemplateSelectComponent() {
        const customTableTemplates = this.state.customTableTemplates;
        let selectValues = [];
        for (let key of Object.keys(customTableTemplates)) {
            selectValues.push({
                checked: this.props.template === key,
                text: key === "default" ? translate("default") : key === "new_template" ? translate("new_template") : key,
                value: key
            });
        };
        return (
            <MDBSelect
                label={translate("select_template") + ":"}
                getValue={value => this.clickTemplateSelect(value)}
                options={selectValues}
            />
        );
    }

    closeEditTemplateModal() {
        this.setState({
            openEditTemplateModal: false
        });
    }

    closeNewTemplateModal() {
        this.setState({
            openNewTemplateModal: false
        });
    }

    saveNewTemplate(templateName, template) {
        let custom_table_templates = Object.assign({}, this.state.customTableTemplates);
        custom_table_templates[templateName] = JSON.parse(JSON.stringify(this.state.customTableTemplates)).default;
        custom_table_templates[templateName].custom_table = template;
        custom_table_templates[templateName].sorted = { dataField: "", order: "no" };
        this.props.set_template(templateName);
        this.props.set_custom_table_templates(custom_table_templates);
        this.props.set_custom_table_header_fields(getCustomTableTemplateHeaderFields(custom_table_templates, this.state.menu_filter_array));
        //console.log(template);
        this.setCustomTableFacets(template);
        this.setState({
            customTableTemplates: custom_table_templates,
            openNewTemplateModal: false
        });
    }

    saveEditedTemplate(templateName, template) {
        let custom_table_templates = Object.assign({}, this.state.customTableTemplates);
        custom_table_templates[templateName] = JSON.parse(JSON.stringify(this.state.customTableTemplates))[this.props.template];
        custom_table_templates[templateName].custom_table = template;
        if (templateName !== this.props.template) {
            delete custom_table_templates[this.props.template];
            this.props.set_template(templateName);
        }
        this.setCustomTableFacets(template);
        this.props.set_custom_table_templates(custom_table_templates);
        this.props.set_custom_table_header_fields(getCustomTableTemplateHeaderFields(custom_table_templates, this.state.menu_filter_array));
        this.setState({
            customTableTemplates: custom_table_templates,
            openEditTemplateModal: false
        });
    }

    deleteCurrentTemplate() {
        let new_custom_table_templates = JSON.parse(JSON.stringify(this.state.customTableTemplates));
        delete new_custom_table_templates[this.props.template];
        this.props.set_template("default");
        this.props.set_custom_table_templates(new_custom_table_templates);
        if (this.props.template === "new_template") {
            this.props.change_new_table_template_status();
        }
        this.setCustomTableFacets(new_custom_table_templates.default.custom_table);
        this.setState({
            customTableTemplates: new_custom_table_templates,
            openDeleteTemplateModal: !this.state.openDeleteTemplateModal
        });
    }

    handleEditTemplate() {
        this.closeCustomTableModal();
        this.setState({
            openEditTemplateModal: true
        });
    }

    handleNewTemplate() {
        this.closeCustomTableModal();
        this.setState({
            openNewTemplateModal: true
        });
    }

    getSearchHeaderComponent(field) {
        return (
            <FacetAsDropdown
                field={field}
            />
        );
    }

    closeModal(modal) {
        if (modal === "downloadExcelModal") {
            this.setState({
                excelModalData: Object.assign(Object.assign({}, this.state.excelModalData), {
                    open: false
                })
            });
        } else {
            this.setState({
                [modal]: false
            });
        }
    }

    switchDownloadAll() {
        if (this.state.download_fetched_products) {
            this.setState({
                download_fetched_products: false,
                excelModalData: Object.assign(Object.assign({}, this.state.excelModalData), {
                    excelTableFields: [],
                    fetched_products: [],
                    numberOfResults: 0
                })
            });
        } else {
            this.setState({
                download_fetched_products: true
            });
        }
    }

    handleMultiEditConfirmButtonClick() {
        const query_size = this.props.portalConfig.query_size;
        this.setState({
            openConfirmModal: true,
            openConfirmModalBodyText: this.props.numberOfResults > query_size ? translate("max_multi_edit") + query_size + ".\n\nMöchten Sie sicher die Änderungen auf " + query_size + " Treffer anwenden und alle Änderungen speichern?\n\nNach dem Speichern wird das Portal neu geladen und alle Änderungen übernommen." : "Möchten Sie sicher die Änderungen auf alle " + this.props.numberOfResults + " Treffer anwenden und alle Änderungen speichern?\n\nNach dem Speichern wird das Portal neu geladen und alle Änderungen übernommen."
        });
    }

    switchMultiEditKey(dataField, edit_type, editField = null) {
        const key = editField ? editField : dataField;
        let newMultiEditKeysArray = [];
        if (this.state.multiEditKeysArray.includes(key)) {
            this.state.multiEditKeysArray.filter(edit_key => edit_key !== key).map(edit_key => newMultiEditKeysArray.push(edit_key));
        } else {
            this.state.multiEditKeysArray.map(edit_key => newMultiEditKeysArray.push(edit_key));
            newMultiEditKeysArray.push(key);
            if (!Object.keys(this.state.multiEditObject).includes(key)) {
                this.setMultiEditObject(dataField, edit_type === "_Checkbox" ? getNewCheckboxValue("", true) : "", editField);
            }
        }
        this.setState({
            multiEditKeysArray: newMultiEditKeysArray
        });
    }

    setMultiEditObject(dataField, value, editField = null, mapped_value = null) {
        let newMultiEditObject = Object.assign({}, this.state.multiEditObject);
        let newMultiEditKeyMapping = Object.assign({}, this.state.multiEditKeyMapping);
        let newMultiEditValueMapping = Object.assign({}, this.state.multiEditValueMapping);
        if (editField) {
            newMultiEditObject[editField] = value;
            newMultiEditKeyMapping[editField] = dataField;
        } else {
            newMultiEditObject[dataField] = value;
        }
        if (mapped_value) {
            newMultiEditValueMapping[value] = mapped_value;
        }
        this.setState({
            multiEditObject: newMultiEditObject,
            multiEditKeyMapping: newMultiEditKeyMapping,
            multiEditValueMapping: newMultiEditValueMapping
        });
    }

    getMultiEditField(field) {
        return (
            <MultiEditField
                field={field}
                portalConfig={this.props.portalConfig}
                selectFields={this.props.selectFields}
                setMultiEditObject={this.setMultiEditObject}
            />
        );
    }

    getDependentMultiEditObject() {
        let dependentMultiEditObject = {};
        Object.keys(this.state.multiEditObject).filter(key => this.state.multiEditKeysArray.includes(key)).map(key => dependentMultiEditObject[key] = this.state.multiEditObject[key]);
        return dependentMultiEditObject;
    }

    saveMultiEditToStore(products) {
        const { portalConfig, language } = this.props;
        const productIDs = products.map(product => product[portalConfig.keyfield]);
        const multiEditObject = this.getDependentMultiEditObject();
        const elastic_index = getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language);
        const changeIndexFields = this.getMultiEditChangeIndexFields(productIDs, multiEditObject, language, elastic_index, portalConfig.elastic_index_changes_structure, portalConfig.multiselect_separator);
        const updateSourceFields = this.getMultiEditUpdateSourceFields(productIDs, multiEditObject, language);
        uploadChanges(changeIndexFields, updateSourceFields);
    }

    getMultiEditUpdateSourceFields(productIDs, multiEditObject, language) {
        let updateSourceFields = this.props.updateSourceFields.filter(field => !productIDs.includes(field[this.props.portalConfig.keyfield]));
        for (let productID of productIDs) {
            let new_field = {};
            for (let key of Object.keys(multiEditObject)) {
                const mapped_key = this.state.multiEditKeyMapping[key] ? this.state.multiEditKeyMapping[key] : key;
                const mapped_value = this.state.multiEditValueMapping[multiEditObject[key]] ? this.state.multiEditValueMapping[multiEditObject[key]] : multiEditObject[key];
                new_field = merge(new_field, getUpdateSourceFields(this.props.updateSourceFields, productID, language, mapped_key, mapped_value, true));
            };
            updateSourceFields.push(new_field);
        };
        return updateSourceFields;
    }

    getMultiEditChangeIndexFields(productIDs, multiEditObject, language, elastic_index, elastic_index_changes_structure, multiselect_separator) {
        let changeIndexFields = this.props.changeIndexFields.filter(field => !productIDs.includes(field[this.props.portalConfig.keyfield]));
        for (let productID of productIDs) {
            let new_field = {};
            for (let key of Object.keys(multiEditObject)) {
                const value = getChangeIndexValue(multiEditObject[key], multiselect_separator);
                new_field = merge(new_field, getChangeIndexFields(this.props.changeIndexFields, productID, language, false, false, value, key, elastic_index_changes_structure, elastic_index, false, true));
            };
            changeIndexFields.push(new_field);
        };
        return changeIndexFields;
    }

    handleMultiEdit() {
        if (this.props.numberOfResults > this.props.data.length) {
            fetchProducts(this.saveMultiEditToStore);
        } else {
            this.saveMultiEditToStore(this.props.data);
        }
    }

    getMultiEditTableHeadStyle(field, index) {
        let className = "multi-edit-tr-th";
        if (index === 0 || (field.usage && field.usage.includes("MULTIEDIT")) || field.dataField === "actions_field") {
            className += " border-left border-light";
            if (index !== 0 || field.dataField === "actions_field") {
                className += " border-right";
            }
        }
        return className;
    }

    openDownloadExcelModal() {
        this.closeCustomTableModal();
        this.setState({
            excelModalData: Object.assign(Object.assign({}, this.state.excelModalData), {
                open: true,
                numberOfResults: this.props.numberOfResults
            })
        });
    }

    getExcelModalBodyText() {
        const query_size = this.props.portalConfig.query_size;
        const text = this.state.excelModalData.numberOfResults > query_size ? translate("max_download_excel") + query_size + ".\n\n" + translate("really_download") + query_size : translate("really_download") + this.state.excelModalData.numberOfResults;
        return text;
    }

    downloadExcelData() {
        if (this.state.download_fetched_products && this.props.numberOfResults > this.props.data.length) {
            fetchProducts(this.setExcelData);
        } else {
            this.setExcelData(this.props.data);
        }
    }

    setExcelData(fetchedProducts) {
        this.setState({
            excelModalData: Object.assign(Object.assign({}, this.state.excelModalData), {
                fetched_products: fetchedProducts,
                excelTableFields: this.state.customTableTemplates[this.props.template].custom_table.filter(field => field.dataField !== "actions_field")
            })
        });
        setTimeout(() => {
            const excel_button = document.getElementById("custom-table-excel-modal-button");
            excel_button.click();
            this.setState({
                excelModalData: Object.assign(Object.assign({}, this.state.excelModalData), {
                    open: false
                })
            });
        }, 2000);
    }

    closeCustomTableModal() {
        this.props.switch_edit_custom_view();
    }

    handleDeleteTemplateModal() {
        this.closeCustomTableModal();
        this.setState({
            openDeleteTemplateModal: !this.state.openDeleteTemplateModal
        });
    }

    uploadFile() {
        this.closeCustomTableModal();
        this.setState({
            stillWorkedOnModal: !this.state.stillWorkedOnModal
        });
    }

    openMultiSelectModal(type) {
        if (type === "copy") {
            this.setState({
                multiSelectCopyModal: true,
                multiSelectType: type
            });
        } else {
            this.setState({
                multiSelectConfirmModal: true,
                multiSelectType: type
            });
        }
    }

    setMultiSelectProducts(products) {
        const { downloadTableModalProps, compareItems } = this.props;
        //console.log(products);
        addToFetchedProducts(products);
        switch (this.state.multiSelectType) {
            case 'download':
                this.props.set_download_table_products(this.getMultiSelectProducts(products, downloadTableModalProps.productIDs));
                this.props.notify("download_table_success");
                break;
            case 'compare':
                this.props.set_compare_items(this.getMultiSelectProducts(products, compareItems));
                this.props.notify("compare_success");
                break;
            default:
                console.log("Sorry, we are out of " + this.state.multiSelectType + ".");

        }
    }

    getMultiSelectProducts(new_products, store_data) {
        const multiselect_ids = new_products.map(product => product[this.props.portalConfig.keyfield]);
        let multiselect_product_ids = store_data.map(productID => productID);
        for (let multiselect_id of multiselect_ids) {
            if (!multiselect_product_ids.includes(multiselect_id)) {
                multiselect_product_ids.push(multiselect_id);
            }
        }
        return multiselect_product_ids;
    }

    handleMultiSelectConfirmModal(load_products = false) {
        const { downloadTableModalProps, data, compareItems } = this.props;
        switch (this.state.multiSelectType) {
            case 'download':
                if (load_products) {
                    fetchProducts(this.setMultiSelectProducts);
                } else {
                    this.props.set_download_table_products(this.getMultiSelectProducts(data, downloadTableModalProps.productIDs));
                    this.props.notify("download_table_success");
                }
                break;
            case 'compare':
                if (load_products) {
                    fetchProducts(this.setMultiSelectProducts);
                } else {
                    this.props.set_compare_items(this.getMultiSelectProducts(data, compareItems));
                    this.props.notify("compare_success");
                }
                break;
            default:
                console.log("Sorry, we are out of " + this.state.multiSelectType + ".");
        }
        this.setState({
            multiSelectConfirmModal: false
        });
    }

    getCopiedProducts(products, editedFields) {
        let copied_products = [];
        let product_id = getTimestamp(true, true);
        for (let product of products) {
            const { new_copied_product, mapped_key_obj } = this.createNewCopiedProduct(product, product_id, editedFields);
            copied_products.push(new_copied_product);
            product_id = (parseInt(product_id) + 1).toString();
        }
        console.log(copied_products);
        this.saveCopiedProductsToStore(copied_products);
    }

    saveCopiedProducts(editedFields, copyAll) {
        //console.log(editedFields);
        //console.log(copyAll);
        if (copyAll) {
            //console.log("load and save");
            fetchProducts(products => this.getCopiedProducts(products, editedFields));
        } else {
            this.getCopiedProducts(this.props.data, editedFields);
        }
    }

    getMultiCopyChangeIndexFields(products) {
        const { portalConfig, language, changeIndexFields } = this.props;
        const { default_language, field_list, keyfield, multiselect_separator, elastic_index_changes_structure } = portalConfig;
        const elastic_index = getIndexLanguage(language, portalConfig.elastic_index, default_language);
        let multiCopyChangeIndexFields = [...changeIndexFields];
        for (let product of products) {
            let new_field = {};
            for (let field of field_list) {
                const value = getChangeIndexValue(product[field.dataField], multiselect_separator);
                new_field = merge(new_field, getChangeIndexFields(changeIndexFields, product[keyfield], language, false, false, value, field.dataField, elastic_index_changes_structure, elastic_index, false, true, true));
            }
            multiCopyChangeIndexFields.push(new_field);
        };
        return multiCopyChangeIndexFields;
    }

    getMultiCopyUpdateSourceFields(products) {
        const { portalConfig, language, updateSourceFields } = this.props;
        const { field_list, keyfield } = portalConfig;
        let multiCopyUpdateSourceFields = [...updateSourceFields];
        for (let product of products) {
            let new_field = {};
            for (let field of field_list) {
                const value = product[field.dataField];
                new_field = merge(new_field, getUpdateSourceFields(updateSourceFields, product[keyfield], language, field.dataField, value, true, true));
            }
            multiCopyUpdateSourceFields.push(new_field);
        };
        return multiCopyUpdateSourceFields;
    }

    saveCopiedProductsToStore(products) {
        const changeIndexFields = this.getMultiCopyChangeIndexFields(products);
        const updateSourceFields = this.getMultiCopyUpdateSourceFields(products);
        //console.log(changeIndexFields);
        //console.log(updateSourceFields);
        uploadChanges(changeIndexFields, updateSourceFields);
    }

    createNewCopiedProduct(product, productID, editedFields) {
        const { fetchedProducts, portalConfig } = this.props;
        let new_copied_product = {};
        let mapped_key_obj = {};
        for (let field of portalConfig.field_list) {
            if (field.editField && field.editField !== "0") {
                mapped_key_obj[field.dataField] = field.editField;
            }
            new_copied_product[field.dataField] = product[field.dataField];
        }
        if (Object.keys(editedFields).length) {
            //console.log("edit");
            new_copied_product = Object.assign(new_copied_product, editedFields);
        }
        new_copied_product[portalConfig.keyfield] = productID;
        //console.log(new_copied_product);
        //console.log(mapped_key_obj);
        return { new_copied_product, mapped_key_obj };
    }

    render() {
        const portalConfig = this.props.portalConfig;
        const custom_table_template = this.state.customTableTemplates[this.props.template];
        return (
            <MDBContainer fluid className="overflow-auto custom-table-container" style={getResultTableStyle()}>
                {!this.state.openNewTemplateModal ? null :
                    <NewTemplateModal
                        //openNewTemplateModal={this.state.openNewTemplateModal}
                        closeNewTemplateModal={this.closeNewTemplateModal}
                        templateNames={Object.keys(this.state.customTableTemplates)}
                        selectedFields={this.state.customTableTemplates.default.custom_table}
                        saveNewTemplate={this.saveNewTemplate}
                        modalID="custom-table-new-template"
                    />
                }
                {!this.state.openEditTemplateModal ? null :
                    <EditTemplateModal
                        //openEditTemplateModal={this.state.openEditTemplateModal}
                        closeEditTemplateModal={this.closeEditTemplateModal}
                        templateNames={Object.keys(this.state.customTableTemplates)}
                        //customTableTemplates={this.state.customTableTemplates}
                        templateName={this.props.template}
                        selectedFields={this.state.customTableTemplates[this.props.template].custom_table}
                        //field_list={portalConfig.field_list}
                        saveEditedTemplate={this.saveEditedTemplate}
                        modalID="custom-table-edit-template"
                    />
                }
                {this.state.excelModalData.open ?
                    <SimpleModal
                        modalID="downloadExcelModal"
                        isOpen={this.state.excelModalData.open}
                        action={this.downloadExcelData}
                        close={this.closeModal}
                        headerText={translate("download_template_as_excel")}
                        bodyText={this.getExcelModalBodyText()}
                        bodyLoadingText={translate("create_excel")}
                        useLoading={true}
                    />
                    :
                    null
                }
                <SimpleModal
                    modalID="stillWorkedOnModal"
                    isOpen={this.state.stillWorkedOnModal}
                    //action={}
                    close={this.closeModal}
                    bodyText={translate("still_worked_on")}
                />
                <SimpleModal
                    modalID="openDeleteTemplateModal"
                    isOpen={this.state.openDeleteTemplateModal}
                    action={this.deleteCurrentTemplate}
                    close={this.closeModal}
                    bodyText={translate("delete_current_template") + "?"}
                />
                <SimpleModal
                    modalID="openConfirmModal"
                    isOpen={this.state.openConfirmModal}
                    action={this.handleMultiEdit}
                    close={this.closeModal}
                    headerText="Multi-Edit"
                    bodyText={this.state.openConfirmModalBodyText}
                    bodyLoadingText={translate("save_loading")}
                    useLoading={true}
                />
                {!this.state.multiSelectConfirmModal ? null :
                    <MultiSelectConfirmModal
                        modalID="multiSelectConfirmModal"
                        type={this.state.multiSelectType}
                        action={this.handleMultiSelectConfirmModal}
                        numberOfResults={this.props.numberOfResults}
                        close={this.closeModal}
                    />
                }
                {!this.state.multiSelectCopyModal ? null :
                    <MultiSelectCopyModal
                        modalID="multiSelectCopyModal"
                        //type={this.state.multiSelectType}
                        saveCopiedProducts={this.saveCopiedProducts}
                        numberOfResults={this.props.numberOfResults}
                        close={this.closeModal}
                        headerText="Kopieren"
                    />
                }
                {!this.props.editCustomView ? null :
                    <CustomTableModal
                        isOpen={this.props.editCustomView}
                        close={this.closeCustomTableModal}
                        customTableTemplates={this.state.customTableTemplates}
                        activeTemplate={this.props.template}
                        clickTemplateSelect={this.clickTemplateSelect}
                        handleNewTemplate={this.handleNewTemplate}
                        handleEditTemplate={this.handleEditTemplate}
                        handleDeleteTemplateModal={this.handleDeleteTemplateModal}
                        openDownloadExcelModal={this.openDownloadExcelModal}
                        showExcel={portalConfig.show_excel}
                        excelModalData={this.state.excelModalData}
                        excelTableProductsIDs={this.getCustomTableData()}
                        fetchedProducts={this.props.fetchedProducts}
                        keyfield={this.props.portalConfig.keyfield}
                        downloadFetchedProducts={this.state.download_fetched_products}
                        switchDownloadAll={this.switchDownloadAll}
                        edit={this.props.edit}
                        uploadFile={this.uploadFile}
                        excelTableFields={this.state.customTableTemplates[this.props.template].custom_table.filter(field => field.dataField !== "actions_field")}
                    />
                }
                {this.state.download_fetched_products ?
                    <div style={{ display: "none" }}>
                        {<ExcelButton
                            buttonID="custom-table-excel-modal-button"
                            usage="template"
                            excelTableFields={this.state.excelModalData.excelTableFields}
                            fetched_products={this.state.excelModalData.fetched_products}
                            downloadFetchedProducts
                        />}
                    </div>
                    :
                    null
                }
                <MDBTable className="result-table">
                    <MDBTableHead>
                        {!this.props.multiEdit || !this.props.edit || !custom_table_template.custom_table.filter(field => field.usage && field.usage.includes("MULTIEDIT")).length ? null :
                            <tr className="multi-edit-tr">
                                {custom_table_template.custom_table.map((field, index) => {
                                    const className = this.getMultiEditTableHeadStyle(field, index);
                                    if (field.usage && field.usage.includes("MULTIEDIT")) {
                                        const key = field.editField ? field.editField : field.dataField;
                                        const checked = this.state.multiEditKeysArray.includes(key);
                                        return (
                                            <th className={className}>
                                                <div className='custom-switch' style={{ margin: 0 }}>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id={'switch_multi_edit_' + field.dataField}
                                                        checked={checked}
                                                        onChange={() => this.switchMultiEditKey(field.dataField, field.edit_type, field.editField)}
                                                        readOnly
                                                    />
                                                    <label className='custom-control-label' htmlFor={'switch_multi_edit_' + field.dataField}>
                                                        {translate("apply")}
                                                    </label>
                                                </div>
                                            </th>
                                        );
                                    }
                                    return (
                                        <th className={className} />
                                    );
                                })}
                            </tr>
                        }
                        {!this.props.multiEdit || !this.props.edit || !custom_table_template.custom_table.filter(field => field.usage && field.usage.includes("MULTIEDIT")).length ? null :
                            <tr className="multi-edit-tr">
                                {custom_table_template.custom_table.map((field, index) => {
                                    const className = this.getMultiEditTableHeadStyle(field, index);
                                    if (field.dataField === "actions_field" && this.state.multiEditKeysArray.length > 0) {
                                        return (
                                            <td className={className}>
                                                <div onClick={() => this.handleMultiEditConfirmButtonClick()}>
                                                    <MDBTooltip placement="top" domElement>
                                                        <button className="btn btn-sm">
                                                            <MDBIcon icon="check" size="lg" />
                                                        </button>
                                                        <div>{translate("confirm")}</div>
                                                    </MDBTooltip>
                                                </div>
                                            </td>
                                        );
                                    }
                                    return (
                                        <td className={className}>
                                            {field.usage && field.usage.includes("MULTIEDIT") ? this.getMultiEditField(field) : null}
                                        </td>
                                    );
                                })}
                            </tr>
                        }
                        {<tr style={{ backgroundColor: "white" }}>
                            {custom_table_template.custom_table.map((field, index) => {
                                if (field.dataField === "actions_field") {
                                    return (
                                        <th className="result-table-head border-left border-light">
                                            {this.props.multiEdit ?
                                                null
                                                :
                                                getMultiSelectButtons(this.openMultiSelectModal, "table-button-field")
                                            }
                                        </th>
                                    );
                                } else {
                                    if (this.props.hideFacets.includes(field.dataField) || field.filter === "DynamicRangeSlider") {
                                        return (
                                            <th className="result-table-head border-left border-light" style={{ verticalAlign: "middle" }}>
                                                {getTableText(field.text ? field.text : field.dataField)}
                                            </th>
                                        );
                                    }
                                    return (
                                        <th className="result-table-head border-left border-light" style={{ verticalAlign: "middle" }}>
                                            {window.navigator.onLine && !field.dataField.includes(":JSON") ? this.getSearchHeaderComponent(field) : getTableText(field.text ? field.text : field.dataField)}
                                            {//this.getOrder(field.dataField) === "no" ? null : this.getArrowIcon()
                                            }
                                        </th>
                                    );
                                }
                            })}
                        </tr>}
                    </MDBTableHead>
                    {<MDBTableBody>
                        {this.getCustomTableData().map(item => (
                            <tr>
                                {custom_table_template.custom_table.map(field => {
                                    if (field.dataField === "actions_field") {
                                        return (
                                            <td className="border-left border-light">
                                                {getButtons(item[portalConfig.keyfield], this.props.notify, "table-button-field")}
                                            </td>
                                        );
                                    } else if (portalConfig.elastic_index.includes("_bios_") && field.edit_depends_on && item["IsReadOnly"]) {
                                        return (
                                            <td className="border-left border-light">
                                                {item[field.dataField]}
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td className="border-left border-light">
                                                {/*this.createField(item, field)*/}
                                                {getField(item, field, item[field.dataField])}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        ))}
                    </MDBTableBody>}
                </MDBTable>
            </MDBContainer>
        );
    }
}

CustomTable.propTypes = {
    data: PropTypes.array.isRequired,
    notify: PropTypes.func.isRequired,
    numberOfResults: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps())(CustomTable);