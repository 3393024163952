const newCopiedProductsReducer = (state = [], action) => {
    switch(action.type) {
        case 'ADD_NEW_COPIED_PRODUCT':
            //let new_products = 
            state.push(action.payload);
            return state;
        case 'DELETE_NEW_COPIED_PRODUCT_BY_ID':
            state = state.filter(product_id => product_id !== action.payload);
            return state;
        case 'DELETE_NEW_COPIED_PRODUCTS_BY_IDS':
            state = state.filter(product_id => !action.payload.includes(product_id));
            return state;
        default:
            return state;
    }
}

export default newCopiedProductsReducer;