const hideFacetsReducer = (state = [], action) => {
    switch(action.type) {
        case 'HIDE_FACET':
            let hide_facets = state.map(facet => facet);
            hide_facets.push(action.payload);
            //state.push(action.payload);
            state = hide_facets;
            return state;
        default:
            return state;
    }
}

export default hideFacetsReducer;