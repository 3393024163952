const fetchedProductsReducer = (state = [], action) => {
    switch(action.type) {
        case 'ADD_PRODUCT':
            state.push(action.payload);
            return state;
        case 'UPDATE_PRODUCT':
            let new_state1 = [];
            state.map(product => new_state1.push(product));
            new_state1[action.payload.index] = action.payload.item;
            //console.log(new_state1[action.payload.index]);
            state = new_state1;
            return state;
        case 'ADD_PRODUCTS':
            action.payload.map(product => state.push(product));
            return state;
        case 'UPDATE_PRODUCTS':
            let updated_products = state.map(stored_product => stored_product);
            action.payload.map(product_obj => updated_products[product_obj.index] = product_obj.product);
            state = updated_products;
            return state;
        case 'SET_FETCHED_PRODUCTS':
            state = action.payload;
            return state;
        case 'UPDATE_FETCHED_PRODUCTS':
            let _state = [];
            for (var i = 0; i < action.payload.length; i++) {
                const new_product_data = action.payload[i];
                for (var j = 0; j < state.length; j++) {
                    const product = state[j];
                    if (new_product_data.ID.toString() === product.ID.toString()) {
                        if (new_product_data.changes === "delete") {
    
                        } else {
                            let new_product = Object.assign(Object.assign({}, product), new_product_data.changes);
                            _state.push(new_product);
                        }
                        j = action.payload.length;
                    }
                }
            }

            for (var k = 0; k < state.length; k++) {
                const product = state[k];
                for (var l = 0; l < action.payload.length; l++) {
                    const new_product_data = action.payload[l];
                    if (new_product_data.ID.toString() === product.ID.toString()) {
                        if (new_product_data.changes === "delete") {
    
                        } else {
                            let new_product = Object.assign(Object.assign({}, product), new_product_data.changes);
                            _state.push(new_product);
                        }
                        k = action.payload.length;
                    }
                }
                
            }

            /*for (var i = 0; i < state.length; i++) {
                const product = state[i];
                for (var j = 0; j < action.payload.length; j++) {
                    const new_data = action.payload[j];
                    if (action.payload[j].ID.toString() === product.ID.toString()) {
                        if (action.payload[j].changes === "delete") {

                        } else {
                            let new_product = Object.assign(Object.assign({}, product), action.payload[j].changes);
                            _state.push(new_product);
                        }
                        j = action.payload.length;
                    }
                }
            }*/

            /*let _state = [];
            state.map(product => _state.push(product));
            action.payload.map(new_data => {
                state.map((stored_product, index) => {
                    if (new_data.ID.toString() === stored_product.ID.toString()) {
                        let new_product = Object.assign(Object.assign({}, stored_product), new_data.changes);
                        _state[index] = new_product;
                    }
                });
            });*/
            state = _state;
            return state;
        default:
            return state;
    }
}

export default fetchedProductsReducer;