const selectionListReducer = (state = [], action) => {
    switch(action.type) {
        case 'SET_SELECTION_LIST':
            state = action.payload;
            return state;
        case 'ADD_SELECTION_LIST':
            state.push(action.payload);
            return state;
        default:
            return state;
    }
}

export default selectionListReducer;