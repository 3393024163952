import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "../utils/Utils";

class SingleSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            storeValue: this.props.value
        }
        this.timeout = 0;
    }

    componentDidUpdate() {
        /*if (this.state.storeValue !== this.props.value) {
            this.setState({
                value: this.props.value,
                storeValue: this.props.value
            });
        }*/
    }

    changeSelect(value) {
        console.log(value);
        this.setState({
            value
        });
        //this.props.changeText(value);
    }

    render() {
        return (
            <div>
                <select
                    id="test"
                    onChange={event => this.changeSelect(event.target.value)}
                    value={"null"}
                    className="browser-default custom-select"
                >
                    <option disabled value="null">{translate("select_option")}</option>
                    <option value="deselect">{translate("deselect")}</option>
                    <option value="test">test</option>
                </select>
            </div>
        )
    }
}

SingleSelect.propTypes = {
    /*type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    changeText: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired*/
};

export default SingleSelect;