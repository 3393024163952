export const set_active_article = (str) => {
    return {
        type: 'SET_ACTIVE_ARTICLE',
        payload: str
    };
};

export const set_active_article_modal = (str) => {
    return {
        type: 'SET_ACTIVE_ARTICLE_MODAL',
        payload: str
    };
};

export const set_result_view = (str) => {
    return {
        type: 'SET_RESULT_VIEW',
        payload: str
    };
};

export const set_result_view_modal = (str) => {
    return {
        type: 'SET_RESULT_VIEW_MODAL',
        payload: str
    };
};

export const set_shopping_cart_modal = () => {
    return {
        type: 'SET_SHOPPING_CART_MODAL'
    };
};

export const set_shopping_cart_modal_item = (item) => {
    return {
        type: 'SET_SHOPPING_CART_MODAL_ITEM',
        payload: item
    };
};

export const new_tab = (item) => {
    return {
        type: 'NEW_TAB',
        payload: item
    };
};

export const replace_last_article_tab = (item_id) => {
    return {
        type: 'REPLACE_LAST_ARTICLE_TAB',
        payload: item_id
    };
};

export const add_to_shopping_cart = (item) => {
    return {
        type: "ADD_TO_SHOPPING_CART",
        payload: item
    };
};

export const change_item_quantity = (index, value) => {
    return {
        type: "CHANGE_ITEM_QUANTITY",
        payload: {
            index: index,
            value: value
        }
    };
};

export const set_shopping_cart_open = () => {
    return {
        type: 'SET_SHOPPING_CART_OPEN'
    };
};

export const delete_tab = (itemID) => {
    return {
        type: 'DELETE_TAB',
        payload: itemID
    };
};

export const add_product = (item) => {
    return {
        type: 'ADD_PRODUCT',
        payload: item
    };
};

export const add_products = (products) => {
    return {
        type: 'ADD_PRODUCTS',
        payload: products
    };
};

export const update_product = (item, index) => {
    return {
        type: 'UPDATE_PRODUCT',
        payload: {
            item: item,
            index: index
        }
    };
};

export const change_updating = () => {
    return {
        type: 'CHANGE_UPDATING'
    };
};

export const update_products = (products) => {
    return {
        type: 'UPDATE_PRODUCTS',
        payload: products
        /*payload: changes,
        product_id: product_id,
        keyfield: keyfield*/
    };
};

export const update_fetched_products = (products) => {
    return {
        type: 'UPDATE_FETCHED_PRODUCTS',
        payload: products
    };
};

export const delete_shopping_cart_item = (item) => {
    return {
        type: 'DELETE_SHOPPING_CART_ITEM',
        payload: item
    }
}

export const set_compare_modal = () => {
    return {
        type: 'SET_COMPARE_MODAL'
    }
}

export const add_compare_item = (item) => {
    return {
        type: 'ADD_COMPARE_ITEM',
        payload: item
    }
}

export const delete_compare_item = (item) => {
    return {
        type: 'DELETE_COMPARE_ITEM',
        payload: item
    }
}

export const set_language = (language) => {
    return {
        type: 'SET_LANGUAGE',
        payload: language
    };
};

export const change_hits = (hits) => {
    return {
        type: 'CHANGE_HITS',
        payload: hits
    };
};

export const switch_edit = () => {
    return {
        type: 'SWITCH_EDIT'
    };
};

export const switch_multi_edit = () => {
    return {
        type: 'SWITCH_MULTI_EDIT'
    };
};

export const add_edit_field = (productID, field, language, value) => {
    return {
        type: 'ADD_EDIT_FIELD',
        payload: {
            productID,
            field,
            language,
            value
        }
    };
};

export const add_multi_edit_field = (productIDs, multiEditObject, language) => {
    return {
        type: 'ADD_MULTI_EDIT_FIELD',
        payload: {
            productIDs,
            multiEditObject,
            language
        }
    };
};

export const clear_edit = () => {
    return {
        type: 'CLEAR_EDIT'
    };
};

export const set_select_fields = (select_fields) => {
    return {
        type: 'SET_SELECT_FIELDS',
        payload: select_fields
    };
};

export const add_select_fields = (name, value) => {
    return {
        type: 'ADD_SELECT_FIELDS',
        payload: name,
        value: value
    };
};

export const add_change_index_field = (productID, field, language, value, elastic_index, elastic_index_changes_structure, is_subtable_field = false, is_subtable_modal = false, new_row = false, is_new_product = false) => {
    return {
        type: 'ADD_CHANGE_INDEX_FIELD',
        payload: {
            productID,
            field,
            language,
            value,
            elastic_index,
            elastic_index_changes_structure,
            is_subtable_field,
            is_subtable_modal,
            new_row,
            is_new_product
        }
    };
};

export const clear_change_index_fields = () => {
    return {
        type: 'CLEAR_CHANGE_INDEX_FIELDS'
    };
};

export const set_compare_items = (compare_items) => {
    return {
        type: 'SET_COMPARE_ITEMS',
        payload: compare_items
    };
};

export const update_shopping_cart = (article, keyfield) => {
    return {
        type: 'UPDATE_SHOPPING_CART',
        payload: article,
        keyfield: keyfield
    };
};

export const update_source_fields = (productID, field, language, value, isNewProduct = false) => {
    return {
        type: 'UPDATE_SOURCE_FIELDS',
        payload: {
            productID,
            field,
            language,
            value,
            isNewProduct
        }
    };
};

export const clear_update_source_fields = () => {
    return {
        type: 'CLEAR_UPDATE_SOURCE_FIELDS'
    };
};

export const switch_save_changes = () => {
    return {
        type: 'SWITCH_SAVE_CHANGES'
    };
};

export const add_selection_list = () => {
    return {
        type: 'ADD_SELECTION_LIST'
    };
};

export const change_uploaded = (bool) => {
    return {
        type: 'CHANGE_UPLOADED',
        payload: bool
    }
}

export const change_open_all_sections_status = () => {
    return {
        type: 'CHANGE_OPEN_ALL_SECTIONS_STATUS'
    }
}

export const set_custom_table_templates = (custom_table_templates) => {
    return {
        type: 'SET_CUSTOM_TABLE_TEMPLATES',
        payload: custom_table_templates
    }
}

export const set_template = (template) => {
    return {
        type: 'SET_TEMPLATE',
        payload: template
    }
}

export const switch_edit_custom_view = () => {
    return {
        type: 'SWITCH_EDIT_CUSTOM_VIEW'
    }
}

export const change_portal_settings_modal_status = () => {
    return {
        type: 'CHANGE_PORTAL_SETTINGS_MODAL_STATUS'
    }
}

export const set_portal_config = (config) => {
    return {
        type: 'SET_PORTAL_CONFIG',
        payload: config
    }
}

export const change_delete_product_modal_status = () => {
    return {
        type: 'CHANGE_DELETE_PRODUCT_MODAL_STATUS'
    }
}

export const delete_product_by_id = (product_id) => {
    return {
        type: 'DELETE_PRODUCT_BY_ID',
        payload: product_id
    }
}

export const change_new_copied_product_modal_status = () => {
    return {
        type: 'CHANGE_NEW_COPIED_PRODUCT_MODAL_STATUS'
    }
}

export const set_new_copied_product = (product_id) => {
    return {
        type: 'SET_NEW_COPIED_PRODUCT',
        payload: product_id
    }
}

export const change_download_table_modal_status = () => {
    return {
        type: 'CHANGE_DOWNLOAD_TABLE_MODAL_STATUS'
    }
}

export const add_download_table_product = (item_id) => {
    return {
        type: 'ADD_DOWNLOAD_TABLE_PRODUCT',
        payload: item_id
    }
}

export const set_download_table_template = (template_name, download_table_fields, delete_template_name = "") => {
    return {
        type: 'SET_DOWNLOAD_TABLE_TEMPLATE',
        payload: {
            template_name,
            download_table_fields,
            delete_template_name
        }
    }
}

export const set_download_table_products = (product_ids) => {
    return {
        type: 'SET_DOWNLOAD_TABLE_PRODUCTS',
        payload: product_ids
    }
}

export const set_select_fields_loaded = () => {
    return {
        type: 'SET_SELECT_FIELDS_LOADED'
    };
};

export const open_subtable_modal = (item_id, subtable_row, subtable, subtable_row_id, fieldListField, subtable_row_id_field, new_row = false, copied_row = false) => {
    return {
        type: 'OPEN_SUBTABLE_MODAL',
        payload: {
            item_id,
            subtable_row,
            subtable,
            subtable_row_id,
            fieldListField,
            subtable_row_id_field,
            new_row,
            copied_row
        }
    };
};

export const close_subtable_modal = () => {
    return {
        type: 'CLOSE_SUBTABLE_MODAL'
    };
};

export const set_subtable_modal = (subtable, subtable_row) => {
    return {
        type: 'SET_SUBTABLE_MODAL',
        payload: {
            subtable,
            subtable_row
        }
    };
};

export const set_clear_subtable = (clear_subtable = true) => {
    return {
        type: 'SET_CLEAR_SUBTABLE',
        payload: clear_subtable
    };
};

export const set_active_section = (section) => {
    return {
        type: 'SET_ACTIVE_SECTION',
        payload: section
    };
};

export const set_active_section_modal = (section) => {
    return {
        type: 'SET_ACTIVE_SECTION_MODAL',
        payload: section
    };
};

export const update_search_query = (value, dataField) => {
    return {
        type: 'UPDATE_SEARCH_QUERY',
        payload: {
            value,
            dataField
        }
    };
};

export const update_search_query_modal = (value, dataField) => {
    return {
        type: 'UPDATE_SEARCH_QUERY_MODAL',
        payload: {
            value,
            dataField
        }
    };
};

export const change_total_number_of_results = (total_number_of_results) => {
    return {
        type: 'CHANGE_TOTAL_NUMBER_OF_RESULTS',
        payload: {
            total_number_of_results
        }
    };
};

export const set_search_query = (value, dataField, range = false) => {
    return {
        type: 'SET_SEARCH_QUERY',
        payload: {
            value,
            dataField,
            range
        }
    };
};

export const set_preselected_search_query = (preselected_query) => {
    return {
        type: 'SET_PRESELECTED_SEARCH_QUERY',
        payload: preselected_query
    };
};

export const set_reference_modal_article_tabs = (tabs = []) => {
    return {
        type: 'SET_REFERENCE_MODAL_ARTICLE_TABS',
        payload: tabs
    };
};

export const set_search_query_modal = (value, dataField, range = false) => {
    return {
        type: 'SET_SEARCH_QUERY_MODAL',
        payload: {
            value,
            dataField,
            range
        }
    };
};

export const delete_search_query = () => {
    return {
        type: 'DELETE_SEARCH_QUERY'
    };
};

export const delete_search_query_fields = (fields) => {
    return {
        type: 'DELETE_SEARCH_QUERY_FIELDS',
        payload: fields
    };
};

export const delete_search_query_fields_modal = (fields) => {
    return {
        type: 'DELETE_SEARCH_QUERY_FIELDS_MODAL',
        payload: fields
    };
};

export const delete_search_query_field = (field) => {
    return {
        type: 'DELETE_SEARCH_QUERY_FIELD',
        payload: field
    };
};

export const delete_search_query_field_modal = (field) => {
    return {
        type: 'DELETE_SEARCH_QUERY_FIELD_MODAL',
        payload: field
    };
};

export const reset_new_reference_modal_props = () => {
    return {
        type: 'RESET_NEW_REFERENCE_MODAL_PROPS'
    };
};

export const set_menu_filter = (menu_filter) => {
    return {
        type: 'SET_MENU_FILTER',
        payload: menu_filter
    };
};

export const set_custom_table_header_fields = (menu_filter) => {
    return {
        type: 'SET_CUSTOM_TABLE_HEADER_FIELDS',
        payload: menu_filter
    };
};

export const add_image_base64 = (image_id, image_str) => {
    return {
        type: 'ADD_IMAGE_BASE64',
        payload: {
            image_id,
            image_str
        }
    };
};

export const add_image = (id, url) => {
    return {
        type: 'ADD_IMAGE',
        payload: {
            id,
            url
        }
    };
};

export const add_images = (img_objects) => {
    return {
        type: 'ADD_IMAGES',
        payload: img_objects
    };
};

export const change_fuzziness = (fuzziness) => {
    return {
        type: 'CHANGE_FUZZINESS',
        payload: fuzziness
    };
};

export const resetStore = () => {
    return {
        type: 'RESET_STORE'
    };
};

export const hide_facet = (facet) => {
    return {
        type: 'HIDE_FACET',
        payload: facet
    };
};

export const set_edit_checkbox_type = (obj) => {
    return {
        type: 'SET_EDIT_CHECKBOX_TYPE',
        payload: obj
    };
};

export const add_new_product = (product_id) => {
    return {
        type: 'ADD_NEW_PRODUCT',
        payload: product_id
    };
};

export const delete_new_product_by_id = (product_id) => {
    return {
        type: 'DELETE_NEW_PRODUCT_BY_ID',
        payload: product_id
    };
};

export const delete_new_products_by_ids = (products_ids) => {
    return {
        type: 'DELETE_NEW_PRODUCTS_BY_IDS',
        payload: products_ids
    };
};

export const add_new_copied_product = (product_id) => {
    return {
        type: 'ADD_NEW_COPIED_PRODUCT',
        payload: product_id
    };
};

export const delete_new_copied_product_by_id = (product_id) => {
    return {
        type: 'DELETE_NEW_COPIED_PRODUCT_BY_ID',
        payload: product_id
    };
};

export const delete_new_copied_products_by_ids = (products_ids) => {
    return {
        type: 'DELETE_NEW_COPIED_PRODUCTS_BY_IDS',
        payload: products_ids
    };
};

export const set_fetched_products = (products) => {
    return {
        type: 'SET_FETCHED_PRODUCTS',
        payload: products
    };
};

export const set_tabs = (tabs) => {
    return {
        type: 'SET_TABS',
        payload: tabs
    };
};

export const set_change_index_fields = (fields) => {
    return {
        type: 'SET_CHANGE_INDEX_FIELDS',
        payload: fields
    };
};

export const set_update_source_fields = (fields) => {
    return {
        type: 'SET_UPDATE_SOURCE_FIELDS',
        payload: fields
    };
};

export const set_edited_fields = (fields) => {
    return {
        type: 'SET_EDITED_FIELDS',
        payload: fields
    };
};

export const set_shopping_cart = (items) => {
    return {
        type: 'SET_SHOPPING_CART',
        payload: items
    };
};

export const select_new_reference = (item_id) => {
    return {
        type: 'SELECT_NEW_REFERENCE',
        payload: item_id
    };
};

export const deselect_new_reference = (item_id) => {
    return {
        type: 'DESELECT_NEW_REFERENCE',
        payload: item_id
    };
};

export const set_selected_references = (references) => {
    return {
        type: 'SET_SELECTED_REFERENCES',
        payload: references
    };
};

export const set_reference_modal_product_id = (product_id) => {
    return {
        type: 'SET_REFERENCE_MODAL_PRODUCT_ID',
        payload: product_id
    };
};

export const set_reference_modal_product_field = (product_field) => {
    return {
        type: 'SET_REFERENCE_MODAL_PRODUCT_FIELD',
        payload: product_field
    };
};

export const set_reference_modal_reference_object = (reference_object) => {
    return {
        type: 'SET_REFERENCE_MODAL_REFERENCE_OBJECT',
        payload: reference_object
    };
};

export const add_new_tab_modal = (item_id) => {
    return {
        type: 'ADD_NEW_TAB_MODAL',
        payload: item_id
    };
};

export const delete_tab_modal = (item_id) => {
    return {
        type: 'DELETE_TAB_MODAL',
        payload: item_id
    };
};

export const change_hits_modal = (hits) => {
    return {
        type: 'CHANGE_HITS_MODAL',
        payload: hits
    };
};

export const set_download_table_active_template = (template_name) => {
    return {
        type: 'SET_DOWNLOAD_TABLE_ACTIVE_TEMPLATE',
        payload: template_name
    };
};

export const delete_download_table_template = (template_name) => {
    return {
        type: 'DELETE_DOWNLOAD_TABLE_TEMPLATE',
        payload: template_name
    };
};

export const add_route_path = (route) => {
    return {
        type: 'ADD_ROUTE_PATH',
        payload: route
    };
};

export const add_hidden_facet = (facet) => {
    return {
        type: 'ADD_HIDDEN_FACET',
        payload: facet
    };
};

export const set_hidden_facets = (facets) => {
    return {
        type: 'SET_HIDDEN_FACETS',
        payload: facets
    };
};