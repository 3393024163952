import React, { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { deepEqual, translate } from "../../utils/Utils";
import Home from "../../Home";
import store from "../../store/store";
import { reset_new_reference_modal_props } from "../../actions";

class NewReferenceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    closeModal() {
        const {newReferenceModalProps, selectedReferences} = this.props;
        if (!newReferenceModalProps.selectedReferences || !newReferenceModalProps.selectedReferences.length || deepEqual(selectedReferences, newReferenceModalProps.selectedReferences)) {
            store.dispatch(reset_new_reference_modal_props());
            /*store.dispatch(set_reference_modal_product_id(""));
            store.dispatch(set_reference_modal_product_field(""));
            store.dispatch(set_selected_references([]));
            store.dispatch(set_reference_modal_reference_object({}));*/
            //this.props.set_reference_modal_reference_object(referenceObjects[0]);
            console.log(1);
        }/* else if (deepEqual(selectedReferences, newReferenceModalProps.selectedReferences)) {

            console.log(2); //zurück setzen
            store.dispatch(set_reference_modal_product_id(""));
            store.dispatch(set_reference_modal_product_field(""));
        }*/
        this.props.close(this.props.modalID);
    }

    setNewReferences() {
        this.closeModal();
        this.props.setNewReferences();
    }

    render() {
        const { isOpen, newReferenceModalProps, selectedReferences } = this.props;
        return (
            <MDBModal
                className="new-reference-modal"
                position="top"
                size="lg"
                isOpen={isOpen}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                    className="new-reference-modal-header"
                >
                    {translate("select_references")}
                </MDBModalHeader>
                <MDBModalBody className="new-reference-modal-body">
                    <Home isNewReferenceModal />
                </MDBModalBody>
                <MDBModalFooter>
                    {newReferenceModalProps.selectedReferences && newReferenceModalProps.selectedReferences.length && !deepEqual(selectedReferences, newReferenceModalProps.selectedReferences) ?
                        <button
                            className="btn btn-sm"
                            id="set-new-references-button"
                            onClick={() => this.setNewReferences()}
                        >
                            {translate("apply")}
                        </button>
                        :
                        null
                    }
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default NewReferenceModal;