const compareItemsReducer = (state = [], action) => {
    switch(action.type) {
        case 'ADD_COMPARE_ITEM':
            let added_compare_item = [...state];
            added_compare_item.push(action.payload);
            state = [...added_compare_item];
            return state;
        case 'DELETE_COMPARE_ITEM':
            let compareItems = [];
            for (var i = 0; i < state.length; i++) {
                if (state[i] !== action.payload) {
                    compareItems.push(state[i]);
                }
            }
            state = compareItems;
            return state;
        case 'SET_COMPARE_ITEMS':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default compareItemsReducer;