import React, { Component } from "react";
import PropTypes from "prop-types";
import store from "../store/store";
import PictureModal from "./modals/PictureModal";
import { fetchPreview } from "../utils/Webservice";
import { downloadFileFromServer, translate } from "../utils/Utils";
import { MDBTooltip, MDBIcon } from "mdbreact";

class RefFilePicturePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPictureModal: false,
            imgExists: false
        }
        this.closePictureModal = this.closePictureModal.bind(this);
    }

    componentDidMount() {
        this.checkImgExists();
    }

    checkImgExists() {
        const { fileName } = this.props;
        const { portalConfig } = store.getState();
        const { image_base_url, reference_user, reference_password } = portalConfig;
        fetchPreview(image_base_url + fileName /*+ "?ctsUser=" + reference_user + "&ctsPassword=" + reference_password*/, reference_user, reference_password).then(() => {
            this.setState({
                imgExists: true
            });
        });
    }

    openPictureModal() {
        this.setState({
            openPictureModal: true
        });
    }

    closePictureModal() {
        this.setState({
            openPictureModal: false
        });
    }

    render() {
        const { view, fileName } = this.props;
        const { openPictureModal, imgExists } = this.state;
        const { image_base_url } = store.getState().portalConfig;
        if (view === "grid") {
            if (imgExists) {
                return (
                    <img
                        style={{ maxWidth: "180px", maxHeight: "180px", margin: "auto", display: "block" }}
                        alt={fileName}
                        src={image_base_url + fileName}
                    />
                );
            } else {
                return (
                    <img
                        style={{ maxWidth: "180px", maxHeight: "180px", margin: "auto", display: "block" }}
                        alt={fileName}
                        src={process.env.PUBLIC_URL + "/no_image_found.png"}
                    />
                );
            }
        }
        return (
            <div style={{ padding: "10px" }}>
                {openPictureModal ?
                    <PictureModal
                        close={this.closePictureModal}
                        fileName={fileName}
                    />
                    :
                    null
                }
                {imgExists ?
                    <div style={{ display: "grid" }}>
                        <img
                            alt={fileName}
                            src={image_base_url + fileName}
                            //src={process.env.PUBLIC_URL + "/no_image_found.png"}
                            onClick={() => this.openPictureModal()}
                            style={{ cursor: "pointer", maxHeight: "200px", maxWidth: "200px" }}
                        />
                        <div style={{ margin: "auto" }}>
                            <MDBTooltip
                                domElement
                                tag="span"
                                placement="top"
                            >
                                <span>
                                    <button
                                        className="btn btn-sm"
                                        onClick={() => downloadFileFromServer(fileName)}
                                    >
                                        <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                        {translate("download")}
                                    </button>
                                </span>
                                <span>{fileName}</span>
                            </MDBTooltip>
                        </div>
                    </div>
                    :
                    <img
                        alt={fileName}
                        src={process.env.PUBLIC_URL + "/no_image_found.png"}
                        style={{ maxHeight: "100px", maxWidth: "100px" }}
                    />
                }
            </div>
        );
    }
}

RefFilePicturePreview.propTypes = {
    fileName: PropTypes.string.isRequired,
    view: PropTypes.string
};

export default RefFilePicturePreview;