import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBIcon } from "mdbreact";
import { getAllFacetsOpenedObject, getFacetFilterCollapses, translate } from "../../utils/Utils";
import { min_facets_show } from "../../utils/constants";

class FacetsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depend_facet_clicked: {},
            facet_open: this.props.menuFilter.facet.length <= min_facets_show ? getAllFacetsOpenedObject() : {},
            show_all_filter: this.props.menuFilter.facet.length <= min_facets_show
        }
        this.handleFacetClick = this.handleFacetClick.bind(this);
        this.clickFacetCollapse = this.clickFacetCollapse.bind(this);
    }

    closeModal() {
        this.props.close();
    }

    handleFacetClick(facet_key, clicked_items) {
        let new_depend_facet_clicked = Object.assign({}, this.state.depend_facet_clicked);
        new_depend_facet_clicked[facet_key] = clicked_items && clicked_items.length;
        this.setState({
            depend_facet_clicked: new_depend_facet_clicked
        });
    }

    clickFacetCollapse(key) {
        let new_facet_open = Object.assign({}, this.state.facet_open);
        new_facet_open[key] = !this.state.facet_open[key]
        this.setState({
            facet_open: new_facet_open
        });
    }

    handleAllFilterClick() {
        const show_all_filter = this.state.show_all_filter;
        let new_facet_open = Object.assign({}, this.state.facet_open);
        if (!show_all_filter) {
            new_facet_open = getAllFacetsOpenedObject();
        } else {
            new_facet_open = {};
        }
        this.setState({
            show_all_filter: !show_all_filter,
            facet_open: new_facet_open
        });
    }

    render() {
        return (
            <MDBModal
                isOpen={this.props.open}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    {translate("filter")}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer className="body">
                        <div className="facet-filter-container facet-filter-container-head" style={{ marginTop: "10px" }}>
                            <div>
                                {(this.state.show_all_filter ? translate("close_all") : translate("open_all")) + ": "}
                            </div>
                            <button
                                className="btn btn-sm"
                                onClick={() => this.handleAllFilterClick()}
                            >
                                <MDBIcon size="lg" icon={this.state.show_all_filter ? "angle-up" : "angle-down"} />
                            </button>
                        </div>
                        {getFacetFilterCollapses(this.state.depend_facet_clicked, this.clickFacetCollapse, this.state.facet_open, this.handleFacetClick, this.props.isNewReferenceModal, "", true)}
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default FacetsModal;