import React, { Component } from "react";
import PortalStore from "./PortalStore";
import { Provider } from "react-redux";
import store from "./store/store";

class Portal extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        return (
            <Provider store={store}>
                <PortalStore config={this.props.config} />
            </Provider>
        );
    }
}

export default Portal;