import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
    MDBContainer, MDBRow, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane, MDBIcon,
    MDBTooltip, ToastContainer, toast, MDBBtnGroup
} from "mdbreact";
import { ReactiveBase, SelectedFilters } from "@appbaseio/reactivesearch";
import {
    translate, getIndexLanguage, getHitsArray, getAllSections, uploadChanges, getTimestamp,
    deleteProductsInStore, resetChanges, getFacetFilterCollapses, getAllFacetsOpenedObject,
    setActiveArticle, closeAllArticleTabs
} from "./utils/Utils";
import {
    clickNavBarTab, getMenuFilter, getNavItem
} from "./utils/functions";
import i18n from "i18next";
import Article from "./components/Article";
import ShoppingCartModal from "./components/ShoppingCartModal";
import ShoppingCart from "./components/ShoppingCart";
import {
    set_active_article, set_shopping_cart_open, delete_tab, add_product, new_tab, set_compare_modal, set_language,
    set_result_view, switch_edit, set_select_fields, clear_edit, clear_change_index_fields,
    clear_update_source_fields, switch_save_changes, change_uploaded, update_shopping_cart,
    change_hits, switch_edit_custom_view, change_portal_settings_modal_status, set_portal_config,
    set_custom_table_templates, change_download_table_modal_status, set_clear_subtable,
    delete_search_query, update_product, change_updating, switch_multi_edit,
    delete_search_query_field, add_new_product, delete_new_product_by_id, set_change_index_fields,
    set_update_source_fields, set_edited_fields, delete_new_copied_product_by_id, set_active_article_modal,
    set_result_view_modal, delete_search_query_field_modal, delete_search_query_fields, delete_search_query_fields_modal,
    delete_tab_modal, change_hits_modal, set_hidden_facets, set_preselected_search_query
} from "./actions";
import "./Home.css";
import OfflineResults from "./components/OfflineResults";
import PortalSettingsModal from "./components/PortalSettingsModal";
import DeleteProductModal from './components/DeleteProductModal';
import NewCopiedProductModal from './components/NewCopiedProductModal';
import SimpleModal from "./components/modals/SimpleModal";
import { min_facets_show } from "./utils/constants";
import FacetsModal from "./components/modals/FacetsModal";
import DownloadTableModal from "./components/DownloadTableModal";
import SearchResults from "./components/result/SearchResults";
import store from "./store/store";
import ImportProductsModal from "./components/modals/ImportProductsModal";
import NavBar from "./components/NavBar";
import { getIndexStats } from "./utils/Webservice";
import ModalContainer from "./components/modals/ModalContainer";

const portal_version = "v.3.3.9 Beta 1 (29.04.2022)";

/**
 * Connects redux store to component props.
 */
const mapStateToProps = (state) => {
    return {
        activeArticle: state.activeArticle,
        articleTabs: state.articleTabs,
        shoppingCart: state.shoppingCart,
        language: state.language,
        resultView: state.resultView,
        edit: state.edit,
        editedFields: state.editedFields,
        changeIndexFields: state.changeIndexFields,
        updateSourceFields: state.updateSourceFields,
        saveChanges: state.saveChanges,
        selectFields: state.selectFields,
        uploaded: state.uploaded,
        compareItems: state.compareItems,
        hits: state.hits,
        editCustomView: state.editCustomView,
        portalConfig: state.portalConfig,
        menuFilter: state.menuFilter,
        multiEdit: state.multiEdit,
        hideFacets: state.hideFacets,
        newProducts: state.newProducts,
        deleteProductID: state.deleteProductID,
        newReferenceModalProps: state.newReferenceModalProps,
        routePaths: state.routePaths,
        hiddenFacets: state.hiddenFacets
    };
};

/**
 * Connects redux actions to component props.
 */
const mapDispatchToProps = () => {
    return {
        set_active_article,
        set_shopping_cart_open,
        delete_tab,
        add_product,
        new_tab,
        set_compare_modal,
        set_language,
        set_result_view,
        switch_edit,
        set_select_fields,
        clear_edit,
        clear_change_index_fields,
        clear_update_source_fields,
        switch_save_changes,
        change_uploaded,
        update_shopping_cart,
        change_hits,
        switch_edit_custom_view,
        change_portal_settings_modal_status,
        set_portal_config,
        set_custom_table_templates,
        change_download_table_modal_status,
        set_clear_subtable,
        delete_search_query,
        update_product,
        change_updating,
        switch_multi_edit,
        delete_search_query_field,
        add_new_product,
        delete_new_product_by_id,
        set_change_index_fields,
        set_update_source_fields,
        set_edited_fields,
        delete_new_copied_product_by_id,
        set_active_article_modal,
        set_result_view_modal,
        delete_search_query_field_modal,
        delete_search_query_fields,
        delete_search_query_fields_modal,
        delete_tab_modal,
        change_hits_modal,
        set_hidden_facets,
        set_preselected_search_query
    };
};

class Portal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elastic_language_index: getIndexLanguage(this.props.language, this.props.portalConfig.elastic_index, this.props.portalConfig.default_language),
            saveEditModal: false,
            openConfirmDeleteModal: false,
            openImportProductsModal: false,
            facet_width: "normal",
            show_facet: window.innerWidth < parseInt(this.props.portalConfig.viewport_desktop) || this.props.isNewReferenceModal ? false : true,
            open_facets_modal: false,
            facet_open: this.props.menuFilter.facet.length <= min_facets_show ? getAllFacetsOpenedObject() : {},
            depend_facet_clicked: {},
            hide_facets: [],
            show_all_filter: this.props.menuFilter.facet.length <= min_facets_show,
            new_products: [],
            show_apply_new_references_button: false,
            error: null,
            index_creation_date: ""
        };
        this.isNavMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
        this.isFilterMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_desktop);
        this.saveEditModalBodyText = "Sind Sie sicher, dass Sie alle Änderungen speichern möchten?\n\nNach dem Speichern wird das Portal neu geladen und alle Änderungen übernommen.";
        this.all_article_sections = getAllSections(this.props.portalConfig.field_list);
        this.notify = this.notify.bind(this);
        this.setPortalLanguage = this.setPortalLanguage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openSaveModal = this.openSaveModal.bind(this);
        this.changeFacetWidth = this.changeFacetWidth.bind(this);
        this.handleFacetClick = this.handleFacetClick.bind(this);
        this.confirmDeleteProduct = this.confirmDeleteProduct.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.clickShowFacetButton = this.clickShowFacetButton.bind(this);
        this.clickFacetCollapse = this.clickFacetCollapse.bind(this);
    }

    /*static getDerivedStateFromError(error) {
        console.log(error);
        return {
            error: error.message,
        };
    }

    componentDidCatch(error, info) {
        console.log(error, JSON.stringify(info));
    }*/

    componentDidMount() {
        if (this.props.isNewReferenceModal && document.getElementById("set-new-references-button")) {
            this.setState({
                show_apply_new_references_button: true
            });
        }
        getIndexStats().then(timestamp => this.setState({
            index_creation_date: timestamp
        }));
    }

    componentDidUpdate() {
        if (this.props.isNewReferenceModal) {
            if (document.getElementById("set-new-references-button")) {
                if (!this.state.show_apply_new_references_button) {
                    this.setState({
                        show_apply_new_references_button: true
                    });
                }
            } else {
                if (this.state.show_apply_new_references_button) {
                    this.setState({
                        show_apply_new_references_button: false
                    });
                }
            }
        }
    }

    handleFacetClick(facet_key, clicked_items) {
        let new_depend_facet_clicked = Object.assign({}, this.state.depend_facet_clicked);
        new_depend_facet_clicked[facet_key] = clicked_items && clicked_items.length;
        this.setState({
            depend_facet_clicked: new_depend_facet_clicked
        });
    }

    clickFacetCollapse(key) {
        let new_facet_open = Object.assign({}, this.state.facet_open);
        new_facet_open[key] = !this.state.facet_open[key]
        this.setState({
            facet_open: new_facet_open
        });
    }

    openSaveModal() {
        this.setState({
            saveEditModal: true
        });
    }

    createNewProduct() {
        let new_product = {};
        for (let field of this.props.portalConfig.field_list) {
            new_product[field.dataField] = null;
        }
        new_product[this.props.portalConfig.keyfield] = getTimestamp(true);
        return new_product;
    }

    getNavContent(item_id) {
        return (
            <MDBTabPane key={item_id + "-tab-pane"} tabId={item_id} role="tabpanel">
                <Article
                    item_id={item_id}
                    //item={getProductByID(this.props.fetchedProducts, item_id)}
                    notify={this.notify}
                    resetChanges={resetChanges}
                    openSaveModal={this.openSaveModal}
                    allArticleSections={this.all_article_sections}
                    isNewProduct={this.props.newProducts.includes(item_id)}
                    isNewReferenceModal={this.props.isNewReferenceModal}
                />
            </MDBTabPane>
        );
    }

    setViewState(view) {
        const { isNewReferenceModal, newReferenceModalProps, resultView, hiddenFacets } = this.props;
        const { customTableTemplates, template } = store.getState();
        if (isNewReferenceModal && newReferenceModalProps.resultView !== view) {
            this.props.set_result_view_modal(view)
        } else if (!isNewReferenceModal && resultView !== view) {
            this.props.set_result_view(view);
            if (resultView === "custom") {
                //console.log("suche löschen");
                const template_fields = customTableTemplates[template].custom_table;
                const facets = getMenuFilter().facet.map(field => field.dataField);
                const custom_table_facets = template_fields.filter(field => !facets.includes(field.dataField) && field.dataField !== "actions_field").map(field => field.dataField);
                let hidden_facets = hiddenFacets.filter(facet => !custom_table_facets.includes(facet));
                for (let i = 0; i < hiddenFacets.length; i++) {
                    const search_bar_button = document.getElementById("search-bar-button-" + hiddenFacets[i]);
                    if (search_bar_button) {
                        if (!hidden_facets.includes(hiddenFacets[i])) {
                            hidden_facets.push(hiddenFacets[i]);
                        }
                        i = hiddenFacets.length;
                    }
                }
                //console.log(1);
                this.props.set_hidden_facets(hidden_facets);
            }
        }
    }

    /**
     * A function that checks the selected hits value and updates the hits in the store.
     * @param {string} value Selected hits value.
     */
    checkHitsValue(value) {
        const { hits, isNewReferenceModal, newReferenceModalProps } = this.props;
        if (isNewReferenceModal) {
            if (newReferenceModalProps.hits !== parseInt(value)) {
                this.props.change_hits_modal(parseInt(value));
            }
        } else {
            if (hits !== parseInt(value)) {
                this.props.change_hits(parseInt(value));
            }
        }
    }

    /**
     * Opens a new product tab.
     */
    openNewProductTab() {
        const { keyfield } = this.props.portalConfig;
        const new_product = this.createNewProduct();
        this.props.add_product(new_product);
        this.props.add_new_product(new_product[keyfield]);
        setActiveArticle(new_product[keyfield]);
    }

    openImportProductsModal() {
        this.setState({
            openImportProductsModal: true
        });
    }

    clearFilter(component, setValue, component_type) {
        setValue(component, null);
        if (component_type === "modal") {
            this.props.delete_search_query_field_modal(component.substr(0, component.indexOf("-new-reference-modal")));
        } else {
            this.props.delete_search_query_field(component);
        }
    }

    clearFilters(clearValues, component_type, selectedValues, setValue) {
        let delete_search_query_fields = [];
        for (let component in selectedValues) {
            if (selectedValues[component].componentType === component_type) {
                setValue(component, null);
                delete_search_query_fields.push(component_type === "modal" ? component.substr(0, component.indexOf("-new-reference-modal")) : component);
            }
        }
        if (component_type === "modal") {
            this.props.delete_search_query_fields_modal(delete_search_query_fields);
        } else {
            this.props.delete_search_query_fields(delete_search_query_fields);
        }
    }

    /**
     * 
     */
    createTabs() {
        const { portalConfig, isNewReferenceModal, articleTabs, newReferenceModalProps, activeArticle, hits } = this.props;
        const { color_config, show_edit, result_views, hits_options, hits_shown, elastic_index } = portalConfig;
        const { nav_color, nav_item_color, nav_item_active_color, nav_item_active_text_color, nav_item_text_color, view_button_color,
            view_button_text_color, add_button_color, add_button_text_color, remove_button_color, remove_button_text_color
        } = color_config;
        const active_article = isNewReferenceModal ? newReferenceModalProps.activeArticle : activeArticle;
        return (
            <MDBContainer fluid className="search-result-container">
                <MDBNav
                    tabs
                    className="classic-tabs main-tabs"
                    style={{ backgroundColor: nav_color }}
                >
                    <div onClick={() => clickNavBarTab("main", isNewReferenceModal)}>
                        <MDBNavItem>
                            <MDBNavLink className="nav-link"
                                link to="#" active role="tab"
                                style={{
                                    backgroundColor: active_article === "main" ? nav_item_active_color
                                        : nav_item_color, color: active_article === "main" ? nav_item_active_text_color
                                            : nav_item_text_color
                                }}
                            >
                                <div>
                                    {translate("result")}
                                </div>
                            </MDBNavLink>
                        </MDBNavItem>
                    </div>
                    {isNewReferenceModal ?
                        newReferenceModalProps.articleTabs.tabs.length ?
                            newReferenceModalProps.articleTabs.tabs.map(item_id => getNavItem(item_id, true))
                            :
                            null
                        :
                        articleTabs.tabs.length ?
                            articleTabs.tabs.map(item_id => getNavItem(item_id, false))
                            :
                            null
                    }
                    {(isNewReferenceModal && newReferenceModalProps.articleTabs.tabs.length) || (!isNewReferenceModal && articleTabs.tabs.length) ?
                        /*newReferenceModalProps.articleTabs.tabs.length ?
                            newReferenceModalProps.articleTabs.tabs.map(item_id => getNavItem(item_id, true))
                            :
                            null
                        :
                        articleTabs.tabs.length ?*/
                        <MDBNavItem className="ml-2">
                            <MDBTooltip placement="bottom" domElement tag="span">
                                <div onClick={() => closeAllArticleTabs(isNewReferenceModal)}>
                                    <MDBNavLink className="nav-link"
                                        link to="#" active role="tab"
                                        style={{
                                            backgroundColor: color_config.nav_item_color,
                                            color: color_config.nav_item_text_color
                                        }}
                                    >
                                        <MDBIcon icon="times" />
                                    </MDBNavLink>
                                </div>
                                <span>{translate("close_all")}</span>
                            </MDBTooltip>
                        </MDBNavItem>
                        :
                        null
                    }
                </MDBNav>
                <MDBTabContent
                    activeItem={active_article} className="content"
                >
                    <MDBTabPane tabId="main" role="tabpanel">
                        <MDBRow>
                            <div style={{ marginLeft: "5px" }}>
                                <SelectedFilters
                                    style={{ marginLeft: "5px" }}
                                    render={({ selectedValues, clearValues, setValue, }) => {
                                        //console.log(selectedValues);
                                        //return null;
                                        const component_type = isNewReferenceModal ? "modal" : "default";
                                        return (
                                            <div style={{ marginTop: "5px" }}>
                                                {Object.keys(selectedValues).map(component => {
                                                    if (selectedValues[component].showFilter && selectedValues[component].value && selectedValues[component].value.length && (selectedValues[component].componentType === component_type || selectedValues[component].componentType === "DATASEARCH")) {
                                                        return (
                                                            <div className="selected-filter" id={component + "-selected-filter"} key={component} onClick={() => this.clearFilter(component, setValue, component_type)}>
                                                                <span>
                                                                    {selectedValues[component].label + ": " + selectedValues[component].value}
                                                                </span>
                                                                <span>
                                                                    X
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                {Object.keys(selectedValues).filter(component => selectedValues[component].showFilter && selectedValues[component].value && selectedValues[component].componentType === component_type).length > 1 ?
                                                    <div
                                                        id="clear-all-selected-filter"
                                                        className="selected-filter"
                                                        onClick={() => this.clearFilters(clearValues, component_type, selectedValues, setValue)}
                                                    >
                                                        {translate("clear_all")}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            {this.isNavMobileSize || isNewReferenceModal ? null :
                                <MDBBtnGroup className="edit-btn-group">
                                    {!show_edit || !window.navigator.onLine ? null :
                                        this.props.saveChanges ?
                                            <button
                                                className="btn btn-sm"
                                                style={{ backgroundColor: add_button_color, color: add_button_text_color }}
                                                onClick={() => this.setState({ saveEditModal: true })}
                                            >
                                                {translate("save_changes")}
                                            </button>
                                            :
                                            null
                                    }
                                    {!show_edit || !window.navigator.onLine ? null :
                                        this.props.saveChanges ?
                                            <button
                                                className="btn btn-sm"
                                                style={{ backgroundColor: remove_button_color, color: remove_button_text_color }}
                                                onClick={() => resetChanges()}
                                            >
                                                {translate("reset_changes")}
                                            </button>
                                            :
                                            null
                                    }
                                    {!this.props.edit || !window.navigator.onLine ? null :
                                        <button
                                            className="btn btn-sm"
                                            //style={{ backgroundColor: view_button_color, color: view_button_text_color }}
                                            onClick={() => this.openNewProductTab()}
                                        >
                                            <MDBIcon icon="plus" className="mr-1" />
                                            {translate("new")}
                                        </button>
                                    }
                                    {!this.props.edit || !window.navigator.onLine ? null :
                                        <button
                                            className="btn btn-sm"
                                            //style={{ backgroundColor: view_button_color, color: view_button_text_color }}
                                            onClick={() => this.openImportProductsModal()}
                                        >
                                            IMPORT
                                        </button>
                                    }
                                    {show_edit && window.navigator.onLine && this.props.resultView === "custom" && this.props.edit && !isNewReferenceModal ?
                                        <div className='custom-switch' style={{ marginRight: "10px" }}>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id='switch_multi_edit'
                                                checked={this.props.multiEdit}
                                                onChange={() => this.props.switch_multi_edit()}
                                                readOnly
                                            />
                                            <label className='custom-control-label' htmlFor='switch_multi_edit'>
                                                Multi-Edit
                                            </label>
                                        </div>
                                        :
                                        null
                                    }
                                    {!show_edit || !window.navigator.onLine ? null :
                                        <div className='custom-switch'>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id='switch_edit'
                                                checked={this.props.edit}
                                                onChange={() => this.props.switch_edit()}
                                                readOnly
                                            />
                                            <label className='custom-control-label' htmlFor='switch_edit'>
                                                {translate("edit")}
                                            </label>
                                        </div>
                                    }
                                </MDBBtnGroup>
                            }
                        </MDBRow>
                        <MDBRow>
                            {this.state.show_apply_new_references_button ?
                                <button
                                    className="btn btn-sm apply-new-references-button"
                                    style={{ backgroundColor: add_button_color, color: add_button_text_color }}
                                    onClick={() => document.getElementById("set-new-references-button").click()}
                                >
                                    {translate("apply")}
                                </button>
                                :
                                null
                            }
                            <MDBBtnGroup className="view-btn-group">
                                {result_views.filter(view => isNewReferenceModal ? view !== "custom" : true).map(view => (
                                    <button
                                        key={"view_button_" + view}
                                        className="btn btn-sm"
                                        style={{ backgroundColor: view_button_color, color: view_button_text_color }}
                                        onClick={() => this.setViewState(view)}
                                    >
                                        {translate(view)}
                                    </button>
                                ))}
                                {this.props.resultView !== "custom" || isNewReferenceModal ? null :
                                    <button
                                        className="btn btn-sm"
                                        style={{
                                            backgroundColor: view_button_color,
                                            color: this.props.editCustomView ? "red" : view_button_text_color
                                        }}
                                        onClick={() => this.props.switch_edit_custom_view()}
                                    >
                                        <MDBIcon icon="cog" size="lg" />
                                    </button>
                                }
                                <label htmlFor="hits_select" className="hits_select_label">
                                    {translate("show") + ":"}
                                </label>
                                <select
                                    className="browser-default custom-select hits_select"
                                    onChange={event => this.checkHitsValue(event.target.value)}
                                    value={isNewReferenceModal ? newReferenceModalProps.hits : hits}
                                    id="hits_select"
                                >
                                    {getHitsArray(hits_options, hits_shown).map(hit => (
                                        <option
                                            key={"hits_select_" + hit}
                                            value={hit}
                                        >
                                            {hit}
                                        </option>
                                    ))}
                                </select>
                            </MDBBtnGroup>
                        </MDBRow>
                        <MDBRow>
                            {window.navigator.onLine ?
                                <SearchResults notify={this.notify} isNewReferenceModal={isNewReferenceModal} />
                                :
                                <OfflineResults notify={this.notify} />
                            }
                        </MDBRow>
                    </MDBTabPane>
                    {isNewReferenceModal ?
                        newReferenceModalProps.articleTabs.tabs.length ?
                            newReferenceModalProps.articleTabs.tabs.map(item_id => this.getNavContent(item_id))
                            :
                            null
                        :
                        articleTabs.tabs.length ?
                            articleTabs.tabs.map(item_id => this.getNavContent(item_id))
                            :
                            null
                    }
                    {/*articleTabs.tabs.length === 0 ? null :
                        articleTabs.tabs.map(item_id => {
                            return this.getNavContent(item_id);
                        })
                    */}
                </MDBTabContent>
            </MDBContainer>
        );
    }

    notify(type) {
        if (type === "compare_info") {
            toast.info(
                <div className="text-center toast-text">
                    <div className="toast-body" />
                    <div>
                        {translate("already_compare", "article")}
                    </div>
                    {<button
                        className="btn-xs toast-btn"
                        onClick={() => this.props.set_compare_modal()}
                    >
                        {translate("open")}
                    </button>}
                </div>
                , {
                    closeButton: true
                });
        } else if (type === "compare_success") {
            toast.success(
                <div className="text-center toast-text">
                    <div className="toast-body" />
                    <div>
                        {translate("new_compare", "article")}
                    </div>
                    {<button
                        className="btn-xs toast-btn"
                        onClick={() => this.props.set_compare_modal()}
                    >
                        {translate("open")}
                    </button>}
                </div>
                , {
                    closeButton: true
                }
            );
        } else if (type === "download_table_success") {
            toast.success(
                <div className="text-center toast-text">
                    <div className="toast-body" />
                    <div>
                        {translate("new_download_table", "article")}
                    </div>
                    {<button
                        className="btn-xs toast-btn"
                        onClick={() => this.props.change_download_table_modal_status()}
                    >
                        {translate("open")}
                    </button>}
                </div>
                , {
                    closeButton: true
                }
            );
        } else if (type === "download_table_info") {
            toast.info(
                <div className="text-center toast-text">
                    <div className="toast-body" />
                    <div>
                        {translate("already_download_table", "article")}
                    </div>
                    {<button
                        className="btn-xs toast-btn"
                        onClick={() => this.props.change_download_table_modal_status()}
                    >
                        {translate("open")}
                    </button>}
                </div>
                , {
                    closeButton: true
                }
            );
        }
    }

    setPortalLanguage(language) {
        const portalConfig = this.props.portalConfig;
        this.props.set_language(language);
        i18n.changeLanguage(language);
        this.setState({
            elastic_language_index: getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language)
        });
    }

    closeModal(modal) {
        this.setState({
            [modal]: false
        });
    }

    changeFacetWidth(facet_width) {
        this.setState({
            facet_width: facet_width
        });
    }

    handleAllFilterClick() {
        const show_all_filter = this.state.show_all_filter;
        let new_facet_open = Object.assign({}, this.state.facet_open);
        if (!show_all_filter) {
            new_facet_open = getAllFacetsOpenedObject();
            /*this.props.menuFilter.facet.map(field => {
                new_facet_open[field.dataField] = true
            });*/
        } else {
            new_facet_open = {};
        }
        this.setState({
            show_all_filter: !show_all_filter,
            facet_open: new_facet_open
        });
    }

    deleteNewProductInStore(deleteProductID) {
        const { portalConfig, editedFields, saveChanges } = this.props;
        this.props.delete_new_product_by_id(deleteProductID);
        this.props.delete_new_copied_product_by_id(deleteProductID);
        deleteProductsInStore([deleteProductID]);
        const edited_fields = editedFields.filter(field => field[portalConfig.keyfield] !== deleteProductID);
        const changeIndexFields = this.props.changeIndexFields.filter(field => field[portalConfig.keyfield] !== deleteProductID);
        const updateSourceFields = this.props.updateSourceFields.filter(field => field[portalConfig.keyfield] !== deleteProductID);
        if (saveChanges && !edited_fields.length) {
            this.props.switch_save_changes();
        }
        this.props.set_edited_fields(edited_fields);
        this.props.set_change_index_fields(changeIndexFields);
        this.props.set_update_source_fields(updateSourceFields);
    }

    confirmDeleteProduct() {
        const { deleteProductID, newProducts } = this.props;
        if (newProducts.includes(deleteProductID)) {
            this.deleteNewProductInStore(deleteProductID);
        } else {
            this.setState({
                openConfirmDeleteModal: true
            });
        }
    }

    deleteProduct() {
        const { portalConfig, language, deleteProductID } = this.props;
        let changeIndexFields = [];
        let updateSourceFields = [];
        let field_exists = false;
        let delete_products_ids = [];
        for (let field of this.props.changeIndexFields) {
            if (field[portalConfig.keyfield] === deleteProductID) {
                if (!delete_products_ids.includes(deleteProductID)) {
                    changeIndexFields.push({
                        "modus": "delete",
                        "ID": deleteProductID,
                        "target": "maintable"
                    });
                    delete_products_ids.push(deleteProductID);
                }
            } else {
                changeIndexFields.push(field);
            }
        }
        for (let field of this.props.updateSourceFields) {
            if (field[portalConfig.keyfield] === deleteProductID) {
                field_exists = true;
                updateSourceFields.push({
                    "language": language,
                    "ID": deleteProductID,
                    "changes": "delete"
                });
            } else {
                updateSourceFields.push(field);
            }
        }
        if (!field_exists) {
            changeIndexFields.push({
                "modus": "delete",
                "ID": deleteProductID,
                "target": "maintable"
            });
            updateSourceFields.push({
                "language": language,
                "ID": deleteProductID,
                "changes": "delete"
            });
        }
        uploadChanges(changeIndexFields, updateSourceFields);
    }

    clickShowFacetButton() {
        this.setState({
            show_facet: !this.state.show_facet
        });
    }

    render() {
        if (!window.navigator.onLine /*&& this.props.fetchedProducts.length < 1*/ || this.state.error) {
            console.log(this.state.error);
            return (
                <div>Keine Daten vorhanden. Die Anwendung muss mit vorhandener Internetverbindung gestartet werden.</div>
            );
        } else {
            const { isNewReferenceModal, portalConfig, menuFilter } = this.props;
            const { elastic_language_index, saveEditModal, openConfirmDeleteModal, openImportProductsModal,
                show_facet, facet_width, show_all_filter, depend_facet_clicked, facet_open
            } = this.state;
            const color_config = portalConfig.color_config;
            return (
                <Router>
                    <ReactiveBase
                        app={elastic_language_index}
                        url={portalConfig.main_url}
                        headers={{
                            'Content-Type': 'application/json',
                            'Authorization': 'Basic ' + portalConfig.token
                        }}
                    >
                        <NavBar
                            isNewReferenceModal={isNewReferenceModal}
                        />
                        <MDBContainer fluid style={isNewReferenceModal ? { marginTop: 60, paddingTop: 20 } : { marginTop: 50, paddingTop: 50 }} id={isNewReferenceModal ? "home-container-modal" : "home-container"}>
                            <SimpleModal
                                modalID="saveEditModal"
                                isOpen={saveEditModal}
                                action={uploadChanges}
                                close={this.closeModal}
                                headerText="Speichern"
                                bodyText={this.saveEditModalBodyText}
                                bodyLoadingText={translate("save_loading")}
                                useLoading={true}
                            />
                            <SimpleModal
                                modalID="openConfirmDeleteModal"
                                isOpen={openConfirmDeleteModal}
                                action={this.deleteProduct}
                                close={this.closeModal}
                                headerText={translate("delete")}
                                bodyText={translate("are_you_sure") + "\n\nNach dem Speichern wird das Portal neu geladen und alle Änderungen übernommen."}
                                bodyLoadingText={translate("save_loading")}
                                useLoading={true}
                            />
                            {portalConfig.show_edit ?
                                <ImportProductsModal
                                    modalID="openImportProductsModal"
                                    isOpen={openImportProductsModal}
                                    //action={this.deleteProduct}
                                    close={this.closeModal}
                                    //requiredFields={getUsageRequiredFields()}
                                    headerText={"Import"}
                                    bodyText={translate("are_you_sure") + "\n\nNach dem Speichern wird das Portal neu geladen und alle Änderungen übernommen."}
                                />
                                :
                                null
                            }
                            <DeleteProductModal confirmDeleteProduct={this.confirmDeleteProduct} />
                            <NewCopiedProductModal />
                            <DownloadTableModal />
                            <ShoppingCartModal />
                            <ShoppingCart />
                            <FacetsModal
                                open={show_facet && (this.isFilterMobileSize || isNewReferenceModal)}
                                close={this.clickShowFacetButton}
                                menuFilter={menuFilter}
                                isNewReferenceModal={isNewReferenceModal}
                            />
                            <ModalContainer notify={this.notify} />
                            <ToastContainer
                                className="toast-cont"
                                hideProgressBar={false}
                                newestOnTop={true}
                                autoClose={3000}
                            />
                            <PortalSettingsModal
                                setPortalLanguage={this.setPortalLanguage}
                                changeFacetWidth={this.changeFacetWidth}
                                facetWidth={facet_width}
                            />
                            <MDBRow className={"home-row" + (isNewReferenceModal || this.isNavMobileSize ? "-mobile" : "")}>
                                {/*<input type="file" id="input" multiple className="z-facet-button" onInput={console.log} />*/}
                                <div
                                    className={"facet-tab-button" + (show_facet && !this.isFilterMobileSize && !isNewReferenceModal ? facet_width === "normal" ? " facet-tab-button-show facet-tab-button-open" : " facet-tab-button-show-wide facet-tab-button-open" : "")}
                                    onClick={() => show_facet ? {} : this.clickShowFacetButton()}
                                    style={show_facet ? { boxShadow: null } : { cursor: "pointer" }}
                                >
                                    {show_facet && !this.isFilterMobileSize && !isNewReferenceModal ?
                                        <div className="facet-tab-open">
                                            <div onClick={() => this.setState({ show_facet: !show_facet })}>
                                                <MDBTooltip
                                                    domElement
                                                    tag="span"
                                                    placement="top"
                                                >
                                                    <div className="facet-tab-close-button">
                                                        <div className="facet-tab-bar-close-icon">
                                                            <MDBIcon icon="bars" />
                                                        </div>
                                                    </div>
                                                    <span>{translate("hide_filter")}</span>
                                                </MDBTooltip>
                                            </div>
                                            <div style={{ position: "relative", marginLeft: "25%", marginTop: "14px", fontWeight: "bold", cursor: "default" }}>
                                                {translate("filter")}
                                            </div>
                                            <div onClick={() => this.handleAllFilterClick()}>
                                                <MDBTooltip
                                                    domElement
                                                    tag="span"
                                                    placement="top"
                                                >
                                                    <div className="facet-tab-filter-button">
                                                        <div className="facet-tab-filter-icon">
                                                            <MDBIcon size="lg" icon={show_all_filter ? "angle-up" : "angle-down"} />
                                                        </div>
                                                    </div>
                                                    <span>
                                                        {show_all_filter ? translate("close_all") : translate("open_all")}
                                                    </span>
                                                </MDBTooltip>
                                            </div>
                                        </div>
                                        :
                                        <MDBIcon icon="bars" className="facet-tab-bar-icon" />
                                    }
                                </div>
                                <div className={this.isFilterMobileSize || isNewReferenceModal ? "hidden_facets facet-tab" : show_facet ? facet_width === "normal" ? "border-right border-light block-example facet-tab-show" : "border-right border-light block-example facet-tab-show-wide" : "facet-tab"}
                                >
                                    {getFacetFilterCollapses(depend_facet_clicked, this.clickFacetCollapse, facet_open, this.handleFacetClick, isNewReferenceModal, this.state.index_creation_date)}
                                </div>
                                <div className={show_facet && !this.isFilterMobileSize && !isNewReferenceModal ? facet_width === "normal" ? "search-results-normal" : "search-results-wide" : "search-results-large"}
                                    style={{ maxWidth: window.innerWidth - 30, minHeight: window.innerHeight - 147 }}
                                >
                                    <MDBRow>
                                        {this.createTabs()}
                                    </MDBRow>
                                </div>
                                {portalConfig.show_version_info === "1" && !isNewReferenceModal ?
                                    <div className="portal-version">
                                        {portal_version + " ID: " + portalConfig.configID}
                                    </div>
                                    :
                                    null
                                }
                                <Route path="/v">
                                    <div className="portal-version">
                                        {portal_version + " ID: " + portalConfig.configID}
                                    </div>
                                </Route>
                            </MDBRow>
                        </MDBContainer>
                    </ReactiveBase>
                    {/*this.props.routePaths.map(route => {
                            const path = "/" + route["itemID"] + "/" + route["usage"] + "/" + route["fileName"];
                            console.log(path);
                            return (
                                <Route path={path}>
                                    <PDFTab fileName={route["fileName"]} />
                                </Route>
                            );
                        })*/}
                    {/*<Route exact path="/test">
                            <PDFTab />
                    </Route>*/}
                </Router>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Portal);