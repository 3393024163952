import React from "react";
import {
    MDBTooltip, MDBRow, MDBCard, MDBCollapseHeader, MDBIcon, MDBCollapse, MDBCardBody, MDBNavItem, MDBNavLink
} from "mdbreact";
import merge from "deepmerge";
import PriceTable from "../components/PriceTable";
import EditField from "../components/EditField";
import i18n from "i18next";
import SubTable from "../components/SubTable";
import store from "../store/store";
import {
    add_products, update_products, new_tab, add_product, add_compare_item, change_delete_product_modal_status,
    delete_product_by_id, add_download_table_product, //add_image_base64,
    change_updating, clear_change_index_fields, clear_update_source_fields, clear_edit, set_clear_subtable, switch_save_changes,
    switch_edit, add_edit_field, set_active_article,
    set_shopping_cart_modal, set_shopping_cart_modal_item, set_fetched_products, set_tabs, set_compare_items,
    set_shopping_cart, set_download_table_products, add_new_product, set_change_index_fields,
    set_update_source_fields, add_new_copied_product, select_new_reference, deselect_new_reference, set_active_article_modal,
    add_new_tab_modal, set_result_view, replace_last_article_tab, set_edited_fields, delete_new_product_by_id,
    delete_new_copied_product_by_id, delete_tab_modal, delete_tab, set_hidden_facets, add_hidden_facet
} from "../actions";
import { fetchReferencePictures, getChangeIndexBulk, getUpdateSourceBulk } from "./Webservice";
import SearchMultiList from "../components/SearchMultiList";
import OfflineSearchList from "../components/offline_components/OfflineSearchList";
import SearchMultiDataList from "../components/custom_reactive_search/SearchMultiDataList";
import SearchDynamicRange from "../components/SearchDynamicRange";
import CustomReactiveSearch from "../components/CustomReactiveSearch";
import { active_article_limit, default_color_config, default_pdf_config } from "./constants";
import PDF from "../components/PDF";
import { saveAs } from "file-saver";
import HiddenFacet from "../components/HiddenFacet";
import DownloadDocButton from "../components/DownloadDocButton";
import RefFilePicturePreview from "../components/RefFilePicturePreview";
import PDFPreview from "../components/PDFPreview";
import { createHTMLElement, fetchPic, getField, getMapping, getSelectionList } from "./Utils";

//Home
function getNavItem(item_id, isNewReferenceModal) {
    const { portalConfig, fetchedProducts, newReferenceModalProps, activeArticle } = store.getState();
    const item = getProductByID(fetchedProducts, item_id);
    const tab_field = portalConfig.tab_field;
    const active_article = isNewReferenceModal ? newReferenceModalProps.activeArticle : activeArticle;
    const color_config = portalConfig.color_config;
    return (
        <div key={item_id + "-nav-item"} style={{ display: "flex", marginLeft: "5px" }}>
            <div onClick={() => clickNavBarTab(item_id, isNewReferenceModal)}>
                {item[tab_field] && item[tab_field].length > portalConfig.tab_field_length ?
                    <MDBTooltip placement="bottom">
                        {getNavLinkTab(item_id, getLabel(item[tab_field], portalConfig.tab_field_length), isNewReferenceModal)}
                        <div>{item[tab_field]}</div>
                    </MDBTooltip>
                    :
                    getNavLinkTab(item_id, item[tab_field] ? item[tab_field] : "NO_DATA", isNewReferenceModal)
                }
            </div>
            <div onClick={() => closeTab(item_id, isNewReferenceModal)}>
                <MDBNavLink
                    link to="#" active={active_article === item_id} role="tab"
                    style={{
                        backgroundColor: active_article === item_id ? color_config.nav_item_active_color : color_config.nav_item_color,
                        color: active_article === item_id ? color_config.nav_item_active_text_color : color_config.nav_item_text_color,
                        paddingRight: "0.5rem",
                        paddingLeft: "0.25rem",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                    }}
                >
                    <i className="fas fa-times fa-xs icon-top" />
                </MDBNavLink>
            </div>
        </div>
    );
}

//getNavItem
function getNavLinkTab(item_id, label, isNewReferenceModal) {
    const { newReferenceModalProps, activeArticle } = store.getState();
    const active_article = isNewReferenceModal ? newReferenceModalProps.activeArticle : activeArticle;
    return (
        <MDBNavLink
            link to="#" active={active_article === item_id} role="tab"
            style={getTabStyle(item_id, isNewReferenceModal)}
        >
            <div className="tab-label">
                {label}
            </div>
        </MDBNavLink>
    );
}

//getNavLinkTab
function getTabStyle(item_id, isNewReferenceModal) {
    const { newReferenceModalProps, activeArticle, portalConfig } = store.getState();
    const active_article = isNewReferenceModal ? newReferenceModalProps.activeArticle : activeArticle;
    const color_config = portalConfig.color_config;
    return {
        backgroundColor: active_article === item_id ? color_config.nav_item_active_color : color_config.nav_item_color,
        color: active_article === item_id ? color_config.nav_item_active_text_color : color_config.nav_item_text_color,
        paddingRight: "0.25rem",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderTopLeftRadius: "0.25rem",
        borderBottomLeftRadius: "0.25rem"
    };
}

//getNavItem
function getLabel(string, label_field_length) {
    let str = "";
    if (string !== null) {
        str = string;
        if (string.length > label_field_length) {
            str = string.substr(0, label_field_length);
            if (string.indexOf(" ", label_field_length) === label_field_length) {
                str += " ";
            }
            str += "...";
        }
    }
    return str;
}

//getNavItem
/**
 * Get the product by ID.
 * @param {object[]} products Array of products.
 * @param {string|number} product_id Product ID. 
 * @returns {object?} Product by ID or null if no product was found.
 */
function getProductByID(products, product_id) {
    const keyfield = store.getState().portalConfig.keyfield;
    if (product_id) {
        for (var i = 0; i < products.length; i++) {
            if (products[i][keyfield] && products[i][store.getState().portalConfig.keyfield].toString() === product_id.toString()) {
                return products[i];
            }
        }
    }
    return null;
}

//getNavItem, Home
function clickNavBarTab(product_id, isNewReferenceModal) {
    const { newReferenceModalProps, activeArticle } = store.getState();
    if (isNewReferenceModal && newReferenceModalProps.activeArticle !== product_id) {
        store.dispatch(set_active_article_modal(product_id));
    } else if (!isNewReferenceModal && activeArticle !== product_id) {
        store.dispatch(set_active_article(product_id));
    }
}

//getNavItem
function closeTab(product_id, isNewReferenceModal) {
    const { newReferenceModalProps, activeArticle, newProducts } = store.getState();
    if (!isNewReferenceModal && newProducts.includes(product_id)) {
        deleteNewProductInStore(product_id);
    } else {
        if (isNewReferenceModal && newReferenceModalProps.activeArticle === product_id) {
            store.dispatch(set_active_article_modal("main"));
        } else if (!isNewReferenceModal && activeArticle === product_id) {
            store.dispatch(set_active_article("main"));
        }
        if (isNewReferenceModal) {
            store.dispatch(delete_tab_modal(product_id));
        } else {
            store.dispatch(delete_tab(product_id));
        }
    }
}

//closeTab
function deleteNewProductInStore(delete_product_id) {
    const { portalConfig, editedFields, saveChanges, changeIndexFields, updateSourceFields } = store.getState();
    store.dispatch(delete_new_product_by_id(delete_product_id));
    store.dispatch(delete_new_copied_product_by_id(delete_product_id));
    deleteProductsInStore([delete_product_id]);
    const new_edited_fields = editedFields.filter(field => field[portalConfig.keyfield] !== delete_product_id);
    const new_change_index_fields = changeIndexFields.filter(field => field[portalConfig.keyfield] !== delete_product_id);
    const new_update_source_fields = updateSourceFields.filter(field => field[portalConfig.keyfield] !== delete_product_id);
    if (saveChanges && !new_edited_fields.length) {
        store.dispatch(switch_save_changes());
    }
    store.dispatch(set_edited_fields(new_edited_fields));
    store.dispatch(set_change_index_fields(new_change_index_fields));
    store.dispatch(set_update_source_fields(new_update_source_fields));
}

//deleteNewProductInStore
function deleteProductsInStore(delete_products_ids) {
    const { articleTabs, compareItems, activeArticle, shoppingCart, downloadTableModalProps } = store.getState();
    const tabs = articleTabs.tabs;
    if (delete_products_ids.includes(activeArticle)) {
        store.dispatch(set_active_article("main"));
    }
    if (tabs.length) {
        const new_tabs = tabs.filter(tab_id => !delete_products_ids.includes(tab_id));
        store.dispatch(set_tabs(new_tabs));
    }
    if (compareItems.length) {
        const new_compare_items = compareItems.filter(item_id => !delete_products_ids.includes(item_id));
        store.dispatch(set_compare_items(new_compare_items));
    }
    if (shoppingCart.length) {
        const new_shopping_cart = shoppingCart.filter(item => !delete_products_ids.includes(item.id_));
        store.dispatch(set_shopping_cart(new_shopping_cart));
    }
    if (downloadTableModalProps.productIDs.length) {
        const new_download_table_product_ids = downloadTableModalProps.productIDs.filter(item_id => !delete_products_ids.includes(item_id));
        store.dispatch(set_download_table_products(new_download_table_product_ids));
    }
}

//GridView, Article
function checkAndLoadReferences(data, reference_type = "all") {
    const { portalConfig, loadedImages } = store.getState();
    //const { reference_user, reference_password, reference_url } = portalConfig;
    const image_field = reference_type === "img" && portalConfig.field_list.filter(field => field.dataField === portalConfig.image_field).length ? portalConfig.field_list.filter(field => field.dataField === portalConfig.image_field)[0] : {};
    const reference_fields = reference_type === "all" ? portalConfig.field_list.filter(filterByReferences) : [];
    let ref_ids = [];
    for (let product of data) {
        if (reference_type === "img") {
            if (image_field.dataField && product[image_field.dataField]) {
                const img_obj = JSON.parse(product[image_field.dataField])[0];
                if (!loadedImages.hasOwnProperty(img_obj.TargetID) && !ref_ids.includes(img_obj.TargetID)) {
                    //console.log("load img", img_obj.TargetID);
                    ref_ids.push(img_obj.TargetID);
                    //fetchPic(reference_user, reference_password, reference_url + "/file/previewurl/" + img_obj.TargetID + "/?format=png&width=200&height=190", img_obj.TargetID);
                }
            }
        } else {
            for (let field of reference_fields) {
                if (product[field.dataField]) {
                    const ref_obj = JSON.parse(product[field.dataField])[0];
                    if (ref_obj.TYPE.includes("DAM") && !loadedImages.hasOwnProperty(ref_obj.TargetID) && !ref_ids.includes(ref_obj.TargetID)) {
                        //console.log("load all", ref_obj.TargetID);
                        ref_ids.push(ref_obj.TargetID);
                        //fetchPic(reference_user, reference_password, reference_url + "/file/previewurl/" + ref_obj.TargetID + "/?format=png&width=200&height=190", ref_obj.TargetID);
                    }
                }
            }
        }
    }
    //console.log(ref_ids);
    if (ref_ids.length) {
        fetchReferencePictures(ref_ids);
    }
}

//checkAndLoadReferences
function filterByReferences(field) {
    if (field.dataField.includes(":REFERENCE") && !field.dataField.includes(":REFERENCE_FILE")) {
        return true;
    } else {
        return false;
    }
}

//Article
function createDataField(product, field) {
    const { portalConfig, isNewProduct, isNewReferenceModal, loadedImages } = store.getState();
    const { reference_user, reference_password, reference_url } = portalConfig;
    //const product = this.product;
    let value = product[field.dataField];
    let refs = {};
    if (field.dataField.includes(":REFERENCE")) {
        if (product[field.dataField]) {

            /*let arr = [];
            let pic_arr = [];
            let eles = product[field.dataField];
            let obj = JSON.parse(eles);
            obj.forEach(function (entry) {
                if (entry.TYPE.includes("DAM")) {
                    if (!pic_arr.includes(entry.TargetID)) {
                        pic_arr.push(entry.TargetID);
                    }
                } else {   // falls PIM 
                    if (!arr.includes(entry.TargetID)) {
                        arr.push(entry.TargetID);
                    }
                }
            });*/

            const ref_obj = JSON.parse(product[field.dataField])[0];
            console.log(ref_obj);
            if (loadedImages.hasOwnProperty(ref_obj.TargetID)) {
                return (
                    <div /*className={isNavMobileSize || isNewReferenceModal ? "loaded-img-sm" : ""} style={style}*/>
                        <img
                            alt="no_image_found.png"
                            src={loadedImages[ref_obj.TargetID]}
                        //style={{ maxWidth: "180px", maxHeight: "180px", margin: "auto", display: "block" }}
                        />
                    </div>
                );
            }
        }
        const htmlElement = createHTMLElement(product, field.dataField, reference_user, reference_password, reference_url);
        refs[field.dataField] = htmlElement.arr;
        value = htmlElement.html.__html;
    }
    return getField(product, field, value, isNewProduct, isNewReferenceModal);
}

//NavBar
function handleLogoClick() {
    const { searchQuery, activeArticle, resultView, portalConfig } = store.getState();
    if (resultView !== portalConfig.result_views[0]) {
        store.dispatch(set_result_view(portalConfig.result_views[0]));
    }
    if (Object.keys(searchQuery).length) {
        //console.log(searchQuery);
        const preselected_filter = portalConfig.field_list.filter(field => field.hasOwnProperty("preselected") && field["preselected"].length).map(field => field.dataField);
        const delete_filter = Object.keys(searchQuery).filter(key => !preselected_filter.includes(key));
        if (delete_filter.length > 1 && (Object.keys(searchQuery).length === delete_filter.length)) { //wenn keine preselected_filter und mehr als 1 filter
            const all_selected_filter_element = document.getElementById("clear-all-selected-filter");
            //console.log(all_selected_filter_element);
            all_selected_filter_element.click();
        } else {
            for (let filter of delete_filter) {
                const selected_filter_element = document.getElementById(filter + "-selected-filter");
                //console.log(selected_filter_element);
                selected_filter_element.click();
            }
        }
    }
    if (document.getElementById("mainSearch-selected-filter")) {
        document.getElementById("mainSearch-selected-filter").click();
    }
    if (activeArticle !== "main") {
        store.dispatch(set_active_article("main"));
    }
}

//SearchBar
function setHiddenFacetsBySearchBarButton(value, search_field) {
    const { customTableTemplates, template, hiddenFacets, resultView } = store.getState();
    const facets = getMenuFilter().facet.map(field => field.dataField);
    const template_fields = customTableTemplates[template].custom_table;
    const custom_table_facets = template_fields.filter(field => !facets.includes(field.dataField) && field.dataField !== "actions_field").map(field => field.dataField);
    if (value === "all") {
        if (hiddenFacets.includes(search_field)) {
            if (resultView !== "custom" || !custom_table_facets.includes(search_field)) {
                store.dispatch(set_hidden_facets(hiddenFacets.filter(facet => facet !== search_field)));
            }
        }
    } else {
        if (!hiddenFacets.includes(value)) {
            if (facets.includes(value)) {
                if (!Array.isArray(search_field)) {
                    if (hiddenFacets.includes(search_field)) {
                        if (resultView === "custom" && custom_table_facets.includes(search_field)) {
                            store.dispatch(add_hidden_facet(value));
                        } else {
                            let hidden_facets = hiddenFacets.filter(facet => facet !== search_field);
                            store.dispatch(set_hidden_facets(hidden_facets));
                        }
                    }
                }
            } else {
                if (Array.isArray(search_field)) {
                    store.dispatch(add_hidden_facet(value));
                } else {
                    if (hiddenFacets.includes(search_field)) {
                        if (resultView === "custom" && custom_table_facets.includes(search_field)) {
                            store.dispatch(add_hidden_facet(value));
                        } else {
                            let hidden_facets = hiddenFacets.filter(facet => facet !== search_field);
                            hidden_facets.push(value);
                            store.dispatch(set_hidden_facets(hidden_facets));
                        }
                    } else {
                        store.dispatch(add_hidden_facet(value));
                    }
                }
            }
        }
    }
}

/**
 * Returns an array containing every dataField with extension that should be searched for.
 */
//SearchBar
function getSearchBarExtensionArray() {
    const field_list = store.getState().portalConfig.field_list;
    const field_extension = store.getState().portalConfig.field_extension;
    let searchbar_datafield_array = getSearchbarDataFields();
    field_list.filter(field => field.usage.includes("SEARCH")).map(field => searchbar_datafield_array.push(getElasticDataField(field.dataField) + "." + /*"search"*/ field_extension));
    //console.log(searchbar_datafield_array);
    return searchbar_datafield_array;
}
/**
 * Returns the datafield with no forbidden characters at the end.
 * @param {*} dataField 
 * @returns 
 */
function getElasticDataField(dataField) {
    let elastic_data_field = dataField;
    if (elastic_data_field.charAt(elastic_data_field.length - 1) === ".") {
        elastic_data_field = elastic_data_field.substr(0, elastic_data_field.length - 1);
    }
    return elastic_data_field;
}

/**
 * Returns the field based on the datafield.
 * @param {*} data_field 
 * @returns 
 */
function getFieldListFieldByDataField(data_field) {
    const field_list = store.getState().portalConfig.field_list;
    for (var i = 0; i < field_list.length; i++) {
        if (field_list[i].dataField === data_field) {
            return field_list[i];
        }
    }
    return {};
}

/**
 * Returns an array containing every dataField that should be searched for.
 */
function getSearchbarDataFields(used_as_label = false) {
    const field_list = store.getState().portalConfig.field_list;
    return field_list.filter(field => field.usage.includes("SEARCH") && field.filter !== "DynamicRangeSlider").sort(comparePosition).map(field => used_as_label ? { key: field.dataField, text: field.text ? field.text : field.dataField } : getElasticDataField(field.dataField));
}

/**
 * Function for sorting the fields by position.
 * @param {string} a First value.
 * @param {string} b Second value.
 */
function comparePosition(a, b) {
    //let position_a = getParsedPosition(a.position_);
    //let position_b = getParsedPosition(b.position_);
    let position_a = a.position_;
    let position_b = b.position_;
    //console.log(a);
    //console.log(b);
    if (!position_a) {
        if (position_b) {
            return 1;
        }
        return 0;
    } else if (!position_b) {
        if (position_a) {
            return -1;
        }
        return 0;
    }
    if (position_a < position_b) {
        return -1;
    }
    if (position_a > position_b) {
        return 1;
    }
    return 0;
}

/**
 * Parses the field position to integer. 
 * @param {*} position 
 * @returns 
 */
function getParsedPosition(position) {
    if (typeof (position) === "string") {
        let parsed_position = parseInt(position.replace(/[-|]/g, ""));
        return parsed_position ? parsed_position : null;
    }
    return position;
}

/**
 * Creates an object with information about which fields should be displayed in facets or otherwise.
 * @param {object[]} field_list 
 * @returns 
 */
function getMenuFilter(field_list = store.getState().portalConfig.field_list) {
    let menu_filter = {
        facet: [],
        other: [],
        custom_table: []
    };
    for (let field of field_list.sort(compareFacetPosition)) {
        if (!field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE") && field.facet_position && field.facet_position !== "0") {
            if (field.usage.includes("FACET")) {
                menu_filter.facet.push(field);
            } else if (field.usage.includes("SEARCH") || field.usage.includes("TABLE")) {
                menu_filter.other.push(field);
            }
        }
    }
    for (let field of field_list.sort(comparePosition)) {
        if (!field.dataField.includes(":REFERENCE") && !field.dataField.includes(":SUBTABLE") && (!field.facet_position || field.facet_position === "0")) {
            if (field.usage.includes("FACET")) {
                menu_filter.facet.push(field);
            } else if (field.usage.includes("SEARCH") || field.usage.includes("TABLE")) {
                menu_filter.other.push(field);
            }
        }
    }
    return menu_filter;
}

/**
 * Function for sorting the fields by facet position.
 * @param {string} a First value.
 * @param {string} b Second value.
 */
function compareFacetPosition(a, b) {
    let facet_position_a = getParsedPosition(a.facet_position);
    let facet_position_b = getParsedPosition(b.facet_position);
    if (!facet_position_a) {
        if (facet_position_b) {
            return 1;
        }
        return 0;
    } else if (!facet_position_b) {
        if (facet_position_a) {
            return -1;
        }
        return 0;
    }
    if (facet_position_a < facet_position_b) {
        return -1;
    }
    if (facet_position_a > facet_position_b) {
        return 1;
    }
    return 0;
}

/**
 * Returns the mapped label based on the clicked items and the mapping fields.
 * @param {*} clickedItems 
 * @param {*} mappingFields 
 * @returns 
 */
function getMappedLabel(clickedItems, mappingFields) {
    let mapped_lbl = "";
    for (var i = 0; i < clickedItems.length; i++) {
        for (let map_field of mappingFields) {
            if (map_field.value === clickedItems[i]) {
                mapped_lbl += map_field.label;
            }
        }
        if (i + 1 < clickedItems.length) {
            mapped_lbl += ", ";
        }
    }
    return mapped_lbl;
}

/**
 * Returns the mapping fields based on the field.
 * @param {*} field 
 * @returns 
 */
function getMappingFields(field) {
    const portal_store = store.getState();
    const selectFields = portal_store.selectFields;
    const dataField = field.dataField;
    if (portal_store.selectFieldsLoaded && selectFields[dataField]) {
        let selectionList = getSelectionList(selectFields[dataField], field["edit_foreignkey_id"] ? field.edit_foreignkey_id : field.dataField);
        const field_list_mapping = portal_store.portalConfig.field_list_mapping;
        let mapping_fields = [];
        for (let selection_field of selectionList) {
            let mapping = getMapping(selection_field.field_key, selection_field.value_key, field_list_mapping);
            if (mapping !== null) {
                mapping_fields.push({
                    label: selection_field.label_string,
                    value: selection_field.value_key,
                    color: mapping.color
                });
            } else {
                mapping_fields.push({
                    label: selection_field.label_string,
                    value: selection_field.value_key
                });
            }
        }
        return mapping_fields;
    }
    return [];
}

/**
 * Returns the fields to be displayed in the list view.
 * @returns
 */
function getListItemFields() {
    const list_item_fields = store.getState().portalConfig.field_list.filter(field => field.usage.includes("LIST") && field.usage.includes("SHOW") && !field.dataField.includes(":REFERENCE") && !field.dataField.includes(":JSON")).sort(comparePosition);
    return list_item_fields;
}

function downloadReferenceFile(file_id) {
    const { portalConfig } = store.getState();
    const { reference_user, reference_password, reference_url } = portalConfig;
    const ctsweb = "bwi";
    const uri = reference_url + "/file/downloadurl/";
    const url = uri + file_id + "/?ctsWebsite=" + ctsweb + "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
    try {
        fetch(url).then(async (response) => {
            const json = await response.json();
            let ref_pic_url = "";
            if (response && response.status === 200) {
                if (Array.isArray(json)) {
                    ref_pic_url = json[0];
                } else {
                    ref_pic_url = json;
                }
                ref_pic_url += "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
            }
            //console.log(ref_pic_url);
            const element = document.createElement("a");
            element.href = ref_pic_url;
            element.click();
        });
    } catch (error) {
        console.log(error);
    }
}

function getDataAsString(data) {
    if (data || typeof(data) === "boolean") {
        if (typeof (data) === "string") {
            return data;
        } else if (typeof(data) === "object") {
            if (Array.isArray(data)) {
                return data.toString();
            } else {
                return JSON.stringify(data);
            }
        }
        return data.toString();
    } else {
        return "";
    }
}

/**
 * Checks if article buttons should be displayed in the grid view.
 */
function showGridViewArticleButtons() {
    const { show_details, show_excel, show_basket, show_comparelist } = store.getState().portalConfig;
    return show_details || show_basket || show_comparelist || show_excel ? true : false;
}

function getImportTemplateFields() {
    const { field_list } = store.getState().portalConfig;
    let import_template_fields = [];
    import_template_fields = [...field_list].filter(field => field.import_template && Array.isArray(field.import_template) && field.import_template.length);
    return import_template_fields;
}

export {
    getNavItem, checkAndLoadReferences, createDataField, clickNavBarTab, handleLogoClick, setHiddenFacetsBySearchBarButton,
    getSearchBarExtensionArray, getElasticDataField, getFieldListFieldByDataField, getSearchbarDataFields, comparePosition,
    getMenuFilter, getMappedLabel, getMappingFields, getListItemFields, downloadReferenceFile, getDataAsString,
    showGridViewArticleButtons, getImportTemplateFields
};