import axios from "axios";
import {
    getChangeIndexBulkData, getDefaultQuery, getFetchFilterNew, getIndexLanguage, getTimestamp, getUpdateSourceBulkData,
    getDeleteBulkData, includesObject, getLocaleDateString, getDateString
} from "./Utils";
import store from "../store/store";
import {
    delete_new_copied_products_by_ids, delete_new_products_by_ids, set_select_fields, set_select_fields_loaded, add_images
} from "../actions";
//import * as fs from "fs";

const fs = require('fs');

async function loadProductsByID(ids, callback, search_after = null, prefetched_products = []) {
    const { searchQuery, portalConfig } = store.getState();
    const { keyfield, main_url, elastic_index, token, query_size } = portalConfig;
    //const url = main_url + elastic_index_foreignkey + "/_search";
    let id_string = "";
    for (let j = 0; j < ids.length; j++) {
        const id = ids[j];
        id_string += id;
        if (j < ids.length - 1) {
            id_string += ", ";
        }
    }
    let query = {
        "from": 0,
        "size": query_size,
        "query": {
            "bool": {
                "must": [
                    {
                        "match": {
                            "field_id": id_string
                        }
                    }
                ]
            }
        },
        "sort": [
            {
                "_id": "asc"
            }
        ]
    };
    if (search_after) {
        query["search_after"] = [search_after];
    }
    await axios({
        //url: url,
        method: 'post',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + token
        }
    }).then(response => {
        // successfully fetched
        const hits = response.data.hits.hits;
        //console.log(hits);
        let fetched_products = search_after ? prefetched_products : [];
        for (let hit of hits) {
            const data = hit._source;
            if (!fetched_products.map(product => product[keyfield]).includes(data[keyfield])) {

            }
        };
        //console.log(fetched_products);
        callback(fetched_products);
        /*if (hits.length < query_size) {
            callback(fetched_products);
        } else {
            fetchProducts(callback, hits[hits.length - 1]._id, fetched_products);
        }*/
    })
        .catch(function (error) {
            console.log("fetch: ", error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });
}

async function fetchProducts(callback, search_after = null, prefetched_products = []) {
    const { searchQuery, portalConfig } = store.getState();
    const { keyfield, main_url, elastic_index, token, query_size } = portalConfig;
    const url = main_url + elastic_index + "/_search";
    let query = getDefaultQuery();
    //console.log(query);
    query["size"] = query_size;
    //query["size"] = 20;
    /*query["sort"] = [
        {
            "_id": "asc"
        }
    ];*/
    //console.log(query);
    if (search_after) {
        query["search_after"] = [search_after];
    }
    let key_array = [];
    if (Object.keys(searchQuery).length > 0) {
        if (query.query.bool.must[0].match_all) {
            query.query.bool.must = [];
        }
        for (let key of Object.keys(searchQuery)) {
            const key_keyword = key.replace(/\.$/, "") + ".keyword";
            key_array.push(key);
            if (searchQuery[key].range) {
                query.query.bool.must.push({
                    "range": {
                        [key_keyword]: {
                            "gte": searchQuery[key].range.gte,
                            "lte": searchQuery[key].range.lte,
                            "boost": 2.0
                        }
                    }
                });
            } else {
                if (searchQuery[key].includes("empty")) {
                    query.query.bool.must_not.push({
                        "exists": {
                            "field": key_keyword
                        }
                    });
                } else if (searchQuery[key].includes("not_empty")) {
                    if (query.query.bool.hasOwnProperty("must")) {
                        query.query.bool.must.push({
                            "exists": {
                                "field": key_keyword
                            }
                        });
                    } else {
                        query.query.bool.must = {
                            "exists": {
                                "field": key_keyword
                            }
                        };
                    }
                    if (query.query.bool.hasOwnProperty("must_not")) {
                        query.query.bool.must_not.push({
                            "term": {
                                [key_keyword]: " "
                            }
                        });
                    } else {
                        query.query.bool.must_not = {
                            "term": {
                                [key_keyword]: " "
                            }
                        };
                    }
                } else {
                    query.query.bool.must.push({
                        "match": {
                            [key_keyword]: searchQuery[key].toString()
                        }
                    });
                }
            }
        };
    }
    //console.log(query);
    await axios({
        url: url,
        method: 'post',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + token
        }
    }).then(response => {
        // successfully fetched
        const hits = response.data.hits.hits;
        //console.log(hits);
        let fetched_products = search_after ? prefetched_products : [];
        for (let hit of hits) {
            const data = hit._source;
            if (!fetched_products.map(product => product[keyfield]).includes(data[keyfield])) {
                if (key_array.length > 0) {
                    let key_counter = 0; // Wenn ein Produkt den Filterkriterien entspricht, wird der Counter um 1 erhöht. Falls Counter gleich der Anzahl der key´s, sind alle Kriterien erfüllt.
                    for (var i = 0; i < key_array.length; i++) {
                        const key = key_array[i];
                        if (searchQuery[key].range) {
                            if (data[key] >= searchQuery[key].range.gte && data[key] <= searchQuery[key].range.lte) {
                                key_counter++;
                            } else {
                                i = key_array.length;
                            }
                        } else if (searchQuery[key].includes("empty")) {
                            if (!data[key] || (Array.isArray(data[key]) && !data[key].length) || (typeof (data[key]) === "object" && !Object.keys(data[key]).length)) {
                                key_counter++;
                            } else {
                                i = key_array.length;
                            }
                        } else if (searchQuery[key].includes("not_empty")) {
                            if (data[key]) {
                                key_counter++;
                            } else {
                                i = key_array.length;
                            }
                        } else if (data[key] === searchQuery[key].toString() || searchQuery[key].includes(data[key]) || data[key].sort((a, b) => a - b).toString().includes(searchQuery[key].sort((a, b) => a - b).toString())) {
                            key_counter++;
                        } else {
                            i = key_array.length;
                        }
                    }
                    if (key_counter === key_array.length) {
                        fetched_products.push(data);
                    }
                } else {
                    fetched_products.push(data);
                }
            }
        };
        //console.log(fetched_products);
        //callback(fetched_products);
        if (hits.length < parseInt(query_size)) {
            callback(fetched_products);
        } else {
            fetchProducts(callback, hits[hits.length - 1]._id, fetched_products);
        }
    })
        .catch(function (error) {
            console.log("fetch: ", error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });
}

function getUpdateSourceBulk(updateSourceFields) {
    const { portalConfig, language } = store.getState();
    const source_index_url = portalConfig.main_url + getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language);
    const query_size = portalConfig.query_size;
    let update_source_bulk_data = "";
    let id_string = "";
    for (let i = 0; i < updateSourceFields.length; i++) {
        const field = updateSourceFields[i];
        id_string += field.ID.toString();
        if (i < updateSourceFields.length - 1) {
            id_string += ", ";
        }
    }
    const query = {
        "query": {
            "bool": {
                "must": [
                    {
                        "match": {
                            [portalConfig.keyfield]: id_string
                        }
                    }
                ]
            }
        },
        "size": query_size
    };
    return new Promise(async function (resolve, reject) {
        await axios({
            url: source_index_url + "/_search",
            method: 'post',
            data: JSON.stringify(query),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + portalConfig.token
            }
        }).then(response => {
            const hits = response.data.hits.hits;
            let new_products_ids = [];
            if (hits.length < 1) {
                const default_filter = Array.isArray(portalConfig.default_filter) ? getFetchFilterNew(portalConfig.default_filter) : portalConfig.default_filter;
                let default_filter_obj = {};
                if (default_filter.hasOwnProperty("must")) {
                    for (let match of default_filter.must) {
                        if (match.match) {
                            for (let key of Object.keys(match.match)) {
                                default_filter_obj[key] = match.match[key];
                            };
                        }
                    };
                }
                for (let field of updateSourceFields) {
                    let changes = Object.assign({}, field.changes);
                    for (let key of Object.keys(default_filter_obj)) {
                        changes[key] = default_filter_obj[key];
                    };
                    changes[portalConfig.keyfield] = field.ID;
                    new_products_ids.push(field.ID);
                    update_source_bulk_data += getUpdateSourceBulkData("create", changes);
                };
                store.dispatch(delete_new_products_by_ids(new_products_ids));
                store.dispatch(delete_new_copied_products_by_ids(new_products_ids));
                if (update_source_bulk_data) {
                    postBulkRequest(source_index_url, update_source_bulk_data.replace(/X_enter_X/g, "\\n"), portalConfig.token).then(value => {
                        resolve(value);
                    });
                } else {
                    resolve("done");
                }
            } else {
                for (let field of updateSourceFields) {
                    let product_exists = false;
                    for (let hit of hits) {
                        if (hit._source[portalConfig.keyfield].toString() === field.ID.toString()) {
                            product_exists = true;
                            if (field.changes === "delete") {
                                update_source_bulk_data += getDeleteBulkData(hit._id);
                            } else {
                                update_source_bulk_data += getUpdateSourceBulkData("update", field.changes, hit._id);
                            }
                        }
                    };
                    if (!product_exists) {
                        const default_filter = Array.isArray(portalConfig.default_filter) ? getFetchFilterNew(portalConfig.default_filter) : portalConfig.default_filter;
                        let default_filter_obj = {};
                        for (let match of default_filter.must) {
                            if (match.match) {
                                for (let key of Object.keys(match.match)) {
                                    default_filter_obj[key] = match.match[key];
                                };
                            }
                        };
                        let changes = Object.assign({}, field.changes);
                        for (let key of Object.keys(default_filter_obj)) {
                            changes[key] = default_filter_obj[key];
                        };
                        new_products_ids.push(field.ID);
                        changes[portalConfig.keyfield] = field.ID;
                        update_source_bulk_data += getUpdateSourceBulkData("create", changes);
                    }
                };
                store.dispatch(delete_new_products_by_ids(new_products_ids));
                store.dispatch(delete_new_copied_products_by_ids(new_products_ids));
                if (update_source_bulk_data) {
                    postBulkRequest(source_index_url, update_source_bulk_data.replace(/X_enter_X/g, "\\n"), portalConfig.token).then(value => {
                        console.log(value);
                        resolve(value);
                    });
                } else {
                    resolve("done");
                }
            }
        }).catch(error => {
            console.log("getUpdateSourceBulk: ", error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });
    });
}

function getChangeIndexBulk(changeIndexFields) {
    const { portalConfig } = store.getState();
    const change_index_url = portalConfig.main_url + portalConfig.elastic_index_changes;
    const query_size = portalConfig.query_size;
    let change_index_bulk_data = "";
    let id_string = "";
    for (let i = 0; i < changeIndexFields.length; i++) {
        const field = changeIndexFields[i];
        id_string += field.ID.toString();
        if (i < changeIndexFields.length - 1) {
            id_string += ", ";
        }
    }
    const query = {
        "query": {
            "bool": {
                "must": [
                    {
                        "match": {
                            "ID": id_string
                        }
                    }
                ],
                "must_not": [
                    {
                        "exists": {
                            "field": "written2source"
                        }
                    }
                ]
            }
        },
        "size": query_size
    };
    return new Promise(async function (resolve, reject) {
        await axios({
            url: change_index_url + "/_search",
            method: 'post',
            data: JSON.stringify(query),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + portalConfig.token
            }
        }).then(response => {
            const hits = response.data.hits.hits;
            let stored_change_index_items = {};
            let changed_field_index = [];
            for (let hit of hits) {
                if (stored_change_index_items[hit._source.ID]) {
                    stored_change_index_items[hit._source.ID].push({
                        index_id: hit._id,
                        index_changes: hit._source.changes,
                        index_modus: hit._source.modus,
                        index_subtable_id: hit._source.subtable_id,
                        index_target: hit._source.target
                    });
                } else {
                    stored_change_index_items[hit._source.ID] = [{
                        index_id: hit._id,
                        index_changes: hit._source.changes,
                        index_modus: hit._source.modus,
                        index_subtable_id: hit._source.subtable_id,
                        index_target: hit._source.target
                    }];
                }
            };
            for (let j = 0; j < changeIndexFields.length; j++) {
                const field = changeIndexFields[j];
                if (stored_change_index_items[field.ID]) {
                    for (let hit of stored_change_index_items[field.ID]) {
                        if (field.modus === "delete") {
                            if (field.target === "maintable") {
                                if (!changed_field_index.includes(j)) {
                                    changed_field_index.push(j);
                                }
                                if (hit.index_target === "maintable" && hit.index_modus === "update") {
                                    const update_changes = {
                                        changes: "",
                                        date: getTimestamp(),
                                        modus: "delete"
                                    };
                                    change_index_bulk_data += getChangeIndexBulkData("update", update_changes, hit.index_id);
                                } else {
                                    change_index_bulk_data += getDeleteBulkData(hit.index_id);
                                }
                            } else if ((field.target === "subtable" && hit.index_target === "subtable") && (field.subtable_id === hit.index_subtable_id)) {
                                if (hit.index_modus === "create") {
                                    change_index_bulk_data += getDeleteBulkData(hit.index_id);
                                } else if (hit.index_modus === "update") {
                                    const update_changes = {
                                        changes: "",
                                        date: getTimestamp(),
                                        modus: "delete"
                                    };
                                    change_index_bulk_data += getChangeIndexBulkData("update", update_changes, hit.index_id);
                                }
                                if (!changed_field_index.includes(j)) {
                                    changed_field_index.push(j);
                                }
                            }
                        } else if (field.target === "maintable" && hit.index_target === "maintable") {
                            const hit_changes = JSON.parse(hit.index_changes.replace(/\n/g, "X_enter_X"));
                            let new_data = Object.assign(Object.assign({}, hit_changes), field.changes);
                            const update_changes = {
                                changes: new_data,
                                date: getTimestamp()
                            };
                            if (!changed_field_index.includes(j)) {
                                changed_field_index.push(j);
                            }
                            change_index_bulk_data += getChangeIndexBulkData("update", update_changes, hit.index_id);
                        } else if ((field.target === "subtable" && hit.index_target === "subtable") && (field.subtable_id === hit.index_subtable_id)) {
                            const hit_changes = JSON.parse(hit.index_changes.replace(/\n/g, "X_enter_X"));
                            let new_data = Object.assign(Object.assign({}, hit_changes), field.changes);
                            const update_changes = {
                                changes: new_data,
                                date: getTimestamp()
                            };
                            if (!changed_field_index.includes(j)) {
                                changed_field_index.push(j);
                            }
                            change_index_bulk_data += getChangeIndexBulkData("update", update_changes, hit.index_id);
                        }
                    };
                }
            }
            for (var i = 0; i < changeIndexFields.length; i++) {
                const field = changeIndexFields[i];
                if (!changed_field_index.includes(i)) {
                    change_index_bulk_data += getChangeIndexBulkData("create", field);
                }
            }
            if (change_index_bulk_data) {
                console.log(change_index_bulk_data);
                postBulkRequest(change_index_url, change_index_bulk_data.replace(/X_enter_X/g, "\\n"), portalConfig.token).then(value => {
                    console.log(value);
                    resolve(value);
                });
            } else {
                resolve("done");
            }
        }).catch(error => {
            if (error.response.status === "404") {
                console.log("error => postNewDoc");
                for (let k = 0; k < changeIndexFields.length; k++) {
                    const field = changeIndexFields[k];
                    change_index_bulk_data += getChangeIndexBulkData(k === 0 ? "index" : "create", field);
                }
                postBulkRequest(change_index_url, change_index_bulk_data.replace(/X_enter_X/g, "\\n"), portalConfig.token).then(value => {
                    console.log(value);
                    resolve(value);
                });
            } else {
                console.log("postChanges: ", error);
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        });
    });
}

function postBulkRequest(url, bulk_data, token) {
    return new Promise(async function (resolve, reject) {
        await axios({
            url: url + "/_bulk",
            method: 'post',
            data: bulk_data,
            headers: {
                'Content-Type': 'application/x-ndjson',
                'Authorization': 'Basic ' + token
            }
        }).then(response => {
            console.log(response);
            resolve("done");
        }).catch(error => {
            console.log("postBulkRequest", error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });
    });
}

async function loadSelectFieldsByID(ids, languages, main_url, elastic_index_foreignkey, token, current_language, query_size, search_after = null, prefetched_products = {}) {
    const url = main_url + elastic_index_foreignkey + "/_search";
    let language = "";
    for (let i = 0; i < languages.length; i++) {
        const lang = languages[i];
        if (lang !== current_language) {
            language += lang;
            if (i < languages.length - 1) {
                language += ", ";
            }
        }
    }
    /*languages.map((lang, index) => {
        if (lang !== current_language) {
            language += lang;
            if (index < languages.length-1) {
                language += ", ";
            }
        }
    });*/
    let id_string = "";
    for (let j = 0; j < ids.length; j++) {
        const id = ids[j];
        id_string += id;
        if (j < ids.length - 1) {
            id_string += ", ";
        }
    }
    /*ids.map((id, index) => {
        id_string += id;
        if (index < ids.length - 1) {
            id_string += ", ";
        }
    });*/
    let query = {
        "from": 0,
        "size": query_size,
        "query": {
            "bool": {
                "must": [
                    {
                        "match": {
                            "field_id": id_string
                        }
                    }
                ],
                "must_not": [
                    {
                        "match": {
                            "label_language": language
                        }
                    }
                ]
            }
        },
        "sort": [
            {
                "_id": "asc"
            }
        ]
    };
    if (search_after) {
        query["search_after"] = [search_after];
    }
    await axios({
        url: url,
        method: 'post',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + token
        }
    }).then(response => {
        // successfully fetched
        const hits = response.data.hits.hits;
        let selectFields = search_after ? Object.assign({}, prefetched_products) : Object.assign({}, store.getState().selectFields);
        for (let hit of hits) {
            for (var i = 0; i < ids.length; i++) {
                if (hit._source["field_id"] === ids[i]) {
                    if (selectFields[ids[i]] && !includesObject(selectFields, hit._source)) {
                        selectFields[ids[i]].push(hit._source);
                    } else {
                        selectFields[ids[i]] = [hit._source];
                    }
                }
            }
        };
        if (hits.length < query_size) {
            //console.log(selectFields);
            store.dispatch(set_select_fields(selectFields));
            store.dispatch(set_select_fields_loaded());
        } else {
            loadSelectFieldsByID(ids, languages, main_url, elastic_index_foreignkey, token, current_language, query_size, hits[hits.length - 1]._id, selectFields);
        }
    }).catch(error => {
        store.dispatch(set_select_fields_loaded());
        console.warn("loadSelectFieldsByID");
        /*if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        }*/
    });
}

function loadReferenceProducts(ids) {
    console.log(ids);
    const { portalConfig, language } = store.getState();
    //const portalConfig = this.props.portalConfig;
    const url = portalConfig.main_url + getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language) + "/_search";
    const query = {
        "from": 0,
        "size": 10000,
        "query": {
            "bool": {
                "must": [
                    {
                        "terms":
                        {
                            [`${portalConfig.reference_idfield}`]: ids
                        }
                    }
                ]
            }
        }
    };
    return new Promise(async function (resolve, reject) {
        await axios({
            url: url,
            method: 'post',
            data: JSON.stringify(query),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + portalConfig.token
            }
        }).then(response => {
            // successfully fetched ...
            let ref_shown = [];
            let single_ref = {};
            let items = response.data.hits.hits;
            let products = [];
            for (let hit of items) {
                products.push(hit._source);
                //this.addToFetchedProducts(hit._source);
                single_ref = {}
                for (let myfield of portalConfig.reference_linkfields) {
                    single_ref = { ...single_ref, [`${myfield}`]: hit._source[myfield] };
                };
                /*portalConfig.reference_linkfields.map(myfield => {
                    single_ref = { ...single_ref, [`${myfield}`]: hit._source[myfield] }
                })*/
                ref_shown.push(single_ref);
            };
            /*items.map(hit => {
                products.push(hit._source);
                //this.addToFetchedProducts(hit._source);
                single_ref = {}
                portalConfig.reference_linkfields.map(myfield => {
                    single_ref = { ...single_ref, [`${myfield}`]: hit._source[myfield] }
                })
                ref_shown.push(single_ref);
            });*/
            resolve(products);
            /*this.setState({
                fetchNewProducts: false
            });*/
        }).catch(function (error) {
            console.log("fetchProducts: ", error);
        });
    });
}

/*async function fetchPDFPreview(fileName, callBack) {
    const preview_pic_name = fileName.replace(/.pdf/gi, ".jpg");
    await axios({
        url: store.getState().portalConfig.image_base_url + preview_pic_name,
        //url: "http://www.404simulator.com/",
        method: 'get'
    }).then(response => {
        if (response.data.includes("<!DOCTYPE html>") || response.status === 404) {
            callBack({
                previewLoaded: true
            });
        } else {
            callBack({
                previewExists: true,
                previewLoaded: true
            });
        }
    }).catch(error => {
        console.log("pdf: ", error);
        callBack({
            previewLoaded: true
        });
    });
}*/

function fetchPDFPreview(fileName) {
    const { image_base_url, reference_user, reference_password } = store.getState().portalConfig;
    const preview_pic_name = fileName.replace(/.pdf/gi, ".jpg");
    return new Promise(async function (resolve, reject) {
        await axios({
            url: image_base_url + preview_pic_name + "?ctsUser=" + reference_user + "&ctsPassword=" + reference_password,
            //url: "http://www.404simulator.com/",
            method: 'head',
            /*auth: {
                username: reference_user,
                password: reference_password
            }*/
        }).then(response => {
            if (response.data.includes("<!DOCTYPE html>") || response.status === 404) {
                //reject();
                resolve({
                    previewLoaded: true
                });
            } else {
                resolve({
                    previewExists: true,
                    previewLoaded: true
                });
            }
        }).catch(error => {
            //console.log("pdf: ", error);
            resolve({
                previewLoaded: true
            });
            //reject();
            /*console.log("error", error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }*/
        });
    });
}

function fetchPreview(url, username = "", password = "") {
    //console.log(url);
    return new Promise(async function (resolve, reject) {
        const preview_url = username && password ? url + "?ctsUser=" + username + "&ctsPassword=" + password : url;
        await axios({
            //url: url,
            url: preview_url,
            //url: "http://www.404simulator.com/",
            method: 'head',
            /*auth: {
                username: username,
                password: password
            }*/
        }).then(response => {
            if (response.data.includes("<!DOCTYPE html>") || response.status === 404) {
                //reject();
                //console.log("error");
            } else {
                //console.log("y");
                resolve(true);
            }
        }).catch(error => {
            //console.log(error);
            //reject();
            /*console.log("error", error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }*/
        });
    });
}

function checkPDFExists(fileName) {
    return new Promise(async function (resolve, reject) {
        await axios({
            url: store.getState().portalConfig.image_base_url + fileName,
            //url: "http://www.404simulator.com/",
            method: 'head'
        }).then(response => {
            if (response.data.includes("<!DOCTYPE html>") || response.status === 404) {
                resolve(false);
            } else {
                resolve(true);
            }
        }).catch(error => {
            resolve(false);
        });
    });
}

async function fetchReferencePictures(ref_ids) {
    const { portalConfig } = store.getState();
    const { reference_user, reference_password, reference_url } = portalConfig;
    const ctsweb = "bwi";
    const uri = reference_url + "/file/previewurl/";
    try {
        var ref_pictures = await Promise.all(ref_ids.map(id => {
            const url = uri + id + "/?format=png&width=200&height=190&ctsWebsite=" + ctsweb + "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
            return fetch(url).then(async (response) => {
                const json = await response.json();
                let ref_pic_url = "";
                if (response && response.status === 200) {
                    if (Array.isArray(json)) {
                        ref_pic_url = json[0];
                    } else {
                        ref_pic_url = json;
                    }
                    ref_pic_url += "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
                }
                //console.log("ref pic url: ", ref_pic_url);
                return {
                    "id": id,
                    "url": ref_pic_url
                }
            });
        }));
        //console.log(ref_pictures);
        store.dispatch(add_images(ref_pictures));
    } catch (error) {
        console.log(error);
    }
}

async function getIndexStats() {
    return new Promise(async function (resolve, reject) {
        await axios({
            url: store.getState().portalConfig.url + "/_settings",
            //url: "http://www.404simulator.com/",
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + store.getState().portalConfig.token
            }
        }).then(response => {
            const timestamp = response.data[store.getState().portalConfig.elastic_index].settings.index.creation_date;
            const date = new Date(parseInt(timestamp));
            //const day = date.getDate() + 1 
            //console.log(date.getMonth());
            //var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            const formatted_date = getDateString(date) + " " + date.toLocaleTimeString();
            //const formatted_date = date.toLocaleDateString(/*"de-DE", options*/) + " " + date.toLocaleTimeString();
            resolve(formatted_date);
        }).catch(error => {
            console.log(error);
            const date = new Date();
            resolve(getDateString(date) + " " + date.toLocaleTimeString());
        });
    });
}

function checkReferenceFileIsPDF(fileID) {
    const { portalConfig } = store.getState();
    const { reference_user, reference_password, reference_url } = portalConfig;
    const ctsweb = "bwi";
    const uri = reference_url + "/file/pdfurl/";
    const url = uri + fileID + "/?ctsWebsite=" + ctsweb + "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
    //console.log(fileID);
    return new Promise(async function (resolve, reject) {
        await axios({
            url: url,
            //url: "http://www.404simulator.com/",
            method: 'head'
        }).then(response => {
            //console.log(response.data);
            if (response.data.includes("'Code':400") || response.status === 404) {
                //console.log(fileID, response);
                resolve(false);
            } else {
                //console.log(fileID, response);
                resolve(true);
            }
        }).catch(error => {
            //console.log("checkReferenceFileIsPDF " + fileID, error);
            resolve(false);
        });
    });
}

function getPDFFile(fileID) {
    const { portalConfig } = store.getState();
    const { reference_user, reference_password, reference_url } = portalConfig;
    const ctsweb = "bwi";
    const uri = reference_url + "/file/pdf/";
    const url = uri + fileID + "/?ctsWebsite=" + ctsweb + "&ctsUser=" + reference_user + "&ctsPassword=" + reference_password;
    //console.log(fileID);
    return new Promise(async function (resolve, reject) {
        await axios({
            url: url,
            //url: "http://www.404simulator.com/",
            method: 'get',
            responseType: "blob"
        }).then(response => {
            const pdf_file = new File([response.data], "tst");
            resolve(pdf_file);
        }).catch(error => {
            //console.log("getPDFFile " + fileID, error);
            resolve(false);
        });
    });
}

export {
    fetchProducts, postBulkRequest, getChangeIndexBulk, getUpdateSourceBulk, loadSelectFieldsByID,
    loadReferenceProducts, fetchPDFPreview, fetchPreview, checkPDFExists, fetchReferencePictures, getIndexStats,
    checkReferenceFileIsPDF, getPDFFile
}