const numberOfResultsReducer = (state = {total_number_of_results: 0}, action) => {
    switch(action.type) {
        case 'CHANGE_TOTAL_NUMBER_OF_RESULTS':
            state = Object.assign(Object.assign({}, state), action.payload);
            //state = action.payload;
            return state;
        default:
            return state;
    }
}

export default numberOfResultsReducer;