import React, { Component } from "react";
import { ResultCard } from "@appbaseio/reactivesearch";
import {
    MDBRow, MDBTooltip, MDBIcon
} from "mdbreact";
import {
    getLabel, translate, getButtons, openArticleDetails, getMapping, getSelection,
    getMappingComponent, getTableText, getFlipCardBackDataByField, checkPicture
} from "../../utils/Utils";
import "../../css/SearchResults.css";
import "../../css/FlippingCard.css";
import "../../Home.css";
import PropTypes from "prop-types";
import store from "../../store/store";
import PicturePreview from "../PicturePreview";

class FlippingCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flipped: false,
            className: ""
        }
        this.isNavMobileSize = window.innerWidth < parseInt(store.getState().portalConfig.viewport_mobile);
        this.portalConfig = store.getState().portalConfig;
    }

    componentDidMount() {
        this.setState({
            className: this.getInitClassName()
        });
    }

    rotateCard() {
        const { flipped } = this.state;
        this.setState({
            flipped: !flipped
        });
    }

    getInitClassName() {
        const { isNewReferenceModal, item, cardBackFields, showButtons } = this.props;
        const { portalConfig } = store.getState();
        const { label_field, label_field_length } = portalConfig;
        let className = "";
        if (isNewReferenceModal) {
            className = "result-card-reference";
        } else {
            if (this.isNavMobileSize) {
                if (cardBackFields.length) {
                    className = "flip-card-mobile";
                } else {
                    className = "result-card-mobile";
                }
                if (!showButtons) {
                    //className += "-no-buttons";
                }
            } else {
                if (cardBackFields.length) {
                    className = "flip-card";
                } else {
                    className = "result-card";
                }
                if (item[label_field].length > 35 && label_field_length > 35) {
                    className += "-label-wrap";
                }
                if (!showButtons) {
                    className += "-no-buttons";
                }
            }
        }
        //console.log(className);
        return className;
    }

    render() {
        const { item, isNewReferenceModal, cardBackFields, showButtons } = this.props;
        const { className, flipped } = this.state;
        const { image_field, label_field, label_field_length, keyfield } = this.portalConfig;
        return (
            <ResultCard key={"flip-card-" + item[keyfield]} className={flipped ? className + "-flipped" : className}
            //className={className}
            >
                <div className="flip-card-inner">
                    <div className="flip-card-front" /*style={this.state.flipped ? {visibility: "hidden"} : {}}*/>
                        {/*this.state.flipped ? null
                            :*/
                            !image_field.includes(":") ?
                                <PicturePreview
                                    isNewReferenceModal={isNewReferenceModal}
                                    item={item}
                                    view="grid"
                                />
                                :
                                <ResultCard.Image
                                    onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                                >
                                    <MDBRow center
                                        className={this.isNavMobileSize || isNewReferenceModal ? "img-transform pt-3" : "pt-3"}
                                        style={{ marginLeft: "-22px", height: "-webkit-fill-available" }}
                                    >
                                        <div className={this.isNavMobileSize || isNewReferenceModal ? "loaded-img-sm" : ""}
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                margin: "unset",
                                            }}
                                        >
                                            {/*this.props.imageData ?
                                            <img src={"data:image/png;base64, " + this.props.imageData} alt="" />
                                            :*/
                                                //<div dangerouslySetInnerHTML={createHTMLElement(item, image_field, reference_user, reference_password, reference_url).html} />
                                                checkPicture(item, isNewReferenceModal, "grid")
                                            }
                                        </div>
                                    </MDBRow>
                                </ResultCard.Image>
                        }
                        <ResultCard.Title
                            className="resultcard-title"
                            onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                        >
                            {item[label_field] ?
                                item[label_field].length > label_field_length ?
                                    <MDBTooltip
                                        domElement
                                        tag="span"
                                        placement="top"
                                    >
                                        <span>{getLabel(item[label_field], this.isNavMobileSize ? 20 : label_field_length)}</span>
                                        <span>{item[label_field]}</span>
                                    </MDBTooltip>
                                    :
                                    <span>{item[label_field]}</span>
                                :
                                "NO_DATA"
                            }
                        </ResultCard.Title>
                        <ResultCard.Description className="resultcard-description">
                            {showButtons ?
                                getButtons(item[keyfield], this.props.notify, this.isNavMobileSize ? "buttons-mobile" : "buttons-td", isNewReferenceModal)
                                :
                                null
                            }
                            {/*getButtons(item[keyfield], this.props.notify, this.isNavMobileSize ? "buttons-mobile" : "buttons-td", isNewReferenceModal)*/}
                            {isNewReferenceModal || !cardBackFields.length ? null
                                :
                                <div
                                    className={this.isNavMobileSize ? "rotate-btn-mobile" : "rotate-btn"}
                                    onClick={() => this.rotateCard()}>
                                    <MDBIcon icon="undo" /> {translate("rotate")}
                                </div>
                            }
                        </ResultCard.Description>
                    </div>
                    <div className="flip-card-back">
                        <ResultCard.Title
                            className={this.isNavMobileSize ? "resultcard-title-mobile" : "resultcard-title"}
                        >
                            <table className="flip-card-back-list">
                                <tbody>
                                    {cardBackFields.map(field => {
                                        return (
                                            <tr
                                                key={field.text + "-flip-card-tr"}
                                            >
                                                <td className="label-col">
                                                    {getTableText(field.text && field.text.replace(/\s/g, "") ? field.text : field.dataField)}:
                                                </td>
                                                <td className="data-col">
                                                    {/*this.getFlipCardBackDataByField(field)*/}
                                                    {getFlipCardBackDataByField(item[field.dataField], field)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </ResultCard.Title>
                        <ResultCard.Description className="resultcard-description">
                            {showButtons ?
                                getButtons(item[keyfield], this.props.notify, this.isNavMobileSize ? "buttons-mobile" : "buttons-td", isNewReferenceModal)
                                :
                                null
                            }
                            {/*getButtons(item[keyfield], this.props.notify, this.isNavMobileSize ? "buttons-mobile" : "buttons-td", isNewReferenceModal)*/}
                            {<div
                                className={this.isNavMobileSize || isNewReferenceModal ? "rotate-btn-mobile" : "rotate-btn"}
                                onClick={() => this.rotateCard()}>
                                <MDBIcon icon="redo" /> {translate("rotate")}
                            </div>}
                        </ResultCard.Description>
                    </div>
                </div>
            </ResultCard>
        );
    }
}

FlippingCard.propTypes = {
    item: PropTypes.object.isRequired,
    isNewReferenceModal: PropTypes.bool,
    notify: PropTypes.func.isRequired,
    cardBackFields: PropTypes.array.isRequired,
    imageData: PropTypes.string,
    showButtons: PropTypes.bool
};

export default FlippingCard;