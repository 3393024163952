import React, { Component } from "react";
import { ResultCard, ReactiveList, ResultList } from '@appbaseio/reactivesearch';
import { MDBRow, MDBTooltip, MDBTable, MDBTableHead, MDBTableBody, MDBCol, MDBContainer
} from "mdbreact";
import { getLabel, getPicture, getField, getTableText, getTableList, deepEqual, getButtons, 
    openArticleDetails 
} from "../utils/Utils";
import { connect } from "react-redux";
import { set_active_article, set_shopping_cart_modal, set_shopping_cart_modal_item, new_tab, add_product,add_compare_item, 
    change_hits
} from "../actions";
import CustomTable from "./CustomTable";
import "../css/OfflineResults.css";
import { getListItemFields } from "../utils/functions";
const { ResultCardsWrapper, ResultListWrapper } = ReactiveList;

const mapStateToProps = (state) => {
    return {
        fetchedProducts: state.fetchedProducts,
        articleTabs: state.articleTabs,
        compareItems: state.compareItems,
        hits: state.hits,
        portalConfig: state.portalConfig,
        resultView: state.resultView,
        selectFields: state.selectFields,
        searchQuery: state.searchQuery
    };
};

const mapDispatchToProps = () => {
    return {
        add_product,
        set_active_article,
        new_tab,
        set_shopping_cart_modal,
        set_shopping_cart_modal_item,
        add_compare_item,
        change_hits
    };
};

class OfflineResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //number_of_products: this.props.fetchedProducts.length,
            //pages: this.props.fetchedProducts.length / this.props.hits,
            active_page: 1,
            list_list: getListItemFields(),
            table_list: getTableList(this.props.portalConfig.field_list, this.props.portalConfig.image_field),
            //products_by_page: this.getProductsByPage(1),
            hits: this.props.hits,
            //filtered_products: this.getFilteredProducts(this.props.searchQuery),
            search_query: this.props.searchQuery
        }
        console.log("constructor");
    }

    componentDidUpdate() {
        if (this.props.hits !== this.state.hits) {
            this.setState({
                active_page: 1,
                //products: this.getProductsByPage(1),
                hits: this.props.hits
            });
        }
        //console.log(this.state.search_query);
        //console.log(this.props.searchQuery);
        //console.log(deepEqual(this.state.search_query, this.props.searchQuery));
        if (!deepEqual(this.state.search_query, this.props.searchQuery)) {
            //console.log(this.state.search_query);
        //console.log(this.props.searchQuery);
            this.setState({
                search_query: this.props.searchQuery,
                active_page: 1
                //filtered_products: this.getFilteredProducts(this.props.searchQuery)
            });
        }
        /*if (this.state.products.length < 1 && this.state.active_page !== 1) {
            this.setState({
                active_page: 1,
                products: this.getProductsByPage(1)
            });
        }*/
    }

    getStartIndex() {
        const active_page = this.state.active_page;
        if (active_page === 1) {
            return 0;
        } else {
            return this.state.hits * (active_page - 1);
        }
    }

    getEndIndex(filtered_products_length) {
        const active_page = this.state.active_page;
        //const filtered_products = this.state.filtered_products;
        let end_index = this.state.hits;
        if (active_page === 1) {
            if (filtered_products_length < end_index) {
                end_index = filtered_products_length;
            }
            return end_index;
        } else {
            end_index = this.state.hits * active_page;
            if (filtered_products_length < end_index) {
                end_index = filtered_products_length;
            }
            return end_index;
        }
    }

    getFilteredProducts() {
        const fetched_products = this.props.fetchedProducts;
        const search_query = this.props.searchQuery;
        let filtered_products = [];
        //console.log(fetched_products);
        //console.log(search_query);
        if (Object.keys(search_query).length < 1) {
            return fetched_products;
        }
        for (let product of fetched_products) {
            let push_product = false;
            for (let key in search_query) {
                if (search_query[key].includes(product[key])) {
                    push_product = true;
                }
            }
            if (push_product) {
                filtered_products.push(product);
            }
        }
        /*fetched_products.map(product => {
            let push_product = false;
            Object.keys(search_query).map(key => {
                //console.log(search_query[key]);
                if (search_query[key].includes(product[key])) {
                    push_product = true;
                }
            });
            if (push_product) {
                filtered_products.push(product);
            }
        });*/
        return filtered_products;
    }

    getProductsByPage(filtered_products) {
        //const filtered_products = this.getFilteredProducts();
        let products = [];
        //console.log(this.getStartIndex(active_page));
        //console.log(this.getEndIndex(active_page));
        const end_index = this.getEndIndex(filtered_products.length);
        for (var i = this.getStartIndex(); i < end_index; i++) {
            products.push(filtered_products[i]);
        }
        console.log(products);
        return products;
    }

    getImageComponent(item) {
        const portalConfig = this.props.portalConfig;
        const keyfield = portalConfig.keyfield;
        return (
            <ResultCard.Image 
                id={item[keyfield]} 
                src={getPicture(item, "no_image_found.png", portalConfig.image_field, portalConfig.image_base_url, portalConfig.image_suffix)} 
                onClick={(event) => openArticleDetails(event, item[keyfield])} 
            />
        );
    }

    createField(item, field = this.props.portalConfig.image_field) {
        return getField(item, field, item[field.dataField]);
    }

    getFieldData(field) {
        const image_field = this.props.portalConfig.image_field;
        if (field === image_field || field.dataField === image_field) {
            return image_field;
        } else {
            return field.dataField;
        }
    }

    setTime(time) {
        this.setState({
            time: Date.now() - time
        });
    }

    onPageClick(page) {
        window.scrollTo(0,0);
        this.setState({
            active_page: page
        });
    }

    getPageButtons(pages_array) {
        let show_array = [];
        for (let page of pages_array) {
            if (page === 1 || page === pages_array.length) {
                show_array.push(page);
            } else if (page === this.state.active_page - 1 || page === this.state.active_page || page === this.state.active_page + 1) {
                show_array.push(page);
            } else if (page === this.state.active_page - 2 || page === this.state.active_page + 2) {
                show_array.push(0)
            }
        }
        /*pages_array.map(page => {
            if (page === 1 || page === pages_array.length) {
                show_array.push(page);
            } else if (page === this.state.active_page - 1 || page === this.state.active_page || page === this.state.active_page + 1) {
                show_array.push(page);
            } else if (page === this.state.active_page - 2 || page === this.state.active_page + 2) {
                show_array.push(0)
            }
        });*/
        return (
            show_array.map(page => {
                if (page === 0) {
                    return (
                        <button
                            className="pagination-placeholder"
                        >
                            ...
                        </button>
                    );
                } else {
                    return (
                        <button
                            className={this.state.active_page === page ? "page-btn-active" : "page-btn"}
                            onClick={() => this.onPageClick(page)}
                        >
                            {page}
                        </button>
                    );
                }
            })
        );
    }

    getPagination(products_length) {
        let pages = products_length / this.state.hits;
        console.log(pages);
        if (!Number.isInteger(pages)) {
            pages = Math.ceil(pages);
        }
        let pages_array = [];
        for (var i = 1; i <= pages; i++) {
            pages_array.push(i);
        }
        return (
            <div className="custom-pagination">
                <button
                    className="pagination-btn"
                    style={{marginRight: 2.5, cursor: this.state.active_page === 1 ? "not-allowed" : "pointer"}}
                    onClick={() => this.state.active_page === 1 ? null : this.onPageClick(this.state.active_page - 1)}
                >
                    {"<"}
                </button>
                {this.getPageButtons(pages_array)}
                <button
                    className="pagination-btn"
                    style={{marginLeft: 2.5, cursor: this.state.active_page === pages_array.length ? "not-allowed" : "pointer"}}
                    onClick={() => this.state.active_page === pages_array.length ? null : this.onPageClick(this.state.active_page + 1)}
                >
                    {">"}
                </button>
            </div>
        );
    }

    render() {
        const portalConfig = this.props.portalConfig;
        const label_field = portalConfig.label_field;
        const image_field = portalConfig.image_field;
        const keyfield = portalConfig.keyfield;
        const button_field = portalConfig.button_field;
        const label_field_length = portalConfig.label_field_length;
        const filtered_products = this.getFilteredProducts();
        const products_by_page = this.getProductsByPage(filtered_products);
        //console.log(products_by_page);
        if (this.props.resultView === "grid") {
            return (
                <MDBContainer fluid>
                    <MDBRow className="results">
                        <ResultCardsWrapper >
                            {products_by_page.map(item => (
                                <ResultCard key={item._id} >
                                    {!image_field.includes(":") ?
                                        this.getImageComponent(item)
                                        :
                                        <ResultCard.Image
                                            onClick={(event) => openArticleDetails(event, item[keyfield])}
                                        >
                                            <MDBRow center className="pt-3">
                                                {/*this.createField(item)*/}
                                            </MDBRow>
                                        </ResultCard.Image>
                                    }
                                    <ResultCard.Title
                                        className="resultcard-title"
                                    >
                                        {item[label_field] !== null && item[label_field] !== undefined ?
                                            item[label_field].length > label_field_length ?
                                                <MDBTooltip
                                                    domElement
                                                    tag="span"
                                                    placement="top"
                                                >
                                                    <span>{getLabel(item[label_field], label_field_length)}</span>
                                                    <span>{item[label_field]}</span>
                                                </MDBTooltip>
                                                :
                                                <span>{item[label_field]}</span>
                                            :
                                            "NO_DATA"
                                        }
                                    </ResultCard.Title>
                                    <ResultCard.Description className="resultcard-description">
                                        {getButtons(item[keyfield], this.props.notify, "buttons-td")}
                                    </ResultCard.Description>
                                </ResultCard>
                            ))}
                        </ResultCardsWrapper>
                    </MDBRow>
                    <MDBRow>
                            {this.getPagination(filtered_products.length)}
                    </MDBRow>
                </MDBContainer>
            );
        } else if (this.props.resultView === "list") {
            return (
                <MDBContainer fluid>
                    <MDBRow className="results">
                        <ResultListWrapper className="result-list">
                            {products_by_page.map(item => (
                                <ResultList key={item._id} className="resultlist">
                                    {!image_field.includes(":") ?
                                        <div>
                                            <ResultList.Image
                                                className="resultlist-img"
                                                src={getPicture(item, "no_image_found.png", image_field, portalConfig.image_base_url, portalConfig.image_suffix)}
                                                onClick={(event) => openArticleDetails(event, item[keyfield])}
                                            />
                                            {getButtons(item[keyfield], this.props.notify, "buttons-td")}
                                        </div>
                                        :
                                        <ResultList.Image
                                            className="resultlist-img"
                                            onClick={(event) => openArticleDetails(event, item[keyfield])}
                                        >
                                            <MDBRow center>
                                                {/*this.createField(item)*/}
                                                {getButtons(item[keyfield], this.props.notify, "buttons-td")}
                                            </MDBRow>
                                        </ResultList.Image>
                                    }
                                    <ResultList.Content>
                                        <ResultList.Description>
                                            <MDBTable>
                                                <MDBTableBody>
                                                    {this.state.list_list.map(field => (
                                                        <MDBRow className="result-list-body">
                                                            <MDBCol className="col content" size="3">
                                                                {getTableText(field.text) + ":"}
                                                            </MDBCol>
                                                            <MDBCol className="overflow-auto content" size="auto">
                                                                {this.createField(item, field)}
                                                            </MDBCol>
                                                        </MDBRow>
                                                    ))}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </ResultList.Description>
                                    </ResultList.Content>
                                </ResultList>
                            ))}
                        </ResultListWrapper>
                    </MDBRow>
                    <MDBRow>
                        {this.getPagination(filtered_products.length)}
                    </MDBRow>
                </MDBContainer>
            );
        } else if (this.props.resultView === "table") {
            return (
                <div>
                    <MDBTable className="result-table overflow-auto">
                        <MDBTableHead>
                            <tr>
                                {this.state.table_list.map(field => (
                                    <th className="result-table-head border-left border-light">
                                        {getTableText(field.text)}
                                    </th>
                                ))}
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {products_by_page.map(item => (
                                <tr>
                                    {this.state.table_list.map(field => (
                                        <td
                                            className={field.dataField === button_field ?
                                                "border-left border-light button-field" :
                                                "border-left border-light"
                                            }
                                        >
                                            {this.createField(item, field)}
                                            {field.dataField === button_field ?
                                                getButtons(item[keyfield], this.props.notify, "buttons-td")
                                                :
                                                null
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                    <MDBRow>
                        {this.getPagination(filtered_products.length)}
                    </MDBRow>
                </div>
            );
        } else if (this.props.resultView === "custom") {
            return (
                <div>
                    <MDBRow>
                        <CustomTable data={products_by_page} notify={this.props.notify} />
                    </MDBRow>
                    <MDBRow>
                        {this.getPagination(filtered_products.length)}
                    </MDBRow>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(OfflineResults);