import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import { translate } from "../utils/Utils";
//import { switch_show_subtables_directly } from "../actions";

const mapStateToProps = (state) => {
    return {
        //language: state.language,
        //showSubtablesDirectly: state.showSubtablesDirectly
    };
};

const mapDispatchToProps = () => {
    return {
        //switch_show_subtables_directly
    };
};

class PriceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTable: false,
            showTableDirectly: true
        };
    }

    click() {
        this.setState({
            showTable: !this.state.showTable
        });

    }

    getTable() {
        const priceTable = this.props.priceTable;
        let price_table_string = priceTable;
        let table_rows_counter = [];
        let table_heads_counter = 0;
        let table_data_counter = 0;
        let table_heads = [];
        let table_data = {};
        while (price_table_string.includes("<tr>")) {
            table_rows_counter.push("tr" + table_rows_counter.length);
            price_table_string = price_table_string.replace("<tr>", "");
        }
        table_rows_counter.pop();
        while (price_table_string.includes("<th>")) {
            table_heads_counter++;
            const regexp = new RegExp("<th>(.*?)</th>");
            table_heads.push(price_table_string.match(regexp)[1]);
            price_table_string = price_table_string.replace("<th>", "");
        }
        for (let head of table_heads) {
            table_data[head] = [];
        }
        /*table_heads.map(head => {
            table_data[head] = [];
        });*/
        while (price_table_string.includes("<td>")) {
            const regexp = new RegExp("<td>(.*?)</td>");
            console.log(table_data_counter);
            table_data[table_heads[table_data_counter]].push(price_table_string.match(regexp)[1]);
            price_table_string = price_table_string.replace("<td>", "");
            if (table_data_counter === table_heads_counter - 1) {
                table_data_counter = 0; 
            } else {
                table_data_counter++;
            }
        }
        //price_table_string = priceTable;
        console.log(price_table_string);
        console.log(table_rows_counter);
        console.log(table_heads_counter);
        //console.log(table_data_counter);
        console.log(table_heads);
        console.log(table_data);
        if (table_heads_counter === 0) {
            return null;
        }
        const regexp = new RegExp('class="(.*?)">');
        console.log(price_table_string.match(regexp)[1]);
        return (
            <table className={price_table_string.match(regexp)[1]}>
                <thead>
                    <tr>
                        {table_heads.map(head => (
                            <th>
                                {head}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {table_rows_counter.map((row, index) => (
                        <tr id={row}>
                            {table_heads.map(head => (
                                <td>
                                    {table_data[head][index]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            !this.state.showTable ?
                (<MDBBtn size="sm" onClick={() => this.click()}
                    gradient="morpheus-den"
                    style={{ color: "white" }}
                >
                    {translate("show")}
                </MDBBtn>)
                :
                (<div>
                    <MDBBtn size="sm" onClick={() => this.click()}
                        gradient="morpheus-den"
                        style={{ color: "white" }}
                    >
                        {translate("hide")}
                    </MDBBtn>
                    {/*this.getTable()*/}
                    <div dangerouslySetInnerHTML={{ __html: this.props.priceTable }} id={this.props.id} />
                </div>)
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(PriceTable);