import React, { Component } from "react";
import {
    MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDropdown, MDBDropdownMenu,
    MDBDropdownToggle, MDBDropdownItem
} from "mdbreact";
import { connect } from "react-redux";
import { change_portal_settings_modal_status, change_fuzziness } from "../actions";
import { translate } from "../utils/Utils";
import "../css/PortalSettingsModal.css";

const mapStateToProps = (state) => {
    return {
        openPortalSettingsModal: state.openPortalSettingsModal,
        language: state.language,
        portalConfig: state.portalConfig,
        fuzziness: state.fuzziness
    };
};

const mapDispatchToProps = () => {
    return {
        change_portal_settings_modal_status, change_fuzziness
    };
};

class PortalSettingsModal extends Component {
    constructor(props) {
        super(props);
        this.isNavMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
        this.fuzziness_array = [0, 1, 2, "AUTO"];
    }

    handleLanguageChange(lang) {
        this.props.setPortalLanguage(lang);
    }

    getFuzzinessLabel(fuzziness) {
        switch (fuzziness) {
            case 0:
                return translate("no");
            case 1:
                return translate("weak");
            case 2:
                return translate("strong");
            default:
                return "AUTO";
        }
    }

    changeFuzziness(fuzziness) {
        this.props.change_fuzziness(fuzziness);

    }

    changeFacetWidth(facet_width) {
        this.props.changeFacetWidth(facet_width);
    }

    render() {
        const language_options = this.props.portalConfig.languages.length > 1 ? this.props.portalConfig.languages.filter(lang => lang !== this.props.language) : this.props.portalConfig.languages;
        const fuzziness_options = this.fuzziness_array.filter(fuzziness => fuzziness !== this.props.fuzziness);
        return (
            <MDBModal
                isOpen={this.props.openPortalSettingsModal}
                toggle={() => this.props.change_portal_settings_modal_status()}
                className="settings-modal"
            >
                <MDBModalHeader
                    toggle={() => this.props.change_portal_settings_modal_status()}
                >
                    {translate("settings")}
                </MDBModalHeader>
                <MDBModalBody className="mt-1">
                    <MDBRow>
                        <MDBCol className="modal-col">
                            {translate("national_language")}
                        </MDBCol>
                        <MDBCol className="modal-col">
                            <MDBDropdown key="language-dropdown">
                                <MDBDropdownToggle
                                    nav
                                    caret
                                    className="dropdown-toggle"
                                    key="language-dropdown-toggle"
                                    style={{ color: "black" }}
                                >
                                    {this.props.language.toUpperCase()}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {language_options.map(lang => (
                                        <MDBDropdownItem
                                            key={"dropdown_" + lang}
                                            className="dropdown-item"
                                            onClick={() => this.handleLanguageChange(lang)}
                                        >
                                            {lang.toUpperCase()}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="modal-col">
                            {translate("fuzziness")}
                        </MDBCol>
                        <MDBCol className="modal-col">
                            <MDBDropdown key="fuzziness-dropdown">
                                <MDBDropdownToggle
                                    nav
                                    caret
                                    className="dropdown-toggle"
                                    key="fuzziness-dropdown-toggle"
                                    style={{ color: "black" }}
                                >
                                    {this.getFuzzinessLabel(this.props.fuzziness)}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {fuzziness_options.map(fuzziness => (
                                        <MDBDropdownItem
                                            key={"dropdown_" + fuzziness}
                                            className="dropdown-item"
                                            onClick={() => this.changeFuzziness(fuzziness)}
                                        >
                                            {this.getFuzzinessLabel(fuzziness)}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>
                    {this.isNavMobileSize ? null :
                        <MDBRow>
                            <MDBCol className="modal-col">
                                {translate("facet_width")}
                            </MDBCol>
                            <MDBCol className="modal-col">
                                <MDBDropdown key="facet-width-dropdown">
                                    <MDBDropdownToggle
                                        nav
                                        caret
                                        className="dropdown-toggle"
                                        key="facet-width-dropdown-toggle"
                                        style={{ color: "black" }}
                                    >
                                        {translate(this.props.facetWidth === "normal" ? "normal" : "wide")}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem
                                            className="dropdown-item"
                                            onClick={() => this.changeFacetWidth(this.props.facetWidth === "normal" ? "wide" : "normal")}
                                        >
                                            {translate(this.props.facetWidth === "normal" ? "wide" : "normal")}
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBCol>
                        </MDBRow>
                    }
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.props.change_portal_settings_modal_status()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(PortalSettingsModal);