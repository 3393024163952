const newReferenceModalPropsReducer = (state = { articleTabs: { tabs: [], active_section: null }, activeArticle: "main", resultView: "", searchQuery: {}, selectedReferences: [], product_id: "", product_field: "", referenceObject: {}, hits: 0 }, action) => {
    switch (action.type) {
        case 'ADD_NEW_TAB_MODAL':
            //let added_tab_state = Object.assign({}, state);
            state.articleTabs.tabs.push(action.payload);
            //added_tab_state.tabs.push(action.payload);
            //state = added_tab_state;
            return state;
        case 'SET_ACTIVE_ARTICLE_MODAL':
            state = Object.assign({}, state);
            state.activeArticle = action.payload;
            return state;
        case 'SET_RESULT_VIEW_MODAL':
            state = Object.assign({}, state);
            state.resultView = action.payload;
            return state;
        case 'SET_ACTIVE_SECTION_MODAL':
            state = Object.assign({}, state);
            state.articleTabs.active_section = action.payload;
            return state;
        case 'SET_SEARCH_QUERY_MODAL':
            state = Object.assign({}, state);
            if (action.payload.range) {
                state.searchQuery[action.payload.dataField] = {
                    "range": {
                        "gte": action.payload.value[0],
                        "lte": action.payload.value[1]
                    }
                };
            } else {
                state.searchQuery[action.payload.dataField] = [action.payload.value];
            }
            return state;
        case 'DELETE_SEARCH_QUERY_FIELD_MODAL':
            state = Object.assign({}, state);
            delete state.searchQuery[action.payload];
            console.log(state);
            return state;
        case 'UPDATE_SEARCH_QUERY_MODAL':
            const dataField = action.payload.dataField;
            const item_key = action.payload.value;
            state = Object.assign({}, state);
            if (state.hasOwnProperty(dataField)) {
                if (state.searchQuery[dataField].includes(item_key)) {
                    let new_array = [];
                    for (let key of state.searchQuery[dataField]) {
                        if (key !== item_key) {
                            new_array.push(key);
                        }
                    }
                    /*state.searchQuery[dataField].map(key => {
                        if (key !== item_key) {
                            new_array.push(key);
                        }
                    });*/
                    if (!new_array.length) {
                        delete state.searchQuery[dataField];
                    } else {
                        state.searchQuery[dataField] = new_array;
                    }
                } else {
                    state.searchQuery[dataField].push(item_key);
                }
            } else {
                state.searchQuery[dataField] = [item_key];
            }
            return state;
        case 'RESET_NEW_REFERENCE_MODAL_PROPS':
            state.product_id = "";
            state.product_field = "";
            state.selectedReferences = [];
            state.referenceObject = {};
            /*state = {
                articleTabs: {
                    tabs: [],
                    active_section: null
                },
                activeArticle: "main",
                resultView: "",
                searchQuery: {}
            }*/
            return state;
        case 'DELETE_SEARCH_QUERY_FIELDS_MODAL':
            state = Object.assign({}, state);
            for (let key of action.payload) {
                delete state.searchQuery[key];
            }
            /*action.payload.map(key => {
                delete state.searchQuery[key];
            });*/
            return state;
        case 'SELECT_NEW_REFERENCE':
            state = Object.assign({}, state);
            let selected_references = [];
            state.selectedReferences.map(item_id => selected_references.push(item_id));
            selected_references.push(action.payload);
            state.selectedReferences = selected_references;
            return state;
        case 'DESELECT_NEW_REFERENCE':
            state = Object.assign({}, state);
            let deselected_references = state.selectedReferences.filter(item_id => item_id !== action.payload);
            state.selectedReferences = deselected_references;
            return state;
        case 'SET_SELECTED_REFERENCES':
            state.selectedReferences = action.payload;
            return state;
        case 'SET_REFERENCE_MODAL_PRODUCT_ID':
            state.product_id = action.payload;
            return state;
        case 'SET_REFERENCE_MODAL_PRODUCT_FIELD':
            state.product_field = action.payload;
            return state;
        case 'SET_REFERENCE_MODAL_REFERENCE_OBJECT':
            state.referenceObject = action.payload;
            return state;
        case 'DELETE_TAB_MODAL':
            state.articleTabs.tabs = state.articleTabs.tabs.filter(tab => tab !== action.payload);
            return state;
        case 'CHANGE_HITS_MODAL':
            state = Object.assign({}, state);
            state.hits = action.payload;
            return state;
        case 'SET_REFERENCE_MODAL_ARTICLE_TABS':
            state.articleTabs.tabs = [...action.payload];
            state = {...state};
            return state;
        default:
            return state;
    }
}

export default newReferenceModalPropsReducer;