import React, { Component } from "react";
import { connect } from "react-redux";
import { add_change_index_field, update_source_fields, switch_save_changes } from "../actions";
import {
    translate, getSelectFieldsByID, getSelectFieldID, getMultiselectMaxcharByField, getSelection,
    getNewCheckboxValue, getMapping, saveEditStore, getField
} from "../utils/Utils";
import { MDBDatePicker, MDBIcon } from "mdbreact";
import { Multiselect } from "multiselect-react-dropdown";
import "../Home.css";
import TextInput from "./TextInput";
import { getFieldListFieldByDataField } from "../utils/functions";

const mapStateToProps = (state) => {
    return {
        editedFields: state.editedFields,
        selectFields: state.selectFields,
        language: state.language,
        selectionList: state.selectionList,
        portalConfig: state.portalConfig,
        saveChanges: state.saveChanges,
        fetchedProducts: state.fetchedProducts
    };
};

const mapDispatchToProps = () => {
    return {
        add_change_index_field,
        update_source_fields,
        switch_save_changes
    };
};

class EditField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select_fields: this.props.field.edit_type === "_Selection" ? this.props.field.edit_depends_on ? this.getDependentOptions() : getSelectFieldsByID(this.props.selectFields, this.props.field.edit_foreignkey_id) : []
        };
        this.maxLength = getMultiselectMaxcharByField(this.props.field);
        this.changeText = this.changeText.bind(this);
    }

    getDependentOptions() {
        const { field } = this.props;
        const depentent_field_value = this.getFieldData(getFieldListFieldByDataField(field.edit_depends_on));
        let select_options = [];
        if (depentent_field_value) {
            if (typeof (depentent_field_value) === "string") {
                if (depentent_field_value.includes(field.edit_depends_separator)) {
                    const values = depentent_field_value.split(field.edit_depends_separator).map(value => value.indexOf("*") === 0 ? value.substring(1) : value);
                    select_options = [...values];
                } else {
                    select_options = [depentent_field_value];
                }
            } else {
                if (Array.isArray(depentent_field_value)) {
                    select_options = [...depentent_field_value];
                } else {
                    select_options = [depentent_field_value.toString()];
                }
            }
        }
        return select_options;
    }

    getFieldData(field) {
        const { editedFields, selectFields, item, subtable_modal, language, portalConfig, value, fieldListField, table_row_id_field } = this.props;
        let dataField = field.dataField;
        let editField = field.editField && field.editField !== "0" ? field.editField : false;
        if (fieldListField) {
            dataField = fieldListField.dataField;
            if (fieldListField.editField && fieldListField.editField !== "0") {
                editField = fieldListField.editField;
            } else {
                editField = false;
            }
        }
        if (field.dataField === "GesetzterWert") {
            //console.log(field);
        }
        for (var i = 0; i < editedFields.length; i++) {
            if (editedFields[i]["ID"] === item[portalConfig.keyfield]) {
                if (editedFields[i]["language"] === language) {
                    if (editField && editField !== "") {
                        if (editedFields[i]["changes"].hasOwnProperty(editField)) {
                            const edit_value = editedFields[i]["changes"][editField];
                            if (this.props.hasOwnProperty("subtable_modal") && subtable_modal.item_id === item[portalConfig.keyfield]) {
                                if (!this.props.new_subtable_row) {
                                    for (var j = 0; j < edit_value.length; j++) {
                                        if (edit_value[j][table_row_id_field] === subtable_modal.subtable_row_id) {
                                            return edit_value[j][field.dataField];
                                        }
                                    }
                                }
                            }
                            return edit_value;
                        }
                    } else {
                        if (editedFields[i]["changes"].hasOwnProperty(dataField)) {
                            const edit_value = editedFields[i]["changes"][dataField];
                            if (this.props.hasOwnProperty("subtable_modal") && subtable_modal.item_id === item[portalConfig.keyfield]) {
                                if (!this.props.new_subtable_row) {
                                    for (var k = 0; k < edit_value.length; k++) {
                                        if (edit_value[k][table_row_id_field] === subtable_modal.subtable_row_id) {
                                            return edit_value[k][field.dataField];
                                        }
                                    }
                                }
                            } else {
                                return edit_value;
                            }
                        }
                    }
                }
            }
        }
        if (this.props.hasOwnProperty("value")) {
            return value;
        }
        if (field.edit_type === "_Date") {
            return item[dataField];
        } else if (field.edit_type === "_Selection" && field.multiselect_maxchar !== "0") {
            if (item[dataField] && typeof (item[dataField]) === "string") {
                if (portalConfig.multiselect_separator && item[dataField].includes(portalConfig.multiselect_separator)) {
                    return item[dataField].split(portalConfig.multiselect_separator).filter(val => val).map(val => selectFields[field.dataField] ? getSelection(selectFields[field.dataField], field.edit_foreignkey_id, val) : val);
                }
                return item[dataField].split(",");
            } else if (item[dataField] && Array.isArray(item[dataField]) && field.edit_type === "_Selection") {
                return item[dataField].filter(val => val).map(val => {
                    let mapping = getMapping(field.dataField, val, portalConfig.field_list_mapping);
                    let mapped_value = val;
                    if (mapping === null) {
                        mapped_value = getSelection(selectFields[field.dataField], field.edit_foreignkey_id, val);
                    }
                    return mapped_value;
                });
            } else {
                if (field.edit_depends_on) {
                    return "";
                }
                return [];
            }
        } else {
            return item[dataField];
        }
    }

    changeText(value) {
        const { item, field, subtable_modal, fieldListField, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct, portalConfig } = this.props;
        saveEditStore(item[portalConfig.keyfield], field, value, false, this.state.select_fields, fieldListField, subtable_modal, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct);
    }

    changeDate(dateObject, time) {
        const { portalConfig, item, field, subtable_modal, fieldListField, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct } = this.props;
        if (typeof (dateObject) === "object" && dateObject !== null) {
            let date_string = "";
            if (time) {
                let date = new Date(Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(),
                    dateObject.getHours(), dateObject.getMinutes(), dateObject.getSeconds()));
                date_string = portalConfig.datetimeformat;
                date_string = date.toISOString();
            } else {
                let date = new Date(Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate()));
                let month = date.getUTCMonth() + 1;
                let day = date.getUTCDate();
                date_string = portalConfig.dateformat;
                if (month < 10) {
                    month = "0" + month;

                }
                if (day < 10) {
                    day = "0" + day;
                }
                date_string = date_string.replace("YYYY", date.getUTCFullYear()).replace("MM", month).replace("DD", day);
            }
            saveEditStore(item[portalConfig.keyfield], field, date_string, false, this.state.select_fields, fieldListField, subtable_modal, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct);
        }
    }

    changeSelect(value) {
        const { item, portalConfig, field, subtable_modal, fieldListField, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct } = this.props;
        const new_value = value === "deselect" || value === "null" ? "" : value;
        saveEditStore(item[portalConfig.keyfield], field, new_value, false, this.state.select_fields, fieldListField, subtable_modal, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct);
    }

    changeCheckbox(value) {
        const { item, portalConfig, field, subtable_modal, fieldListField, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct } = this.props;
        const new_value = getNewCheckboxValue(value);
        saveEditStore(item[portalConfig.keyfield], field, new_value, false, this.state.select_fields, fieldListField, subtable_modal, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct);
    }

    onSelect(selectedList) {
        const { item, field, portalConfig, subtable_modal, fieldListField, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct } = this.props;
        saveEditStore(item[portalConfig.keyfield], field, selectedList, true, this.state.select_fields, fieldListField, subtable_modal, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct);
    }

    onRemove(selectedList) {
        const { item, field, portalConfig, subtable_modal, fieldListField, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct } = this.props;
        saveEditStore(item[portalConfig.keyfield], field, selectedList, true, this.state.select_fields, fieldListField, subtable_modal, table_row_id_field, isSubtableModal, setEditSubtable, setSubtableModal, isNewProduct);
    }

    getTextInputElement(type, value) {
        return (
            <TextInput
                type={type}
                value={value}
                maxLength={this.maxLength}
                changeText={this.changeText}
                timeoutObject={{ active: true, time: 500 }}
            />
        );
    }

    render() {
        const { field, subtable_modal, isSubtableModal, isModal, portalConfig, item } = this.props;
        var value = this.getFieldData(field);
        //var value = this.getFieldData(field.edit_depends_on ? getFieldListFieldByDataField(field.dataField) : field);
        if (subtable_modal && !isSubtableModal) {
            value = this.props.value;
        }
        if (isModal) {
            return getField(item, field, {}, false, true, true, value);
        }
        if (field.edit_type === "_SimpleText") {  //_NumberInt _NumberFloat
            return this.getTextInputElement("text", value);
        } else if (field.edit_type === "_Selection") {
            if ((this.state.select_fields.length === 0 || field.edit_foreignkey_id === "") && !field.edit_depends_on) {
                return this.getTextInputElement("text", value);
            } else {
                if (field.edit_depends_on) {
                    //console.log(field);
                    return (
                        <select
                            //id="select_fields"
                            onChange={event => this.changeSelect(event.target.value)}
                            value={!value ? "null" : value}
                            className="browser-default custom-select"
                        >
                            <option value="null">{translate("empty")}</option>
                            {this.state.select_fields.map(option => (
                                <option value={option}>{option}</option>
                            ))}
                        </select>
                    );
                } else if (field.multiselect_maxchar === "0") {
                    var value_id = value ? getSelectFieldID(value, this.state.select_fields) : null;
                    return (
                        <select
                            id="select_fields"
                            onChange={event => this.changeSelect(event.target.value)}
                            value={value_id === null || value_id === undefined || value_id === "" ? "null" : value_id}
                            className="browser-default custom-select"
                        >
                            <option disabled value="null">{translate("select_option")}</option>
                            <option value="deselect">{translate("deselect")}</option>
                            {this.state.select_fields.map(field => (
                                <option value={field[portalConfig.foreignkey_reference2changes]}>{field[portalConfig.foreignkey_show_field]}</option>
                            ))}
                        </select>
                    );
                } else {
                    var options = [];
                    for (let field of this.state.select_fields) {
                        options.push({
                            text: field[portalConfig.foreignkey_show_field],
                            value: field[portalConfig.foreignkey_reference2changes]
                        });
                    }
                    var selectedValues = [];
                    if (value) {
                        if (typeof (value) === "object") {
                            for (let field of value) {
                                if (typeof (field) === "object") {
                                    selectedValues.push(field);
                                } else {
                                    selectedValues.push({
                                        text: field,
                                        value: getSelectFieldID(field, this.state.select_fields)
                                    });
                                }
                            }
                        } else {
                            selectedValues = [{
                                text: value,
                                value: getSelectFieldID(value, this.state.select_fields)
                            }];
                        }
                    }
                    return (
                        <Multiselect
                            options={options} // Options to display in the dropdown
                            onSelect={selectedList => this.onSelect(selectedList)} // Function will trigger on select event
                            onRemove={selectedList => this.onRemove(selectedList)} // Function will trigger on remove event
                            displayValue="text" // Property name to display in the dropdown options
                            selectedValues={selectedValues} // Preselected value to persist in dropdown
                        />
                    );
                }
            }
        } else if (field.edit_type === "_Date") {
            var time = false;
            if (value !== null && value !== undefined) {
                if (value.trim().length >= 11) {
                    time = true;
                }
            }
            return (
                <MDBDatePicker
                    value={value !== "" ? value : null}
                    getValue={e => this.changeDate(e, time)}
                />
            );
        } else if (field.edit_type === "_Checkbox") {
            if (value && (value === true || value === "true" || value === "1" || value === 1)) {
                return (
                    <MDBIcon icon="check" size="lg" key={field.dataField} onClick={() => this.changeCheckbox(value)} className="checkbox-icon" />
                );
            }
            return (
                <MDBIcon far icon="square" size="lg" key={field.dataField} onClick={() => this.changeCheckbox(value)} className="checkbox-icon" />
            );
        } else if (field.edit_type === "_Currency") {
            return this.getTextInputElement("number", value);
        } else {
            return this.getTextInputElement("text", value);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(EditField);