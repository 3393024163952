const subTableModalReducer = (state = {}, action) => {
    switch(action.type) {
        case 'OPEN_SUBTABLE_MODAL':
            //console.log(action);
            state = {
                "item_id": action.payload.item_id,
                "subtable_row": action.payload.subtable_row,
                "subtable": action.payload.subtable,
                "subtable_row_id": action.payload.subtable_row_id,
                "fieldListField": action.payload.fieldListField,
                "subtable_row_id_field": action.payload.subtable_row_id_field,
                "new_subtable_row": action.payload.new_row,
                "copied_row": action.payload.copied_row
            };
            return state;
        case 'CLOSE_SUBTABLE_MODAL':
            state = {};
            return state;
        case 'SET_SUBTABLE_MODAL':
            let new_state = Object.assign({}, state);
            new_state.subtable = action.payload.subtable;
            new_state.subtable_row = action.payload.subtable_row;
            state = new_state;
            return state;
        default:
            return state;
    }
}

export default subTableModalReducer;