import { compose, createStore } from "redux";
import allReducers from "../reducers";

function getStateArr(state) {
    let state_arr = {};
    state_fields.map(field => state_arr[field] = state[field]);
    return state_arr;
}

function saveToSessionStorage() {
    try {
        const serializedState = JSON.stringify(getStateArr(store.getState()));
        sessionStorage.setItem('state', serializedState);
    } catch(e) {
        console.log(e);
    }
}

function loadFromSessionStorage() {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        } else {
            return JSON.parse(serializedState);
        }
    } catch(e) {
        console.log(e);
        return undefined;
    }
}

const persistedState = loadFromSessionStorage();

const store = createStore(
    allReducers, 
    persistedState,
    compose(
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

const state_fields = [
    "articleTabs", "compareItems", "fetchedProducts", "shoppingCart", "activeArticle", "resultView", "edit", "editedFields",
    "changeIndexFields", "updateSourceFields", "saveChanges", "selectFields", "hits", "customTableTemplates", "template", 
    "portalConfig", "language", "selectFieldsLoaded", "openAllSections", "searchQuery", "menuFilter", "editCheckboxType", 
    "newProducts", "newCopiedProducts", "newReferenceModalProps", "downloadTableModalProps", "loadedImages"
];

store.subscribe(() => saveToSessionStorage());

export default store;