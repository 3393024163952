import React, { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBTooltip } from "mdbreact";
import { translate } from "../../utils/Utils";
import "../../Home.css";
import ExcelButton from "../ExcelButton";

class CustomTableModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            download_fetched_products: false
        }
    }

    closeModal() {
        this.props.close();
    }

    render() {
        //console.log(this.state);
        //console.log(this.props);
        return (
            <MDBModal
                className={"custom-table-modal"}
                isOpen={this.props.isOpen}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    { }
                </MDBModalHeader>
                <MDBModalBody className="mt-1">
                    <MDBRow>
                        <MDBCol className="modal-category">
                            {translate("templates")}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-1">
                        <MDBCol className="modal-col">
                            {translate("select_template") + ":"}
                        </MDBCol>
                        <MDBCol className="modal-col">
                            <select
                                id="custom_table_modal_template_select_fields"
                                onChange={event => this.props.clickTemplateSelect(event.target.value)}
                                value={this.props.activeTemplate}
                                className="browser-default custom-select"
                            >
                                {Object.keys(this.props.customTableTemplates).map(template => {
                                    return (
                                        <option value={template}>
                                            {template === "default" ? translate(template) : template}
                                        </option>
                                    );
                                })}
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-2">
                        <MDBCol className="modal-col mt-2">
                            {translate("template") + ":"}
                        </MDBCol>
                        <MDBCol className="">
                            <MDBRow className="btn-row">
                                <MDBCol>
                                    <div onClick={() => this.props.handleNewTemplate()}>
                                        <MDBTooltip
                                            domElement
                                            tag="span"
                                            placement="bottom"
                                        >
                                            <button
                                                className="btn-xs btn-left create-button"
                                            >
                                                <MDBIcon icon="plus" className="create-icon" />
                                            </button>
                                            <span className="btn-span">{translate("new_template")}</span>
                                        </MDBTooltip>
                                    </div>
                                </MDBCol>
                                {this.props.activeTemplate === "default" ? null :
                                    <MDBCol>
                                        <div onClick={() => this.props.handleEditTemplate()}>
                                            <MDBTooltip
                                                domElement
                                                tag="span"
                                                placement="bottom"
                                            >
                                                <button
                                                    className="btn-xs edit-button"
                                                >
                                                    <MDBIcon icon="pencil-alt" className="edit-icon" />
                                                </button>
                                                <span className="btn-span">{translate("edit_template")}</span>
                                            </MDBTooltip>
                                        </div>
                                    </MDBCol>
                                }
                                {this.props.activeTemplate === "default" ? null :
                                    <MDBCol>
                                        <div onClick={() => this.props.handleDeleteTemplateModal()}>
                                            <MDBTooltip
                                                domElement
                                                tag="span"
                                                placement="bottom"
                                            >
                                                <button
                                                    className="btn-xs btn-right delete-button"
                                                >
                                                    <MDBIcon icon="trash-alt" className="delete-icon" />
                                                </button>
                                                <span className="btn-span">{translate("delete_current_template")}</span>
                                            </MDBTooltip>
                                        </div>
                                    </MDBCol>
                                }
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    {this.props.showExcel ?
                        <MDBRow className="mt-2">
                            <MDBCol className="modal-col mt-2">
                                {translate("download") + ":"}
                            </MDBCol>
                            <MDBCol className="">
                                <MDBRow className="btn-row">
                                    <MDBCol>
                                        {this.props.downloadFetchedProducts ?
                                            <div onClick={() => {
                                                this.props.openDownloadExcelModal();
                                            }}>
                                                <MDBTooltip
                                                    domElement
                                                    tag="span"
                                                    placement="bottom"
                                                >
                                                    <button
                                                        className="btn-xs btn-left excel-button"
                                                    >
                                                        <MDBIcon icon="file-excel" className="excel-icon" />
                                                    </button>
                                                    <span className="btn-span">
                                                        {translate("as_excel")}
                                                    </span>
                                                </MDBTooltip>
                                            </div>
                                            :
                                            null
                                        }
                                        {this.props.downloadFetchedProducts ?
                                            <div style={{ display: "none" }}>
                                                {<ExcelButton
                                                    buttonID="custom-table-excel-modal-button"
                                                    usage="template"
                                                    excelTableFields={this.props.excelModalData.excelTableFields}
                                                    fetched_products={this.props.excelModalData.fetched_products}
                                                    downloadFetchedProducts
                                                />}
                                            </div>
                                            :
                                            <ExcelButton
                                                buttonID="custom-table-excel-button"
                                                usage="template"
                                                excelTableProductsIDs={this.props.excelTableProductsIDs}
                                                fetchedProducts={this.props.fetchedProducts}
                                                portalConfigKeyfield={this.props.keyfield}
                                                excelTableFields={this.props.excelTableFields}
                                            />
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        :
                        null
                    }
                    {this.props.showExcel ?
                        <MDBRow className="mt-2">
                            <MDBCol className="modal-col mt-2" />
                            <MDBCol>
                                <div className="custom-switch">
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        id='switch_download_all'
                                        checked={this.props.downloadFetchedProducts}
                                        onChange={() => this.props.switchDownloadAll()}
                                        readOnly
                                    />
                                    <label className='custom-control-label' htmlFor='switch_download_all'>
                                        {translate("download_all")}
                                    </label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        :
                        null
                    }
                    <MDBRow className="mt-2">
                        <MDBCol className="modal-col mt-2">
                            {translate("upload") + ":"}
                        </MDBCol>
                        <MDBCol className="">
                            <MDBRow className="btn-row">
                                <MDBCol>
                                    <button
                                        className="btn-xs btn-left"
                                        onClick={() => this.props.uploadFile()}
                                    >
                                        <MDBIcon icon="file-upload" />
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    //disabled={this.state.loading}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default CustomTableModal;