const fuzzinessReducer = (state = "AUTO", action) => {
    switch(action.type) {
        case 'CHANGE_FUZZINESS':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default fuzzinessReducer;