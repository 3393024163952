import React, { Component } from "react";
import store from "../store/store";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import "../css/FlippingCardPDF.css";
import "../Home.css";
import { downloadFileFromServer, translate, getTableText, getFlipCardBackDataByField } from "../utils/Utils";
import {
    MDBTooltip, MDBIcon
} from "mdbreact";
import PropTypes from "prop-types";
import { fetchPDFPreview } from "../utils/Webservice";

class FlippingCardPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flipped: false,
            previewLoaded: false,
            previewExists: false,
            pdfExists: false
        }
        this.isNavMobileSize = false;
        //this.setPDFPreviewStates = this.setPDFPreviewStates.bind(this);
    }

    /*setPDFPreviewStates(res) {
        //console.log(res);
        this.setState(res);
    }*/

    componentDidMount() {
        this.isNavMobileSize = window.innerWidth < parseInt(store.getState().portalConfig.viewport_mobile);
        fetchPDFPreview(this.props.fileName).then(res => {
            this.setState(res);
        });
    }

    rotateCard() {
        this.setState({
            flipped: !this.state.flipped
        });
    }

    getFlippingCardClassname() {
        let className = this.props.cardBackFields.length ? "flip-card-pdf" : "flip-card-pdf-noflip";
        if (this.state.flipped) {
            className += " flip-card-pdf-flipped";
        }
        return className;
    }

    handlePDFClick() {
        if (this.state.pdfExists) {
            this.props.showPDF();
        }
    }

    render() {
        const { portalConfig } = store.getState();
        const { image_base_url,
        } = portalConfig;
        return (
            <div className={this.getFlippingCardClassname()}>
                <div className="flip-card-pdf-inner">
                    <div className="flip-card-pdf-front">
                        <span style={{ marginTop: "5px", marginBottom: "5px", fontWeight: "bold" }}>
                            {this.props.fileName}
                        </span>
                        <div onClick={() => this.handlePDFClick()}>
                            {this.state.previewLoaded ?
                                this.state.previewExists ?
                                    <img src={image_base_url + this.props.fileName.replace(/.pdf/gi, ".jpg")} alt={this.props.fileName} />
                                    :
                                    <Document
                                        file={image_base_url + this.props.fileName}
                                        //onLoadError={(error) => console.log(error)}
                                        //onSourceError={(error) => console.log(error)}
                                        onLoadSuccess={() => this.setState({ pdfExists: true })}
                                    >
                                        <Page pageNumber={1} scale={0.5} />
                                    </Document>
                                :
                                <div className="flip-card-pdf-loading">
                                    Loading PDF...
                                </div>
                            }
                        </div>
                        {this.props.cardBackFields.length ?
                            <div
                                className="rotate-btn"
                                onClick={() => this.rotateCard()}
                            >
                                <MDBIcon icon="undo" /> {translate("rotate")}
                            </div>
                            :
                            null
                        }
                        <div style={{ marginTop: "5px" }}>
                            <MDBTooltip
                                domElement
                                tag="span"
                                placement="top"
                            >
                                <span>
                                    <button
                                        disabled={!this.state.pdfExists}
                                        className="btn btn-sm"
                                        style={{ marginRight: "5px" }}
                                        onClick={() => downloadFileFromServer(this.props.fileName)}
                                    >
                                        <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                        {translate("download")}
                                    </button>
                                </span>
                                <span>{this.props.fileName}</span>
                            </MDBTooltip>
                            <button
                                disabled={!this.state.pdfExists}
                                className="btn btn-sm"
                                style={{ marginLeft: "5px" }}
                                onClick={() => this.props.showPDF()}
                            >
                                <MDBIcon far icon="eye" style={{ marginRight: "5px" }} />
                                {translate("show")}
                            </button>
                        </div>
                    </div>
                    <div className="flip-card-pdf-back">
                        <div>
                            <table>
                                <tbody>
                                    {this.props.cardBackFields.map(field => {   //TODO: anzeige ändern, wenn felder definiert sind
                                        return (
                                            <tr
                                                key={field.text + "-flip-card-pdf-tr"}
                                            >
                                                <td>
                                                    {getTableText(field.text && field.text.replace(/\s/g, "") ? field.text : field.dataField)}:
                                                </td>
                                                <td>
                                                    {getFlipCardBackDataByField(this.props.field_value, field)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className="rotate-btn"
                            onClick={() => this.rotateCard()}
                        >
                            <MDBIcon icon="redo" /> {translate("rotate")}
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <MDBTooltip
                                domElement
                                tag="span"
                                placement="top"
                            >
                                <span>
                                    <button
                                        disabled={!this.state.pdfExists}
                                        className="btn btn-sm"
                                        style={{ marginRight: "5px" }}
                                        onClick={() => downloadFileFromServer(this.props.fileName)}
                                    >
                                        <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                        {translate("download")}
                                    </button>
                                </span>
                                <span>{this.props.fileName}</span>
                            </MDBTooltip>
                            <button
                                disabled={!this.state.pdfExists}
                                className="btn btn-sm"
                                style={{ marginLeft: "5px" }}
                                onClick={() => this.props.showPDF()}
                            >
                                <MDBIcon far icon="eye" style={{ marginRight: "5px" }} />
                                {translate("show")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FlippingCardPDF.propTypes = {
    cardBackFields: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    showPDF: PropTypes.func.isRequired,
    field_value: PropTypes.object
};

export default FlippingCardPDF;