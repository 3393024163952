const shoppingCartModalItemReducer = (state = null, action) => {
    switch(action.type) {
        case 'SET_SHOPPING_CART_MODAL_ITEM':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default shoppingCartModalItemReducer;