import { combineReducers } from "redux";
import activeArticleReducer from "./activeArticle";
import resultViewReducer from "./resultView";
import shoppingCartModalReducer from "./shoppingCartModal";
import shoppingCartModalItemReducer from "./shoppingCartModalItem";
import articleTabsReducer from "./articleTabs";
import shoppingCartReducer from "./shoppingCart";
import shoppingCartOpenReducer from "./shoppingCartOpen";
import fetchedProductsReducer from "./fetchedProducts";
import compareModalReducer from "./compareModal";
import compareItemsReducer from "./compareItems";
import languageReducer from "./language";
import hitsReducer from "./hits";
import editReducer from "./edit";
import editedFieldsReducer from "./editedFields";
import selectFieldsReducer from "./selectFields";
import changeIndexFieldsReducer from "./changeIndexFields";
import updateSourceFieldsReducer from "./updateSourceFields";
import saveChangesReducer from "./saveChanges";
import selectionListReducer from "./selectionList";
import openAllSectionsReducer from "./openAllSections";
import customTableTemplatesReducer from "./customTableTemplates";
import templateReducer from "./template";
import editCustomViewReducer from "./editCustomView";
import openPortalSettingsModalReducer from "./openPortalSettingsModal";
import portalConfigReducer from "./portalConfig";
import openDeleteProductModalReducer from "./openDeleteProductModal";
import deleteProductIDReducer from "./deleteProductID";
import openNewCopiedProductModalReducer from "./openNewCopiedProductModal";
import newCopiedProductReducer from "./newCopiedProduct";
import selectFieldsLoadedReducer from "./selectFieldsLoaded";
import subTableModalReducer from "./subTableModal";
import clearSubtableReducer from "./clearSubtable";
import searchQueryReducer from "./searchQuery";
import menuFilterReducer from "./menuFilter";
import updatingSourceReducer from "./updatingSource";
import imageBase64Reducer from "./imageBase64";
import multiEditReducer from "./multiEdit";
import numberOfResultsReducer from "./numberOfResults";
import hideFacetsReducer from "./hideFacets";
import editCheckboxTypeReducer from "./editCheckboxType";
import newProductsReducer from "./newProducts";
import newCopiedProductsReducer from "./newCopiedProducts";
import newReferenceModalPropsReducer from "./newReferenceModalProps";
import downloadTableModalPropsReducer from "./downloadTableModalProps";
import routePathsReducer from "./routePaths";
import hiddenFacetsReducer from "./hiddenFacets";
import loadedImagesReducer from "./loadedImages";
import fuzzinessReducer from "./fuzziness";

const allReducers = combineReducers({
    hiddenFacets: hiddenFacetsReducer,
    activeArticle: activeArticleReducer,
    resultView: resultViewReducer,
    shoppingCartModal: shoppingCartModalReducer,
    shoppingCartModalItem: shoppingCartModalItemReducer,
    articleTabs: articleTabsReducer,
    shoppingCart: shoppingCartReducer,
    shoppingCartOpen: shoppingCartOpenReducer,
    fetchedProducts: fetchedProductsReducer,
    compareModal: compareModalReducer,
    compareItems: compareItemsReducer,
    language: languageReducer,
    hits: hitsReducer,
    edit: editReducer,
    editedFields: editedFieldsReducer,
    selectFields: selectFieldsReducer,
    changeIndexFields: changeIndexFieldsReducer,
    updateSourceFields: updateSourceFieldsReducer,
    saveChanges: saveChangesReducer,
    selectionList: selectionListReducer,
    openAllSections: openAllSectionsReducer,
    customTableTemplates: customTableTemplatesReducer,
    template: templateReducer,
    editCustomView: editCustomViewReducer,
    openPortalSettingsModal: openPortalSettingsModalReducer,
    portalConfig: portalConfigReducer,
    openDeleteProductModal: openDeleteProductModalReducer,
    deleteProductID: deleteProductIDReducer,
    openNewCopiedProductModal: openNewCopiedProductModalReducer,
    newCopiedProduct: newCopiedProductReducer,
    selectFieldsLoaded: selectFieldsLoadedReducer,
    subTableModal: subTableModalReducer,
    clearSubtable: clearSubtableReducer,
    searchQuery: searchQueryReducer,
    menuFilter: menuFilterReducer,
    updatingSource: updatingSourceReducer,
    imageBase64: imageBase64Reducer,
    multiEdit: multiEditReducer,
    numberOfResults: numberOfResultsReducer,
    hideFacets: hideFacetsReducer,
    editCheckboxType: editCheckboxTypeReducer,
    newProducts: newProductsReducer,
    newCopiedProducts: newCopiedProductsReducer,
    newReferenceModalProps: newReferenceModalPropsReducer,
    downloadTableModalProps: downloadTableModalPropsReducer,
    routePaths: routePathsReducer,
    loadedImages: loadedImagesReducer,
    fuzziness: fuzzinessReducer
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'RESET_STORE') {
        console.log("reset");
        state = undefined;
    }
    return allReducers(state, action);
};

export default rootReducer;