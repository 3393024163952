const activeArticleReducer = (state = "main", action) => {
    switch(action.type) {
        case 'SET_ACTIVE_ARTICLE':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default activeArticleReducer;