import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactiveComponent } from "@appbaseio/reactivesearch";
import { getComponents, translate, getDefaultQuery, getMappedData, hideFacetComponent, 
    getClickedFacetItems, setElasticQuery, deepEqual, getFacetComponentID
} from "../../utils/Utils";
import { update_search_query, delete_search_query_field, set_search_query, delete_search_query_field_modal,
    set_search_query_modal, update_search_query_modal, set_active_article, set_active_article_modal
} from "../../actions";
import { MDBSpinner, MDBIcon } from "mdbreact";
import "../../Home.css";
import PropTypes from "prop-types";
import { getElasticDataField, getMappingFields } from "../../utils/functions";

const mapStateToProps = (state) => {
    return {
        searchQuery: state.searchQuery,
        portalConfig: state.portalConfig,
        selectFieldsLoaded: state.selectFieldsLoaded,
        activeArticle: state.activeArticle,
        newReferenceModalProps: state.newReferenceModalProps
    };
};

const mapDispatchToProps = () => {
    return {
        update_search_query,
        delete_search_query_field,
        set_search_query,
        delete_search_query_field_modal,
        set_search_query_modal,
        update_search_query_modal,
        set_active_article,
        set_active_article_modal
    };
};

class SearchMultiDataList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //sortBy: this.getOrder(),
            bucket_size: parseInt(this.props.portalConfig.bucket_size),
            mappingFields: getMappingFields(this.props.field),
            new_query: false,
            mapping_fields_loaded: false,
            componentId: getFacetComponentID(this.props.field.dataField, this.props.isNewReferenceModal, this.props.isFacetModal),
            elasticDataField: getElasticDataField(this.props.field.dataField),
            isOverflownWidth: false,
            listElement: null
        };
        this.clickedItems = getClickedFacetItems(this.props.field, this.props.isNewReferenceModal);
        this.not_empty_doc_count_sum = 0;
        this.getDefaultQuery = this.getDefaultQuery.bind(this);
    }

    componentDidMount() {
        const { isOpen } = this.props;
        //const list_element = document.getElementById(this.state.componentId + "-facet");
        let new_state = {};
        if (this.clickedItems.length > 0) {
            new_state["new_query"] = true;
        }
        if (this.state.mappingFields.length) {
            new_state["mapping_fields_loaded"] = true;
        }
        /*if (list_element) {
            const scrollWidth = list_element.scrollWidth;
            const clientWidth = list_element.clientWidth;
            if (isOpen) {
                console.log(isOpen);
                if (clientWidth) {
                    if (!this.state.isOverflownWidth && (scrollWidth > clientWidth)) {
                        this.setState({
                            isOverflownWidth: true
                        });
                    }
                } else {
                    this.setState({});
                }
            }
        }*/
        this.setState(new_state);
        
    }

    componentDidUpdate() {
        const { field, isNewReferenceModal, selectFieldsLoaded, isOpen } = this.props;
        const {listElement} = this.state;
        const newClickedItems = getClickedFacetItems(field, isNewReferenceModal);
        
        if (newClickedItems.length < 1 && this.clickedItems.length > 0) {
            this.clickedItems = [];
            this.props.handleFacetClick(field.dataField, []);
            if (field.depend) {
                this.setState({
                    new_query: false
                });
            }
        }
        if (!deepEqual(this.clickedItems, newClickedItems)) {
            this.clickedItems = newClickedItems;
            this.props.handleFacetClick(field.dataField, newClickedItems);
            this.setState({
                new_query: true
            });
        }
        if (selectFieldsLoaded && !this.state.mapping_fields_loaded) {
            this.setState({
                mappingFields: getMappingFields(field),
                mapping_fields_loaded: true,
                new_query: true
            });
        }
    }

    getDefaultQuery() {
        let defaultQuery = getDefaultQuery(this.props.field, this.state.elasticDataField + "." + this.props.portalConfig.field_extension);
        defaultQuery.aggs.values.aggs.values.terms.size = this.state.bucket_size;
        return defaultQuery;
    }

    handleClick(key, setQuery, dataField, clickedItems) {
        const { isNewReferenceModal, activeArticle, newReferenceModalProps, isFacetModal } = this.props;
        let newClickedItems = [];
        let is_empty = false;
        let new_value = null;
        for (let item_key of clickedItems) {
            if (item_key !== "empty" && item_key !== "not_empty") {
                newClickedItems.push(item_key);
            } else {
                is_empty = true;
            }
        }
        if ((key === "true" && clickedItems.includes("false")) || (key === "false" && clickedItems.includes("true"))) {
            newClickedItems = ["not_empty"];
            new_value = "not_empty";
        } else if (key === "empty" || key === "not_empty") {
            if (!is_empty || (clickedItems.includes("empty") && key === "not_empty") || (clickedItems.includes("not_empty") && key === "empty")) {
                newClickedItems = [key];
            }
            is_empty = true;
        } else {
            if (clickedItems.includes(key)) {
                newClickedItems = [];
                for (let item_key of clickedItems) {
                    if (key !== item_key) {
                        newClickedItems.push(item_key);
                    }
                }
            } else {
                newClickedItems.push(key);
            }
        }
        this.clickedItems = newClickedItems;
        if (isFacetModal) {
            const component_id = getFacetComponentID(dataField, isNewReferenceModal, false);
            if (this.props.field.filter === "DynamicRangeSlider") {
                const range_slider = document.getElementById(component_id + "-dynamic-range-slider");
                console.log(range_slider);
            } else {
                const filter_facet = document.getElementById(component_id + "-" + key.replace(/\s/g, "") + "-facet-item");
                filter_facet.click();
            }
        } else {
            this.props.handleFacetClick(dataField, newClickedItems);
            if (!newClickedItems.length) {
                if (isNewReferenceModal) {
                    this.props.delete_search_query_field_modal(dataField);
                } else {
                    this.props.delete_search_query_field(dataField);
                }
            } else {
                if (new_value) {
                    if (isNewReferenceModal) {
                        this.props.set_search_query_modal(new_value, dataField);
                    } else {
                        this.props.set_search_query(new_value, dataField);
                    }
                } else if (is_empty) {
                    if (isNewReferenceModal) {
                        this.props.set_search_query_modal(key, dataField);
                    } else {
                        this.props.set_search_query(key, dataField);
                    }
                } else {
                    if (isNewReferenceModal) {
                        this.props.update_search_query_modal(key, dataField);
                    } else {
                        this.props.update_search_query(key, dataField);
                    }
                }
            }
            if (isNewReferenceModal && newReferenceModalProps.activeArticle !== "main") {
                this.props.set_active_article_modal("main");
            } else if (!isNewReferenceModal && activeArticle !== "main") {
                this.props.set_active_article("main");
            }
            setElasticQuery(this.state.elasticDataField + "." + this.props.portalConfig.field_extension, setQuery, newClickedItems, isNewReferenceModal, this.getMappedValues(newClickedItems));
        }
    }

    getMappedValues(clickedKeys) {
        if (clickedKeys.includes("empty") || clickedKeys.includes("not_empty")) {
            return null;
        }
        const mappingFields = this.state.mappingFields;
        let mapped_values = [];
        for (let key of clickedKeys) {
            for (let map_field of mappingFields) {
                if (map_field.value === key) {
                    mapped_values.push(map_field.label);
                }
            }
        }
        return mapped_values;
    }

    loadMore() {
        this.setState({
            bucket_size: this.state.bucket_size + parseInt(this.props.portalConfig.bucket_size)
        });
    }

    render() {
        const {field, isNewReferenceModal, emptyComponent, isFacetModal, portalConfig} = this.props;
        const dataField = field.dataField;
        const clickedItems = getClickedFacetItems(field, isNewReferenceModal);
        const componentId = this.state.componentId;
        //console.log(this.state.new_query);
        return (
            <div className="search-field" style={emptyComponent || hideFacetComponent(field, this.clickedItems.length > 0) ? { display: "none" } : {}}>
                <ReactiveComponent
                    react={{
                        and: getComponents(dataField, isNewReferenceModal)
                    }}
                    filterLabel={field.text && field.text.replace(/\s/g, "") ? field.text : dataField}
                    showFilter={isFacetModal ? false : true}
                    componentId={componentId}
                    defaultQuery={this.getDefaultQuery}
                    render={({ aggregations, setQuery, error, loading }) => {
                        if (error) {
                            console.warn(componentId, error);
                        }
                        if (this.state.new_query) {
                            this.setState({
                                new_query: false
                            });
                            setElasticQuery(this.state.elasticDataField + "." + portalConfig.field_extension, setQuery, clickedItems, isNewReferenceModal, this.getMappedValues(clickedItems));
                        }
                        if (loading) {
                            return (
                                <div className="search-bar-spinner">
                                    <MDBSpinner small />
                                </div>
                            );
                        }
                        if (aggregations) {
                            const mapped_data = getMappedData(aggregations.values.values.buckets, this.state.mappingFields);
                            //const not_empty_doc_count_sum = aggregations.values.doc_count;
                            const show_doc_count = field.filter_props && field.filter_props.showCount;
                            /*if (this.not_empty_doc_count_sum < not_empty_doc_count_sum) {
                                this.not_empty_doc_count_sum = not_empty_doc_count_sum;
                            }*/
                            const elements = this.state.isOverflownWidth ? portalConfig.show_max_facet_items + 1 : portalConfig.show_max_facet_items;
                            return (
                                <div className="multidatalist-container" id={componentId + "-facet"} style={{ maxHeight: (elements * 25) + "px" }}>
                                    {aggregations.empty_values.doc_count ?
                                        <div className="checkbox-container" style={{ maxHeight: 25 }} id={componentId + "-empty-facet-item"} onClick={() => this.handleClick("empty", setQuery, dataField, clickedItems)}>
                                            {clickedItems.includes("empty") ?
                                                <MDBIcon icon="check" size="lg" key={componentId + "-empty-checkbox"} />
                                                :
                                                <MDBIcon far icon="square" size="lg" key={componentId + "-empty-checkbox"} />
                                            }
                                            <label className="" htmlFor={componentId + "-empty-checkbox"}>
                                                <text>
                                                    {translate("empty")}
                                                </text>
                                                {show_doc_count ?
                                                    <text className={clickedItems.includes("empty") ? "checkbox-doc-count doc-count-selected" : "checkbox-doc-count"}>
                                                        {"(" + aggregations.empty_values.doc_count + ")"}
                                                    </text>
                                                    :
                                                    null
                                                }
                                            </label>
                                        </div>
                                        :
                                        null
                                    }
                                    {aggregations.empty_values.doc_count ?
                                        <div className="checkbox-container" style={{ maxHeight: 25 }} id={componentId + "-not-empty-facet-item"} onClick={() => this.handleClick("not_empty", setQuery, dataField, clickedItems)}>
                                            {clickedItems.includes("not_empty") ?
                                                <MDBIcon icon="check" size="lg" key={componentId + "-not-empty-checkbox"} />
                                                :
                                                <MDBIcon far icon="square" size="lg" key={componentId + "-not-empty-checkbox"} />
                                            }
                                            <label className="" htmlFor={componentId + "-not-empty-checkbox"}>
                                                <text>
                                                    {translate("not_empty")}
                                                </text>
                                                {show_doc_count ?
                                                    <text className={clickedItems.includes("not_empty") ? "checkbox-doc-count doc-count-selected" : "checkbox-doc-count"}>
                                                        {/*"(" + not_empty_doc_count_sum + ")"*/}
                                                        {"(" + aggregations.values.doc_count + ")"}
                                                    </text>
                                                    :
                                                    null
                                                }
                                            </label>
                                        </div>
                                        :
                                        null
                                    }
                                    {mapped_data.map(item => {
                                        const key = item.value;
                                        const label = item.label;
                                        const isSelected = clickedItems.includes(key);
                                        return (
                                            <div key={componentId + "-" + key.replace(/\s/g, "") + "-facet-item"} className="checkbox-container" style={{ maxHeight: 25 }} id={componentId + "-" + key.replace(/\s/g, "") + "-facet-item"} onClick={() => this.handleClick(key, setQuery, dataField, clickedItems)}>
                                                {isSelected ?
                                                    <MDBIcon icon="check" size="lg" key={componentId + "-checkbox"} />
                                                    :
                                                    <MDBIcon far icon="square" size="lg" key={componentId + "-checkbox"} />
                                                }
                                                <label className="" htmlFor={componentId + "-checkbox"} id={dataField + "-key:" + key}>
                                                    {item.color ?
                                                        <div className="traffic-light" style={{ backgroundColor: item.color }} />
                                                        :
                                                        null
                                                    }
                                                    <span style={{ marginLeft: item.color ? 5 : 0 }}>
                                                        {label + (field.unit_filter && field.display_unit_on_value === "1" ? " " + field.unit_filter.replace(/\s/g, "") : "")}
                                                    </span>
                                                    {show_doc_count ?
                                                        <span className={isSelected ? "checkbox-doc-count doc-count-selected" : "checkbox-doc-count"}>
                                                            {"(" + item.doc_count + ")"}
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </label>
                                            </div>
                                        );
                                    })}
                                    {mapped_data.length === this.state.bucket_size || mapped_data.length === this.state.bucket_size - 1 ?
                                        <button
                                            className="btn btn-sm"
                                            onClick={() => this.loadMore()}
                                            id={componentId + "-facet-load-more-button"}
                                        >
                                            {translate("load_more")}
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                            );
                        }
                        return null;
                    }}
                />
            </div>
        );
    }
}

SearchMultiDataList.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    field: PropTypes.object.isRequired,
    isFacetModal: PropTypes.bool,
    isNewReferenceModal: PropTypes.bool,
    handleFacetClick: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps())(SearchMultiDataList);