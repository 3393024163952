import React, { Component } from "react";
import PropTypes from "prop-types";
import PDFModal from "./modals/PDFModal";
import FlippingCardPDF from "./FlippingCardPDF";
import { checkPDFExists, fetchPDFPreview } from "../utils/Webservice";
import { downloadFileFromServer, translate } from "../utils/Utils";
import {
    MDBTooltip, MDBIcon
} from "mdbreact";
import store from "../store/store";

class PDFPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPDFModal: false,
            pdfImgExists: false,
            pdfExists: false
        }
        this.closePDFModal = this.closePDFModal.bind(this);
        this.openPDFModal = this.openPDFModal.bind(this);
        this.showPreviewIMG = store.getState().portalConfig.show_pdf_preview;
    }

    componentDidMount() {
        if (this.props.view === "subtable") {
            fetchPDFPreview(this.props.fileName).then(res => {
                if (res.previewExists) {
                    this.setState({
                        pdfImgExists: true
                    });
                }
                checkPDFExists(this.props.fileName).then((res) => {
                    if (res) {
                        this.setState({
                            pdfExists: true
                        });
                    }
                });
            });
        }
    }

    openPDFModal() {
        if (this.state.pdfExists) {
            this.setState({
                openPDFModal: true
            });
        }
    }

    closePDFModal() {
        this.setState({
            openPDFModal: false
        });
    }

    getPDFButtons() {
        return (
            <div>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <span>
                        <button
                            //disabled={!this.state.pdfExists}
                            className="btn btn-sm"
                            onClick={() => downloadFileFromServer(this.props.fileName)}
                            style={{ width: "150px" }}
                        >
                            <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                            {translate("download")}
                        </button>
                    </span>
                    <span>{this.props.fileName}</span>
                </MDBTooltip>
                <button
                    //disabled={!this.state.pdfExists}
                    className="btn btn-sm"
                    style={{ width: "150px" }}
                    onClick={() => this.openPDFModal()}
                >
                    <MDBIcon far icon="eye" style={{ marginRight: "5px" }} />
                    {translate("show")}
                </button>
            </div>
        );
    }

    render() {
        const { view, fileName, field_value } = this.props;
        const { pdfExists, openPDFModal, pdfImgExists } = this.state;
        if (view === "subtable") {
            return (
                <div>
                    {pdfExists ?
                        <PDFModal
                            close={this.closePDFModal}
                            fileName={fileName}
                            open={openPDFModal}
                        />
                        :
                        null
                    }
                    {pdfImgExists ?
                        <div style={{ textAlign: "center" }}>
                            {this.showPreviewIMG || !pdfExists ?
                                <img
                                    alt={fileName}
                                    src={store.getState().portalConfig.image_base_url + fileName.replace(/.pdf/gi, ".jpg")}
                                    height="50%"
                                    width="50%"
                                    onClick={() => this.openPDFModal()}
                                    style={{ cursor: "pointer" }}
                                />
                                :
                                null
                            }
                            {pdfExists ?
                                this.getPDFButtons()
                                :
                                null
                            }
                        </div>
                        :
                        pdfExists ?
                            this.getPDFButtons()
                            :
                            <div className="file-not-found">
                                <MDBIcon icon="exclamation-triangle" size="lg" />
                                <span>
                                    {fileName}
                                </span>
                            </div>
                    }
                </div>
            );
        }
        return (
            <div>
                {pdfExists ?
                    <PDFModal
                        close={this.closePDFModal}
                        fileName={fileName}
                        open={openPDFModal}
                    />
                    :
                    null
                }
                <FlippingCardPDF
                    cardBackFields={[]}
                    fileName={fileName}
                    showPDF={this.openPDFModal}
                    field_value={field_value}
                />
            </div>
        );
    }
}

PDFPreview.propTypes = {
    fileName: PropTypes.string.isRequired,
    view: PropTypes.string,
    field_value: PropTypes.object
};

export default PDFPreview;