import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    translate, getDefaultQuery
} from "../utils/Utils";
import { DataSearch } from "@appbaseio/reactivesearch";
import { MDBIcon, MDBRow } from "mdbreact";
import SearchBarButton from "./SearchBarButton";
import "../css/SearchBar.css";
import FacetAsDropdown from "./FacetAsDropdown";
import {
    set_search_query, add_hidden_facet, set_hidden_facets, set_active_article_modal,
    set_active_article
} from "../actions";
import { setHiddenFacetsBySearchBarButton, getSearchBarExtensionArray, getElasticDataField, getFieldListFieldByDataField
} from "../utils/functions";

/**
 * Connects redux store to component props.
 */
const mapStateToProps = (state) => {
    return {
        language: state.language,
        portalConfig: state.portalConfig,
        newReferenceModalProps: state.newReferenceModalProps,
        activeArticle: state.activeArticle,
        fuzziness: state.fuzziness
    };
};

/**
 * Connects redux actions to component props.
 */
const mapDispatchToProps = () => {
    return {
        set_search_query,
        add_hidden_facet,
        set_hidden_facets,
        set_active_article_modal,
        set_active_article
    };
};

/**
 * Component renders a search bar and two dropdown buttons for advanced search.
 */
class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_field: getSearchBarExtensionArray(),
            value: "",
            className: ""
        };
        this.isMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
        this.search_all = getSearchBarExtensionArray();
        this.changeSearchBarState = this.changeSearchBarState.bind(this);
        this.getDefaultQuery = this.getDefaultQuery.bind(this);
        this.timeout = 0;
    }

    componentDidMount() {
        this.setState({
            className: this.getInitClassName()
        });
    }

    getInitClassName() {
        const { isNewReferenceModal } = this.props;
        let className = "";
        if (isNewReferenceModal) {
            className = "data-search-reference";
        } else if (this.isMobileSize) {
            className = "data-search-mobile";
        } else {
            className = "data-search";
        }
        //console.log(className);
        return className;
    }

    changeSearchBarState(value) {
        setHiddenFacetsBySearchBarButton(value, this.state.search_field);
        this.setState({
            search_field: value === "all" ? this.search_all : value,
            value: "",
            className: value === "all" ? "data-search" : "data-search-advanced"
        });
    }

    getDefaultQuery() {
        return getDefaultQuery();
    }

    getFacetAsDropdown() {
        const field = getFieldListFieldByDataField(this.state.search_field);
        if (field && field.hasOwnProperty("dataField")) {
            return (
                <FacetAsDropdown
                    field={field}
                    searchbar
                />
            );
        }
        return null;
    }

    changeValue(triggerQuery) {
        if (this.props.portalConfig.searchbar_live_search) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                triggerQuery();
            }, 1000);
        }
    }

    render() {
        const { isNewReferenceModal, newReferenceModalProps, activeArticle, portalConfig } = this.props;
        const { search_field, value, className } = this.state;
        const { fuzziness, show_advanced_search, query_format } = portalConfig;
        const elastic_data_field = typeof (search_field) === "string" ? getElasticDataField(search_field) : search_field;
        return (
            <MDBRow className="search-bar">
                <DataSearch
                    className={className}
                    componentId="mainSearch"
                    dataField={elastic_data_field}
                    placeholder={translate("search")}
                    filterLabel={translate("search")}
                    URLParams={false}
                    defaultQuery={this.getDefaultQuery}
                    showIcon={true}
                    iconPosition="right"
                    icon={<MDBIcon icon="search" size="lg" className="icon-center" />}
                    fuzziness={fuzziness}
                    renderError={error => console.log("DataSearch: ", error)}
                    value={value}
                    queryFormat={query_format}
                    onValueSelected={value => {
                        if (isNewReferenceModal && newReferenceModalProps.activeArticle !== "main") {
                            this.props.set_active_article_modal("main");
                        } else if (!isNewReferenceModal && activeArticle !== "main") {
                            this.props.set_active_article("main");
                        }
                    }}
                    onChange={(value, triggerQuery) => {
                        this.setState({
                            value: value
                        },
                        () => this.changeValue(triggerQuery));
                    }}
                    onKeyDown={(event, triggerQuery) => {
                        if (event.key === "Enter") {
                            triggerQuery();
                        }
                    }}
                    debounce={100}
                    render={({loading, error, data, popularSuggestions, querySuggestions, recentSearches, rawData, promotedData, resultStats, value, downshiftProps: { isOpen, getItemProps } }) => {
                        //console.log(loading);
                        //console.log(error);
                        //console.log(data);
                        
                        //console.log(rawData);
                        //console.log(promotedData);
                        //console.log(resultStats);
                        //console.log(value);
                        //console.log(isOpen);
                        //console.log(getItemProps);
                        //console.log(popularSuggestions);
                        //console.log(querySuggestions);
                        //console.log(recentSearches);
                        /*if (loading) {
                            return <div>Fetching Suggestions.</div>;
                        }*/
                        if (error) {
                            return <div>Something went wrong! Error details {JSON.stringify(error)}</div>;
                        }
                        return null;
                        //console.log(data);
                        /*return isOpen && Boolean(value.length) ? (
                            <div>
                                {data.slice(0, 5).map((suggestion, index) => (
                                    <div key={suggestion.value} {...getItemProps({ item: suggestion })}>
                                        {suggestion.value}
                                    </div>
                                ))}
                                {Boolean(value.length) && (
                                    <div {...getItemProps({ item: { label: value, value: value } })}>
                                        Show all results for "{value}"
                                    </div>
                                )}
                            </div>
                        ) : null;*/
                    }}
                />
                {this.isMobileSize || isNewReferenceModal || !show_advanced_search ? null :
                    <div>
                        <SearchBarButton changeSearchBarState={this.changeSearchBarState} />
                        {value !== "all" ? this.getFacetAsDropdown() : null}
                    </div>
                }
            </MDBRow>
        );
    }
}

SearchBar.propTypes = {
    isNewReferenceModal: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps())(SearchBar);