const selectFieldsReducer = (state = {}, action) => {
    switch(action.type) {
        case 'SET_SELECT_FIELDS':
            state = Object.assign({}, action.payload);
            return state;
        case 'ADD_SELECT_FIELDS':
            state[action.payload].push(action.value);
            return state;
        default:
            return state;
    }
}

export default selectFieldsReducer;