import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    MDBNavbar, MDBDropdown, MDBDropdownToggle, MDBIcon, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import { translate } from "../utils/Utils";
import SearchBar from "./SearchBar";
import {
    change_download_table_modal_status, change_portal_settings_modal_status, set_compare_modal, set_shopping_cart_open
} from "../actions";
import "../css/NavBar.css";
import { handleLogoClick } from "../utils/functions";

/**
 * Connects redux store to component props.
 */
const mapStateToProps = (state) => {
    return {
        portalConfig: state.portalConfig
    };
};

/**
 * Connects redux actions to component props.
 */
const mapDispatchToProps = () => {
    return {
        change_download_table_modal_status, change_portal_settings_modal_status, set_compare_modal, set_shopping_cart_open
    };
};

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.isMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
    }

    render() {
        const { isNewReferenceModal, portalConfig } = this.props;
        const color_config = portalConfig.color_config;
        return (
            <MDBNavbar className="home-navbar" dark expand scrolling fixed="top" style={{ backgroundColor: color_config.head_color, position: "absolute" }}>
                {isNewReferenceModal || this.isMobileSize ? null :
                    <div className="logo" style={{ width: (window.innerWidth * 0.18) + "px" }}>
                        <img
                            src="logo.png"
                            alt="logo.png"
                            onClick={() => handleLogoClick()}
                        />
                    </div>
                }
                <div /*className="navbar-searchbar"*/ style={isNewReferenceModal ? { width: "100%" } : { width: "77%" }}>
                    <SearchBar isNewReferenceModal={isNewReferenceModal} />
                </div>
                {isNewReferenceModal || !portalConfig.show_user_button ? null :
                    <MDBDropdown className="navbar-dropdown">
                        <MDBDropdownToggle nav caret style={{ color: color_config.head_item_color }}>
                            <MDBIcon icon="user" />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu color="primary" right>
                            {!portalConfig.show_account ? null :
                                <div>
                                    <MDBDropdownItem>
                                        {translate("account")}
                                    </MDBDropdownItem>
                                    <MDBDropdownItem divider />
                                </div>
                            }
                            {!portalConfig.show_basket ? null :
                                <MDBDropdownItem
                                    onClick={() => this.props.set_shopping_cart_open()}
                                >
                                    {translate("basket")}
                                </MDBDropdownItem>
                            }
                            {!portalConfig.show_comparelist ? null :
                                <MDBDropdownItem
                                    onClick={() => this.props.set_compare_modal()}
                                >
                                    {translate("compare")}
                                </MDBDropdownItem>
                            }
                            {!portalConfig.show_excel ? null :
                                <MDBDropdownItem
                                    onClick={() => this.props.change_download_table_modal_status()}
                                >
                                    {translate("download_table")}
                                </MDBDropdownItem>
                            }
                            <MDBDropdownItem divider />
                            <MDBDropdownItem
                                onClick={() => this.props.change_portal_settings_modal_status()}
                            >
                                <MDBIcon icon="wrench" className="settings-icon" />
                                {translate("settings")}
                            </MDBDropdownItem>
                            {!portalConfig.show_login ? null :
                                <MDBDropdownItem>
                                    {translate("logout")}
                                </MDBDropdownItem>
                            }
                        </MDBDropdownMenu>
                    </MDBDropdown>
                }
            </MDBNavbar>
        );
    }
}

NavBar.propTypes = {
    isNewReferenceModal: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps())(NavBar);