const articleTabsReducer = (state = { tabs: [], active_section: null }, action) => {
    switch (action.type) {
        case 'NEW_TAB':
            let added_tab_state = Object.assign({}, state);
            added_tab_state.tabs.push(action.payload);
            state = added_tab_state;
            return state;
        case 'SET_TABS':
            let new_tabs_state = {...state};
            new_tabs_state.tabs = action.payload;
            state = new_tabs_state;
            return state;
        case 'DELETE_TAB':
            let articleTabs = [];
            for (var i = 0; i < state.tabs.length; i++) {
                if (state.tabs[i] !== action.payload) {
                    articleTabs.push(state.tabs[i]);
                }
            }
            let deleted_tab_state = Object.assign({}, state);
            deleted_tab_state.tabs = articleTabs;
            state = deleted_tab_state;
            return state;
        case 'SET_ACTIVE_SECTION':
            let new_state = Object.assign({}, state);
            new_state.active_section = action.payload;
            state = new_state;
            return state;
        case "REPLACE_LAST_ARTICLE_TAB":
            let new_tabs = [...state.tabs];
            new_tabs[state.tabs.length - 1] = action.payload;
            state.tabs = new_tabs;
            console.log(state);
            return state;
        default:
            return state;
    }
}

export default articleTabsReducer;