import React, { Component } from "react";
import {
    getPicture, openArticleDetails,
} from "../utils/Utils";
import { ResultCard, ResultList } from "@appbaseio/reactivesearch";
import "../Home.css";
import store from "../store/store";
import { fetchPreview } from "../utils/Webservice";

class PicturePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pic_loaded: false
        }
        this.isNavMobileSize = window.innerWidth < parseInt(store.getState().portalConfig.viewport_mobile);
        this.loadImagePreview();
    }

    loadImagePreview() {
        const { portalConfig } = store.getState();
        const { image_base_url, image_field, image_suffix } = portalConfig;
        const { item } = this.props;
        if (item[image_field]) {
            fetchPreview(image_base_url + item[image_field].replace(/"/g, '') + image_suffix).then(() => {
                this.setState({
                    pic_loaded: true
                });
            });
        }
    }

    render() {
        const { portalConfig } = store.getState();
        const { image_base_url, image_field, image_suffix, keyfield } = portalConfig;
        const { isNewReferenceModal, item, view } = this.props;
        if (this.state.pic_loaded) {
            if (view === "grid") {
                return (
                    <ResultCard.Image
                        className={this.isNavMobileSize || isNewReferenceModal ? "img-transform img" : "img"}
                        id={"flip-card-image-" + item[keyfield]}
                        src={getPicture(item, "no_image_found.png", image_field, image_base_url, image_suffix)}
                        onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                    />
                );
            } else if (view === "list") {
                return (
                    <ResultList.Image
                        className={this.isNavMobileSize || isNewReferenceModal ? "resultlist-img-sm" : ""}
                        src={getPicture(item, "no_image_found.png", image_field, image_base_url, image_suffix)}
                        onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                    />
                );
            }
            return (
                <img
                    className={this.isNavMobileSize || isNewReferenceModal ? "img-transform img" : "img"}
                    src={getPicture(item, "no_image_found.png", image_field, image_base_url, image_suffix)}
                    alt=""
                />
            );
        }
        if (view === "grid") {
            return (
                <ResultCard.Image
                    className={this.isNavMobileSize || isNewReferenceModal ? "img-transform img" : "img"}
                    id={"flip-card-image-" + item[keyfield]}
                    src={process.env.PUBLIC_URL + "/no_image_found.png"}
                    onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                />
            );
        } else if (view === "list") {
            return (
                <ResultList.Image
                    className={this.isNavMobileSize || isNewReferenceModal ? "resultlist-img-sm" : ""}
                    src={process.env.PUBLIC_URL + "/no_image_found.png"}
                    onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                />
            );
        }
        return (
            <img
                className={this.isNavMobileSize || isNewReferenceModal ? "img-transform img" : "img"}
                src={process.env.PUBLIC_URL + "/no_image_found.png"}
                alt=""
            />
        );
    }
}

export default PicturePreview;