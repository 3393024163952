import React, { Component } from "react";
import { connect } from "react-redux";
import {
    MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTable, MDBTableBody, MDBIcon,
    MDBTableHead, MDBContainer, MDBTooltip
} from "mdbreact";
import {
    translate, getProductByID, getField, getFileTimestamp, getFieldListFields, downloadNewFile, 
    getProductsByID
} from "../utils/Utils";
import {
    change_download_table_modal_status, set_download_table_products, set_download_table_template, set_download_table_active_template,
    delete_download_table_template
} from "../actions";
import "../css/ExcelTableModal.css";
import "../Home.css";
import ExcelButton from "./ExcelButton";
import NewTemplateModal from "./NewTemplateModal";
import EditTemplateModal from "./EditTemplateModal";
import SimpleModal from "./modals/SimpleModal";

const mapStateToProps = (state) => {
    return {
        portalConfig: state.portalConfig,
        downloadTableModalProps: state.downloadTableModalProps,
        fetchedProducts: state.fetchedProducts
    };
};

const mapDispatchToProps = () => {
    return {
        change_download_table_modal_status,
        set_download_table_products,
        set_download_table_template,
        set_download_table_active_template,
        delete_download_table_template
    };
};

class DownloadTableModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadTableFields: this.props.downloadTableModalProps.templates[this.props.downloadTableModalProps.active_template],
            fieldListFields: getFieldListFields(this.props.downloadTableModalProps.templates[this.props.downloadTableModalProps.active_template]),
            excelTimestamp: "",
            openDragDrop: false,
            downloadFormat: "txt",
            createEmptyXMLFields: false,
            openNewTemplateModal: false,
            openEditTemplateModal: false,
            openDeleteTemplateModal: false,
            downloadTableProducts: [],
            exportForImport: false
        }
        this.handleDragDrop = this.handleDragDrop.bind(this);
        this.closeNewTemplateModal = this.closeNewTemplateModal.bind(this);
        this.saveNewTemplate = this.saveNewTemplate.bind(this);
        this.closeEditTemplateModal = this.closeEditTemplateModal.bind(this);
        this.saveEditedTemplate = this.saveEditedTemplate.bind(this);
        this.closeDeleteTemplateModal = this.closeDeleteTemplateModal.bind(this);
        this.deleteCurrentTemplate = this.deleteCurrentTemplate.bind(this);
    }

    removeProduct(product_id) {
        const download_table_product_ids = this.props.downloadTableModalProps.productIDs.filter(id => id !== product_id);
        this.props.set_download_table_products(download_table_product_ids);
    }

    handleDragDrop(result) {
        this.props.set_download_table_template(this.props.downloadTableModalProps.active_template, result);
        this.setState({
            downloadTableFields: result,
            fieldListFields: getFieldListFields(result)
        });
    }

    changeDownloadFormat(value) {
        this.setState({
            downloadFormat: value
        });
    }

    getTXTContent() {
        const { fetchedProducts, downloadTableModalProps } = this.props;
        let txt = "";
        //let txt = "test\n\ttest";  //TODO: hier den String verarbeiten. Dazu fehlen noch Parameter in der Config
        for (var i = 0; i < downloadTableModalProps.productIDs.length; i++) {
            const product_id = downloadTableModalProps.productIDs[i];
            const product = getProductByID(fetchedProducts, product_id);
            txt += "#" + (i + 1) + "\n";
            for (let field of downloadTableModalProps.templates[downloadTableModalProps.active_template]) {
                const data = product[field.dataField] ? product[field.dataField].toString() : "";
                txt += field.text + "\n\t" + data + "\n\n";
            }
            txt += "\n\n";
            if (i === downloadTableModalProps.productIDs.length - 1) {
                //txt += "\n\n\n";
            }
        }
        return txt;
    }

    getXMLContent() {
        const { fetchedProducts, downloadTableModalProps } = this.props;
        let xml = "<?xml version='1.0' standalone='yes' ?>\n<documents>\n";
        for (let product_id of downloadTableModalProps.productIDs) {
            const product = getProductByID(fetchedProducts, product_id);
            xml += "\t<document>\n";
            for (let field of downloadTableModalProps.templates[downloadTableModalProps.active_template]) {
                const field_id = field.text.replace(/[\s()]/g, "");
                if (this.state.createEmptyXMLFields) {
                    const data = product[field.dataField] ? product[field.dataField].toString() : "";
                    xml += "\t\t<" + field_id + ">\n\t\t\t<![CDATA[\n\t\t\t\t" + data + "\n\t\t\t]]>\n\t\t</" + field_id + ">\n";
                } else {
                    if (product[field.dataField]) {
                        const data = product[field.dataField].toString();
                        xml += "\t\t<" + field_id + ">\n\t\t\t<![CDATA[\n\t\t\t\t" + data + "\n\t\t\t]]>\n\t\t</" + field_id + ">\n";
                    }
                }
            }
            xml += "\t</document>\n";
        }
        xml += "</documents>";
        return xml;
    }

    downloadTXTFile() {
        const element = document.createElement("a");
        const txt = this.getTXTContent();
        const file = new Blob([txt], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = translate("download_table") + getFileTimestamp() + ".txt";
        //element.download = product[portalConfig.keyfield] + ".txt"; //TODO: name der datei festlegen, parameter fehlt noch in der config
        //document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    downloadXMLFile() {
        const parser = new DOMParser();
        const xml = this.getXMLContent();
        const xmlDoc = parser.parseFromString(xml, "application/xml");
        console.log(xmlDoc);
        const element = document.createElement("a");
        const file = new Blob([xml], { type: 'application/xml' });
        element.href = URL.createObjectURL(file);
        element.download = translate("download_table") + getFileTimestamp() + ".xml";
        element.click();
    }

    downloadFile() {
        if (this.state.downloadFormat === "txt") {
            this.downloadTXTFile();
        } else if (this.state.downloadFormat === "xml") {
            this.downloadXMLFile();
        }
    }

    closeNewTemplateModal() {
        this.setState({
            openNewTemplateModal: false
        });
    }

    openNewTemplateModal() {
        this.setState({
            openNewTemplateModal: true
        });
    }

    openEditTemplateModal() {
        this.setState({
            openEditTemplateModal: true
        });
    }

    saveNewTemplate(templateName, template) {
        this.closeNewTemplateModal();
        this.props.set_download_table_template(templateName, template);
        this.setState({
            downloadTableFields: template,
            fieldListFields: getFieldListFields(template)
        });
    }

    saveEditedTemplate(templateName, template) {
        this.closeEditTemplateModal();
        this.props.set_download_table_template(templateName, template, templateName !== this.props.downloadTableModalProps.active_template ? this.props.downloadTableModalProps.active_template : "");
        this.setState({
            downloadTableFields: template,
            fieldListFields: getFieldListFields(template)
        });
    }

    deleteCurrentTemplate() {
        const { downloadTableModalProps } = this.props;
        this.closeDeleteTemplateModal();
        this.props.delete_download_table_template(downloadTableModalProps.active_template);
        this.setState({
            downloadTableFields: downloadTableModalProps.templates["default"],
            fieldListFields: getFieldListFields(downloadTableModalProps.templates["default"])
        });
    }

    clickTemplateSelect(value) {
        if (value !== this.props.downloadTableModalProps.active_template) {
            this.props.set_download_table_active_template(value);
            this.setState({
                downloadTableFields: this.props.downloadTableModalProps.templates[value],
                fieldListFields: getFieldListFields(this.props.downloadTableModalProps.templates[value])
            });
        }
    }

    closeEditTemplateModal() {
        this.setState({
            openEditTemplateModal: false
        });
    }

    openDeleteTemplateModal() {
        this.setState({
            openDeleteTemplateModal: true
        });
    }

    closeDeleteTemplateModal() {
        this.setState({
            openDeleteTemplateModal: false
        });
    }

    removeAll() {
        this.props.set_download_table_products([]);
    }

    render() {
        const { portalConfig, downloadTableModalProps } = this.props;
        const color_config = portalConfig.color_config;
        const downloadTableFields = this.state.downloadTableFields;
        return (
            <MDBModal
                fullHeight
                position="top"
                className="overflow-auto excel-table-modal"
                isOpen={downloadTableModalProps.openModal}
                toggle={() => this.props.change_download_table_modal_status()}
            >
                <MDBModalHeader
                    toggle={() => this.props.change_download_table_modal_status()}
                >
                    {translate("download_table")}
                </MDBModalHeader>
                <MDBModalBody
                    className="excel-modal-body"
                >
                    {!this.state.openNewTemplateModal ? null :
                        <NewTemplateModal
                            //openNewTemplateModal={this.state.openNewTemplateModal}
                            closeNewTemplateModal={this.closeNewTemplateModal}
                            templateNames={Object.keys(downloadTableModalProps.templates)}
                            selectedFields={downloadTableModalProps.templates[downloadTableModalProps.active_template]}
                            saveNewTemplate={this.saveNewTemplate}
                            modalID="download-table-new-template"
                        />
                    }
                    {!this.state.openEditTemplateModal ? null :
                        <EditTemplateModal
                            closeEditTemplateModal={this.closeEditTemplateModal}
                            templateNames={Object.keys(downloadTableModalProps.templates)}
                            templateName={downloadTableModalProps.active_template}
                            selectedFields={downloadTableModalProps.templates[downloadTableModalProps.active_template]}
                            saveEditedTemplate={this.saveEditedTemplate}
                            modalID="download-table-edit-template"
                        />
                    }
                    <SimpleModal
                        modalID="download-table-delete-template"
                        isOpen={this.state.openDeleteTemplateModal}
                        action={this.deleteCurrentTemplate}
                        close={this.closeDeleteTemplateModal}
                        bodyText={translate("delete_current_template") + "?"}
                    />
                    <MDBRow>
                        <MDBContainer
                            fluid
                            className="excel-table-container"
                        >
                            <MDBTable scrollY maxHeight={(window.innerHeight - 230) + "px"}>
                                <MDBTableHead>
                                    <tr>
                                        {downloadTableFields.map(field => (
                                            <th key={"download_table_head_" + field.text} className="border-left border-light" style={{ fontWeight: "bold" }}>
                                                {field.text}
                                            </th>
                                        ))}
                                        <th className="border-left border-light">
                                            <button
                                                className="btn btn-sm"
                                                style={{ backgroundColor: color_config.remove_button_color, color: color_config.remove_button_text_color }}
                                                onClick={() => this.removeAll()}
                                            >
                                                {translate("remove_all")}
                                            </button>
                                        </th>
                                        {/*<th className="border-left border-light" />*/}
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody >
                                    {downloadTableModalProps.productIDs.map(product_id => {
                                        const product = getProductByID(this.props.fetchedProducts, product_id);
                                        if (!product) {
                                            console.log("empty product");
                                            return null;
                                        }
                                        return (
                                            <tr key={"download-table-modal-" + product_id + "-tr"}>
                                                {downloadTableFields.map(field => (
                                                    <td className="border-left border-light" key={"download-table-modal-" + product_id + "-" + field.text + "-td"}>
                                                        {getField(product, field, product[field.dataField], false, true)}
                                                    </td>
                                                ))}
                                                <td className="border-left border-light">
                                                    <button
                                                        className="btn btn-sm"
                                                        style={{ backgroundColor: color_config.remove_button_color, color: color_config.remove_button_text_color }}
                                                        onClick={() => this.removeProduct(product_id)}
                                                    >
                                                        {translate("remove")}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBContainer>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <div className="template-select">
                        <label htmlFor="download_table_template_select">
                            {translate("template")}:
                        </label>
                        <select
                            className="browser-default custom-select format-select"
                            onChange={event => this.clickTemplateSelect(event.target.value)}
                            value={downloadTableModalProps.active_template}
                            id="download_table_template_select"
                        >
                            {Object.keys(downloadTableModalProps.templates).map(key =>
                                <option
                                    key={key}
                                    value={key}
                                >
                                    {key === "default" ? translate(key) : key}
                                </option>
                            )}
                        </select>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.openNewTemplateModal()}
                        >
                            <MDBIcon icon="plus" className="create-icon" />
                            <div style={{ marginLeft: "5px", float: "right" }}>
                                {translate("new_template")}
                            </div>
                        </button>
                        {downloadTableModalProps.active_template === "default" ? null :
                            <button
                                className="btn btn-sm"
                                onClick={() => this.openEditTemplateModal()}
                            >
                                <MDBIcon icon="pencil-alt" className="edit-icon" />
                                <div style={{ marginLeft: "5px", float: "right" }}>
                                    {translate("edit_template")}
                                </div>
                            </button>
                        }
                        {downloadTableModalProps.active_template === "default" ? null :
                            <button
                                className="btn btn-sm"
                                onClick={() => this.openDeleteTemplateModal()}
                            >
                                <MDBIcon icon="trash-alt" className="delete-icon" />
                                <div style={{ marginLeft: "5px", float: "right" }}>
                                    {translate("delete_current_template")}
                                </div>
                            </button>
                        }
                    </div>
                    <label htmlFor="format_select" className="">
                        Format:
                    </label>
                    <select
                        className="browser-default custom-select format-select"
                        onChange={event => this.changeDownloadFormat(event.target.value)}
                        value={this.state.downloadFormat}
                        id="format_select"
                    >
                        <option
                            value="txt"
                        >
                            {portalConfig.elastic_index.includes("_bios_") ? "BIOS" : "TXT"}
                        </option>
                        <option
                            value="excel"
                        >
                            Excel
                        </option>
                        <option
                            value="xml"
                        >
                            XML
                        </option>
                    </select>
                    {downloadTableModalProps.productIDs.length && this.state.downloadFormat === "xml" ?
                        <div className='custom-switch' style={{ marginTop: "5px" }}>
                            <input
                                type='checkbox'
                                className='custom-control-input'
                                id="switch_xml_empty_fields"
                                checked={this.state.createEmptyXMLFields}
                                onChange={() => this.setState({ createEmptyXMLFields: !this.state.createEmptyXMLFields })}
                                readOnly
                            />
                            <label className='custom-control-label' htmlFor="switch_xml_empty_fields">
                                {translate("show_empty_fields")}
                            </label>
                        </div>
                        :
                        null
                    }
                    {downloadTableModalProps.productIDs.length && this.state.downloadFormat === "excel" ?
                        <div className='custom-switch' style={{ marginTop: "5px" }}>
                            <input
                                type='checkbox'
                                className='custom-control-input'
                                id="switch_export_for_import"
                                checked={this.state.exportForImport}
                                onChange={() => this.setState({ exportForImport: !this.state.exportForImport })}
                                readOnly
                            />
                            <label className='custom-control-label' htmlFor="switch_export_for_import">
                                {translate("export_for_import")}
                            </label>
                        </div>
                        :
                        null
                    }
                    {!downloadTableModalProps.productIDs.length || this.state.downloadFormat !== "excel" ? null :
                        //<button>excel</button>
                        <ExcelButton
                            buttonID="excel-table-modal-button"
                            usage="download_table"
                            excelTableProductsIDs={downloadTableModalProps.productIDs}
                            fetchedProducts={this.props.fetchedProducts}
                            portalConfigKeyfield={this.props.portalConfig.keyfield}
                            excelTableFields={this.state.downloadTableFields}
                            exportForImport={this.state.exportForImport}
                        />
                    }
                    {!downloadTableModalProps.productIDs.length || this.state.downloadFormat === "excel" ? null :
                        <MDBTooltip
                            domElement
                            tag="span"
                            placement="top"
                        >
                            <span>
                                <button
                                    className="btn btn-sm"
                                    onClick={() => downloadNewFile(this.state.downloadFormat, this.state.createEmptyXMLFields, getProductsByID(downloadTableModalProps.productIDs), downloadTableFields.filter(field => !field.dataField.includes(":JSON")))}
                                    style={{ backgroundColor: color_config.add_button_color, color: color_config.add_button_text_color }}
                                >
                                    <MDBIcon icon="download" />
                                </button>
                            </span>
                            <span>{translate("download")}</span>
                        </MDBTooltip>
                    }
                    <button
                        className="btn btn-sm"
                        onClick={() => this.props.change_download_table_modal_status()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(DownloadTableModal);