const hiddenFacetsReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_HIDDEN_FACET':
            state = state.map(facet => facet);
            state.push(action.payload);
            //console.log(state);
            return state;
        case 'SET_HIDDEN_FACETS':
            state = action.payload;
            //console.log(state);
            return state;
        default:
            return state;
    }
}

export default hiddenFacetsReducer;