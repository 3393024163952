import React, { Component } from "react";
import { MDBSpinner } from "mdbreact";
import { getComponents } from "../utils/Utils";
import { connect } from "react-redux";
import { set_search_query, hide_facet, set_active_article, set_active_article_modal } from "../actions";
import { DynamicRangeSlider } from "@appbaseio/reactivesearch";
import "../Home.css";

const mapStateToProps = (state) => {
    return {
        portalConfig: state.portalConfig,
        searchQuery: state.searchQuery,
        newReferenceModalProps: state.newReferenceModalProps,
        activeArticle: state.activeArticle
    };
};

const mapDispatchToProps = () => {
    return {
        set_search_query,
        hide_facet,
        set_active_article,
        set_active_article_modal
    };
};

class SearchDynamicRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_is_integer: true
        };
        this.checked_data_is_integer = false;

    }

    componentDidUpdate() {
        if (!this.checked_data_is_integer) {
            this.checked_data_is_integer = true;
            const range_slider = document.getElementById(this.props.field.dataField + "-dynamic-range-slider");
            if (!range_slider.children.length) {
                console.log("data type should be integer, but isn´t", this.props.field);
                this.setState({
                    data_is_integer: false
                });
                this.props.hide_facet(this.props.field.dataField);
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.componentDidUpdate();
        }, 3000);
    }

    handleChange(value) {
        const { field, isNewReferenceModal, newReferenceModalProps, activeArticle } = this.props;
        this.props.set_search_query(value, field.dataField, true);
        this.props.handleFacetClick(field.dataField, value);
        if (isNewReferenceModal && newReferenceModalProps.activeArticle !== "main") {
            this.props.set_active_article_modal("main");
        } else if (!isNewReferenceModal && activeArticle !== "main") {
            this.props.set_active_article("main");
        }
    }

    render() {
        const field = this.props.field;
        const dataField = field.dataField;
        if (this.state.data_is_integer) {
            return (
                <div id={dataField + "-dynamic-range-slider"}>
                    <DynamicRangeSlider
                        innerClass={{
                            title: "search-title"
                        }}
                        className="search-field"
                        title={field.unit_filter && field.display_unit_on_attribute === "1" ? field.text + " [" + field.unit_filter.trim() + "]" : field.text}
                        componentId={dataField}
                        dataField={dataField}
                        react={{
                            and: getComponents(dataField)
                        }}
                        showFilter={this.props.isFacetModal ? false : true}
                        filterLabel={field.text}
                        stepValue={1}
                        tooltipTrigger="focus"
                        onValueChange={value => this.handleChange(value)}
                        loader={
                            <div className="search-bar-spinner">
                                <MDBSpinner small />
                            </div>
                        }
                        rangeLabels={(min, max) => ({
                            start: field.unit_filter && field.display_unit_on_value === "1" ? min + " " + field.unit_filter.trim() : min,
                            end: field.unit_filter && field.display_unit_on_value === "1" ? max + " " + field.unit_filter.trim() : max
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(SearchDynamicRange);