import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBTooltip, MDBIcon } from "mdbreact";
import { translate, downloadNewFile, getFieldListFieldsByDataFields, getUsageRequiredFields } from "../../utils/Utils";
import PropTypes from "prop-types";
import store from "../../store/store";
import { compare_list_limit, download_table_limit } from "../../utils/constants";
import "../../Home.css";
import { fetchProducts } from "../../utils/Webservice";
import ExcelButton from "../ExcelButton";
import EditFieldsTable from "../EditFieldsTable";

class MultiSelectCopyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadProducts: false,
            fieldListFields: store.getState().portalConfig.field_list,
            downloadFields: store.getState().downloadTableModalProps.templates["default"].map(field => field.dataField),
            isLoading: false,
            showEditFieldsTable: false,
            copyHits: false,
            //editedFields: {}
        };
        this.setEditedFields = this.setEditedFields.bind(this);
    }

    closeModal() {
        if (!this.state.isLoading) {
            this.props.close(this.props.modalID);
        }
    }

    getBodyText() {
        const { hits, language } = store.getState();
        const { numberOfResults } = this.props;
        //let body_text = hits < numberOfResults ? translate("would_you_like_to_copy") + hits + translate("items") + translate("or_all") + translate("items") : (numberOfResults < 2 ? translate("would_you_like_to_copy") + "1" + translate("item") : translate("would_you_like_to_copy") + numberOfResults + translate("items"));
        let body_text = hits < numberOfResults ? translate("would_you_like_to_copy") + hits + translate("items") + translate("or_all") + translate("items") : "";
        if (hits < numberOfResults) {
            if (language === "de") {
                body_text += " " + translate("copy") + "?";
            } else {
                body_text += "?";
            }
        }
        return body_text;
    }

    handleAllButton() {
        this.setState({
            copyHits: false,
            showEditFieldsTable: true,
        });
        /*const { numberOfResults, type } = this.props;
        const { downloadTableModalProps } = store.getState();
        const limit = type === "download" ? download_table_limit : compare_list_limit;
        if (numberOfResults > limit || (downloadTableModalProps.productIDs.length + numberOfResults) > limit) {
            this.setState({
                loadProducts: true
            });
        } else {
            this.props.action(true);
        }*/
    }

    handleHitsButton() {
        this.setState({
            copyHits: true,
            showEditFieldsTable: true
        });
    }

    getExtendedBodyText() {
        const copy_text = "Geben Sie die Daten ein, die für alle kopierten Produkte übernommen werden sollen:";
        return copy_text;
    }

    setEditedFields(editedFields) {
        const { numberOfResults } = this.props;
        const { hits } = store.getState();
        this.props.saveCopiedProducts(editedFields, hits > numberOfResults ? false : !this.state.copyHits);
        this.setState({
            isLoading: true
        });
    }

    render() {
        const { headerText, numberOfResults } = this.props;
        const { hits, portalConfig } = store.getState();
        const header_text = headerText ? headerText : "";
        const body_text = this.getBodyText();
        const extended_body_text = this.getExtendedBodyText();
        //console.log(this.state.editedFields);
        return (
            <MDBModal
                isOpen
                toggle={() => this.closeModal()}
                className={this.state.isLoading ? "cursor-wait" : ""}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    {header_text}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer className="body">
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            {body_text}
                        </div>
                        {numberOfResults < hits ? null :
                            <div style={{ justifyContent: "center", marginTop: "10px", marginBottom: "10px", display: "flex" }}>
                                <div>
                                    <button
                                        className="btn btn-sm"
                                        disabled={this.state.isLoading || this.state.copyHits}
                                        onClick={() => this.handleHitsButton()}
                                    >
                                        {hits}
                                    </button>
                                    <button
                                        className="btn btn-sm"
                                        onClick={() => this.handleAllButton()}
                                        disabled={this.state.showEditFieldsTable && !this.state.copyHits}
                                    >
                                        {translate("all")}
                                    </button>
                                </div>
                            </div>
                        }
                        {numberOfResults < hits || this.state.showEditFieldsTable ?
                            <div>
                                <div style={{ justifyContent: "center", display: "flex", marginBottom: "10px" }}>
                                    {extended_body_text}
                                </div>
                                <EditFieldsTable
                                    editFields={getUsageRequiredFields()}
                                    setEditedFields={this.setEditedFields}
                                />
                            </div>
                            :
                            null
                        }
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                        disabled={this.state.isLoading}
                    >
                        {"Kopieren Abbrechen"}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

MultiSelectCopyModal.propTypes = {
    modalID: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    numberOfResults: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
    saveCopiedProducts: PropTypes.func.isRequired
};

export default MultiSelectCopyModal;