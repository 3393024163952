import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTable, MDBTableBody } from "mdbreact";
import { translate, getProductByID, getTableText, createHTMLElement, getFieldData } from "../utils/Utils";
import { change_new_copied_product_modal_status } from "../actions";

const mapStateToProps = (state) => {
    return {
        openNewCopiedProductModal: state.openNewCopiedProductModal,
        newCopiedProduct: state.newCopiedProduct,
        fetchedProducts: state.fetchedProducts,
        portalConfig: state.portalConfig,
        edit: state.edit,
        selectFields: state.selectFields
    };
};

const mapDispatchToProps = () => {
    return {
        change_new_copied_product_modal_status
    };
};

class NewCopiedProductModal extends Component {
    /*constructor(props) {
        super(props);
    }*/

    closeModal() {
        this.props.change_new_copied_product_modal_status();
    }

    createNewCopiedProduct() {
        this.closeModal();
        //anlegen von Produkt fehlt noch
        console.log(getProductByID(this.props.fetchedProducts, this.props.newCopiedProduct))
    }

    createField(item, field = this.props.portalConfig.image_field) {
        const { portalConfig } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        const htmlElement = createHTMLElement(item, getFieldData(field), reference_user, reference_password, reference_url);
        //arr = htmlElement.arr;
        return (
            <div dangerouslySetInnerHTML={htmlElement.html} />
        );
    }

    render() {
        const portalConfig = this.props.portalConfig;
        const product = getProductByID(this.props.fetchedProducts, this.props.newCopiedProduct);
        if (product !== null) {
            
        }
        return (
            <MDBModal
                size="lg"
                isOpen={this.props.openNewCopiedProductModal}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                />
                <MDBModalBody>
                    {product === null ? null :
                        <MDBTable>
                            <MDBTableBody>
                                {portalConfig.field_list.map(field => (
                                    <tr>
                                        <td>
                                            {getTableText(field.text)}
                                        </td>
                                        <td className="border-left border-light">
                                            {this.createField(product, field)}
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    }
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn 
                        color="primary"
                        onClick={() => this.createNewCopiedProduct()}
                    >
                        {translate("yes")}
                    </MDBBtn>
                    <MDBBtn color="secondary" 
                        onClick={() => this.closeModal()}
                    >
                        {translate("no")}
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(NewCopiedProductModal);