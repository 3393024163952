import React, { Component } from "react";
import { ResultCard, ReactiveList, ResultList } from "@appbaseio/reactivesearch";
import {
    MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBCol, MDBContainer, MDBSpinner
} from "mdbreact";
import {
    getAllComponents, getTableList, getTableText, translate,
    getPicture, createHTMLElement, getField, getDefaultQuery, addToFetchedProducts, getButtons, openArticleDetails,
    getResultTableStyle, getFlipCardFields, checkPicture
} from "../../utils/Utils";
import "../../css/SearchResults.css";
import "../../Home.css";
import { connect } from "react-redux";
import {
    set_active_article, set_shopping_cart_modal, set_shopping_cart_modal_item, add_product, add_compare_item,
    set_compare_modal, change_hits, set_result_view, set_language, update_product, change_delete_product_modal_status,
    change_new_copied_product_modal_status, set_new_copied_product,
    add_products, change_total_number_of_results
} from "../../actions";
import CustomTable from "../CustomTable";
import FlippingCard from "./FlippingCard";
import PicturePreview from "../PicturePreview";
import GridView from "./GridView";
import ListView from "./ListView";
import { getListItemFields } from "../../utils/functions";

const { ResultCardsWrapper, ResultListWrapper } = ReactiveList;

var refs = {};

//var img_ids = [];

const mapStateToProps = (state) => {
    return {
        resultView: state.resultView,
        articleTabs: state.articleTabs,
        compareItems: state.compareItems,
        language: state.language,
        hits: state.hits,
        selectFields: state.selectFields,
        showSubtablesDirectly: state.showSubtablesDirectly,
        fetchedProducts: state.fetchedProducts,
        customTable: state.customTable,
        portalConfig: state.portalConfig,
        customTableTemplates: state.customTableTemplates,
        template: state.template,
        updatingSource: state.updatingSource,
        imageBase64: state.imageBase64,
        searchQuery: state.searchQuery,
        numberOfResults: state.numberOfResults,
        newReferenceModalProps: state.newReferenceModalProps,
        menuFilter: state.menuFilter
    };
};

const mapDispatchToProps = () => {
    return {
        set_active_article,
        set_shopping_cart_modal,
        set_shopping_cart_modal_item,
        add_product,
        add_compare_item,
        set_compare_modal,
        change_hits,
        set_result_view,
        set_language,
        update_product,
        change_delete_product_modal_status,
        change_new_copied_product_modal_status,
        set_new_copied_product,
        add_products,
        change_total_number_of_results
    };
};

class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table_list: getTableList(this.props.portalConfig.field_list, this.props.portalConfig.image_field),
            list_list: getListItemFields(),
            re_mount: false,
            current_results: [],
            facets_did_mount: false
        };
        //this.cardBackFields = this.props.portalConfig.grid_fancy_fields.map(label_field => getFieldListFieldByLabelField(label_field)).filter(field => field);
        this.isNavMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
        this.hits_shown = this.props.isNewReferenceModal ? this.props.newReferenceModalProps.hits : this.props.hits;
        this.getDefaultQuery = this.getDefaultQuery.bind(this);
    }

    componentDidUpdate() {
        if (this.hits_shown !== (this.props.isNewReferenceModal ? this.props.newReferenceModalProps.hits : this.props.hits)) {
            //console.log(1);
            this.hits_shown = this.props.isNewReferenceModal ? this.props.newReferenceModalProps.hits : this.props.hits;
            this.setState({
                re_mount: !this.state.re_mount
            });
        }
        //this.checkFacetsDidMount();
    }

    checkFacetsDidMount() {
        const { isNewReferenceModal, newReferenceModalProps, resultView, portalConfig, menuFilter } = this.props;
        console.log(menuFilter);
        //console.log(x);
        let facets_did_mount = true;
        for (let field of menuFilter["facet"]) {

            let x = document.getElementById(field.dataField + "-facet");
            console.log(field.dataField, x);
            /*if (!x) {
                facets_did_mount = false;
            }*/
        }
        console.log(facets_did_mount);
        if (!this.state.facets_did_mount) {
            this.setState({
                facets_did_mount
            });
        }
        /*if (!facets_did_mount && !this.state.facets_did_mount) {

        }*/
    }

    getDefaultQuery() {
        return getDefaultQuery();
    }

    createField(item, field = this.props.portalConfig.image_field) {
        const { portalConfig, isNewReferenceModal } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        if (typeof (field) === "object" && field.dataField.includes(":JSON")) {
            return null;
        }
        let value = item[field.dataField];
        if (field.dataField.includes(":REFERENCE")) {
            const htmlElement = createHTMLElement(item, field.dataField, reference_user, reference_password, reference_url);
            refs[field.dataField] = htmlElement.arr;
            //arr = htmlElement.arr;
            value = htmlElement.html.__html;
        }
        return getField(item, field, value, false, isNewReferenceModal);
    }

    getImageComponent(item) {
        const { portalConfig, isNewReferenceModal } = this.props;
        const { keyfield, image_field, image_base_url, image_suffix } = portalConfig;
        return (
            <ResultCard.Image
                className={this.isNavMobileSize || isNewReferenceModal ? "img-transform" : ""}
                id={item[keyfield]}
                src={getPicture(item, "no_image_found.png", image_field, image_base_url, image_suffix)}
                onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
            />
        );
    }

    getReactiveComponents() {
        return getAllComponents(this.props.isNewReferenceModal);
    }

    render() {
        const { isNewReferenceModal, newReferenceModalProps, resultView, portalConfig } = this.props;
        const { label_field, image_field, keyfield } = portalConfig;
        if (this.state.re_mount) {
            this.hits_shown = 0;
            return null;
        }
        /*if (!this.state.facets_did_mount) {
            return <div>loading</div>;
        }*/
        return (
            <MDBContainer fluid>
                {/*this.props.imageBase64 ?
                    <div>
                        <img src={"data:image/png;base64, " + this.props.imageBase64[0]} />
                        <img src={"data:image/png;base64, " + this.props.imageBase64[1]} />
                        <img src={"data:image/png;base64, " + this.props.imageBase64[2]} />
                    </div>
                : null*/}
                <ReactiveList
                    innerClass={{
                        sortOptions: "sort-options"
                    }}
                    className="reactive-list"
                    componentId={isNewReferenceModal ? "results-modal" : "results"}
                    title="Results"
                    dataField={label_field}
                    size={isNewReferenceModal ? this.props.newReferenceModalProps.hits : this.props.hits}
                    pagination
                    paginationAt="bottom"
                    Loader={translate("loading") + "..."}
                    noResults={translate("no_results") + "..."}
                    defaultQuery={this.getDefaultQuery}
                    react={{
                        and: this.getReactiveComponents(),
                        or: ["mainSearch"]
                    }}
                    //sortBy="asc"
                    renderError={(error) => {
                        console.log("ReactiveList: ", error);
                    }}
                    renderResultStats={
                        function (stats) {
                            return (
                                <div className="result-table">
                                    {translate("showing", "article")} {stats.displayedResults} {translate("of_total", "article")} {stats.numberOfResults} {translate("in", "article")} {stats.time} {translate("ms", "article")}
                                </div>
                            );
                        }
                    }
                    render={({ data, aggregationData, streamData, loading, promotedData, customData, rawData, resultStats }) => {
                        if (loading) {
                            return (
                                <div className="search-bar-spinner">
                                    <MDBSpinner small />
                                </div>
                            );
                        }
                        if (data.length > 0) {
                            const active_view = isNewReferenceModal ? newReferenceModalProps.resultView : resultView;
                            if (!this.props.updatingSource) {
                                addToFetchedProducts(data);
                            }
                            if (this.props.numberOfResults.total_number_of_results !== resultStats.numberOfResults && this.props.numberOfResults.total_number_of_results < resultStats.numberOfResults) {
                                this.props.change_total_number_of_results(resultStats.numberOfResults);
                            }
                            if (active_view === "grid") {
                                return (
                                    <GridView
                                        data={data}
                                        isNewReferenceModal={isNewReferenceModal}
                                        notify={this.props.notify}
                                    />
                                );
                            } else if (active_view === "list") {
                                return (
                                    <ListView
                                        data={data}
                                        isNewReferenceModal={isNewReferenceModal}
                                        notify={this.props.notify}
                                    />
                                );
                            } else if (active_view === "table") {
                                //data.map(item => console.log(item));
                                return (
                                    <MDBContainer fluid >
                                        <div className="result-table-container overflow-auto" style={getResultTableStyle(isNewReferenceModal)}>
                                            <MDBTable className="result-table">
                                                <MDBTableHead>
                                                    <tr>
                                                        <th className="result-table-head" />
                                                        {this.state.table_list.map(field => (
                                                            <th className="result-table-head border-left border-light" style={{ verticalAlign: "middle" }}>
                                                                {getTableText(field.text && field.text.replace(/\s/g, "") ? field.text : field.dataField)}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {data.map(item => (
                                                        <tr>
                                                            <td className="table-button-field">
                                                                {getButtons(item[keyfield], this.props.notify, "buttons-td", isNewReferenceModal)}
                                                            </td>
                                                            {this.state.table_list.map(field => {
                                                                if (portalConfig.elastic_index.includes("_bios_") && field.edit_depends_on && item["IsReadOnly"]) {
                                                                    return (
                                                                        <td className="border-left border-light">
                                                                            {item[field.dataField]}
                                                                        </td>
                                                                    );
                                                                }
                                                                return (
                                                                    <td className="border-left border-light">
                                                                        {/*this.createField(item, field)*/}
                                                                        {getField(item, field, item[field.dataField], false, isNewReferenceModal)}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </MDBContainer>
                                );
                            } else if (active_view === "custom") {
                                return (
                                    <CustomTable
                                        data={data}
                                        notify={this.props.notify}
                                        numberOfResults={resultStats.numberOfResults}
                                    />
                                );
                            }
                        } else {
                            return null;
                        }
                    }}
                />
            </MDBContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(SearchResults);