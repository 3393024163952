const templateReducer = (state = "default", action) => {
    switch(action.type) {
        case 'SET_TEMPLATE':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default templateReducer;