import React, { Component } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { translate } from "../utils/Utils";
import { connect } from "react-redux";
import "../css/SearchBarButton.css";
import PropTypes from "prop-types";
import { getSearchbarDataFields } from "../utils/functions";
import "../Home.css";

const mapStateToProps = (state) => {
    return {
        language: state.language,
        portalConfig: state.portalConfig,
        hideFacets: state.hideFacets
    };
};

const mapDispatchToProps = () => {
    return {
    };
};

class SearchBarButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItemKey: "all",
            activeItemText: "",
            search_bar_array: getSearchbarDataFields(true)
        }
    }

    handleClick(value = null) {
        this.setState({
            activeItemKey: value ? value.key : "all",
            activeItemText: value ? value.text : ""
        });
        this.props.changeSearchBarState(value ? value.key : "all");
    }

    render() {
        const { hideFacets } = this.props;
        const { activeItemText, activeItemKey, search_bar_array } = this.state;
        return (
            <MDBDropdown className="dropdown-searchbar">
                <MDBDropdownToggle id={"search-bar-button-" + activeItemText} nav caret className="dropdown-toggle" style={{color: this.props.portalConfig.color_config.head_item_color}}>
                    {activeItemKey === "all" ? translate("all") : activeItemText}
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-menu">
                    <MDBDropdownItem 
                        header
                        disabled
                        className="dropdown-item"
                    >
                        {translate("select_field")}
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem 
                        active={activeItemKey === "all"}
                        className="dropdown-item"
                        onClick={() => this.handleClick()}
                    >
                        {translate("all")}
                    </MDBDropdownItem>
                    {search_bar_array.filter(value => !hideFacets.includes(value.key)).map(value => (
                        <MDBDropdownItem 
                            key={"search_bar_button_dropdown_" + value.key}
                            active={activeItemKey === value.key}
                            className="dropdown-item"
                            onClick={() => this.handleClick(value)}
                        >
                            {value.text}
                        </MDBDropdownItem>
                    ))}
                </MDBDropdownMenu>
            </MDBDropdown>
        );
    }
}

SearchBarButton.propTypes = {
    changeSearchBarState: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps())(SearchBarButton);