import React, { Component } from "react";
import jsPDF from "jspdf";
import { MDBIcon, MDBTooltip } from "mdbreact";
import { getSectionList, getSectionContent, getPDFConfig, translate } from "../utils/Utils";
import "../Home.css";
import "jspdf-autotable";

var doc = new jsPDF("p", "pt");

class PDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdf_config: getPDFConfig(this.props.pdf_config)
        };
    }

    getImageElement() {
        const { logo_width, logo_height } = this.state.pdf_config;
        let img_element = document.createElement("img");
        img_element.src = this.state.pdf_config.logo_src;
        img_element.height = logo_height;
        img_element.width = logo_width;
        return img_element;
    }

    generatePDF() {
        const pdf_config = this.state.pdf_config;
        const { logo_width, logo_height, logo_type } = pdf_config;
        const logo = this.getImageElement();
        const item = this.props.item;
        let tabbi = [];
        var finalY = pdf_config.page_margin_top;
        let hits_in_section = 0;
        var totalPagesExp = "{total_pages_count_string}";
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.getHeight();
        var pageWidth = pageSize.getWidth();
        for (let registerTab of this.props.registerTabList) {
            const sectionList = getSectionList(registerTab);
            tabbi = [];  // eine Tabelle pro Registertab ausgeben ... (wichtig: Sollte konfigurierbar sein)
            for (let section of sectionList) {
                hits_in_section = 0;
                if (typeof section != "undefined" && (section.length > 1)) {
                    tabbi.push([{ content: section, colSpan: 2, styles: { halign: 'left', cellPadding: { top: pdf_config.font_size_section, bottom: 5, left: 5, right: 5 }, fontSize: pdf_config.font_size_section, textColor: pdf_config.textColor_section } }])
                } else
                    hits_in_section = 1;        // vermeiden, dass nachher die Zeile für Section entfernt wird (die ja nicht eingetragen wurde...)
                const section_content = getSectionContent(registerTab, section, this.props.item_id);
                for (let field of section_content) {
                    if (item[field.dataField] !== "") {      // nur ausgeben, falls etwas enthalten ist ...
                        if (!(field.dataField.indexOf(":") > 0)) {        // aktuell nur ausgeben, wenn es kein referenz. Feld / Subtable etc. ist
                            hits_in_section++;
                            tabbi.push([field.text, item[field.dataField]])
                        } else
                            // WICHTIG: es ist möglich, eine html-Table 1:1 zu rendern. Wichtig wäre nur die ID zu setzen 
                            // also mit strreplace - und dann einfach: doc.autoTable({html: '#table'});
                            console.log(field.txt)
                    }
                }
                /*section_content.map(field => {
                    if (item[field.dataField] != "") {      // nur ausgeben, falls etwas enthalten ist ...
                        if (!(field.dataField.indexOf(":") > 0)) {        // aktuell nur ausgeben, wenn es kein referenz. Feld / Subtable etc. ist
                            hits_in_section++;
                            tabbi.push([field.text, item[field.dataField]])
                        } else
                            // WICHTIG: es ist möglich, eine html-Table 1:1 zu rendern. Wichtig wäre nur die ID zu setzen 
                            // also mit strreplace - und dann einfach: doc.autoTable({html: '#table'});
                            console.log(field.txt)
                    }
                });*/
                if (!hits_in_section) tabbi.splice(-1, 1); // falls kein Eintrag in der Section, dann den colspan-Header dafür entfernen...
            }
            doc.setFontSize(pdf_config.font_size_register)
            doc.setTextColor(pdf_config.textColor_register)
            finalY += 2 * pdf_config.font_size_register; // Math.round(pdf_config.font_size_register / 2);
            doc.text(registerTab, pdf_config.positionLeft_register, finalY)
            finalY += pdf_config.font_size_register;
            doc.autoTable({
                body: tabbi,
                //                html: htmltab + "</table>", // "'#" + registertab + "'",
                startY: finalY,
                styles: { fontSize: pdf_config.font_size_tabletext, textColor: pdf_config.textColor_tabletext },
                columnStyles: {
                    0: { cellWidth: pdf_config.columnWidth_fieldLabel }, //  1: {cellWidth: 80} 
                },
                /*
                            styles: { fillColor: [255, 0, 0] },
                            columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
                */
                didDrawPage: function (data) {
                    // Header
                    doc.setFillColor(pdf_config.fillColor_page_header);
                    doc.rect(0, 0, pageWidth, pdf_config.page_header_height, 'F');          // - logo_height:: Grund: bei Text zählt die untere Ecke, beim Logo die obere
                    // hier Konvertierung der tatsaechlichen Groesse des logos (logo_wieth bzw. logo_heigth) zu der in pdf_config vorgesehenden Groesse..
                    // falls das Seitenverhaeltnis zwischen dem tatsaechlichen Logo und dem vorgegeben Platz um mehr als 5% abweicht => setze die logo-dimensions neu!
                    // if (Math.abs( (logo_width / logo_height) - (pdf_config.logo_width / pdf_config.logo_height)) >0.05) {
                    let height = logo_height;
                    let width = logo_width;
                    let y = pdf_config.page_margin_header_top - pdf_config.logo_height + 4
                    if (logo_height > pdf_config.page_header_height - pdf_config.page_header_top || true) {
                        width = logo_width * 0.9 * pdf_config.page_header_height / logo_height;
                        height = 0.9 * pdf_config.page_header_height;
                        y = 0.05 * pdf_config.page_header_height;
                    }
                    let x = Math.max(data.settings.margin.left, pdf_config.logo_right - width); // pageWidth-pdf_config.logo_width-data.settings.margin.right
                    doc.addImage(logo, logo_type, Math.min(x, pageWidth - width), y, width, height)
                    doc.setFontSize(20);
                    doc.setTextColor(pdf_config.textColor_page_header);
                    //doc.setFontStyle('normal'); //existiert in neuer Version nicht
                    doc.text("Product-Datasheet", Math.max(data.settings.margin.left, pdf_config.headline_left), pdf_config.page_margin_header_top);
                    // Footer
                    var str = "Page " + doc.internal.getNumberOfPages()
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof doc.putTotalPages === 'function') {
                        str = str + " of " + totalPagesExp;
                    }
                    doc.setFontSize(10);
                    doc.text(str, data.settings.margin.left, pageHeight - pdf_config.page_margin_bottom);   // left align
                    //                    doc.text(str, (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(str) * doc.internal.getFontSize() / 2), pageHeight - pdf_config.page_margin_bottom); // (center align), 'center');
                },
                margin: {
                    top: pdf_config.page_margin_top
                }
            });
            finalY = doc.lastAutoTable.finalY;
        }
        /*this.props.registerTabList.map(registerTab => {
            const sectionList = getSectionList(registerTab);
            tabbi = [];  // eine Tabelle pro Registertab ausgeben ... (wichtig: Sollte konfigurierbar sein)
            sectionList.map(section => {
                hits_in_section = 0;
                if (typeof section != "undefined" && (section.length > 1)) {
                    tabbi.push([{ content: section, colSpan: 2, styles: { halign: 'left', cellPadding: { top: pdf_config.font_size_section, bottom: 5, left: 5, right: 5 }, fontSize: pdf_config.font_size_section, textColor: pdf_config.textColor_section } }])
                } else
                    hits_in_section = 1;        // vermeiden, dass nachher die Zeile für Section entfernt wird (die ja nicht eingetragen wurde...)
                const section_content = getSectionContent(registerTab, section, this.props.item_id);
                section_content.map(field => {
                    if (item[field.dataField] != "") {      // nur ausgeben, falls etwas enthalten ist ...
                        if (!(field.dataField.indexOf(":") > 0)) {        // aktuell nur ausgeben, wenn es kein referenz. Feld / Subtable etc. ist
                            hits_in_section++;
                            tabbi.push([field.text, item[field.dataField]])
                        } else
                            // WICHTIG: es ist möglich, eine html-Table 1:1 zu rendern. Wichtig wäre nur die ID zu setzen 
                            // also mit strreplace - und dann einfach: doc.autoTable({html: '#table'});
                            console.log(field.txt)
                    }
                });
                if (!hits_in_section) tabbi.splice(-1, 1); // falls kein Eintrag in der Section, dann den colspan-Header dafür entfernen...
            })

            doc.setFontSize(pdf_config.font_size_register)
            doc.setTextColor(pdf_config.textColor_register)
            finalY += 2 * pdf_config.font_size_register; // Math.round(pdf_config.font_size_register / 2);
            doc.text(registerTab, pdf_config.positionLeft_register, finalY)
            finalY += pdf_config.font_size_register;
            doc.autoTable({
                body: tabbi,
                //                html: htmltab + "</table>", // "'#" + registertab + "'",
                startY: finalY,
                styles: { fontSize: pdf_config.font_size_tabletext, textColor: pdf_config.textColor_tabletext },
                columnStyles: {
                    0: { cellWidth: pdf_config.columnWidth_fieldLabel }, //  1: {cellWidth: 80} 
                },
                /*
                            styles: { fillColor: [255, 0, 0] },
                            columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
                
                didDrawPage: function (data) {
                    // Header
                    doc.setFillColor(pdf_config.fillColor_page_header);
                    doc.rect(0, 0, pageWidth, pdf_config.page_header_height, 'F');          // - logo_height:: Grund: bei Text zählt die untere Ecke, beim Logo die obere
                    // hier Konvertierung der tatsaechlichen Groesse des logos (logo_wieth bzw. logo_heigth) zu der in pdf_config vorgesehenden Groesse..
                    // falls das Seitenverhaeltnis zwischen dem tatsaechlichen Logo und dem vorgegeben Platz um mehr als 5% abweicht => setze die logo-dimensions neu!
                    // if (Math.abs( (logo_width / logo_height) - (pdf_config.logo_width / pdf_config.logo_height)) >0.05) {
                    let height = logo_height;
                    let width = logo_width;
                    let y = pdf_config.page_margin_header_top - pdf_config.logo_height + 4
                    if (logo_height > pdf_config.page_header_height - pdf_config.page_header_top || true) {
                        width = logo_width * 0.9 * pdf_config.page_header_height / logo_height;
                        height = 0.9 * pdf_config.page_header_height;
                        y = 0.05 * pdf_config.page_header_height;
                    }
                    let x = Math.max(data.settings.margin.left, pdf_config.logo_right - width); // pageWidth-pdf_config.logo_width-data.settings.margin.right
                    doc.addImage(logo, logo_type, Math.min(x, pageWidth - width), y, width, height)
                    doc.setFontSize(20);
                    doc.setTextColor(pdf_config.textColor_page_header);
                    doc.setFontStyle('normal');
                    doc.text("Product-Datasheet", Math.max(data.settings.margin.left, pdf_config.headline_left), pdf_config.page_margin_header_top);

                    // Footer
                    var str = "Page " + doc.internal.getNumberOfPages()
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof doc.putTotalPages === 'function') {
                        str = str + " of " + totalPagesExp;
                    }
                    doc.setFontSize(10);
                    doc.text(str, data.settings.margin.left, pageHeight - pdf_config.page_margin_bottom);   // left align
                    //                    doc.text(str, (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(str) * doc.internal.getFontSize() / 2), pageHeight - pdf_config.page_margin_bottom); // (center align), 'center');
                },
                margin: {
                    top: pdf_config.page_margin_top
                }

            })
            finalY = doc.lastAutoTable.finalY

        });*/

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp)
        }
        doc.save(item[this.props.label_field] + ".pdf");
    }

    render() {
        //const color_config = this.props.color_config;
        return (
            <MDBTooltip
                domElement
                tag="span"
                placement="top"
            >
                <button
                    className="btn-xs article-button pdf-button"
                    //style={{ backgroundColor: color_config.view_button_color }}
                    onClick={() => this.generatePDF()}
                >
                    <MDBIcon icon="file-pdf" className="pdf-icon" />
                </button>
                <span>{translate("download_pdf")}</span>
            </MDBTooltip>
        );
    }
}

export default PDF;