import React, { Component } from "react";
import PropTypes from "prop-types";
import PictureModal from "./modals/PictureModal";
import { translate } from "../utils/Utils";
import { MDBIcon } from "mdbreact";
import { downloadReferenceFile } from "../utils/functions";
import PDFModal from "./modals/PDFModal";
import { checkReferenceFileIsPDF, getPDFFile } from "../utils/Webservice";

class ReferencePicturePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPictureModal: false,
            //url: "",
            fileLoaded: false,
            numPages: 0,
            pageNumber: 1,
            openPDFModal: false,
            pdfFile: null
        }
        this.closePictureModal = this.closePictureModal.bind(this);
        this.closePDFModal = this.closePDFModal.bind(this);
    }

    componentDidMount() {
        const { fileID } = this.props;
        checkReferenceFileIsPDF(fileID).then(resp => {
            if (resp) {
                let new_state = {
                    fileLoaded: true
                }
                getPDFFile(fileID).then(file => {
                    if (file) {
                        new_state.pdfFile = file;
                    }
                    this.setState(new_state);
                });
            } else {
                this.setState({
                    fileLoaded: true
                });
            }
        });
    }

    openPictureModal() {
        this.setState({
            openPictureModal: true
        });
    }

    openPDFModal() {
        this.setState({
            openPDFModal: true
        });
    }

    closePictureModal() {
        this.setState({
            openPictureModal: false
        });
    }

    closePDFModal() {
        this.setState({
            openPDFModal: false
        });
    }

    render() {
        const { src, fileID } = this.props;
        const { openPictureModal, fileLoaded, openPDFModal, pdfFile } = this.state;
        return (
            <div>
                {openPictureModal ?
                    <PictureModal
                        close={this.closePictureModal}
                        fileName={fileID}
                        src={src}
                    />
                    :
                    null
                }
                {pdfFile ?
                    <PDFModal
                        open={openPDFModal}
                        close={this.closePDFModal}
                        file={pdfFile}
                        fileID={fileID}
                    />
                    :
                    null
                }
                {fileLoaded ?
                    <div style={{ display: "grid", marginBottom: "20px" }}>
                        {pdfFile ?
                            <img
                                alt={fileID}
                                src={src}
                                onClick={() => this.openPDFModal()}
                                style={{ cursor: "pointer", maxHeight: "200px", maxWidth: "200px", margin: "auto" }}
                            />
                            :
                            <img
                                alt={fileID}
                                src={src}
                                //onClick={() => this.openPDFModal()}
                                style={{ cursor: "not-allowed", maxHeight: "200px", maxWidth: "200px", margin: "auto" }}
                            />
                        }
                        <div style={{ margin: "auto", marginTop: "10px" }}>
                            {pdfFile ?
                                <button
                                    className="btn btn-sm"
                                    onClick={() => this.openPDFModal()}
                                >
                                    <MDBIcon far icon="eye" style={{ marginRight: "5px" }} />
                                    {translate("show")}
                                </button>
                                :
                                null
                            }
                            <button
                                className="btn btn-sm"
                                onClick={() => downloadReferenceFile(fileID)}
                            >
                                <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                {translate("download")}
                            </button>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

ReferencePicturePreview.propTypes = {
    fileID: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
};

export default ReferencePicturePreview;