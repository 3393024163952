import { getChangeIndexFields } from "../utils/Utils";

const changeIndexFieldsReducer = (state = [], action) => {
    switch(action.type) {
        case 'ADD_CHANGE_INDEX_FIELD':
            state = getChangeIndexFields(state, action.payload.productID, action.payload.language, action.payload.is_subtable_field, action.payload.is_subtable_modal, action.payload.value, action.payload.field, action.payload.elastic_index_changes_structure, action.payload.elastic_index, action.payload.new_row, false, action.payload.is_new_product);
            console.log(state);
            return state;
        case 'SET_CHANGE_INDEX_FIELDS':
            state = action.payload;
            return state;
        case 'CLEAR_CHANGE_INDEX_FIELDS':
            state = [];
            return state;
        default:
            return state;
    }
}

export default changeIndexFieldsReducer;