import React, { Component } from "react";
import { MDBModal, MDBModalBody, MDBModalFooter } from "mdbreact";
import { translate } from "../utils/Utils";
import "../css/DeleteQuestionModal.css";

class DeleteQuestionModal extends Component {
    /*constructor(props) {
        super(props);

    }*/

    render() {
        return (
            <MDBModal
                isOpen
                toggle={() => this.props.closeModal()}
            >
                <MDBModalBody>
                    <div className="lbl">
                        {translate("are_you_sure")}
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.props.delete(this.props.data)}
                    >
                        {translate("yes")}
                    </button>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.props.closeModal()}
                    >
                        {translate("no")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        )
    }
}

export default DeleteQuestionModal;