import React, { Component } from "react";
import PropTypes from "prop-types";

class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            storeValue: this.props.value
        }
        this.timeout = 0;
    }

    componentDidUpdate() {
        if (this.state.storeValue !== this.props.value) {
            this.setState({
                value: this.props.value,
                storeValue: this.props.value
            });
        }
    }

    changeText(value) {
        const { timeoutObject, fieldID } = this.props;
        this.setState({
            value
        });
        if (timeoutObject && timeoutObject.active) {
            if (this.props.setWriting) {
                this.props.setWriting(true);
            }
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.props.changeText(value, fieldID);
                if (this.props.setWriting) {
                    this.props.setWriting(false);
                }
            }, timeoutObject.time ? timeoutObject.time : 500);
        } else {
            this.props.changeText(value, fieldID);
        }
    }

    render() {
        //console.log(this.props.isLoading);
        return (
            <input
                min={0}
                type={this.props.type}
                value={this.state.value}
                onChange={event => this.changeText(event.target.value)}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholder}
                className={this.props.placeholder ? "form-control search-text" : ""}
                disabled={this.props.isLoading}
            />
        )
    }
}

TextInput.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    changeText: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    timeoutObject: PropTypes.shape({ active: PropTypes.bool, time: PropTypes.number}),
    fieldID: PropTypes.string,
    setWriting: PropTypes.func,
    isLoading: PropTypes.bool
};

export default TextInput;