const searchQueryReducer = (state = {}, action) => {
    switch(action.type) {
        case 'SET_SEARCH_QUERY':
            //const dataField = action.payload.dataField;
            //const item_key = action.payload.value;
            let new_search_query = Object.assign({}, state);
            if (action.payload.range) {
                new_search_query[action.payload.dataField] = {
                    "range": {
                        "gte": action.payload.value[0],
                        "lte": action.payload.value[1]
                    }
                };
            } else {
                new_search_query[action.payload.dataField] = [action.payload.value];
            }
            state = Object.assign({}, new_search_query);
            return state;
        case 'SET_PRESELECTED_SEARCH_QUERY':
            state = action.payload;
            return state;
        case 'UPDATE_SEARCH_QUERY':
            const dataField = action.payload.dataField;
            const item_key = action.payload.value;
            let new_state = Object.assign({}, state);
            if (new_state.hasOwnProperty(dataField)) {
                if (new_state[dataField].includes(item_key)) {
                    let new_array = new_state[dataField].filter(key => key !== item_key);
                    /*let new_array = [];
                    new_state[dataField].map(key => {
                        if (key !== item_key) {
                            new_array.push(key);
                        }
                    });*/
                    if (!new_array.length) {
                        delete new_state[dataField];
                    } else {
                        new_state[dataField] = new_array;
                    }
                } else {
                    new_state[dataField].push(item_key);
                }
            } else {
                new_state[dataField] = [item_key];
            }
            state = Object.assign({}, new_state);
            //console.log(state);
            return state;
        case 'DELETE_SEARCH_QUERY':
            state = {};
            return state;
        case 'DELETE_SEARCH_QUERY_FIELD':
            let new_state_ = Object.assign({}, state);
            delete new_state_[action.payload];
            state = Object.assign({}, new_state_);
            //console.log(state);
            return state;
        case 'DELETE_SEARCH_QUERY_FIELDS':
            state = Object.assign({}, state);
            for (let key of action.payload) {
                delete state[key];
            };
            /*action.payload.map(key => {
                delete state[key];
            });*/
            return state;
        default:
            return state;
    }
}

export default searchQueryReducer;