import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Home from "./Home";
import {
    set_language, set_result_view, change_hits, set_portal_config, set_custom_table_templates,
    set_download_table_template, add_select_fields, set_select_fields, set_select_fields_loaded, set_active_section,
    set_menu_filter, resetStore, set_result_view_modal, set_active_section_modal, change_hits_modal,
    set_preselected_search_query, set_search_query
} from "./actions";
import {
    createCustomTableTemplates, getCheckedConfigValue, getColorConfig, getTableList, getEditList,
    getArticleRegisterTabList, getForeignkeyList, deepEqual
} from "./utils/Utils";
import i18n from "i18next";
import { loadSelectFieldsByID } from "./utils/Webservice";
import deepmerge from "deepmerge";
import { getMenuFilter } from "./utils/functions";

//var test = {};

const mapStateToProps = (state) => {
    return {
        portalConfig: state.portalConfig,
        language: state.language,
        selectFields: state.selectFields,
        selectFieldsLoaded: state.selectFieldsLoaded
    };
};

const mapDispatchToProps = () => {
    return {
        set_portal_config,
        set_custom_table_templates,
        set_language,
        change_hits,
        set_result_view,
        set_download_table_template,
        add_select_fields,
        set_select_fields,
        set_select_fields_loaded,
        set_active_section,
        set_menu_filter,
        resetStore,
        set_result_view_modal,
        set_active_section_modal,
        change_hits_modal,
        set_preselected_search_query,
        set_search_query
    };
};

class PortalStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataField_array: [],
            error: null
        }
        //this.initPortal();
    }

    static getDerivedStateFromError(error) {
        return {
            error: error.message,
        };
    }

    getSnapshotBeforeUpdate(prevProps) {
        //console.log(prevProps);
    }

    componentDidCatch(error, info) {
        console.log(error, JSON.stringify(info));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.initPortal();
    }

    initPortal() {
        if (Object.keys(this.props.portalConfig).length === 0) {
            console.log("setPortalStore");
            this.setPortalStore();
        } else {
            const portalConfig = this.props.portalConfig;
            i18n.changeLanguage(this.props.language);
            this.getSelectFields(portalConfig.field_list, portalConfig.languages, portalConfig.elastic_index_foreignkey, portalConfig.token, portalConfig.main_url, this.props.language, portalConfig.query_size, portalConfig.field_list_json);
        }
    }

    componentDidMount() {
        this.initPortal();
        /*if (Object.keys(this.props.portalConfig).length === 0) {
            console.log("setPortalStore");
            this.setPortalStore();
        } else {
            const portalConfig = this.props.portalConfig;
            i18n.changeLanguage(this.props.language);
            this.getSelectFields(portalConfig.field_list_json, portalConfig.field_list, portalConfig.languages, portalConfig.elastic_index_foreignkey, portalConfig.token, portalConfig.main_url, portalConfig.elastic_index, portalConfig.default_language, this.props.language, portalConfig.query_size);
        }*/
    }

    getSelectFields(field_list, languages, elastic_index_foreignkey, token, main_url, current_language, query_size, field_list_json, afterReset = false) {
        if (!this.props.selectFieldsLoaded || afterReset) {
            const foreignkey_array = field_list.filter(field => field.filter === "MultiDataList" || field.edit_type === "_Selection").map(field => field.dataField);
            if (!foreignkey_array.length) {
                this.props.set_select_fields_loaded();
            } else {
                //const edit_list = getEditList(field_list, field_list_json);
                //console.log(foreignkey_array);
                //console.log(edit_list);
                loadSelectFieldsByID(foreignkey_array, languages, main_url, elastic_index_foreignkey, token, current_language, query_size);
            }
        }
    }

    async fetchSelectFieldsByName(name, index, length, main_url, elastic_index, token) {
        console.log("fetchSelectFieldsByName")
        const url = main_url + elastic_index + "/_search";
        const query = {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_all": {}
                        }
                    ]
                }
            },
            "from": 0,
            "size": 10000
        };
        await axios({
            url: url,
            method: 'post',
            data: JSON.stringify(query),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + token
            }
        }).then(response => {
            const hits = response.data.hits.hits;
            for (let hit of hits) {
                if (hit._source[name] !== undefined && hit._source[name] !== null) {
                    let selectFields = this.props.selectFields;
                    if (selectFields.hasOwnProperty(name)) {
                        if (!selectFields[name].includes(hit._source[name])) {
                            this.props.add_select_fields(name, hit._source[name]);
                        }
                    } else {
                        selectFields[name] = [hit._source[name]];
                        this.props.set_select_fields(name, hit._source[name]);
                    }
                }
            }
            this.props.set_select_fields_loaded();
            if (index === length - 1) {
                this.props.set_select_fields_loaded();
            }
        }).catch(error => {
            console.log("fetchSelectFieldsByName: ", error);
        });
    }

    checkPreselectedFilter(field_list) {
        let set_filter = {};
        for (let field of field_list.filter(field => field.hasOwnProperty("preselected") && field["preselected"].length)) {
            set_filter[field.dataField] = field.preselected;
        }
        if (Object.keys(set_filter).length) {
            this.props.set_preselected_search_query(set_filter);
        }
    }

    setPortalStore(afterReset = false) {
        const checkedConfig = this.getCheckedConfig(this.props.config);
        this.props.set_portal_config(checkedConfig);
        this.props.set_custom_table_templates(createCustomTableTemplates(checkedConfig.field_list, checkedConfig.image_field));
        this.props.change_hits(checkedConfig.hits_shown);
        this.props.set_language(checkedConfig.default_language);
        i18n.changeLanguage(checkedConfig.default_language);
        this.props.set_result_view(checkedConfig.result_views[0]);
        this.props.set_download_table_template("default", getTableList(checkedConfig.field_list, checkedConfig.image_field));
        this.props.set_active_section(getArticleRegisterTabList(checkedConfig.field_list)[0]);
        this.props.set_menu_filter(getMenuFilter(checkedConfig.field_list));
        this.props.set_result_view_modal(checkedConfig.result_views[0]);
        this.props.set_active_section_modal(getArticleRegisterTabList(checkedConfig.field_list)[0]);
        this.props.change_hits_modal(checkedConfig.hits_shown);
        this.checkPreselectedFilter(checkedConfig.field_list);
        this.getSelectFields(checkedConfig.field_list, checkedConfig.languages, checkedConfig.elastic_index_foreignkey, checkedConfig.token, checkedConfig.main_url, checkedConfig.default_language, checkedConfig.query_size, checkedConfig.field_list_json, afterReset);
    }

    getCheckedConfig(config) {
        let checkedConfig = JSON.parse(JSON.stringify(config));
        checkedConfig.configID = config.configID;
        checkedConfig.color_config = getColorConfig(getCheckedConfigValue(config.color_config, "object", {}, "color_config"));
        if (typeof (config.default_filter) === "object" && !Array.isArray(config.default_filter)) {
            checkedConfig.default_filter = getCheckedConfigValue(config.default_filter, "object", { "match_all": {} }, "default_filter");
        } else {
            checkedConfig.default_filter = getCheckedConfigValue(config.default_filter, "array", [{ "default": { "match_all": {} } }], "default_filter");
        }
        //checkedConfig.default_filter1 = getCheckedConfigValue(config.default_filter1, "object", { "match_all": {} }, "default_filter1");
        //checkedConfig.default_filter = getCheckedConfigValue(config.default_filter, "array", [{ "default": { "match_all": {} } }], "default_filter");
        checkedConfig.field_extension = getCheckedConfigValue(config.field_extension, "string", "keyword", "field_extension");
        checkedConfig.default_language = getCheckedConfigValue(config.default_language, "language", "de", "default_language");
        checkedConfig.elastic_index = getCheckedConfigValue(config.elastic_index, "elastic_index", "", "elastic_index");
        checkedConfig.hits_options = getCheckedConfigValue(config.hits_options, "number_array", [10], "hits_options");
        checkedConfig.hits_shown = getCheckedConfigValue(config.hits_shown, "number", 10, "hits_shown");
        checkedConfig.result_views = getCheckedConfigValue(config.result_views, "view_object", ["grid", "list", "table", "custom"], "result_views");
        checkedConfig.tab_field = getCheckedConfigValue(config.tab_field, "string", "ID", "tab_field");
        checkedConfig.main_url = getCheckedConfigValue(config.main_url, "url", config.main_url, "main_url");
        checkedConfig.keyfield = getCheckedConfigValue(config.keyfield, "string", "ID", "keyfield");
        checkedConfig.tab_field_length = getCheckedConfigValue(config.tab_field_length, "number", 20, "tab_field_length");
        checkedConfig.show_account = getCheckedConfigValue(config.show_account, "bool_as_int", 0, "show_account");
        checkedConfig.show_basket = getCheckedConfigValue(config.show_basket, "bool_as_int", 0, "show_basket");
        checkedConfig.show_comparelist = getCheckedConfigValue(config.show_comparelist, "bool_as_int", 0, "show_comparelist");
        checkedConfig.show_login = getCheckedConfigValue(config.show_login, "bool_as_int", 0, "show_login");
        checkedConfig.languages = getCheckedConfigValue(config.languages, "array", ["de", "en", "fr"], "languages");
        checkedConfig.show_edit = getCheckedConfigValue(config.show_edit, "bool_as_int", 0, "show_edit");
        checkedConfig.elastic_index_foreignkey = getCheckedConfigValue(config.elastic_index_foreignkey, "string", config.elastic_index_foreignkey, "elastic_index_foreignkey");
        checkedConfig.elastic_index_changes = getCheckedConfigValue(config.elastic_index_changes, "string", config.elastic_index_changes, "elastic_index_changes");
        checkedConfig.token = getCheckedConfigValue(config.token, "string", config.token, "token");
        checkedConfig.image_base_url = getCheckedConfigValue(config.image_base_url, "url", config.image_base_url, "image_base_url");
        checkedConfig.image_field = getCheckedConfigValue(config.image_field, "string", "ID", "image_field");
        checkedConfig.link_base_url = getCheckedConfigValue(config.link_base_url, "url", config.link_base_url, "link_base_url"); //aktuell nicht genutzt
        checkedConfig.link_field = getCheckedConfigValue(config.link_field, "string", config.link_field, "link_field"); //aktuell nicht genutzt
        checkedConfig.basket_link2_function = getCheckedConfigValue(config.basket_link2_function, "string", config.basket_link2_function, "basket_link2_function"); //aktuell nicht genutzt
        checkedConfig.basket_add2_function = getCheckedConfigValue(config.basket_add2_function, "string", config.basket_add2_function, "basket_add2_function"); //aktuell nicht genutzt
        checkedConfig.field_list = getCheckedConfigValue(config.field_list, "array", [], "field_list");
        checkedConfig.label_field = getCheckedConfigValue(config.label_field, "string", "ID", "label_field");
        checkedConfig.reference_linkfields = getCheckedConfigValue(config.reference_linkfields, "array", [], "reference_linkfields");
        checkedConfig.reference_url = getCheckedConfigValue(config.reference_url, "url", config.reference_url, "reference_url");
        checkedConfig.reference_idfield = getCheckedConfigValue(config.reference_idfield, "string", "ID", "reference_idfield");
        checkedConfig.reference_password = getCheckedConfigValue(config.reference_password, "string", config.reference_password, "reference_password");
        checkedConfig.reference_user = getCheckedConfigValue(config.reference_user, "string", config.reference_user, "reference_user");
        checkedConfig.label_field_length = getCheckedConfigValue(config.label_field_length, "number", 20, "label_field_length");
        checkedConfig.reference_sortorder = getCheckedConfigValue(config.reference_sortorder, "string", config.reference_sortorder, "reference_sortorder");
        checkedConfig.price_field = getCheckedConfigValue(config.price_field, "string", "ID", "price_field");
        checkedConfig.show_details = getCheckedConfigValue(config.show_details, "bool_as_int", 0, "show_details");
        checkedConfig.button_field = getCheckedConfigValue(config.button_field, "string", "ID", "button_field");
        checkedConfig.show_excel = getCheckedConfigValue(config.show_excel, "bool_as_int", 0, "show_excel");
        checkedConfig.show_pdf = getCheckedConfigValue(config.show_pdf, "bool_as_int", 0, "show_pdf");
        checkedConfig.custom_language = getCheckedConfigValue(config.custom_language, "object", {}, "custom_language");
        checkedConfig.pdf_config = getCheckedConfigValue(config.pdf_config, "object", {}, "pdf_config");
        checkedConfig.logo = getCheckedConfigValue(config.logo, "suffix_or_logo", ".png", "logo"); //aktuell nicht genutzt
        checkedConfig.foreignkey_reference2main = getCheckedConfigValue(config.foreignkey_reference2main, "string", config.foreignkey_reference2main, "foreignkey_reference2main");
        checkedConfig.elastic_index_changes_structure = getCheckedConfigValue(config.elastic_index_changes_structure, "object", {}, "elastic_index_changes_structure");
        checkedConfig.foreignkey_reference2changes = getCheckedConfigValue(config.foreignkey_reference2changes, "string", config.foreignkey_reference2changes, "foreignkey_reference2changes");
        checkedConfig.foreignkey_show_field = getCheckedConfigValue(config.foreignkey_show_field, "string", config.foreignkey_show_field, "foreignkey_show_field");
        checkedConfig.filter_unit = getCheckedConfigValue(config.filter_unit, "string", config.filter_unit, "filter_unit");
        checkedConfig.multiselect_separator = getCheckedConfigValue(config.multiselect_separator, "string", config.multiselect_separator, "multiselect_separator");
        checkedConfig.datetimeformat = getCheckedConfigValue(config.datetimeformat, "string", "YYYY-MM-DD hh:mm:ss", "datetimeformat");
        checkedConfig.dateformat = getCheckedConfigValue(config.dateformat, "string", "YYYY-MM-DD", "dateformat");
        checkedConfig.field_list_mapping = getCheckedConfigValue(config.field_list_mapping, "array", [], "field_list_mapping");
        checkedConfig.field_list_json = getCheckedConfigValue(config.field_list_json, "array", [], "field_list_json");
        checkedConfig.bucket_size = getCheckedConfigValue(config.bucket_size, "string", "100", "bucket_size", "100");
        checkedConfig.query_size = getCheckedConfigValue(config.query_size, "string", "5000", "query_size", "10000");
        checkedConfig.viewport_desktop = getCheckedConfigValue(config.viewport_desktop, "string", "1250", "viewport_desktop");
        checkedConfig.viewport_mobile = getCheckedConfigValue(config.viewport_mobile, "string", "850", "viewport_mobile");
        checkedConfig.show_version_info = getCheckedConfigValue(config.show_version_info, "version", "0", "show_version_info");
        //checkedConfig.grid_fancy_fields = getCheckedConfigValue(config.grid_fancy_fields, "array", [], "grid_fancy_fields");
        checkedConfig.grid_fancy_field_length = getCheckedConfigValue(config.grid_fancy_field_length, "number", 15, "grid_fancy_field_length");
        checkedConfig.show_max_facet_items = getCheckedConfigValue(config.show_max_facet_items, "number", 5, "show_max_facet_items");
        checkedConfig.reference_image_config = getCheckedConfigValue(config.reference_image_config, "object", {}, "reference_image_config");
        checkedConfig.open_all_sections_button = getCheckedConfigValue(config.open_all_sections_button, "bool_as_int", 1, "open_all_sections_button");
        checkedConfig.show_user_button = getCheckedConfigValue(config.show_user_button, "bool_as_int", 0, "show_user_button");
        checkedConfig.show_advanced_search = getCheckedConfigValue(config.show_advanced_search, "bool_as_int", 0, "show_advanced_search");
        checkedConfig.fuzziness = getCheckedConfigValue(config.fuzziness, "bool_as_int", 0, "fuzziness");
        checkedConfig.searchbar_live_search = getCheckedConfigValue(config.searchbar_live_search, "bool_as_int", 1, "searchbar_live_search");
        checkedConfig.query_format = getCheckedConfigValue(config.query_format, "string", "and", "query_format");
        checkedConfig.show_creation_date = getCheckedConfigValue(config.show_creation_date, "bool_as_int", 1, "show_creation_date");
        checkedConfig.show_button_reference_subtable = getCheckedConfigValue(config.show_button_reference_subtable, "bool_as_int", 1, "show_button_reference_subtable");
        checkedConfig.config_filetypes = getCheckedConfigValue(config.config_filetypes, "object", {
            "preview": ["pdf"],
            "download": []
        }, "config_filetypes");
        checkedConfig.show_pdf_preview = getCheckedConfigValue(config.show_pdf_preview, "bool_as_int", 0, "show_pdf_preview");
        return checkedConfig;
    }

    render() {
        const { language, selectFields, selectFieldsLoaded, portalConfig, config } = this.props;
        //console.log("portalstore", this.props);
        //console.log(language);
        //console.log(selectFields);
        //console.log(selectFieldsLoaded);
        if (Object.keys(portalConfig).length > 0 && config.configID !== portalConfig.configID) {
            this.props.resetStore();
            this.setPortalStore(true);
        } else if (!Object.keys(portalConfig).length) {
            return <span>Loading...</span>;
        }
        if (Object.keys(portalConfig).length > 0 /*&& window.navigator.onLine && fetchedProducts.length*/) {
            if (this.state.error) {
                return <div>Ein Fehler ist aufgetreten: {this.state.error}</div>;
            } else {
                return <Home />;
            }
        }
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(PortalStore);