const portalConfigReducer = (state = {}, action) => {
    switch(action.type) {
        case 'SET_PORTAL_CONFIG':
            state = JSON.parse(JSON.stringify(action.payload));
            return state;
        default:
            return state;
    }
}

export default portalConfigReducer;