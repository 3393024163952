import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/SearchMultiList.css";
import "../Home.css";
import PropTypes from "prop-types";
import SearchMultiList from "./SearchMultiList";

const mapStateToProps = (state) => {
    return {
        hiddenFacets: state.hiddenFacets
    };
};

const mapDispatchToProps = () => {
    return {

    };
};

class HiddenFacet extends Component {
    render() {
        const { field, isNewReferenceModal, isFacetModal } = this.props;
        if (this.props.hiddenFacets.includes(field.dataField)) {
            return (
                <SearchMultiList
                    key={field.text + "_facet" + (isNewReferenceModal ? "_modal" : "")}
                    field={field}
                    emptyComponent
                    isFacetModal={isFacetModal}
                    isNewReferenceModal={isNewReferenceModal}
                />
            );
        } else {
            return null;
        }
    }
}

HiddenFacet.propTypes = {
    isOpen: PropTypes.bool,
    field: PropTypes.object.isRequired,
    emptyComponent: PropTypes.bool,
    isFacetModal: PropTypes.bool,
    isNewReferenceModal: PropTypes.bool,
    handleFacetClick: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps())(HiddenFacet);