const openNewCopiedProductModalReducer = (state = false, action) => {
    switch(action.type) {
        case 'CHANGE_NEW_COPIED_PRODUCT_MODAL_STATUS':
            state = !state;
            return state;
        default:
            return state;
    }
}

export default openNewCopiedProductModalReducer;