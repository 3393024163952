import React, { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { translate } from "../../utils/Utils";
import "../../Home.css";

class ResetReferencesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    closeModal() {
        this.props.close(this.props.modalID);
    }

    setNewReferences() {
        this.closeModal();
        this.props.setNewReferences(true);
    }

    openNewReferences() {
        this.closeModal();
        this.props.openNewReferenceModal();
    }

    render() {
        const { isOpen } = this.props;
        return (
            <MDBModal
                className="reset-references-modal"
                isOpen={isOpen}
                toggle={() => this.closeModal()}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                />
                <MDBModalBody className="rrm-body">
                    <div>
                        {translate("save_or_discard_references")}
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.setNewReferences()}
                        >
                            {translate("save")}
                        </button>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.openNewReferences()}
                        >
                            {translate("discard")}
                        </button>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

export default ResetReferencesModal;