import React, { Component } from "react";
import { ResultList } from "@appbaseio/reactivesearch";
import {
    MDBRow, MDBTable, MDBTableBody, MDBCol
} from "mdbreact";
import {
    getButtons, openArticleDetails, checkPicture, getTableText, getField
} from "../../utils/Utils";
import PropTypes from "prop-types";
import store from "../../store/store";
import "../../Home.css";
import PicturePreview from "../PicturePreview";

class ListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    openArticleDetails(tagName) {
        const { isNewReferenceModal, item } = this.props;
        const portalConfig = store.getState().portalConfig;
        const { keyfield } = portalConfig;
        if (tagName === "DIV") {
            openArticleDetails("", item[keyfield], isNewReferenceModal);
        }
    }

    render() {
        const { isNewReferenceModal, item, listItemFields } = this.props;
        const { portalConfig, edit } = store.getState();
        const { image_field, keyfield } = portalConfig;
        return (
            <ResultList key={item._id} className="resultlist">
                {image_field === "" ?
                    <ResultList.Image
                        className="resultlist-img-sm"
                    >
                        <MDBRow center>
                            <div className="image-buttons-alone list-button-field">
                                {getButtons(item[keyfield], this.props.notify, "buttons-td", isNewReferenceModal)}
                            </div>
                        </MDBRow>
                    </ResultList.Image>
                    :
                    !image_field.includes(":") ?
                        <div>
                            <PicturePreview
                                isNewReferenceModal={isNewReferenceModal}
                                item={item}
                                view="list"
                            />
                            <div className="list-button-field" onClick={(event) => this.openArticleDetails(event.target.tagName)}>
                                {getButtons(item[keyfield], this.props.notify, "buttons-td", isNewReferenceModal)}
                            </div>
                        </div>
                        :
                        <div>
                            <div
                                className={this.isNavMobileSize || isNewReferenceModal ? "loaded-img-sm" : ""}
                                onClick={(event) => openArticleDetails(event, item[keyfield], isNewReferenceModal)}
                            >
                                {checkPicture(item, isNewReferenceModal)}
                            </div>
                            <div className="list-button-field" style={{ marginTop: "5px" }} onClick={(event) => this.openArticleDetails(event.target.tagName)}>
                                {getButtons(item[keyfield], this.props.notify, "buttons-td", isNewReferenceModal)}
                            </div>
                        </div>
                }
                <ResultList.Content
                    className="content-fill"
                >
                    <ResultList.Description>
                        <MDBTable>
                            <MDBTableBody>
                                {listItemFields.map(field => {
                                    const value = item[field.dataField];
                                    if (value && ((Array.isArray(value) ? value.length : value) || typeof (value) === "boolean") || edit) {
                                        const text = field.text && field.text.replace(/\s/g, "") ? field.text : field.dataField;
                                        return (
                                            <MDBRow className="result-list-body">
                                                <MDBCol className="col content" size="3">
                                                    {getTableText(text) + ":"}
                                                </MDBCol>
                                                <MDBCol className="overflow-auto content" size="9">
                                                    {portalConfig.elastic_index.includes("_bios_") && field.edit_depends_on && item["IsReadOnly"] ?
                                                        item[field.dataField]
                                                        :
                                                        getField(item, field, value, false, isNewReferenceModal)
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        );
                                    }
                                    return null;
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </ResultList.Description>
                </ResultList.Content>
            </ResultList>
        );
    }
}

ListItem.propTypes = {
    item: PropTypes.object.isRequired,
    isNewReferenceModal: PropTypes.bool,
    notify: PropTypes.func.isRequired,
    listItemFields: PropTypes.array.isRequired
};

export default ListItem;