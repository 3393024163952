const editCheckboxTypeReducer = (state = null, action) => {
    switch(action.type) {
        case 'SET_EDIT_CHECKBOX_TYPE':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default editCheckboxTypeReducer;