import React, { Component } from "react";
import PropTypes from "prop-types";

class RequiredTextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
        this.timeout = 0;
    }

    changeText(value) {
        this.setState({
            value
        });
        if (!this.state.value && value) {
            this.props.changeValidation(1, this.props.fieldKey, value);
        } else if (this.state.value && !value) {
            this.props.changeValidation(-1, this.props.fieldKey, value);
        } else {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.props.changeValidation(0, this.props.fieldKey, value);
            }, 500);
        }
    }

    render() {
        return (
            <div class="form-outline">
                <input
                    type="text"
                    className={this.state.value ? "form-control" : "form-control is-invalid"}
                    //class="form-control is-invalid"
                    id={this.props.inputID + "-required-input"}
                    //required={!this.state.value}
                    //onChange={}
                    onChange={event => this.changeText(event.target.value)}
                />
            </div>
        );
    }
}

RequiredTextInput.propTypes = {
    //close: PropTypes.func.isRequired,
    //fileName: PropTypes.string.isRequired
};

export default RequiredTextInput;