import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { translate } from "../utils/Utils";
import "../css/DragDropDoubleList.css";

const grid = 8;

class DragDropDoubleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_fields: this.props.selectedFields,
            available_fields: this.props.fieldListFields
        };
        this.id2List = {
            droppable: "selected_fields",
            droppable2: "available_fields"
        };
    }

    /**
    * Moves an item from one list to another list.
    */
    move(source, destination, droppableSource, droppableDestination) {
        //console.log(destination);
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        //console.log(removed);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        result.removedItem = removed;
        //console.log(sourceClone);
        //console.log(destClone);
        return result;
    };

    // a little function to help us with reordering the result
    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    getItemStyle(key, isDragging, draggableStyle) {
        return {
            userSelect: 'none',
            padding: grid * 2,
            margin: `0 0 ${grid}px 0`,
            background: isDragging ? "lightgreen" : key === "actions_field" ? "#c5ffbc" : "white",
            ...draggableStyle
        }
    }

    getListStyle(isDraggingOver) {
        return {
            background: isDraggingOver ? 'lightgrey' : 'lightgrey',
            padding: grid
        }
    }

    getList(id) {
        return this.state[this.id2List[id]];
    }

    onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) { // dropped outside the list
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const selected_fields = this.reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );
            let state = { selected_fields };
            //console.log(state);
            if (source.droppableId === "droppable2") {
                state = { available_fields: selected_fields };
                //console.log(state);
            } else {
                this.props.handleDragDrop(selected_fields);
            }
            this.setState(state);
        } else {
            const result = this.move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );
            this.setState({
                selected_fields: result.droppable,
                available_fields: result.droppable2
            });

            this.props.handleDragDrop(result.droppable, result.removedItem);
        }
    };

    getDraggableElement(item, index) {
        const key = item.dataField;
        const text = key === "actions_field" ? translate(key) : item.text;
        //console.log(text);
        return (
            <Draggable
                key={key}
                draggableId={key}
                index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(key, snapshot.isDragging, provided.draggableProps.style)}
                    >
                        {text}
                    </div>
                )}
            </Draggable>
        );
    }

    render() {
        //console.log(this.state.available_fields);
        return (
            <DragDropContext onDragEnd={result => this.onDragEnd(result)}>
                <MDBContainer fluid>
                    <MDBRow>
                    <MDBCol className="drag-drop-border-left">
                    <div className="drag-drop-lbl">
                                {translate("selected_fields")}
                            </div>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                className="drag-drop-list"
                                ref={provided.innerRef}
                                style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.selected_fields.map((item, index) => (
                                    this.getDraggableElement(item, index)
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    </MDBCol>
                    <MDBCol className="drag-drop-border-right">
                    <div className="drag-drop-lbl">
                                {translate("available_fields")}
                            </div>
                    <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                            <div
                                className="drag-drop-list"
                                ref={provided.innerRef}
                                style={this.getListStyle(snapshot.isDraggingOver)}>
                                {this.state.available_fields.map((item, index) => (
                                    this.getDraggableElement(item, index)
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </DragDropContext>
        );
    }
}

export default DragDropDoubleList;