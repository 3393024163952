const selectFieldsLoadedReducer = (state = false, action) => {
    switch(action.type) {
        case 'SET_SELECT_FIELDS_LOADED':
            state = true;
            return state;
        default:
            return state;
    }
}

export default selectFieldsLoadedReducer;