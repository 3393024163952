import React, { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTooltip, MDBIcon } from "mdbreact";
import { downloadFileFromServer, translate } from "../../utils/Utils";
import PropTypes from "prop-types";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import store from "../../store/store";
import { downloadReferenceFile } from "../../utils/functions";

class PDFModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 0,
            pageNumber: 1,
            file: this.props.fileName ? store.getState().portalConfig.image_base_url + this.props.fileName : this.props.file
        }
    }

    closeModal() {
        this.props.close();
    }

    onDocumentLoadSuccess(pdf) {
        this.setState({
            numPages: pdf.numPages
        });
    }

    changePage(offset) {
        this.setState({
            pageNumber: this.state.pageNumber + offset
        });
    }

    render() {
        const { pageNumber, numPages, file } = this.state;
        const { fileName, fileID, open } = this.props;
        return (
            <MDBModal
                size="lg"
                isOpen={open}
                toggle={() => this.closeModal()}
                className="pdf-modal"
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    <div className="pdf-modal-head-div">
                        {fileName ?
                            <MDBTooltip
                                domElement
                                tag="span"
                                placement="top"
                            >
                                <span>
                                    <button
                                        disabled={!numPages}
                                        className="btn btn-sm"
                                        onClick={() => downloadFileFromServer(fileName)}
                                    >
                                        <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                        {translate("download")}
                                    </button>
                                </span>
                                <span>{fileName}</span>
                            </MDBTooltip>
                            :
                            <button
                                disabled={!numPages}
                                className="btn btn-sm"
                                onClick={() => downloadReferenceFile(fileID)}
                            >
                                <MDBIcon icon="download" style={{ marginRight: "5px" }} />
                                {translate("download")}
                            </button>
                        }
                        <div className="page-controls-head">
                            <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={() => this.changePage(-1)}
                            >
                                {"<"}
                            </button>
                            <span>
                                {pageNumber + " / " + numPages}
                            </span>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={() => this.changePage(1)}
                            >
                                {">"}
                            </button>
                        </div>
                    </div>
                </MDBModalHeader>
                <MDBModalBody>
                    <Document
                        file={file}
                        onLoadSuccess={pdf => this.onDocumentLoadSuccess(pdf)}
                        onLoadError={(error) => alert('Error while loading document! ' + error)}
                        onSourceError={(error) => alert('Error while retrieving document source! ' + error)}
                    >
                        <Page pageNumber={pageNumber} />
                        <div className="page-controls">
                            <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={() => this.changePage(-1)}
                            >
                                {"<"}
                            </button>
                            <span>
                                {pageNumber + " / " + numPages}
                            </span>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={() => this.changePage(1)}
                            >
                                {">"}
                            </button>
                        </div>
                    </Document>
                </MDBModalBody>
                <MDBModalFooter>

                </MDBModalFooter>
            </MDBModal>
        );
    }
}

PDFModal.propTypes = {
    close: PropTypes.func.isRequired,
    fileName: PropTypes.string,
    fileID: PropTypes.string,
    open: PropTypes.bool.isRequired,
    file: PropTypes.instanceOf(File)
};

export default PDFModal;