import React, { Component } from "react";
import {
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTable, MDBTableBody, MDBTableHead, MDBCol, MDBRow,
    MDBContainer
} from "mdbreact";
import { connect } from "react-redux";
import { set_shopping_cart_open } from "../actions";
import QuantityDropdown from "./QuantityDropdown";
import {
    getBasketList, translate, createHTMLElement, getPicture, getField, getProductByID, checkPicture
} from "../utils/Utils";
import "../css/ShoppingCart.css";
import ExcelButton from "./ExcelButton";
import PicturePreview from "./PicturePreview";

const mapStateToProps = (state) => {
    return {
        shoppingCart: state.shoppingCart,
        shoppingCartOpen: state.shoppingCartOpen,
        //language: state.language,
        selectFields: state.selectFields,
        fetchedProducts: state.fetchedProducts,
        portalConfig: state.portalConfig
    };
};

const mapDispatchToProps = () => {
    return {
        set_shopping_cart_open
    };
};

class ShoppingCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shoppingCart: this.props.shoppingCart,
            modal: false,
            basket_list: getBasketList()
        }
        this.isNavMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
        this.updateShoppingCart = this.updateShoppingCart.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    getSum() {
        const shoppingCart = this.props.shoppingCart;
        let sum = 0;
        for (let shoppingCartObject of shoppingCart) {
            const product = getProductByID(this.props.fetchedProducts, shoppingCartObject.id_);
            sum += (this.getPrice(product[this.props.portalConfig.price_field]) * shoppingCartObject.quantity);
        };
        /*shoppingCart.map(shoppingCartObject => {
            const product = getProductByID(this.props.fetchedProducts, shoppingCartObject.id_);
            sum += (this.getPrice(product[this.props.portalConfig.price_field]) * shoppingCartObject.quantity);
        });*/
        return sum;
    }

    updateShoppingCart() {
        this.setState({
            shoppingCart: this.props.shoppingCart
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    getQuantity() {
        const shoppingCart = this.props.shoppingCart;
        let quantity = 0;
        for (let shoppingCartObject of shoppingCart) {
            quantity += parseInt(shoppingCartObject.quantity);
        };
        /*shoppingCart.map(shoppingCartObject => {
            quantity += parseInt(shoppingCartObject.quantity);
        });*/
        return quantity;
    }

    getPrice(item) {
        if (item === null || item === undefined) {
            return 0;
        }
        if (item.includes(translate("currency"))) {
            return item.substring(0, item.length - 2);
        }
        return item;
    }

    createField(item, field) {
        const { portalConfig } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        //const htmlElement = createHTMLElement(item, getFieldData(field), reference_user, reference_password, reference_url);
        //arr = htmlElement.arr;
        if (typeof (field) === "object" && field.dataField.includes(":JSON")) {
            return null;
        }
        let value = item[field.dataField];
        if (field.dataField.includes(":REFERENCE")) {
            const htmlElement = createHTMLElement(item, field.dataField, reference_user, reference_password, reference_url);
            //arr = htmlElement.arr;
            value = htmlElement.html.__html;
        }
        return getField(item, field, value, false, true);
    }

    checkPicture(item) {
        const { portalConfig } = this.props;
        const { image_field, image_base_url, image_suffix, reference_user, reference_password, reference_url } = portalConfig;
        //const item = this.product;
        if (!image_field.includes(":REFERENCE")) {
            return (
                <img
                    alt=""
                    className={this.isNavMobileSize ? "img-transform img" : "img"}
                    //className="img" 
                    src={getPicture(item, "no_image_found.png", image_field, image_base_url, image_suffix)}
                />
            );
        } else {
            const htmlElement = createHTMLElement(item, image_field, reference_user, reference_password, reference_url);
            //arr = htmlElement.arr;
            return (
                <div className={this.isNavMobileSize ? "loaded-img-sm" : ""}>
                    <div dangerouslySetInnerHTML={htmlElement.html} />
                </div>
            );
        }
    }

    render() {
        const shoppingCart = this.props.shoppingCart;
        if (shoppingCart.length === 0) {
            return null;
        } else {
            const portalConfig = this.props.portalConfig;
            const price_field = portalConfig.price_field;
            const image_field = portalConfig.image_field;
            const sum = this.getSum();
            const quantity = this.getQuantity();
            return (
                <MDBModal
                    //size="fluid"
                    className="shopping-cart-modal"
                    fullHeight
                    position="top"
                    isOpen={this.props.shoppingCartOpen}
                    toggle={() => this.props.set_shopping_cart_open()}
                >
                    <MDBModalHeader
                        toggle={() => this.props.set_shopping_cart_open()}
                    >
                        {translate("basket")}
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer fluid className="overflow-auto">
                            <MDBRow>
                                <MDBCol size="12">
                                    <MDBTable>
                                        <MDBTableHead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th />
                                                <th />
                                                <th className="th-size">
                                                    {translate("price")}
                                                </th>
                                                <th className="th-size">
                                                    {translate("amount")}
                                                </th>
                                                <th className="th-size">
                                                    {translate("total_price")}
                                                </th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {shoppingCart.map((shoppingCartObject, counter = 0) => {
                                                const product = getProductByID(this.props.fetchedProducts, shoppingCartObject.id_);
                                                return (
                                                    <tr>
                                                        <td className="td-pos">
                                                            {counter + 1}
                                                        </td>
                                                        <td>
                                                            {image_field.includes(":") ?
                                                                <div className={this.isNavMobileSize ? "img-transform" : ""}>
                                                                    {checkPicture(product)}
                                                                    {/*this.checkPicture(product)*/}
                                                                    {/*this.createField(product)*/}
                                                                </div>
                                                                :
                                                                <PicturePreview
                                                                    //isNewReferenceModal={isNewReferenceModal}
                                                                    item={product}
                                                                    view="shopping-cart"
                                                                />
                                                                //<img alt="" className={this.isNavMobileSize ? "img-transform img" : "img"} src={getPicture(product, "no_image_found.png", image_field, portalConfig.image_base_url, portalConfig.image_suffix)} />
                                                            }
                                                        </td>
                                                        <td style={{ minWidth: "400px" }}>
                                                            {this.state.basket_list.map(field => (
                                                                <MDBRow>
                                                                    <MDBCol>
                                                                        <text style={{ fontWeight: "500" }}>
                                                                            {field.text + ":"}
                                                                        </text>

                                                                    </MDBCol>
                                                                    <MDBCol className="list-value">
                                                                        {this.createField(product, field)}
                                                                    </MDBCol>
                                                                </MDBRow>
                                                            ))}
                                                        </td>
                                                        <td className="td-pos">
                                                            {this.getPrice(product[price_field])} {translate("currency")}
                                                        </td>
                                                        <td className="td-pos">
                                                            <QuantityDropdown item={shoppingCartObject} index={counter++} updateShoppingCart={this.updateShoppingCart} />
                                                        </td>
                                                        <td className="td-pos">
                                                            {(this.getPrice(product[price_field]) * shoppingCartObject.quantity).toFixed(2)} {translate("currency")}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                {quantity > 1 ?
                                                    <td className="table-foot">
                                                        {translate("sum")} ({quantity} {translate("products")}) :
                                                    </td>
                                                    :
                                                    <td className="table-foot">
                                                        {translate("sum")} ({quantity} {translate("product")}) :
                                                    </td>
                                                }
                                                <td className="table-foot">
                                                    {sum.toFixed(2)} {translate("currency")}
                                                </td>
                                            </tr>
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.toggle()}
                        >
                            {translate("order")}
                        </button>
                        <button
                            className="btn btn-sm"
                            onClick={() => this.props.set_shopping_cart_open()}
                        >
                            {translate("close")}
                        </button>
                    </MDBModalFooter>
                    {!this.state.modal ? null :
                        <MDBModal isOpen toggle={() => this.toggle()}>
                            <MDBModalHeader toggle={() => this.toggle()} />
                            <MDBModalBody className="download-excel-body">
                                {translate("download_excel", "article")}
                            </MDBModalBody>
                            <MDBModalFooter>
                                {//<button>excel</button>
                                }
                                {<ExcelButton
                                    buttonID="shopping-cart-excel-button"
                                    usage="basket"
                                    portalConfigPriceField={this.props.portalConfig.price_field} //falls usage = basket
                                    excelTableProductsIDs={this.props.shoppingCart}
                                    fetchedProducts={this.props.fetchedProducts}
                                    portalConfigKeyfield={this.props.portalConfig.keyfield}
                                    excelTableFields={this.state.basket_list}
                                />}
                                <button
                                    className="btn btn-sm"
                                    onClick={() => this.toggle()}
                                >
                                    {translate("no")}
                                </button>
                            </MDBModalFooter>
                        </MDBModal>
                    }
                </MDBModal>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(ShoppingCart);