const saveChangesReducer = (state = false, action) => {
    switch(action.type) {
        case 'SWITCH_SAVE_CHANGES':
            state = !state;
            return state;
        default:
            return state;
    }
}

export default saveChangesReducer;