import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import {
    sortKeyfield, translate, addToFetchedProducts, openArticleDetails, addToDownloadTable, openShoppingCartModal,
    addToCompareList, saveEditStore, getProductByID, deepEqual
} from "../utils/Utils";
import {
    set_active_article, set_shopping_cart_modal, add_product, set_shopping_cart_modal_item, add_compare_item,
    update_product, set_result_view_modal, set_active_section_modal, reset_new_reference_modal_props,
    set_selected_references, set_reference_modal_product_id, set_reference_modal_product_field,
    set_reference_modal_reference_object
} from "../actions";
import "../Home.css";
import { loadReferenceProducts } from "../utils/Webservice";
import SimpleModal from "./modals/SimpleModal";
import NewReferenceModal from "./modals/NewReferenceModal";
import ResetReferencesModal from "./modals/ResetReferencesModal";

const mapStateToProps = (state) => {
    return {
        articleTabs: state.articleTabs,
        fetchedProducts: state.fetchedProducts,
        compareItems: state.compareItems,
        language: state.language,
        portalConfig: state.portalConfig,
        edit: state.edit,
        editedFields: state.editedFields,
        newReferenceModalProps: state.newReferenceModalProps
    };
};

const mapDispatchToProps = () => {
    return {
        set_active_article,
        set_shopping_cart_modal,
        set_shopping_cart_modal_item,
        add_product,
        add_compare_item,
        update_product,
        set_result_view_modal,
        set_active_section_modal,
        reset_new_reference_modal_props,
        set_selected_references,
        set_reference_modal_product_id,
        set_reference_modal_product_field,
        set_reference_modal_reference_object
    };
};

class ArticleReferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showReferences: this.props.isNewReferenceModal ? true : false,
            fetchNewProducts: true,
            deleteReferenceModal: false,
            deleteReferenceProductID: "",
            referenceProducts: [],
            referenceObjects: [],
            editedReferenceProducts: [],
            editedReferenceObjects: [],
            newReferenceModal: false,
            resetReferencesModal: false
        };
        //console.log(this.props.field.dataField);
        this.edited_reference_ids = [];
        this.deleteReferenceProduct = this.deleteReferenceProduct.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setNewReferences = this.setNewReferences.bind(this);
        this.openNewReferenceModal = this.openNewReferenceModal.bind(this);
    }

    componentDidMount() {
        this.getReferenceProducts();
    }

    componentDidUpdate() {
        const edited_reference_products = this.getReferenceData(this.state.editedReferenceProducts);
        const edited_reference_ids = edited_reference_products.map(ref_prod => ref_prod[this.props.portalConfig.keyfield]);
        const edited_reference_objects = this.getEditedReferenceObjects(edited_reference_products, true);
        if (!deepEqual(this.edited_reference_ids.sort((a, b) => a - b), edited_reference_ids.sort((a, b) => a - b))) {
            this.edited_reference_ids = edited_reference_ids;
            this.setState({
                editedReferenceProducts: edited_reference_products,
                editedReferenceObjects: edited_reference_objects
            });
        }
        if (!deepEqual(this.state.referenceProducts.map(ref_prod => ref_prod[this.props.portalConfig.keyfield]).sort((a, b) => a - b), edited_reference_ids.sort((a, b) => a - b)) && !this.props.editedFields.length) {
            this.edited_reference_ids = this.state.referenceProducts.map(ref_prod => ref_prod[this.props.portalConfig.keyfield]);
            this.setState({
                editedReferenceProducts: this.state.referenceProducts,
                editedReferenceObjects: this.state.referenceObjects
            });
        }
    }

    getReferenceProducts(initial = true) {
        const { references, referenceObjects, portalConfig } = this.props;
        //const references = this.props.references;
        console.log(references);
        loadReferenceProducts(references).then(products => {
            if (initial) {
                addToFetchedProducts(products);
            }
            //console.log(products);
            let new_state = {
                fetchNewProducts: false,
                referenceProducts: products,
                referenceObjects: referenceObjects
            };
            if (initial) {
                const edited_reference_products = this.getReferenceData(products);
                const edited_reference_objects = this.getEditedReferenceObjects(edited_reference_products, true);
                this.edited_reference_ids = edited_reference_products.map(ref_prod => ref_prod[portalConfig.keyfield]);
                new_state["editedReferenceProducts"] = edited_reference_products;
                new_state["editedReferenceObjects"] = edited_reference_objects;
            }
            this.setState(new_state);
        });
    }

    getEditedReferenceObjects(edited_reference_products, initial = false) {
        const { referenceObjects, portalConfig, newReferenceModalProps } = this.props;
        const reference_obj = initial ? referenceObjects[0] : newReferenceModalProps.referenceObject;   //TODO: neue referenz anlegen falls noch keine existiert, fehlt noch. neue parameter für referenzobjekt fehlt noch
        let edited_reference_objects = [];
        for (let ref_product of edited_reference_products) {
            let reference_object = Object.assign({}, reference_obj);
            //let reference_object = Object.assign({}, referenceObjects[0]);
            reference_object["TargetID"] = ref_product[portalConfig.keyfield];
            edited_reference_objects.push(reference_object);
        }
        /*edited_reference_products.map(ref_product => {
            let reference_object = Object.assign({}, reference_obj);
            //let reference_object = Object.assign({}, referenceObjects[0]);
            reference_object["TargetID"] = ref_product[portalConfig.keyfield];
            edited_reference_objects.push(reference_object);
        });*/
        return edited_reference_objects;
    }

    click() {
        this.setState({
            showReferences: !this.state.showReferences
        });
    }

    getTableHead() {
        const { portalConfig, isNewReferenceModal, edit, sourceProductID, field } = this.props;
        const { reference_linkfields, show_details, show_excel, show_basket, show_comparelist } = portalConfig;
        let table_heads = [];
        let table_heads_buttons = [];
        reference_linkfields.map(field => table_heads.push(field));
        if (show_details) {
            table_heads_buttons.push(translate("details"));
        }
        if (!isNewReferenceModal) {
            if (show_excel && !edit) {
                table_heads_buttons.push(translate("download_table"));
            }
            if (show_basket && !edit) {
                table_heads_buttons.push(translate("basket"));
            }
            if (show_comparelist && !edit) {
                table_heads_buttons.push(translate("compare"));
            }
            if (edit) {
                table_heads_buttons.push(translate("delete"));
            }
        }
        return (
            <tr>
                {table_heads.map(head =>
                    <th key={sourceProductID + "-" + field.dataField.replace(/\s/g, "") + "-" + head}>
                        {head}
                    </th>
                )}
                {table_heads_buttons.map(head =>
                    <th style={{ textAlign: "center" }}>
                        {head}
                    </th>
                )}
            </tr>
        );
    }

    /**
     * falls referencen bearbeitet wurden, werden diese aus dem edit-store geladen. ansonsten werden die unbearbeiteten referencen zurückgegeben.
     * @param {*} products 
     * @returns 
     */
    getReferenceData(products) {
        const { editedFields, field, language } = this.props;
        let dataField = field.dataField;
        let editField = field.editField && field.editField !== "0" ? field.editField : false;
        let reference_products = products;
        for (var i = 0; i < editedFields.length; i++) {
            if (editedFields[i]["ID"] === this.props.sourceProductID) {
                if (editedFields[i]["language"] === language) {
                    if (editField && editField !== "") {
                        if (editedFields[i]["changes"].hasOwnProperty(editField)) {
                            const references = JSON.parse(editedFields[i]["changes"][editField]);
                            reference_products = [];
                            for (let ref of references) {
                                const ref_product = getProductByID(this.props.fetchedProducts, ref["TargetID"]);
                                reference_products.push(ref_product);
                            }
                            /*references.map(ref => {
                                reference_products.push(getProductByID(this.props.fetchedProducts, ref["TargetID"]));
                            });*/
                        }
                    } else {
                        if (editedFields[i]["changes"].hasOwnProperty(dataField)) {
                            const references = JSON.parse(editedFields[i]["changes"][dataField]);
                            reference_products = [];
                            for (let ref of references) {
                                reference_products.push(getProductByID(this.props.fetchedProducts, ref["TargetID"]));
                            }
                            /*references.map(ref => {
                                reference_products.push(getProductByID(this.props.fetchedProducts, ref["TargetID"]));
                            });*/
                        }
                    }
                }
            }
        }
        return reference_products;
    }

    getTableBody() {
        const { portalConfig, isNewReferenceModal, isModal, edit } = this.props;
        const { reference_sortorder, reference_linkfields, show_details, keyfield, show_excel, show_basket,
            show_comparelist, color_config
        } = portalConfig;
        const referenceProducts = edit ? this.state.editedReferenceProducts : this.state.referenceProducts;
        return (
            referenceProducts.sort((a, b) => sortKeyfield(a, b, reference_sortorder)).map(product => (
                <tr>
                    {reference_linkfields.map(field => (
                        <td>
                            {product[field]}
                        </td>
                    ))}
                    {!show_details ? null :
                        <td style={{ textAlign: "center" }}>
                            <button
                                className="btn-xs article-button info-button"
                                style={{ backgroundColor: color_config.view_button_color }}
                                onClick={(event) => openArticleDetails(event, product[keyfield], isNewReferenceModal)}
                            >
                                <MDBIcon icon="info" className="info-icon" />
                            </button>
                        </td>
                    }
                    {!show_excel || edit ? null :
                        <td style={{ textAlign: "center" }}>
                            <button
                                className="btn-xs article-button excel-button"
                                style={{ backgroundColor: color_config.view_button_color }}
                                onClick={() => addToDownloadTable(product[keyfield], this.props.notify)}
                            >
                                <MDBIcon icon="file-download" />
                            </button>
                        </td>
                    }
                    {!show_basket || edit ? null :
                        <td style={{ textAlign: "center" }}>
                            <button
                                className="btn-xs article-button"
                                style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color, borderColor: color_config.view_button_text_color }}
                                onClick={() => openShoppingCartModal(product[keyfield])}
                            >
                                <MDBIcon icon="shopping-cart" />
                            </button>
                        </td>
                    }
                    {!show_comparelist || edit ? null :
                        <td style={{ textAlign: "center" }}>
                            <button
                                className="btn-xs article-button"
                                style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color, borderColor: color_config.view_button_text_color }}
                                onClick={() => addToCompareList(product[keyfield], this.props.notify)}
                            >
                                <MDBIcon icon="exchange-alt" />
                            </button>
                        </td>
                    }
                    {!edit || isNewReferenceModal || isModal ? null :
                        <td style={{ textAlign: "center" }}>
                            <button
                                className="btn-xs article-button delete-button"
                                style={{ backgroundColor: color_config.view_button_color }}
                                onClick={() => this.openDeleteReferenceModal(product[keyfield])}
                            >
                                <MDBIcon icon="trash-alt" className="delete-icon" />
                            </button>
                        </td>
                    }
                </tr>
            ))
        );
    }

    openDeleteReferenceModal(item_id) {
        this.setState({
            deleteReferenceModal: true,
            deleteReferenceProductID: item_id
        });
    }

    deleteReferenceProduct() {
        const { portalConfig } = this.props;
        const references = this.state.editedReferenceObjects.filter(reference_field => reference_field["TargetID"] !== this.state.deleteReferenceProductID);
        const edited_reference_products = this.state.editedReferenceProducts.filter(product => product[portalConfig.keyfield] !== this.state.deleteReferenceProductID);
        this.editedFields++;
        saveEditStore(this.props.sourceProductID, this.props.field, JSON.stringify(references));
        this.setState({
            deleteReferenceModal: false,
            deleteReferenceProductID: "",
            editedReferenceProducts: edited_reference_products,
            editedReferenceObjects: references
        });
    }

    closeModal(modal) {
        this.setState({
            [modal]: false
        });
    }

    clickEditReferences() {
        const { newReferenceModalProps, sourceProductID, referenceObjects, field } = this.props;
        if (newReferenceModalProps.product_id) {
            if (newReferenceModalProps.product_id === sourceProductID && newReferenceModalProps.product_field === field.dataField) {
                this.setState({
                    newReferenceModal: true
                });
            } else {
                const selected_references = this.getSelectedReferences();
                if (newReferenceModalProps.selectedReferences.length && !deepEqual(selected_references, newReferenceModalProps.selectedReferences)) {
                    this.setState({
                        resetReferencesModal: true
                    });
                } else {
                    this.props.set_reference_modal_product_id(sourceProductID);
                    this.props.set_reference_modal_product_field(field.dataField);
                    this.props.set_reference_modal_reference_object(referenceObjects[0]);
                    this.setState({
                        newReferenceModal: true
                    });
                }
            }
        } else {
            this.openNewReferenceModal();
        }
    }

    openNewReferenceModal() {
        const { sourceProductID, referenceObjects, field } = this.props;
        const selected_references = this.getSelectedReferences();
        this.props.set_selected_references(selected_references);
        this.props.set_reference_modal_product_id(sourceProductID);
        this.props.set_reference_modal_product_field(field.dataField);
        this.props.set_reference_modal_reference_object(referenceObjects[0]);
        this.setState({
            newReferenceModal: true
        });
    }

    getReferenceField() {
        const reference_field = this.props.portalConfig.field_list.filter(field => field.dataField === this.props.newReferenceModalProps.product_field)[0];
        return reference_field;
    }

    setNewReferences(open_new_reference_modal = false) {
        const { sourceProductID, newReferenceModalProps, fetchedProducts, field, portalConfig } = this.props;
        const edited_reference_products = newReferenceModalProps.selectedReferences.map(item_id => getProductByID(fetchedProducts, item_id));
        const edited_reference_objects = this.getEditedReferenceObjects(edited_reference_products);
        this.editedFields++;
        saveEditStore(open_new_reference_modal ? newReferenceModalProps.product_id : sourceProductID, open_new_reference_modal && field.dataField !== newReferenceModalProps.product_field ? this.getReferenceField() : field, JSON.stringify(edited_reference_objects));
        if (!open_new_reference_modal) {
            this.edited_reference_ids = edited_reference_products.map(ref_prod => ref_prod[portalConfig.keyfield]);
            this.setState({
                editedReferenceProducts: edited_reference_products,
                editedReferenceObjects: edited_reference_objects
            });
            this.props.reset_new_reference_modal_props();
        } else {
            this.openNewReferenceModal();
        }
    }

    getSelectedReferences() {
        const selected_references = this.state.editedReferenceProducts.map(edit_ref_product => edit_ref_product[this.props.portalConfig.keyfield]);
        return selected_references;
    }

    getReferences() {
        const { edit, isNewReferenceModal, isModal, newReferenceModalProps } = this.props;
        const selected_references = this.getSelectedReferences();
        return (
            <div>
                <SimpleModal
                    modalID="deleteReferenceModal"
                    isOpen={this.state.deleteReferenceModal}
                    action={this.deleteReferenceProduct}
                    close={this.closeModal}
                    headerText={translate("delete")}
                    bodyText={translate("are_you_sure")}
                />
                <NewReferenceModal
                    modalID="newReferenceModal"
                    isOpen={this.state.newReferenceModal}
                    setNewReferences={this.setNewReferences}
                    selectedReferences={selected_references}
                    close={this.closeModal}
                    newReferenceModalProps={newReferenceModalProps}
                />
                <ResetReferencesModal
                    modalID="resetReferencesModal"
                    isOpen={this.state.resetReferencesModal}
                    close={this.closeModal}
                    setNewReferences={this.setNewReferences}
                    openNewReferenceModal={this.openNewReferenceModal}
                />
                <button
                    className="btn btn-sm"
                    onClick={() => this.click()}
                >
                    {translate("hide")}
                </button>
                {edit && !isNewReferenceModal && !isModal ?
                    <button
                        className="btn btn-sm"
                        //style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color }}
                        onClick={() => this.clickEditReferences()}
                    >
                        {translate("edit")}
                    </button>
                    :
                    null
                }
                <MDBTable className="overflow-auto">
                    <MDBTableHead>
                        {this.state.fetchNewProducts ? null :
                            this.getTableHead()
                        }
                    </MDBTableHead>
                    <MDBTableBody>
                        {this.state.fetchNewProducts ? null :
                            this.getTableBody()
                        }
                    </MDBTableBody>
                </MDBTable>
            </div>
        );
    }

    render() {
        return (
            !this.state.showReferences ?
                <button
                    className="btn btn-sm"
                    //style={{ backgroundColor: color_config.view_button_color, color: color_config.view_button_text_color }}
                    onClick={() => this.click()}
                >
                    {translate("show")}
                </button>
                :
                this.getReferences()
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(ArticleReferences);