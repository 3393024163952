const clearSubtableReducer = (state = false, action) => {
    switch (action.type) {
        case 'SET_CLEAR_SUBTABLE':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default clearSubtableReducer;