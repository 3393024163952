import React, { Component } from "react";
import { deepEqual, getArticleSubtables, getDatafieldAsString, getFileTimestamp, getProductByID, getSubtableFields, getSubtableFieldsByDatafield, translate } from "../utils/Utils";
import "../Home.css";
import { MDBIcon, MDBTooltip } from "mdbreact";
import store from "../store/store";
import ReactExport from "react-export-excel";
import { getDataAsString, getImportTemplateFields } from "../utils/functions";
import deepmerge from "deepmerge";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            excelTimestamp: "",
            downloadSubtables: false
        };
        this.subtableFields = {};
        this.metaData = [];
        this.importTemplateFields = getImportTemplateFields();
    }

    componentDidUpdate() {
        const subtable_fields_counter = this.props.excelTableFields.filter(field => field.dataField.includes(":JSON")).length;
        if (this.state.downloadSubtables && subtable_fields_counter < 1) {
            this.setState({
                downloadSubtables: false
            });
        }
        if (this.props.usage !== "basket" && !this.state.downloadSubtables && subtable_fields_counter > 0) {
            //let meta_data = [...this.importTemplateFields];
            let export_products = [];
            console.log(this.props.excelTableFields);
            console.log(this.importTemplateFields);
            const excel_table_datafields = [...this.props.excelTableFields].map(field => field.dataField);
            let template_fields = [...this.props.excelTableFields];
            for (let field of this.importTemplateFields) {
                if (!excel_table_datafields.includes(field.dataField)) {
                    template_fields.push(field);
                }
            }
            console.log(template_fields);
            //const template_fields = this.importTemplateFields.filter(field => !excel_table_datafields.includes(field.dataField));
            //console.log(template_fields);
            for (let ID of this.props.excelTableProductsIDs) {
                //console.log(ID);
                const product = getProductByID(this.props.fetchedProducts, ID);
                let itemData = {
                    "ID": ID
                };
                for (let field of template_fields) {
                    itemData[field.dataField] = product.hasOwnProperty(field.dataField) ? product[field.dataField] : "";
                }
                export_products.push(itemData);
            }
            //console.log(export_products);
            let meta_data = {
                "products": [...export_products],
                "field_list": [...template_fields]
            }
            //console.log(meta_data);
            let objJsonStr = JSON.stringify(meta_data);
            let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
            let template_meta_data = {
                "meta_data": objJsonB64
            };
            //console.log(template_meta_data);
            this.metaData.push(template_meta_data);
            this.setState({
                downloadSubtables: true
            });
        }
    }

    getExcelData() {
        const { excelTableProductsIDs, excelTableFields, fetchedProducts, portalConfigPriceField, usage,
            downloadFetchedProducts, fetched_products, exportForImport
        } = this.props;
        const { field_list } = store.getState().portalConfig;
        let data = [];
        let subtable_data = [];
        if (usage === "basket") {
            for (let i = 0; i < excelTableProductsIDs.length; i++) {
                const product_obj = excelTableProductsIDs[i];
                const product = getProductByID(fetchedProducts, product_obj.id_);
                let itemData = {};
                //itemData["pos_nr"] = i + 1;
                for (let field of excelTableFields.filter(field => !field.dataField.includes(":JSON"))) {
                    itemData[field.text] = !product[field.dataField] ? "" : getDatafieldAsString(product[field.dataField]);
                };
                itemData["quantity"] = product_obj.quantity;
                itemData["price"] = !product[portalConfigPriceField] ? "0" : getDatafieldAsString(product[portalConfigPriceField]);
                data.push(itemData);
            }
        } else if (usage === "template") {
            const products = downloadFetchedProducts ? fetched_products : excelTableProductsIDs;
            for (var i = 0; i < products.length; i++) {
                const product = products[i];
                const product_id = product[store.getState().portalConfig.keyfield];
                let itemData = {};
                //itemData["pos_nr"] = i + 1;
                for (let field of excelTableFields) {
                    const product_data = product[field.dataField];
                    if (this.state.downloadSubtables && field.dataField.includes(":JSON")) {
                        try {
                            const subtable = product_data ? this.getSubtableData(field, JSON.parse(product_data), product_id) : [];
                            subtable_data = subtable_data.concat(subtable);
                        } catch (error) {
                            let sub_data = product_data.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
                            try {
                                const subtable = this.getSubtableData(field, JSON.parse(sub_data), product_id);
                                subtable_data = subtable_data.concat(subtable);
                            } catch (err) {
                                console.log(err);
                                itemData[field.text] = getDataAsString(product_data);
                            }
                        }
                    } else {
                        itemData[field.text] = getDataAsString(product_data);
                    }
                }
                if (this.state.downloadSubtables) {
                    itemData["ID"] = product_id;
                }
                data.push(itemData);
            }
        } else {
            //const excel_fields = exportForImport ? field_list : excelTableFields;
            const excel_fields = excelTableFields;
            for (var j = 0; j < excelTableProductsIDs.length; j++) {
                const product_id = excelTableProductsIDs[j];
                const product = getProductByID(fetchedProducts, product_id);
                let itemData = {};
                //itemData["pos_nr"] = j + 1;
                for (let field of excel_fields) {
                    const product_data = product[field.dataField];
                    if (this.state.downloadSubtables && field.dataField.includes(":JSON")) {
                        try {
                            const subtable = product_data ? this.getSubtableData(field, JSON.parse(product_data), product_id) : [];
                            subtable_data = subtable_data.concat(subtable);
                        } catch (error) {
                            let sub_data = product_data.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
                            try {
                                const subtable = this.getSubtableData(field, JSON.parse(sub_data), product_id);
                                subtable_data = subtable_data.concat(subtable);
                            } catch (err) {
                                console.log(err);
                                itemData[field.dataField] = getDataAsString(product_data);
                            }
                        }
                    } else {
                        itemData[field.dataField] = getDataAsString(product_data);
                    }
                }
                if (this.state.downloadSubtables/* && !exportForImport*/) {
                    itemData["ID"] = product_id;
                }
                data.push(itemData);
            }
        }
        return { data, subtable_data };
    }

    getSubtableData(field, subtable_array, product_id) {
        const subtable_fields = getSubtableFieldsByDatafield(field.dataField);
        //console.log(subtable_fields);
        let subtable_data = [];
        let row_number = 1;
        for (let subtable_row of subtable_array) {
            let subtable = {};
            subtable[translate("main_product")] = product_id;
            subtable[translate("main_field")] = field.text ? field.text : field.dataField;
            subtable[translate("row_id")] = product_id + "-" + field.dataField + "-" + row_number;
            subtable[translate("action")] = "";
            for (let subtable_field of subtable_fields) {
                subtable[subtable_field.dataField] = getDataAsString(subtable_row[subtable_field.dataField]);
            }
            subtable_data.push(subtable);
            row_number++;
        }
        if ((!this.subtableFields.hasOwnProperty(field.dataField) && subtable_fields.length) || (this.subtableFields.hasOwnProperty(field.dataField) && !this.subtableFields[field.dataField].length && subtable_fields.length)) {
            this.subtableFields[field.dataField] = [...subtable_fields];
            let meta_data = {
                [field.dataField]: [...subtable_fields]
            };
            //console.log(meta_data);
            let objJsonStr = JSON.stringify(meta_data);
            let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
            //console.log(objJsonB64);
            let subtable_meta_data = {
                "meta_data": objJsonB64
            };
            this.metaData.push(subtable_meta_data);
        }
        return subtable_data;
    }

    getExcelButton(usage) {
        const { color_config } = store.getState().portalConfig;
        const { add_button_color, add_button_text_color } = color_config;
        if (usage === "template") {
            return (
                <div onMouseDown={() => this.setState({})}>
                    <MDBTooltip
                        domElement
                        tag="span"
                        placement="bottom"
                    >
                        <button
                            className="btn-xs btn-left excel-button"
                            id={this.props.buttonID}
                        >
                            <MDBIcon icon="file-excel" className="excel-icon" />
                        </button>
                        <span className="btn-span">
                            {translate("as_excel")}
                        </span>
                    </MDBTooltip>
                </div>
            );
        } else {
            if (this.props.downloadFetchedProducts) {
                return (
                    <button
                        className="btn btn-sm"
                        onMouseDown={() => this.setState({})}
                        id={this.props.buttonID}
                        style={{ backgroundColor: add_button_color, color: add_button_text_color }}
                    >
                        {translate("yes")}
                    </button>
                );
            }
            return (
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="top"
                >
                    <span>
                        <button
                            className="btn btn-sm"
                            onMouseDown={() => this.setState({})}
                            id={this.props.buttonID}
                            style={{ backgroundColor: add_button_color, color: add_button_text_color }}
                        >
                            <MDBIcon icon="download" />
                        </button>
                    </span>
                    <span>{translate("download")}</span>
                </MDBTooltip>
            );
        }
    }

    render() {
        const { usage, excelTableFields, exportForImport } = this.props;
        const { field_list } = store.getState().portalConfig;
        const { data, subtable_data } = this.getExcelData();
        const excelname = translate(usage);
        const filename = excelname + getFileTimestamp();
        //console.log(subtable_data);
        //console.log(this.metaData);
        if (usage === "basket") {
            return (
                <ExcelFile
                    filename={filename}
                    element={this.getExcelButton(usage)}
                >
                    <ExcelSheet
                        data={data}
                        name={excelname}
                    >
                        {/*<ExcelColumn label={translate("pos_nr")} value="pos_nr" />*/}
                        {excelTableFields.filter(field => !field.dataField.includes(":JSON")).map(field => (
                            <ExcelColumn label={field.text} value={field.text} />
                        ))}
                        <ExcelColumn label={translate("amount")} value="quantity" />
                        <ExcelColumn label={translate("price")} value="price" />
                    </ExcelSheet>
                </ExcelFile>
            );
        } else if (this.state.downloadSubtables && subtable_data.length) {
            if (usage === "download_table" && exportForImport) {
                return (
                    <ExcelFile
                        filename={filename}
                        element={this.getExcelButton(usage)}
                    >
                        <ExcelSheet
                            data={data}
                            name={excelname}
                        >
                            {/*<ExcelColumn label={translate("pos_nr")} value="pos_nr" />*/}
                            <ExcelColumn label="ID" value="ID" />
                            {excelTableFields.filter(field => !field.dataField.includes(":JSON")).map(field => (
                                <ExcelColumn label={field.dataField} value={field.dataField} />
                            ))}
                        </ExcelSheet>
                        <ExcelSheet
                            data={subtable_data}
                            name={"Subtables"}
                        >
                            <ExcelColumn label={translate("main_product")} value={translate("main_product")} />
                            <ExcelColumn label={translate("main_field")} value={translate("main_field")} />
                            <ExcelColumn label={translate("row_id")} value={translate("row_id")} />
                            {Object.keys(this.subtableFields).map(sub_key => this.subtableFields[sub_key].map(field => (
                                <ExcelColumn label={field.dataField} value={field.dataField} />
                            )))}
                            <ExcelColumn label={translate("action")} value={translate("action")} />
                        </ExcelSheet>
                        <ExcelSheet
                            data={this.metaData}
                            name={"meta"}
                        >
                            <ExcelColumn label={"data"} value={"meta_data"} />
                        </ExcelSheet>
                    </ExcelFile>
                );
            } else {
                return (
                    <ExcelFile
                        filename={filename}
                        element={this.getExcelButton(usage)}
                    >
                        <ExcelSheet
                            data={data}
                            name={excelname}
                        >
                            {/*<ExcelColumn label={translate("pos_nr")} value="pos_nr" />*/}
                            <ExcelColumn label="ID" value="ID" />
                            {excelTableFields.filter(field => !field.dataField.includes(":JSON")).map(field => (
                                <ExcelColumn label={field.text} value={field.text} />
                            ))}
                        </ExcelSheet>
                        <ExcelSheet
                            data={subtable_data}
                            name={"Subtables"}
                        >
                            <ExcelColumn label={translate("main_product")} value={translate("main_product")} />
                            <ExcelColumn label={translate("main_field")} value={translate("main_field")} />
                            <ExcelColumn label={translate("row_id")} value={translate("row_id")} />
                            {Object.keys(this.subtableFields).map(sub_key => this.subtableFields[sub_key].map(field => (
                                <ExcelColumn label={field.dataField} value={field.dataField} />
                            )))}
                            <ExcelColumn label={translate("action")} value={translate("action")} />
                        </ExcelSheet>
                    </ExcelFile>
                );
            }
        } else {
            if (exportForImport) {
                return (
                    <ExcelFile
                        filename={filename}
                        element={this.getExcelButton(usage)}
                    >
                        <ExcelSheet
                            data={data}
                            name={excelname}
                        >
                            {field_list.filter(field => !field.dataField.includes(":JSON")).map(field => (
                                <ExcelColumn label={field.dataField} value={field.dataField} />
                            ))}
                        </ExcelSheet>
                    </ExcelFile>
                );
            }
            return (
                <ExcelFile
                    filename={filename}
                    element={this.getExcelButton(usage)}
                >
                    <ExcelSheet
                        data={data}
                        name={excelname}
                    >
                        {/*<ExcelColumn label={translate("pos_nr")} value="pos_nr" />*/}
                        {excelTableFields.filter(field => !field.dataField.includes(":JSON")).map(field => (
                            <ExcelColumn label={field.text} value={field.text} />
                        ))}
                    </ExcelSheet>
                </ExcelFile>
            );
        }
    }
}

export default ExcelButton;