import React, { Component } from "react";
import { translate } from "../utils/Utils";
import {
    MDBDropdownItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu
} from "mdbreact";
import "../css/SearchMultiDropdown.css";
import "../Home.css";
import { connect } from "react-redux";
import { update_search_query, delete_search_query_field, add_hidden_facet, set_hidden_facets } from "../actions";
import PropTypes from "prop-types";
import { getMappedLabel, getMappingFields } from "../utils/functions";

const mapStateToProps = (state) => {
    return {
        language: state.language,
        portalConfig: state.portalConfig,
        searchQuery: state.searchQuery,
        hiddenFacets: state.hiddenFacets
    };
};

const mapDispatchToProps = () => {
    return {
        update_search_query,
        delete_search_query_field,
        add_hidden_facet,
        set_hidden_facets
    };
};

class FacetAsDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterFacet: null,
            headerDataField: this.props.field.dataField
        };
    }

    checkReload() {
        if (!this.state.filterFacet && document.getElementById(this.props.field.dataField + "-facet")) {
            this.setState({
                filterFacet: document.getElementById(this.props.field.dataField + "-facet")
            });
        }
    }

    isItemChecked(key) {
        const searchQuery = this.props.searchQuery;
        const dataField = this.props.field.dataField;
        if (searchQuery[dataField] && searchQuery[dataField].includes(key)) {
            return true;
        } else {
            return false;
        }
    }

    getDropdownItems() {
        let dropdown_items = [];
        for (let i = 0; i < this.state.filterFacet.children.length; i++) {
            const child = this.state.filterFacet.children[i];
            dropdown_items.push(child);
        }
        return (
            dropdown_items.map(item => {
                const facet_element = item;
                const label = facet_element.localName === "button" || !this.props.field.usage.includes("FACET") || this.props.field.filter === "TagCloud" ? facet_element.innerText : facet_element.children[1].innerText;
                if (facet_element.localName === "button") {
                    return (
                        <MDBDropdownItem
                            className="dropdown-item"
                            onClick={() => {
                                this.setState({
                                    filterFacet: null
                                });
                                facet_element.click();
                            }}
                        >
                            {label}
                        </MDBDropdownItem>
                    )
                } else {
                    if (facet_element.id.includes("empty")) {
                        const key = facet_element.id.includes("-not-empty") ? "not_empty" : "empty";
                        return (
                            <MDBDropdownItem
                                active={this.isItemChecked(key)}
                                className="dropdown-item"
                                onClick={() => facet_element.click()}
                            >
                                {label}
                            </MDBDropdownItem>
                        );
                    } else {
                        if (this.props.field.filter === "MultiDataList") {
                            const key = facet_element.children[1].id.substring(facet_element.children[1].id.indexOf("-key:") + 5, facet_element.children[1].id.length);
                            return (
                                <MDBDropdownItem
                                    active={this.isItemChecked(key)}
                                    className="dropdown-item"
                                    onClick={() => facet_element.click()}
                                >
                                    <div className="custom-table-search-head-mapped-data-label" dangerouslySetInnerHTML={{ __html: facet_element.children[1].innerHTML }} />
                                </MDBDropdownItem>
                            );
                        } else {
                            const key = this.props.field.usage.includes("FACET") && this.props.field.filter !== "TagCloud" ? facet_element.children[1].children[0].innerText : facet_element.innerText;
                            return (
                                <MDBDropdownItem
                                    active={this.isItemChecked(this.props.field.unit_filter ? key.substring(0, key.indexOf(" " + this.props.field.unit_filter)) : key)}
                                    className="dropdown-item"
                                    onClick={() => facet_element.click()}
                                >
                                    {label}
                                </MDBDropdownItem>
                            );
                        }
                    }
                }
            })
        );
    }

    getSearchbarLabel() {
        const { field, searchQuery } = this.props;
        const dataField = field.dataField;
        const max_label_size = 23;
        if (searchQuery[dataField]) {
            let label = "";
            if (field.filter === "MultiDataList") {
                label = getMappedLabel(searchQuery[dataField], getMappingFields(field));
            } else {
                label = searchQuery[dataField].toString();
            }
            if (label.length > max_label_size) {
                label = label.substring(0, max_label_size) + "...";
            }
            if (label === "empty") {
                return translate("empty");
            } else if (label === "not_empty") {
                return translate("not_empty");
            }
            return label;
        } else {
            return translate("select");
        }
    }

    render() {
        const { field, portalConfig, searchbar } = this.props;
        if (this.state.headerDataField !== field.dataField) {
            this.setState({
                headerDataField: this.props.field.dataField,
                filterFacet: null
            });
            return null;
        }
        if (!this.state.filterFacet) {
            return (
                <div className="multi-dropdown-list" onMouseOver={() => this.checkReload()}>
                    <MDBDropdown className="dropdown-searchbar">
                        <MDBDropdownToggle nav caret className="dropdown-toggle" style={{ color: portalConfig.color_config.head_item_color }}>
                            {searchbar ? this.getSearchbarLabel() : field.text ? field.text : field.dataField}
                        </MDBDropdownToggle>
                    </MDBDropdown>
                </div>
            );
        } else {
            return (
                <MDBDropdown className="dropdown-searchbar">
                    <MDBDropdownToggle nav caret className="dropdown-toggle" style={{ color: portalConfig.color_config.head_item_color }}>
                        {searchbar ? this.getSearchbarLabel() : field.text ? field.text : field.dataField}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-menu">
                        <MDBDropdownItem
                            header
                            disabled
                            className="dropdown-item"
                        >
                            {translate(searchbar ? "select" : "select_fields")}
                        </MDBDropdownItem>
                        <MDBDropdownItem divider />
                        {this.getDropdownItems()}
                    </MDBDropdownMenu>
                </MDBDropdown>
            );
        }
    }
}

FacetAsDropdown.propTypes = {
    field: PropTypes.object.isRequired,
    searchbar: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps())(FacetAsDropdown);