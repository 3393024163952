import React, { Component } from "react";
import { connect } from "react-redux";
import {
    open_subtable_modal,
    set_clear_subtable, add_edit_field, switch_save_changes, add_change_index_field, update_source_fields,
    set_change_index_fields, set_update_source_fields
} from "../actions";
import merge from "deepmerge";
import {
    MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBIcon
} from "mdbreact";
import {
    getTableRowIDField, getProductByID, getTimestamp, getIndexLanguage, getField, createHTMLElement,
    getChangeIndexFields, getUpdateSourceFields, createNewCopiedProduct, translate, getReferenceFiles
} from "../utils/Utils";
import EditField from "./EditField";
import "../css/SubTable.css";
import SubTableModal from "./SubTableModal";
import DeleteQuestionModal from "./DeleteQuestionModal";
import SubtableDropdown from "./SubtableDropdown";

const mapStateToProps = (state) => {
    return {
        edit: state.edit,
        portalConfig: state.portalConfig,
        fetchedProducts: state.fetchedProducts,
        clearSubtable: state.clearSubtable,
        language: state.language,
        saveChanges: state.saveChanges,
        editedFields: state.editedFields,
        selectFields: state.selectFields,
        changeIndexFields: state.changeIndexFields,
        updateSourceFields: state.updateSourceFields,
        newProducts: state.newProducts,
        newCopiedProducts: state.newCopiedProducts
    };
};

const mapDispatchToProps = () => {
    return {
        open_subtable_modal,
        set_clear_subtable,
        add_edit_field,
        switch_save_changes,
        add_change_index_field,
        update_source_fields,
        set_change_index_fields,
        set_update_source_fields
    };
};

class SubTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subtable: this.getEditSubtable(),
            delete_row: false,
            delete_row_data: null,
            sort: {},
            subtable_data: {},
            subtable_search: [],
            subtable_max_width: 0,
            show_subtable: this.props.portalConfig.show_button_reference_subtable ? false : true
        };
        this.setEditSubtable = this.setEditSubtable.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteTableRow = this.deleteTableRow.bind(this);
        this.handleDropdownClick = this.handleDropdownClick.bind(this);
    }

    componentDidMount() {
        const subtable = this.props.edit ? this.getEditSubtable() : this.props.subtable;
        let subtable_data = {};
        for (let table_row of subtable) {
            for (let key in table_row) {
                if (subtable_data[key]) {
                    if (table_row[key] && !subtable_data[key].includes(table_row[key])) {
                        subtable_data[key].push(table_row[key]);
                    }
                } else {
                    if (table_row[key]) {
                        subtable_data[key] = [table_row[key]];
                    } else {
                        subtable_data[key] = [];
                    }
                }
            }
        }
        this.setState({
            subtable: this.getEditSubtable(),
            subtable_data: subtable_data
        });
    }

    getEditSubtable() {
        const { editedFields, item_id, subtable_modal, language, fieldListField, table_row_id_field, subtable } = this.props;
        if (editedFields.length === 0) {
            return subtable;
        } else {
            let dataField = fieldListField.dataField;
            let editField = fieldListField.hasOwnProperty("editField") && fieldListField.editField !== "" ? fieldListField.editField : false;
            if (this.props.hasOwnProperty("fieldListField")) {
                dataField = fieldListField.dataField;
                if (fieldListField.hasOwnProperty("editField")) {
                    editField = fieldListField.editField;
                } else {
                    editField = false;
                }
            }
            for (var i = 0; i < editedFields.length; i++) {
                if (editedFields[i]["ID"] === item_id) {
                    if (editedFields[i]["language"] === language) {
                        if (editField && editField !== "") {
                            if (editedFields[i]["changes"].hasOwnProperty(editField)) {
                                const edit_value = editedFields[i]["changes"][editField];
                                if (this.props.hasOwnProperty("subtable_modal") && subtable_modal.item_id === item_id) {
                                    if (!this.props.new_subtable_row) {
                                        for (var j = 0; j < edit_value.length; j++) {
                                            if (edit_value[j][table_row_id_field] === subtable_modal.subtable_row_id) {
                                                return edit_value[j][fieldListField.dataField];
                                            }
                                        }
                                    }
                                }
                                return edit_value;
                            }
                        } else {
                            if (editedFields[i]["changes"].hasOwnProperty(dataField)) {
                                const edit_value = editedFields[i]["changes"][dataField];
                                if (this.props.hasOwnProperty("subtable_modal") && subtable_modal.item_id === item_id) {
                                    if (!this.props.new_subtable_row) {
                                        for (var k = 0; k < edit_value.length; k++) {
                                            if (edit_value[k][table_row_id_field] === subtable_modal.subtable_row_id) {
                                                return edit_value[k][fieldListField.dataField];
                                            }
                                        }
                                    }
                                } else {
                                    return edit_value;
                                }
                            }
                        }

                    }
                }
            }
            return subtable;
        }
    }

    componentDidUpdate() {
        if (this.props.clearSubtable) {
            this.setState({
                subtable: this.props.subtable
            });
            this.props.set_clear_subtable(false);
        }
        if (this.props.changed_subtable && JSON.stringify(this.state.subtable) !== JSON.stringify(this.props.subtable)) {
            this.setState({
                subtable: this.props.subtable
            });
            this.props.handleUpdateSubtable();
        }
    }

    handleCopyButton(item_id, subtable_row, subtable, table_row_id_field) {
        const new_id = getTimestamp(true);
        let new_subtable_row = Object.assign({}, subtable_row);
        new_subtable_row[table_row_id_field] = new_id;
        let new_subtable = [new_subtable_row];
        for (let table_row of subtable) {
            new_subtable.push(table_row);
        }
        /*subtable.map(table_row => {
            new_subtable.push(table_row);
        });*/
        this.props.open_subtable_modal(item_id, new_subtable_row, new_subtable, new_id, this.props.fieldListField, table_row_id_field, true, true);
    }

    enableSaveButton() {
        if (!this.props.saveChanges) {
            this.props.switch_save_changes();
        }
    }

    deleteTableRow(data) {
        const { item_id, fieldListField, language, portalConfig, changeIndexFields, updateSourceFields, newProducts, newCopiedProducts } = this.props;
        const elastic_index = getIndexLanguage(language, portalConfig.elastic_index, portalConfig.default_language);
        const editField = fieldListField.editField;
        const dataField = fieldListField.dataField;
        const subtable_row_id = data.subtable_row_id;
        const table_row_id_field = data.table_row_id_field;
        let new_subtable = [];
        let new_subtable_row = {};
        for (let table_row of this.state.subtable) {
            if (table_row[table_row_id_field] !== subtable_row_id) {
                new_subtable.push(table_row);
            } else {
                new_subtable_row = {
                    "table_row_id": subtable_row_id,
                    "changes": "deleted"
                };
            }
        }
        /*this.state.subtable.map(table_row => {
            if (table_row[table_row_id_field] !== subtable_row_id) {
                new_subtable.push(table_row);
            } else {
                new_subtable_row = {
                    "table_row_id": subtable_row_id,
                    "changes": "deleted"
                };
            }
        });*/
        let change_index_fields = getChangeIndexFields(changeIndexFields, item_id, language, true, false, new_subtable_row, editField && editField !== "0" ? editField : dataField, "", elastic_index, false, false, newProducts.includes(item_id));
        let update_source_fields = getUpdateSourceFields(updateSourceFields, item_id, language, dataField, JSON.stringify(new_subtable), false, newProducts.includes(item_id));

        if (newCopiedProducts.includes(item_id)) {
            let field_exists = false;
            for (var i = 0; i < changeIndexFields.length; i++) {
                if (changeIndexFields[i][portalConfig.keyfield] === item_id) {
                    field_exists = true;
                    i = changeIndexFields.length;
                }
            }
            if (!field_exists) {
                let { new_copied_product, mapped_key_obj } = createNewCopiedProduct(item_id);
                new_copied_product[portalConfig.keyfield] = item_id;
                change_index_fields = changeIndexFields;
                let new_change_index_field = {};
                for (let key in new_copied_product) {
                    if (new_copied_product[key]) {
                        const mapped_key = mapped_key_obj[key] ? mapped_key_obj[key] : key;
                        new_change_index_field = merge(new_change_index_field, getChangeIndexFields(change_index_fields, item_id, language, false, false, new_copied_product[key], mapped_key, "", elastic_index, false, true, newProducts.includes(item_id)));
                    }
                }
                /*Object.keys(new_copied_product).filter(key => new_copied_product[key]).map(key => {
                    const mapped_key = mapped_key_obj[key] ? mapped_key_obj[key] : key;
                    new_change_index_field = merge(new_change_index_field, getChangeIndexFields(change_index_fields, item_id, language, false, false, new_copied_product[key], mapped_key, "", elastic_index, false, true, newProducts.includes(item_id)));
                });*/
                change_index_fields.push(new_change_index_field);
                change_index_fields = getChangeIndexFields(change_index_fields, item_id, language, true, false, new_subtable_row, editField && editField !== "0" ? editField : dataField, "", elastic_index, false, false, newProducts.includes(item_id));
                update_source_fields = updateSourceFields;
                let new_field = {};
                for (let key in new_copied_product) {
                    if (new_copied_product[key]) {
                        new_field = merge(new_field, getUpdateSourceFields(update_source_fields, item_id, language, key, new_copied_product[key], true, newProducts.includes(item_id)));
                    }
                }
                /*Object.keys(new_copied_product).filter(key => new_copied_product[key]).map(key => {
                    new_field = merge(new_field, getUpdateSourceFields(update_source_fields, item_id, language, key, new_copied_product[key], true, newProducts.includes(item_id)));
                });*/
                update_source_fields.push(new_field);
                update_source_fields = getUpdateSourceFields(update_source_fields, item_id, language, dataField, JSON.stringify(new_subtable), false, newProducts.includes(item_id));
            }
        }
        this.props.add_edit_field(item_id, editField && editField !== "0" ? editField : dataField, language, new_subtable);
        this.props.set_change_index_fields(change_index_fields);
        this.props.set_update_source_fields(update_source_fields);
        this.setState({
            subtable: new_subtable,
            delete_row: false,
            delete_row_data: null
        });
        this.enableSaveButton();
    }

    handleDeleteButton(subtable_row_id, table_row_id_field) {
        this.setState({
            delete_row: true,
            delete_row_data: {
                subtable_row_id,
                table_row_id_field
            }
        });
    }

    getButtons(item_id, subtable_row, subtable, subtable_row_id, table_row_id_field) {
        if (this.props.edit) {
            return (
                <MDBRow center>
                    <button
                        className="btn-xs subtable-buttons edit-button"
                        /*style={{
                            backgroundColor: color_config.view_button_color,
                            color: color_config.view_button_text_color,
                        }}*/
                        onClick={() => this.props.open_subtable_modal(item_id, subtable_row, subtable, subtable_row_id, this.props.fieldListField, table_row_id_field)}
                    >
                        <MDBIcon icon="pencil-alt" className="edit-icon" size="lg" />
                    </button>
                    <button
                        className="btn-xs subtable-buttons"
                        /*style={{
                            backgroundColor: color_config.view_button_color,
                            color: color_config.view_button_text_color,
                        }}*/
                        onClick={() => this.handleCopyButton(item_id, subtable_row, subtable, table_row_id_field)}
                    >
                        <MDBIcon icon="copy" size="lg" />
                    </button>
                    <button
                        className="btn-xs subtable-buttons delete-button"
                        /*style={{
                            backgroundColor: color_config.view_button_color,
                            color: color_config.view_button_text_color,
                        }}*/
                        onClick={() => this.handleDeleteButton(subtable_row_id, table_row_id_field)}
                    >
                        <MDBIcon icon="trash-alt" className="delete-icon" size="lg" />
                    </button>
                </MDBRow>
            );
        } else {
            return null;
        }
    }

    setEditSubtable(edited_subtable) {
        this.setState({
            subtable: edited_subtable
        });
    }

    closeDeleteModal() {
        this.setState({
            delete_row: false,
            delete_row_data: null
        });
    }

    createField(item, field) {
        const { portalConfig, fieldListField, item_id } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        if (typeof (field) === "object" && field.dataField.includes(":JSON")) {
            return null;
        }
        let value = item[field.dataField];
        if (field.dataField.includes(":REFERENCE")) {
            if (field.dataField.includes(":REFERENCE_FILE")) {
                if (item[field.dataField]) {
                    const key = item_id + "-" + fieldListField.dataField.replace(/\s/g, "");
                    return getReferenceFiles(field.dataField, Array.isArray(item[field.dataField]) ? item[field.dataField] : [item[field.dataField]], key);
                }
                return null;
            }
            const htmlElement = createHTMLElement(item, field.dataField, reference_user, reference_password, reference_url);
            value = htmlElement.html.__html;
        }
        return getField(item, field, value);
    }

    sortDataByField(a, b, key, order) {
        let a_field = a[key];
        let b_field = b[key];
        if (order === "asc") {
            if (a_field < b_field) {
                return -1;
            }
            if (a_field > b_field) {
                return 1;
            }
            return 0;
        } else {
            if (a_field > b_field) {
                return -1;
            }
            if (a_field < b_field) {
                return 1;
            }
            return 0;
        }
    }

    handleHeaderSort(data_field, sort_order = "") {
        const sort_obj = sort_order ? { [data_field]: sort_order } : {};
        this.setState({
            sort: sort_obj
        });
    }

    getSortedSubtable(subtable) {
        if (Object.keys(this.state.sort).length) {
            const key = Object.keys(this.state.sort)[0];
            let temp_subtable = [];
            if (this.state.subtable_search.length) {
                for (let table_row of subtable) {
                    let conditions_met = true;
                    for (let i = 0; i < this.state.subtable_search.length; i++) {
                        const search_obj = this.state.subtable_search[i];
                        if (table_row[search_obj.key] !== search_obj.value) {
                            conditions_met = false;
                            i = this.state.subtable_search.length;
                        }
                    }
                    if (conditions_met) {
                        temp_subtable.push(table_row);
                    }
                }
            } else {
                temp_subtable = subtable.map(table_row => table_row);
            }
            const sorted_subtable = temp_subtable.sort((a, b) => this.sortDataByField(a, b, key, this.state.sort[key]));
            //console.log(sorted_subtable);
            return sorted_subtable;
        } else {
            if (this.state.subtable_search.length) {
                let filtered_subtable = [];
                for (let table_row of subtable) {
                    let conditions_met = true;
                    for (let i = 0; i < this.state.subtable_search.length; i++) {
                        const search_obj = this.state.subtable_search[i];
                        if (table_row[search_obj.key] !== search_obj.value) {
                            conditions_met = false;
                            i = this.state.subtable_search.length;
                        }
                    }
                    if (conditions_met) {
                        filtered_subtable.push(table_row);
                    }
                }
                return filtered_subtable;
            }
            return subtable;
        }
    }

    handleDropdownClick(field, search_data) {
        if (this.state.subtable_search.length) {
            let new_subtable_search = this.state.subtable_search.filter(search_obj => search_obj.key !== field.dataField);
            if (search_data === "all") {
                console.log("all");
            } else {
                new_subtable_search.push({ "key": field.dataField, "value": search_data, "label": field.text ? field.text : field.dataField });
            }
            this.setState({
                subtable_search: new_subtable_search
            });
        } else {
            this.setState({
                subtable_search: [{ "key": field.dataField, "value": search_data, "label": field.text ? field.text : field.dataField }]
            });
        }
    }

    clearSubtableFilter(key) {
        //console.log(key);
        //console.log(this.state.subtable_search);
        if (key === "all") {
            this.setState({
                subtable_search: []
            });
        } else {
            this.setState({
                subtable_search: this.state.subtable_search.filter(search_obj => search_obj.key !== key)
            });
        }
    }

    render() {
        const { portalConfig, fieldListField, fetchedProducts, item_id, edit } = this.props;
        const { subtable_search, show_subtable, delete_row, delete_row_data, sort, subtable_data, subtable } = this.state;
        const { field_list_json, keyfield, show_button_reference_subtable } = portalConfig;
        const dataField = fieldListField.dataField;
        const item = getProductByID(fetchedProducts, item_id);
        return (
            <div>
                {show_button_reference_subtable ?
                    show_subtable ?
                        <button
                            className="btn btn-sm"
                            onClick={() => this.setState({ show_subtable: !show_subtable })}
                            style={{ marginBottom: "20px" }}
                        >
                            {translate("hide")}
                        </button>
                        :
                        <button
                            className="btn btn-sm"
                            onClick={() => this.setState({ show_subtable: !show_subtable })}
                            style={{ marginBottom: "5px" }}
                        >
                            {translate("show")}
                        </button>
                    :
                    null
                }
                {/*show_subtable ?
                    <button
                        className="btn btn-sm"
                        onClick={() => this.setState({ show_subtable: !show_subtable })}
                        style={{ marginBottom: "20px" }}
                    >
                        {translate("hide")}
                    </button>
                    :
                    null
            */}
                {show_subtable ?
                    <div className="overflow-auto" style={{ /*minHeight: "270px",*/ maxHeight: "500px" }}>
                        <SubTableModal setEditSubtable={this.setEditSubtable} />
                        {!delete_row ? null :
                            <DeleteQuestionModal
                                closeModal={this.closeDeleteModal}
                                delete={this.deleteTableRow}
                                data={delete_row_data}
                            />
                        }
                        {subtable_search.length ?
                            subtable_search.map(filter => {
                                return (
                                    <div
                                        className="selected-filter"
                                        onClick={() => this.clearSubtableFilter(filter.key)}
                                    >
                                        <span>
                                            {filter.label + ": " + filter.value}
                                        </span>
                                        <span>
                                            X
                                        </span>
                                    </div>
                                );
                            })
                            :
                            null
                        }
                        {subtable_search.length && subtable_search.length > 1 ?
                            <div
                                className="selected-filter"
                                onClick={() => this.clearSubtableFilter("all")}
                            >
                                {translate("clear_all")}
                            </div>
                            :
                            null
                        }
                        <MDBTable style={subtable_search.length ? { marginTop: "5px" } : {}}>
                            <MDBTableHead>
                                <tr>
                                    {field_list_json.map(json_field => {
                                        if (json_field.hasOwnProperty(dataField)) {
                                            return (
                                                json_field[dataField].map(field => {
                                                    if (!field.usage.includes("ID")) {
                                                        //console.log(field);
                                                        const sort_obj = sort;
                                                        return (
                                                            <th
                                                                key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-" + field.dataField.replace(/\s/g, "") + "-subtable-head"}
                                                                className="border-left border-light"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                <div style={{ whiteSpace: "nowrap", width: "90%" }}>
                                                                    {sort_obj[field.dataField] ?
                                                                        sort_obj[field.dataField] === "desc" ?
                                                                            <MDBIcon icon="sort-down" className="subtable-head-icon"
                                                                                onClick={() => this.handleHeaderSort(field.dataField, "asc")}
                                                                            />
                                                                            :
                                                                            <MDBIcon icon="sort-up" className="subtable-head-icon"
                                                                                onClick={() => this.handleHeaderSort(field.dataField)}
                                                                            />
                                                                        :
                                                                        <MDBIcon icon="sort" className="subtable-head-icon"
                                                                            onClick={() => this.handleHeaderSort(field.dataField, "desc")}
                                                                        />
                                                                    }
                                                                    {<SubtableDropdown
                                                                        dropdownClick={this.handleDropdownClick}
                                                                        dropdownKey={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-" + field.dataField.replace(/\s/g, "")}
                                                                        field={field}
                                                                        dataArray={subtable_data[field.dataField]}
                                                                        filterActive={subtable_search.filter(search_obj => search_obj.key === field.dataField).length}
                                                                    />}
                                                                </div>
                                                            </th>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            );
                                        }
                                        return null;
                                    })}
                                    {!edit ? null :
                                        <th className="border-left border-light subtable-buttons-field" />
                                    }
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {field_list_json.map(json_field => {
                                    if (json_field.hasOwnProperty(dataField)) {
                                        var table_row_id_field = getTableRowIDField(json_field[dataField]);
                                        const subtable = edit ? subtable : this.props.subtable;
                                        if (!subtable) {
                                            return null;
                                        }
                                        //console.log(subtable);
                                        const sorted_subtable = this.getSortedSubtable(subtable);
                                        //console.log(sorted_subtable);
                                        return (
                                            sorted_subtable.map((table_row, index) => {
                                                return (
                                                    <tr key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-subtable-row" + index}>
                                                        {json_field[dataField].map(field => {
                                                            if (!field.usage.includes("ID")) {
                                                                if (field.usage.includes("EDIT") && field.hasOwnProperty("edit_type") && table_row_id_field !== null) {
                                                                    if (edit) {
                                                                        return (
                                                                            <td key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-" + field.dataField.replace(/\s/g, "") + "-subtable"} className={field.edit_type === "_Checkbox" ? "border-left border-light" : "border-left border-light edit-selection"}>
                                                                                <EditField
                                                                                    item={item}
                                                                                    field={field}
                                                                                    fieldListField={fieldListField}
                                                                                    value={table_row[field.dataField]}
                                                                                    subtable_modal={{
                                                                                        "item_id": item[keyfield],
                                                                                        "subtable_row": table_row,
                                                                                        "subtable": sorted_subtable,
                                                                                        "subtable_row_id": table_row[table_row_id_field]
                                                                                    }}
                                                                                    setEditSubtable={this.setEditSubtable}
                                                                                    table_row_id_field={table_row_id_field}
                                                                                />
                                                                            </td>
                                                                        );
                                                                    }
                                                                }
                                                                if (field.edit_type === "_Checkbox") {
                                                                    var checked = !item[field.dataField] || item[field.dataField] === "0" || item[field.dataField] === "" ? false : true;
                                                                    if (checked) {
                                                                        return (
                                                                            <td key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-" + field.dataField.replace(/\s/g, "") + "-subtable"} className="border-left border-light">
                                                                                <MDBIcon icon="check" />
                                                                            </td>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <td key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-" + field.dataField.replace(/\s/g, "") + "-subtable"} className="border-left border-light">
                                                                            <MDBIcon icon="times" />
                                                                        </td>
                                                                    );
                                                                }
                                                                return (
                                                                    <td key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-" + field.dataField.replace(/\s/g, "") + "-subtable"} className="border-left border-light">
                                                                        {this.createField(table_row, field)}
                                                                    </td>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        {!edit ? null :
                                                            <td key={item_id + "-" + fieldListField.dataField.replace(/\s/g, "") + "-buttons-subtable"} className="border-left border-light">
                                                                {this.getButtons(item[keyfield], table_row, sorted_subtable, table_row[table_row_id_field], table_row_id_field)}
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            })
                                        );
                                    }
                                    return null;
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(SubTable);