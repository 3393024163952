import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBTooltip, MDBIcon } from "mdbreact";
import { translate, downloadNewFile, getFieldListFieldsByDataFields } from "../../utils/Utils";
import PropTypes from "prop-types";
import store from "../../store/store";
import { compare_list_limit, download_table_limit } from "../../utils/constants";
import "../../Home.css";
import { fetchProducts } from "../../utils/Webservice";
import ExcelButton from "../ExcelButton";

class MultiSelectConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadProducts: false,
            downloadFormat: "txt",
            fieldListFields: store.getState().portalConfig.field_list,
            downloadFields: store.getState().downloadTableModalProps.templates["default"].map(field => field.dataField),
            createEmptyXMLFields: false,
            isLoading: false,
            excelProducts: []
        };
        this.startDownload = this.startDownload.bind(this);
        this.downloadExcelFile = this.downloadExcelFile.bind(this);
    }

    closeModal() {
        if (!this.state.isLoading) {
            this.props.close(this.props.modalID);
        }
    }

    getBodyText() {
        const { hits, language } = store.getState();
        const { numberOfResults, type } = this.props;
        let type_text_object = {
            "download": hits < numberOfResults ? translate("would_you_like_to_add") + hits + translate("items") + translate("or_all") + translate("items") + translate("to_the") + translate("download_table") : (numberOfResults < 2 ? translate("would_you_like_to_add") + "1" + translate("item") + translate("to_the") + translate("download_table") : translate("would_you_like_to_add") + numberOfResults + translate("items") + translate("to_the") + translate("download_table")),
            "compare": hits < numberOfResults ? translate("would_you_like_to_add") + hits + translate("items") + translate("or_all") + translate("items") + translate("to_the") + translate("compare") : (numberOfResults < 2 ? translate("would_you_like_to_add") + "1" + translate("item") + translate("to_the") + translate("compare") : translate("would_you_like_to_add") + numberOfResults + translate("items") + translate("to_the") + translate("compare"))
        }
        if (language === "de") {
            type_text_object.download += " " + translate("add") + "?";
            type_text_object.compare += " " + translate("add") + "?";
        } else {
            type_text_object.download += "?";
            type_text_object.compare += "?";
        }
        return type_text_object[type];
    }

    handleAddAllButton() {
        const { numberOfResults, type } = this.props;
        const { downloadTableModalProps } = store.getState();
        const limit = type === "download" ? download_table_limit : compare_list_limit;
        if (numberOfResults > limit || (downloadTableModalProps.productIDs.length + numberOfResults) > limit) {
            this.setState({
                loadProducts: true
            });
        } else {
            this.props.action(true);
        }
    }

    changeDownloadFormat(value) {
        this.setState({
            downloadFormat: value
        });
    }

    deleteSelectField(delete_index) {
        let new_download_fields = [...this.state.downloadFields];
        new_download_fields.splice(delete_index, 1);
        this.setState({
            downloadFields: new_download_fields
        });
    }

    changeSelection(new_field, change_index) {
        let new_download_fields = [...this.state.downloadFields];
        new_download_fields[change_index] = new_field;
        this.setState({
            downloadFields: new_download_fields
        });
    }

    addSelection(new_field) {
        let new_download_fields = [...this.state.downloadFields];
        new_download_fields.push(new_field);
        this.setState({
            downloadFields: new_download_fields
        });
    }

    startDownload(products) {
        downloadNewFile(this.state.downloadFormat, this.state.createEmptyXMLFields, products, getFieldListFieldsByDataFields(this.state.downloadFields));
        this.setState({
            isLoading: false
        });
    }

    downloadExcelFile(products) {
        this.setState({
            excelProducts: products
        });
        setTimeout(() => {
            const excel_button = document.getElementById("multiselect-confirm-modal-excel-button");
            excel_button.click();
            this.setState({
                isLoading: false
            });
        }, 2000);
    }

    prepareDownload() {
        if (this.state.downloadFormat === "excel") {
            fetchProducts(this.downloadExcelFile);
        } else {
            fetchProducts(this.startDownload);
        }
        this.setState({
            isLoading: true
        });
    }

    handleAddHitsButton() {
        this.props.action();
    }

    getExtendedBodyText() {
        const { elastic_index } = store.getState().portalConfig;
        const download_all_text = "Die Anzahl der Produkte überschreitet das Limit von " + download_table_limit + " in der Download-Tabelle. Falls Sie die ausgewählten Produkte direkt herunterladen wollen, wählen Sie das Format und die erwünschten Felder aus:";
        const compare_all_text = "Die Anzahl der Produkte überschreitet das Limit von " + compare_list_limit + " in der Vergleichsliste.";
        switch (this.props.type) {
            case "download":
                //console.log(this.props.type);
                if (elastic_index.includes("_bios_") && this.state.downloadFormat === "txt") {
                    return "Die Anzahl der Produkte überschreitet das Limit von " + download_table_limit + " in der Download-Tabelle. Falls Sie die ausgewählten Produkte direkt herunterladen wollen, wählen Sie das Format aus.";
                }
                return download_all_text;
            case "compare":
                //console.log(this.props.type);
                return compare_all_text;
            default:
                console.log("Sorry, we are out of " + this.props.type + ".");
        }
    }

    render() {
        const { headerText, numberOfResults } = this.props;
        const { hits, portalConfig } = store.getState();
        const header_text = headerText ? headerText : "";
        const body_text = this.getBodyText();
        const extended_body_text = this.getExtendedBodyText();
        return (
            <MDBModal
                isOpen
                toggle={() => this.closeModal()}
                className={this.state.isLoading ? "cursor-wait" : ""}
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    {header_text}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer className="body">
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            {body_text}
                        </div>
                        <div style={{ justifyContent: "center", marginTop: "10px", marginBottom: "10px", display: "flex" }}>
                            {numberOfResults < hits ?
                                <button
                                    className="btn btn-sm"
                                    onClick={() => this.props.action()}
                                >
                                    {translate("yes")}
                                </button>
                                :
                                <div>
                                    <button
                                        className="btn btn-sm"
                                        disabled={this.state.isLoading}
                                        onClick={() => this.handleAddHitsButton()}
                                    >
                                        {hits}
                                    </button>
                                    <button
                                        className="btn btn-sm"
                                        onClick={() => this.handleAddAllButton()}
                                        disabled={this.state.loadProducts}
                                    >
                                        {translate("all")}
                                    </button>
                                </div>
                            }
                        </div>
                        {this.state.loadProducts ?
                            <div>
                                <div>
                                    {extended_body_text}
                                </div>
                                {this.props.type === "compare" ?
                                    <div style={{ justifyContent: "center", display: "flex" }}>
                                        <button
                                            className="btn btn-sm"
                                            onClick={() => this.closeModal()}
                                        >
                                            OK
                                        </button>
                                    </div>
                                    :
                                    <div>
                                        {portalConfig.elastic_index.includes("_bios_") && this.state.downloadFormat === "txt" ?
                                            null
                                            :
                                            <div className="download-selection-block" style={{ maxHeight: window.innerHeight * 0.35 }}>
                                                {this.state.downloadFields.map((download_field, index) => {
                                                    return (
                                                        <div className="download-selection-row">
                                                            <select
                                                                className="browser-default custom-select format-select"
                                                                onChange={event => this.changeSelection(event.target.value, index)}
                                                                value={download_field}
                                                                disabled={this.state.isLoading}
                                                            >
                                                                {this.state.fieldListFields.map(field => {
                                                                    return (
                                                                        <option
                                                                            value={field.dataField}
                                                                        >
                                                                            {field.text ? field.text : field.dataField}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                            <button
                                                                className="btn-xs article-button delete-button"
                                                                onClick={() => this.deleteSelectField(index)}
                                                                disabled={this.state.isLoading}
                                                            >
                                                                <MDBIcon icon="trash-alt" className="delete-icon" />
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                                <div className="download-selection-row">
                                                    <select
                                                        className="browser-default custom-select format-select"
                                                        onChange={event => this.addSelection(event.target.value)}
                                                        value="select"
                                                        disabled={this.state.isLoading}
                                                    >
                                                        <option
                                                            disabled
                                                            value="select"
                                                        >
                                                            {translate("select")}
                                                        </option>
                                                        {this.state.fieldListFields.map(field => {
                                                            return (
                                                                <option
                                                                    value={field.dataField}
                                                                >
                                                                    {field.text ? field.text : field.dataField}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <button
                                                        className="btn-xs article-button delete-button"
                                                        style={{ visibility: "hidden" }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <MDBRow style={{ justifyContent: "center", marginTop: "10px" }}>
                                            <label htmlFor="download_format_select" className={this.state.isLoading ? "cursor-wait" : ""} style={{ marginBottom: "auto", marginTop: "auto", marginRight: "5px" }}>
                                                Format:
                                            </label>
                                            <select
                                                className="browser-default custom-select format-select"
                                                style={{ marginTop: "4px" }}
                                                onChange={event => this.changeDownloadFormat(event.target.value)}
                                                value={this.state.downloadFormat}
                                                id="download_format_select"
                                                disabled={this.state.isLoading}
                                            >
                                                <option
                                                    value="txt"
                                                >
                                                    {portalConfig.elastic_index.includes("_bios_") ? "BIOS" : "TXT"}
                                                </option>
                                                <option
                                                    value="excel"
                                                >
                                                    Excel
                                                </option>
                                                <option
                                                    value="xml"
                                                >
                                                    XML
                                                </option>
                                            </select>
                                            {this.state.downloadFormat === "xml" ?
                                                <div
                                                    className="custom-switch"
                                                    style={{ marginTop: "10px", marginRight: "5px", marginLeft: "5px" }}
                                                >
                                                    <input
                                                        type='checkbox'
                                                        className="custom-control-input"
                                                        id="switch_xml_empty_fields"
                                                        checked={this.state.createEmptyXMLFields}
                                                        onChange={() => this.setState({ createEmptyXMLFields: !this.state.createEmptyXMLFields })}
                                                        readOnly
                                                        disabled={this.state.isLoading}
                                                    />
                                                    <label className={this.state.isLoading ? "custom-control-label cursor-wait" : "custom-control-label"} htmlFor="switch_xml_empty_fields">
                                                        {translate("show_empty_fields")}
                                                    </label>
                                                </div>
                                                :
                                                null
                                            }
                                            {this.state.isLoading ?
                                                <button
                                                    className="btn btn-sm"
                                                    disabled
                                                >
                                                    <MDBIcon icon="download" />
                                                </button>
                                                :
                                                <MDBTooltip
                                                    domElement
                                                    tag="span"
                                                    placement="top"
                                                >
                                                    <span>
                                                        <button
                                                            className="btn btn-sm"
                                                            disabled={this.state.isLoading}
                                                            onClick={() => this.prepareDownload()}
                                                        >
                                                            <MDBIcon icon="download" />
                                                        </button>
                                                    </span>
                                                    <span>{translate("download")}</span>
                                                </MDBTooltip>
                                            }
                                            {this.state.downloadFormat === "excel" ?
                                                <div style={{ display: "none" }}>
                                                    {<ExcelButton
                                                        buttonID="multiselect-confirm-modal-excel-button"
                                                        usage="template"
                                                        excelTableFields={getFieldListFieldsByDataFields(this.state.downloadFields)}
                                                        fetched_products={this.state.excelProducts}
                                                        downloadFetchedProducts
                                                    />}

                                                </div>
                                                :
                                                null
                                            }
                                        </MDBRow>
                                    </div>
                                }
                            </div>
                            :
                            null
                        }
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                        disabled={this.state.isLoading}
                    >
                        {"Abbrechen"}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

MultiSelectConfirmModal.propTypes = {
    modalID: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    numberOfResults: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired
};

export default MultiSelectConfirmModal;