import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import PortalApp from "./PortalApp";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <PortalApp />
    , document.getElementById('elasticform')
);

//registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();