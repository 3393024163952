import React, { Component } from "react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
const Range = Slider.createSliderWithTooltip(Slider.Range);

class SearchRange extends Component {
	constructor(props) {
        super(props);
        this.state = {
            
        };
        this.range = this.getRange(this.props.aggregations);
        this.marks = this.getMarks(this.props.aggregations);
    }

    getRange(aggregations) {
        let min = parseFloat(aggregations.values.buckets[0]["key"].replace(",", "."));
        let max = parseFloat(aggregations.values.buckets[0]["key"].replace(",", "."));
        for (let bucket of aggregations.values.buckets) {
            let value = parseFloat(bucket["key"].replace(",", "."));
            if (min > value) {
                min = value;
            }
            if (max < value) {
                max = value;
            }
        };
        /*aggregations.values.buckets.map(bucket => {
            let value = parseFloat(bucket["key"].replace(",", "."));
            if (min > value) {
                min = value;
            }
            if (max < value) {
                max = value;
            }
        });*/
        return {
            min: min,
            max: max
        };
    }

    handleChange(values) {
        this.props.setQuery({
            query: {
                terms: {
                    [this.props.dataField]: this.getQueryArray(values)
                }
            }
        });
    }

    getQueryArray(values) {
        let aggregationsArray = [];
        let sortedAggregationsArray = [];
        let queryArray = [];
        for (let bucket of this.props.aggregations.values.buckets) {
            aggregationsArray.push({
                key: parseFloat(bucket["key"].replace(",", ".")),
                value: bucket["key"]
            });
        };
        /*this.props.aggregations.values.buckets.map(bucket => {
            aggregationsArray.push({
                key: parseFloat(bucket["key"].replace(",", ".")),
                value: bucket["key"]
            })
        });*/
        sortedAggregationsArray = aggregationsArray.sort((a, b) => a["key"] - b["key"]); //.sort evtl. unnötig
        for (let field of sortedAggregationsArray) {
            if (field["key"] >= values[0] && field["key"] <= values[1]) {
                queryArray.push(field["value"]);
            }
        }
        /*sortedAggregationsArray.map(field => {
            if (field["key"] >= values[0] && field["key"] <= values[1]) {
                queryArray.push(field["value"]);
            }
        });*/
        queryArray.push("");
        return queryArray;
    }

    getMarks(aggregations) {
        let marks = {};
        for (let bucket of aggregations.values.buckets) {
            marks[parseFloat(bucket["key"].replace(",", "."))] = "";
        }
        /*aggregations.values.buckets.map(bucket => {
            marks[parseFloat(bucket["key"].replace(",", "."))] = "";
        });*/
        return marks;
    }

    render() {
        return (
            <Range 
                min={this.range["min"]}
                max={this.range["max"]}
                defaultValue={[this.range["min"], this.range["max"]]}
                step={null}
                marks={this.marks}
                onAfterChange={values => this.handleChange(values)}
            />
        );
    }
}

export default SearchRange;