import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBContainer, MDBTable, MDBTableHead,
    MDBTableBody
} from "mdbreact";
import { getCompareList, getProductByID, deepEqual, translate, checkPicture, getReferenceFiles, createHTMLElement, getField, getProductsByID } from "../../utils/Utils";
import "../../Home.css";
import { set_compare_modal, delete_compare_item, set_compare_items } from "../../actions";
import { checkAndLoadReferences, comparePosition } from "../../utils/functions";
import PicturePreview from "../PicturePreview";
import ReferencePicturePreview from "../ReferencePicturePreview";
import ArticleReferences from "../ArticleReferences";
import SubTable from "../SubTable";

var refs = {};

const mapStateToProps = (state) => {
    return {
        compareModal: state.compareModal,
        compareItems: state.compareItems,
        //selectFields: state.selectFields,
        fetchedProducts: state.fetchedProducts,
        portalConfig: state.portalConfig,
        isNewReferenceModal: state.isNewReferenceModal,
        loadedImages: state.loadedImages
    };
};

const mapDispatchToProps = () => {
    return {
        set_compare_modal,
        delete_compare_item,
        set_compare_items
    };
};

class CompareModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compare_switch: false,
            compare_fields: getCompareList(),
            content_fields: [],
            compare_ids: [],
            open_compare_modal: false
        };
        this.isMobileSize = window.innerWidth < parseInt(this.props.portalConfig.viewport_mobile);
    }

    componentDidMount() {
        const { compareItems } = this.props;
        //console.log(compareItems);
        if (compareItems.length) {
            this.changeCompareData();
        }
    }

    componentDidUpdate() {
        const { compareItems, compareModal } = this.props;
        if (!deepEqual(this.state.compare_ids, compareItems)) {
            //console.log("changeCompareData");
            this.changeCompareData(this.state.compare_switch);
        } else if (compareModal && !this.state.open_compare_modal && compareItems.length) {
            //console.log("load references");
            checkAndLoadReferences(getProductsByID(compareItems));
            this.setState({
                open_compare_modal: true
            });
        } else if (!compareModal && this.state.open_compare_modal) {
            //console.log("close modal");
            this.setState({
                open_compare_modal: false
            });
        }
    }

    getContentFields() {
        const { compareItems, fetchedProducts } = this.props;
        let content_fields = [];
        for (let item_id of compareItems) {
            const product = getProductByID(fetchedProducts, item_id);
            for (let field of this.state.compare_fields) {
                if (product[field.dataField] !== null && product[field.dataField] !== undefined) {
                    if (typeof (product[field.dataField]) === "string") {
                        if (product[field.dataField].trim().length > 0) {
                            if (!content_fields.includes(field)) {
                                content_fields.push(field);
                            }
                        }
                    } else {
                        if (!content_fields.includes(field)) {
                            content_fields.push(field);
                        }
                    }
                }
            }
        }
        content_fields.sort(comparePosition);
        return content_fields;
    }

    getCompareContentFields() {
        const { compareItems, fetchedProducts } = this.props;
        let compare_content_fields = [];
        for (let field of this.state.content_fields) {
            const product = getProductByID(fetchedProducts, compareItems[0]);
            const data = product[field.dataField];
            if (!this.checkEquality(data, field.dataField)) {
                if (!compare_content_fields.includes(field)) {
                    compare_content_fields.push(field);
                }
            }
        }
        compare_content_fields.sort(comparePosition);
        return compare_content_fields;
    }

    changeCompareData(compare_switch = false) {
        const { compareItems } = this.props;
        const content_fields = compare_switch ? this.getCompareContentFields() : this.getContentFields();
        //console.log(content_fields);
        this.setState({
            content_fields: content_fields,
            compare_ids: compareItems,
            compare_switch: compare_switch
        });
    }

    /**
     * Checks whether all products in the comparison list have the same product field value.
     * @param {*} data Product field value.
     * @param {*} field field_list object.
     */
    checkEquality(compare_data, dataField) {
        const compareItems = this.props.compareItems;
        for (var i = 1; i < compareItems.length; i++) {
            const product_data = getProductByID(this.props.fetchedProducts, compareItems[i])[dataField];
            if (!deepEqual(compare_data, product_data)) {
                return false;
            }
        }
        return true;
    }

    closeModal() {
        this.props.set_compare_modal();
    }

    createField(item, field) {
        const { portalConfig, isNewReferenceModal, loadedImages } = this.props;
        const { reference_user, reference_password, reference_url } = portalConfig;
        let value = item[field.dataField];
        if (field.dataField.includes(":REFERENCE")) {
            if (item[field.dataField]) {
                const product_data = item[field.dataField];
                const file_obj = JSON.parse(product_data)[0];
                const file_id = file_obj.TargetID;
                if (file_obj.TYPE.includes("DAM") && loadedImages.hasOwnProperty(file_id)) {
                    return (
                        <ReferencePicturePreview
                            fileID={file_id}
                            src={loadedImages[file_id]}
                        />
                    );
                }
            }
            const htmlElement = createHTMLElement(item, field.dataField, reference_user, reference_password, reference_url);
            refs[field.dataField] = htmlElement.arr;
            value = htmlElement.html.__html;
        }
        return getField(item, field, value, true, isNewReferenceModal);
    }

    getSubTableObject(field, item) {
        const { portalConfig } = this.props;
        return (
            <div>
                <SubTable
                    fieldListField={field}
                    item_id={item[portalConfig.keyfield]}
                    subtable={item[field.dataField] ? JSON.parse(item[field.dataField]) : []}
                />
            </div>
        );
    }

    removeAll() {
        this.props.set_compare_items([]);
        //this.props.set_compare_modal();
    }

    removeCompareItem(item_id) {
        const {compareItems} = this.props;
        if ((compareItems.length - 1) < 2) {
            if (!(compareItems.length - 1)) {
                this.props.set_compare_modal();
            }
            this.setState({
                compare_switch: false
            });
        }
        this.props.delete_compare_item(item_id);
    }

    render() {
        const { compareItems, portalConfig, fetchedProducts, compareModal } = this.props;
        const { remove_button_color, remove_button_text_color } = portalConfig.color_config;
        //console.log("CompareModal");
        return (
            <MDBModal
                fullHeight position="top"
                isOpen={compareModal}
                toggle={() => this.closeModal()}
                className="compare-modal"
            >
                <MDBModalHeader
                    toggle={() => this.closeModal()}
                >
                    {translate("compare", "compare")}
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer fluid>
                        <MDBRow>
                            <MDBCol size="12">
                                <div className='custom-control custom-switch'>
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        id='customSwitches'
                                        checked={this.state.compare_switch}
                                        onChange={() => this.changeCompareData(!this.state.compare_switch)}
                                        readOnly
                                        disabled={compareItems.length <= 1}
                                    />
                                    <label className='custom-control-label' htmlFor='customSwitches'>
                                        {translate("equality", "compare")}
                                    </label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        {compareItems.length ?
                            <MDBRow className="mt-2">
                                <MDBCol id="compare-modal-table-col" className="overflow-auto" style={{ maxHeight: (window.outerHeight - 350) + "px" }}>
                                    <MDBTable>
                                        <MDBTableHead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>
                                                    <button
                                                        className="btn btn-sm"
                                                        onClick={() => this.removeAll()}
                                                        style={{ backgroundColor: remove_button_color, color: remove_button_text_color }}
                                                    >
                                                        {translate("remove_all")}
                                                    </button>
                                                </th>
                                                {compareItems.map(item_id => {
                                                    return (
                                                        <th style={{ textAlign: "center" }} id={"compare-modal-" + item_id + "-table-head"}>
                                                            <button
                                                                className="btn btn-sm"
                                                                onClick={() => this.removeCompareItem(item_id)}
                                                                style={{ backgroundColor: remove_button_color, color: remove_button_text_color }}
                                                            >
                                                                {translate("remove")}
                                                            </button>
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {this.state.content_fields.map(field => (
                                                <tr className="table-row">
                                                    <td style={{ fontWeight: "bold" }}>
                                                        {field.text ? field.text : field.dataField}
                                                    </td>
                                                    {compareItems.map(item_id => {
                                                        const item = getProductByID(fetchedProducts, item_id);
                                                        const item_field = field.dataField.includes(":REFERENCE_FILE") ? field.dataField.substring(0, field.dataField.length - 15) : "";
                                                        return (
                                                            <td className="border-left border-light overflow-auto">
                                                                {field.dataField === portalConfig.image_field ?
                                                                    !portalConfig.image_field.includes(":") ?
                                                                        <PicturePreview
                                                                            item={item}
                                                                            view="compare"
                                                                        />
                                                                        :
                                                                        checkPicture(item)
                                                                    :
                                                                    field.dataField.includes(":JSON") ?
                                                                        null
                                                                        :
                                                                        <div className={this.isMobileSize ? "loaded-img-sm" : ""}>
                                                                            {field.dataField.includes(":REFERENCE_FILE") ?
                                                                                //this.getReferenceFiles(item_field)
                                                                                getReferenceFiles(item_field, item[item_field])
                                                                                :
                                                                                this.createField(item, field)
                                                                            }
                                                                        </div>
                                                                }
                                                                {refs[field.dataField] && refs[field.dataField].length ?
                                                                    <ArticleReferences
                                                                        references={refs[field.dataField]}
                                                                        field={field}
                                                                        referenceObjects={JSON.parse(item[field.dataField])}
                                                                        sourceProductID={item[portalConfig.keyfield]}
                                                                        notify={this.props.notify}
                                                                        isModal
                                                                    />
                                                                    :
                                                                    null
                                                                }
                                                                {typeof (field) === "object" && field.dataField.includes(":JSON") ?
                                                                    item[field.dataField] ?
                                                                        this.getSubTableObject(field, item)
                                                                        :
                                                                        null
                                                                    :
                                                                    null
                                                                }
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCol>
                            </MDBRow>
                            :
                            <div style={{display: "table", margin: "auto"}}>
                                {translate("no_products_available") + "."}
                            </div>
                        }

                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-sm"
                        onClick={() => this.closeModal()}
                    >
                        {translate("close")}
                    </button>
                </MDBModalFooter>
            </MDBModal>
        );
    }
}

CompareModal.propTypes = {
    notify: PropTypes.func.isRequired
    //close: PropTypes.func.isRequired,
    //fileName: PropTypes.string.isRequired,
    //src: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps())(CompareModal);