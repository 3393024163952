import React, { Component } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { MDBDatePicker, MDBIcon } from "mdbreact";
import { getMultiselectMaxcharByField, getNewCheckboxValue, getSelectFieldID, getSelectFieldsByID, getSelection, translate } from "../utils/Utils";
import "../Home.css";

class MultiEditField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select_fields: this.props.field.edit_type === "_Selection" ? getSelectFieldsByID(this.props.selectFields, this.props.field.edit_foreignkey_id) : [],
            value: ""
        }
        this.timeout = 0;
        this.maxLength = getMultiselectMaxcharByField(this.props.field);
    }

    changeText(value) {
        //console.log(value);
        this.setState({
            value: value
        });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            const { field } = this.props;
            this.props.setMultiEditObject(field.dataField, value, field.editField);
        }, 500);
    }

    getTextInputElement(type) {
        return (
            <input
                min={0}
                type={type}
                value={this.state.value}
                onChange={event => this.changeText(event.target.value)}
                maxLength={this.maxLength}
            />
        );
    }

    changeSelect(value) {
        const { field } = this.props;
        this.setState({
            value: value
        });
        this.props.setMultiEditObject(field.dataField, value, field.editField, field.filter === "MultiDataList" ? null : getSelection(this.state.select_fields, field.edit_foreignkey_id, value));
    }

    changeDate(dateObject, time) {
        if (typeof (dateObject) === "object" && dateObject !== null) {
            const { portalConfig, field } = this.props;
            let date_string = "";
            if (time) {
                let date = new Date(Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), dateObject.getHours(), dateObject.getMinutes(), dateObject.getSeconds()));
                date_string = date.toISOString();
            } else {
                let date = new Date(Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate()));
                let month = date.getUTCMonth() + 1;
                let day = date.getUTCDate();
                date_string = portalConfig.dateformat;
                if (month < 10) {
                    month = "0" + month;

                }
                if (day < 10) {
                    day = "0" + day;
                }
                date_string = date_string.replace("YYYY", date.getUTCFullYear()).replace("MM", month).replace("DD", day);
            }
            this.setState({
                value: date_string
            });
            this.props.setMultiEditObject(field.dataField, date_string, field.editField);
        }
    }

    changeMultiSelect(selectedList) {
        const { field, portalConfig } = this.props;
        const multiselect_separator = portalConfig.multiselect_separator;
        //console.log(selectedList);
        this.setState({
            value: selectedList
        });
        let value = [];
        let sourceValue = [];
        for (var i = 0; i < selectedList.length; i++) {
            const field = selectedList[i];
            if (multiselect_separator === "array") {
                value.push(field["value"].toString());
            } else {
                value += field["value"].toString();
                if (i < selectedList.length - 1) {
                    if (multiselect_separator === "\n") {
                        value += "X_enter_X";
                    } else {
                        value += multiselect_separator;
                    }
                }
            }
            sourceValue.push(this.props.field.filter === "MultiDataList" ? field["value"] : field["text"]);
        }
        /*selectedList.map((field, index) => {
            if (multiselect_separator === "array") {
                value.push(field["value"].toString());
            } else {
                value += field["value"].toString();
                if (index < selectedList.length - 1) {
                    if (multiselect_separator === "\n") {
                        value += "X_enter_X";
                    } else {
                        value += multiselect_separator;
                    }
                }
            }
            sourceValue.push(this.props.field.filter === "MultiDataList" ? field["value"] : field["text"]);
        });*/
        this.props.setMultiEditObject(field.dataField, value, field.editField, sourceValue.length ? sourceValue : null);
    }

    changeCheckbox() {
        const { field } = this.props;
        const value = this.state.value;
        const new_value = getNewCheckboxValue(value);
        this.setState({
            value: new_value
        });
        this.props.setMultiEditObject(field.dataField, new_value, field.editField);
    }

    render() {
        const { field, portalConfig } = this.props;
        var value = this.state.value;
        if (field.edit_type === "_SimpleText") {
            return this.getTextInputElement("text");
        } else if (field.edit_type === "_Selection") {
            if (this.state.select_fields.length === 0 || !field.edit_foreignkey_id) {
                return this.getTextInputElement("text");
            } else {
                if (field.multiselect_maxchar === "0") {
                    var value_id = getSelectFieldID(this.state.value, this.state.select_fields);
                    if (!value_id) {
                        value_id = "null";
                    }
                    return (
                        <select 
                            id="select_fields" 
                            onChange={event => this.changeSelect(event.target.value)}
                            value={value_id} 
                            className="browser-default custom-select"
                        >
                            <option disabled value="null">{translate("select_option")}</option>
                            <option value="deselect">{translate("deselect")}</option>
                            {this.state.select_fields.map(field => (
                                <option value={field[portalConfig.foreignkey_reference2changes]}>{field[portalConfig.foreignkey_show_field]}</option>
                            ))}
                        </select>
                    );
                } else if (field.multiselect_maxchar === "1") {
                    var options = [];
                    for (let field of this.state.select_fields) {
                        options.push({
                            text: field[portalConfig.foreignkey_show_field],
                            value: field[portalConfig.foreignkey_reference2changes]
                        });
                    }
                    /*this.state.select_fields.map(field => {
                        options.push({
                            text: field[portalConfig.foreignkey_show_field],
                            value: field[portalConfig.foreignkey_reference2changes]
                        });
                    });*/
                    var selectedValues = [];
                    if (value) {
                        if (Array.isArray(value)) {
                            for (let field of value) {
                                if (typeof(field) === "object") {
                                    selectedValues.push(field);
                                } else {
                                    selectedValues.push({
                                        text: field,
                                        value: getSelectFieldID(value, this.state.select_fields)
                                    });
                                }
                            }
                            /*value.map(field => {
                                if (typeof(field) === "object") {
                                    selectedValues.push(field);
                                } else {
                                    selectedValues.push({
                                        text: field,
                                        value: getSelectFieldID(value, this.state.select_fields)
                                    });
                                }
                            });*/
                        } else {
                            selectedValues = [{
                                text: value,
                                value: getSelectFieldID(value, this.state.select_fields)
                            }];
                        }
                    }
                    return (
                        <Multiselect
                            options={options} // Options to display in the dropdown
                            onSelect={selectedList => this.changeMultiSelect(selectedList)} // Function will trigger on select event
                            onRemove={selectedList => this.changeMultiSelect(selectedList)} // Function will trigger on remove event
                            displayValue="text" // Property name to display in the dropdown options
                            selectedValues={selectedValues} // Preselected value to persist in dropdown
                        />
                    );
                }
            }
        } else if (field.edit_type === "_Date") {
            var time = false;
            if (value) {
                if (value.trim().length >= 11) {
                    time = true;
                }
            }
            return (
                <MDBDatePicker 
                    value={value ? value : null}
                    getValue={e => this.changeDate(e, time)} 
                />
            );
        } else if (field.edit_type === "_Checkbox") {
            if (value && (value === true || value === "true" || value === "1" || value === 1)) {
                return (
                    <MDBIcon icon="check" size="lg" key={field.dataField} onClick={() => this.changeCheckbox()} className="checkbox-icon" />
                );
            }
            return (
                <MDBIcon far icon="square" size="lg" key={field.dataField} onClick={() => this.changeCheckbox()} className="checkbox-icon" />
            );
        } else if (field.edit_type === "_Currency") {
            return this.getTextInputElement("number");
        } else {
            return this.getTextInputElement("text");
        }
    }
}

export default MultiEditField;