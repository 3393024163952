const editCustomViewReducer = (state = false, action) => {
    switch(action.type) {
        case 'SWITCH_EDIT_CUSTOM_VIEW':
            state = !state;
            return state;
        default:
            return state;
    }
}

export default editCustomViewReducer;