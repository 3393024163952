const resultViewReducer = (state = "", action) => {
    switch(action.type) {
        case 'SET_RESULT_VIEW':
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default resultViewReducer;